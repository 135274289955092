// Listener for the events of the zoom-in and zoom-out of the map.
let listener;

const checkCoordinates = (lat, lng) => {
  // console.log("Checking coordinates for lat/lng", lat, lng);
  if (lat === 0 && lng === 0) {
    // console.log("Returning default coordinates");
    // console.trace("checkCoordinates");
    return { lat: -33.69119577, lng: -70.63170049 };
  }
  return { lat, lng };
};

const checkCoordinatesObject = (obj) => {
  // console.log("Checking coordinates for", obj);
  if (obj.lat === 0 && obj.lng === 0) {
    return { lat: -33.69119577, lng: -70.63170049 };
  }
  return obj;
};

let host = window.location.host;
if (host.match(/localhost/)) host = 'localhost:4000';

/**
 * Handy functions to project lat/lng to pixel
 * Extracted from: https://developers.google.com/maps/documentation/javascript/examples/map-coordinates
 **/
function project(latLng: google.maps.LatLngLiteral) {
  const TILE_SIZE = 256;
  let siny = Math.sin((latLng.lat * Math.PI) / 180);

  // Truncating to 0.9999 effectively limits latitude to 89.189. This is
  // about a third of a tile past the edge of the world tile.
  siny = Math.min(Math.max(siny, -0.9999), 0.9999);

  return new google.maps.Point(
    TILE_SIZE * (0.5 + latLng.lng / 360),
    TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI)),
  );
}

/**
 * Handy functions to project lat/lng to pixel
 * Extracted from: https://developers.google.com/maps/documentation/javascript/examples/map-coordinates
 **/
function getPixel(latLng: google.maps.LatLngLiteral, zoom: number) {
  const scale = 1 << zoom;
  const worldCoordinate = project(latLng);
  return new google.maps.Point(
    Math.floor(worldCoordinate.x * scale),
    Math.floor(worldCoordinate.y * scale),
  );
}

/**
 * Given a map, return the map dimension (width and height)
 * in pixels.
 **/
function getMapDimenInPixels(map: google.maps.Map) {
  const zoom = map.getZoom();
  const bounds = map.getBounds();
  const southWestPixel = getPixel(bounds.getSouthWest().toJSON(), zoom);
  const northEastPixel = getPixel(bounds.getNorthEast().toJSON(), zoom);
  return {
    width: Math.abs(southWestPixel.x - northEastPixel.x),
    height: Math.abs(southWestPixel.y - northEastPixel.y),
  };
}

/**
 * Given a map and a destLatLng returns true if calling
 * map.panTo(destLatLng) will be smoothly animated or false
 * otherwise.
 *
 * optionalZoomLevel can be optionally be provided and if so
 * returns true if map.panTo(destLatLng) would be smoothly animated
 * at optionalZoomLevel.
 **/
function willAnimatePanTo(
  map: google.maps.Map,
  destLatLng: google.maps.LatLngLiteral,
  optionalZoomLevel?: number,
) {
  const dimen = getMapDimenInPixels(map);
  const mapCenter = map.getCenter().toJSON();

  optionalZoomLevel = optionalZoomLevel ? optionalZoomLevel : map.getZoom();

  const destPixel = getPixel(destLatLng, optionalZoomLevel);
  const mapPixel = getPixel(mapCenter, optionalZoomLevel);
  const diffX = Math.abs(destPixel.x - mapPixel.x);
  const diffY = Math.abs(destPixel.y - mapPixel.y);

  return diffX < dimen.width && diffY < dimen.height;
}

/**
 * Returns the optimal zoom value when animating
 * the zoom out.
 *
 * The maximum change will be currentZoom - 3.
 * Changing the zoom with a difference greater than
 * 3 levels will cause the map to "jump" and not
 * smoothly animate.
 *
 * Unfortunately the magical number "3" was empirically
 * determined as we could not find any official docs
 * about it.
 **/
function getOptimalZoomOut(
  latLng: google.maps.LatLngLiteral,
  currentZoom: number,
  map: google.maps.Map,
) {
  if (willAnimatePanTo(map, latLng, currentZoom - 1)) return currentZoom - 1;
  if (willAnimatePanTo(map, latLng, currentZoom - 2)) return currentZoom - 2;
  return currentZoom - 3;
}

/**
 * Given a map and a destLatLng, smoothly animates the map center to
 * destLatLng by zooming out until distance (in pixels) between map center
 * and destLatLng are less than map width and height, then panTo to destLatLng
 * and finally animate to restore the initial zoom.
 *
 **/
function smoothlyAnimatePanToWorkarround(
  map: google.maps.Map,
  destLatLng: google.maps.LatLngLiteral,
  optionalZoomLevel?: number,
) {
  const finalZoom = optionalZoomLevel ? optionalZoomLevel : map.getZoom();

  function zoomIn() {
    if (map.getZoom() < finalZoom) {
      map.setZoom(Math.min(map.getZoom() + 3, finalZoom));
    } else {
      // We arrived at the final zoom, so we remove the listener
      google.maps.event.removeListener(listener);
      listener = null;

      //here we renable the ui controls of the map
      map.setOptions({
        draggable: true,
        zoomControl: true,
        scrollwheel: true,
        disableDoubleClickZoom: false,
      });
    }
  }

  function zoomOut() {
    if (willAnimatePanTo(map, destLatLng)) {
      google.maps.event.removeListener(listener);
      listener = google.maps.event.addListener(map, 'idle', zoomIn);
      map.panTo(destLatLng);
    } else {
      map.setZoom(getOptimalZoomOut(destLatLng, map.getZoom(), map));
    }
  }

  //here you should disable all the ui controls that your app uses
  const initialZoom = map.getZoom();
  map.setOptions({
    draggable: false,
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
  });
  map.setZoom(getOptimalZoomOut(destLatLng, initialZoom, map));
  listener = google.maps.event.addListener(map, 'idle', zoomOut);
}

/**
 * Smoothly pans to a latitude-longitude coordinate. If it's close. it only uses the google maps pan functions.
 * If it's too far away, it smoothly zooms out, pans then zoom in, to the given coordinate.
 * @param map google maps map
 * @param destLatLng the position to pan to
 * @param optionalZoomLevel the optional final zoom for the coordinate.
 */
function smoothlyAnimatePanTo(
  map: google.maps.Map,
  destLatLng: google.maps.LatLngLiteral,
  optionalZoomLevel?: number,
) {
  // Check if the listner is non-empty.
  if (listener) {
    google.maps.event.removeListener(listener);
    map.setOptions({
      draggable: true,
      zoomControl: true,
      scrollwheel: true,
      disableDoubleClickZoom: false,
    });
  }

  if (
    willAnimatePanTo(map, destLatLng, optionalZoomLevel) &&
    optionalZoomLevel === map.getZoom()
  ) {
    map.panTo(destLatLng);
  } else {
    smoothlyAnimatePanToWorkarround(map, destLatLng, optionalZoomLevel);
  }
}

export { checkCoordinates, checkCoordinatesObject, smoothlyAnimatePanTo };
