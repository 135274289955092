import { Dialog, Grid, TextField, Typography, DialogTitle, DialogContent } from '@mui/material';
import { useEffect, useState } from 'react';

// graphql
import SEND_ACCOUNT_CODE_EMAIL from '@features/recoveryPassword/graphql/mutations/sendPasswordRecoveryEmail';
import { useMutation } from '@apollo/client';

// components
import ActionButtons from '@components/ActionButtons';

// hooks
import { useModalStatusMessage } from 'src/hooks';
import { useLingui } from '@lingui/react';
import { Trans, msg } from '@lingui/macro';

interface Props {
  open: boolean;
  handleClose: () => void;
  emailValue?: string;
}

const RecoveryPassword = ({ open, handleClose, emailValue = '' }: Props) => {
  const { _ } = useLingui();
  const [email, setEmail] = useState(emailValue);
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const [sendEmail, { loading }] = useMutation(SEND_ACCOUNT_CODE_EMAIL, {
    onCompleted: () => {
      handleClose();
      openModalSuccessMessage(
        _(
          msg`Se le ha enviado un correo electrónico para cambiar la contraseña. (revisar correos de spam)`,
        ),
      );
    },
    onError: () => {
      openModalErrorMessage(
        _(msg`No se pudo enviar el correo electrónico. Verifique que el correo sea el correcto`),
      );
    },
  });

  useEffect(() => {
    setEmail(emailValue);
  }, [emailValue]);

  const sendEmailVerification = async () => {
    await sendEmail({
      variables: {
        input: {
          username: email,
        },
      },
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Trans>Recuperar contraseña</Trans>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item>
            <Typography>
              <Trans>Por favor ingrese su correo electrónico para recuperar su contraseña</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={_(msg`Correo electrónico`)}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <ActionButtons
              closeFunction={handleClose}
              actionFunction={sendEmailVerification}
              actionButtonText={_(msg`Enviar`)}
              loading={loading}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default RecoveryPassword;
