import { useEffect, useMemo, useState } from 'react';
import { enc, iv } from 'src/utils/encrypt';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  InputAdornment,
  IconButton,
  DialogActions,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch, useSelector } from 'src/store';
import useAuth from 'src/hooks/useAuth';
import { useModalStatusMessage, useUploadS3Images } from 'src/hooks';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import UPDATE_USER from 'src/graphql/mutations/updateUser';
import { validationSchema } from '../validation/validationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import FileInputController from 'src/components/FileInputController';
import generate from 'src/features/userSaveModal/utils/generatePassword';
import CountryCallingAutocomplete from 'src/features/countryCallingAutocomplete';
import { callingCode } from 'src/features/countryCallingAutocomplete/utils/callingCode';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { closeUpdateUserProfileModal } from '@features/userProfile/slices/userProfileSlice';
import { User } from 'src/types/user';
import GET_USER_PROFILE from 'src/features/userProfile/graphql/queries/getUserProfile';
import { LoadingButton } from '@mui/lab';
import DisableUserModal from './DisableUserModal';

interface UserFormData {
  id: number;
  name: string;
  lastnames: string;
  username: string;
  phone_number: string;
  code: CallingCode;
  password: string;
  confirmPassword: string;
  picture: File | string;
  verify_auth: number;
}

interface CallingCode {
  country: string;
  calling_code: string;
  abbreviation: string;
}

const getDefaultValues = (user: User | null) => {
  const defaultValues = {
    id: user?.id,
    name: user?.name ?? '',
    lastnames: user?.lastnames ?? '',
    username: user?.username ?? '',
    phone_number: user?.phone_number ?? '',
    code: callingCode?.find((code) => code?.calling_code === user?.calling_code),
    picture: user?.picture ?? '',
    verify_auth: user?.verify_auth ?? 0,
    password: '',
    confirmPassword: '',
  };
  return defaultValues;
};

const UPLOAD_BUCKET = 'amaru-user';

const UserProfileUpdateModal = () => {
  const { _ } = useLingui();
  const dispatch = useDispatch();
  const { user, updateUser: updateUserRedux } = useAuth();
  const { updateUserProfileModal } = useSelector((state) => state.user_profile_store);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const { uploadS3Images } = useUploadS3Images(UPLOAD_BUCKET);
  const { openModalSuccessMessage, openModalErrorMessage } = useModalStatusMessage();
  const [openDisableUserModal, setOpenDisableUserModal] = useState(false);

  const resolver = useMemo(
    () => yupResolver(validationSchema(showUpdatePassword)),
    [showUpdatePassword],
  );

  const [updateUser, { reset: updateReset }] = useMutation(UPDATE_USER, {
    onCompleted(dataUpdate) {
      updateReset();
      if (showUpdatePassword) {
        openModalSuccessMessage(_(msg`Contraseña actualizada correctamente`));
      } else {
        openModalSuccessMessage(_(msg`Perfil actualizado correctamente`));
      }
      const response = dataUpdate?.updateUser;
      updateUserRedux({
        ...response,
        phone: `${response?.calling_code}${response?.phone_number}`,
      });

      handleClose();
    },
    onError() {
      openModalErrorMessage(_(msg`Error al actualizar el perfil`));
    },
    refetchQueries: [GET_USER_PROFILE],
  });

  useEffect(() => {
    if (!user) return;
    reset(getDefaultValues(user));
  }, [user]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    getValues,
    watch,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<UserFormData>({
    defaultValues: getDefaultValues(user),
    resolver,
  });

  const handleClose = () => {
    const defaultValues = getDefaultValues(user);
    reset(defaultValues);
    dispatch(closeUpdateUserProfileModal());
  };

  const handleCancelClick = () => {
    if (showUpdatePassword) {
      setShowUpdatePassword(false);
    } else {
      handleClose();
    }
  };

  const handleDisableUserClick = () => {
    setOpenDisableUserModal(true);
  };

  const submitHandler = async (formData: UserFormData) => {
    const input: any = {
      ...formData,
      calling_code: formData?.code?.calling_code,
      iv: undefined,
    };

    delete input.code;
    delete input.id;

    if (formData?.picture instanceof File) {
      input.picture = await uploadS3Images(formData.picture as File);
    }

    if (formData.password) {
      input.password = enc(formData.password);
      input.iv = iv.toString('hex');
    }

    try {
      await updateUser({
        variables: {
          userId: formData.id,
          ...input,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const generatePassword = () => {
    const password = generate({ length: 12 });
    setValue('password', password);
    setValue('confirmPassword', password);
    clearErrors('password');
    clearErrors('confirmPassword');
  };

  if (!user) return null;

  return (
    <>
      <DisableUserModal
        userId={user.id}
        open={openDisableUserModal}
        onClose={() => setOpenDisableUserModal(false)}
      />
      <Dialog open={updateUserProfileModal.open} onClose={handleClose} maxWidth="sm" fullWidth>
        <form onSubmit={handleSubmit(submitHandler)}>
          <DialogTitle>
            <Trans>Editar perfil</Trans>
          </DialogTitle>
          <DialogContent
            dividers
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            {!showUpdatePassword && (
              <>
                <FileInputController
                  name="picture"
                  control={control}
                  label={_(msg`Foto de perfil`)}
                />
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={_(msg`Nombre`)}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                    />
                  )}
                />

                <Controller
                  name="lastnames"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={_(msg`Apellidos`)}
                      error={!!errors.lastnames}
                      helperText={errors.lastnames?.message}
                    />
                  )}
                />

                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onChange={(event) => {
                        event.target.value = event.target.value.toLowerCase().trim();
                        field.onChange(event);
                      }}
                      label={_(msg`Correo electrónico`)}
                      error={!!errors.username}
                      helperText={errors.username?.message}
                    />
                  )}
                />

                <Box display="flex" gap={2} flexDirection="row">
                  <Controller
                    name="code"
                    control={control}
                    render={({ field }) => (
                      <CountryCallingAutocomplete
                        {...field}
                        sx={{ minWidth: '160px' }}
                        getOptionDisabled={(option) => {
                          const { code } = getValues();
                          return option?.calling_code === code?.calling_code;
                        }}
                        error={!!errors.code}
                        helperText={errors.code?.message}
                      />
                    )}
                  />
                  <Controller
                    name="phone_number"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        sx={{ flexGrow: 1 }}
                        label={_(msg`Teléfono`)}
                        error={!!errors.phone_number}
                        helperText={errors.phone_number?.message}
                      />
                    )}
                  />
                </Box>
                <Controller
                  name="verify_auth"
                  control={control}
                  render={({ field }) => (
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox {...field} checked={Boolean(field.value)} />}
                        label={_(msg`Activar autenticación en dos pasos`)}
                      />
                    </FormGroup>
                  )}
                />

                <Box>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => setShowUpdatePassword(true)}
                  >
                    <Trans>Cambiar contraseña</Trans>
                  </Button>
                </Box>
              </>
            )}
            {showUpdatePassword && (
              <>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={_(msg`Contraseña`)}
                      type={showPassword ? 'text' : 'password'}
                      error={!!errors.password}
                      autoComplete="new-password"
                      helperText={errors.password?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button onClick={generatePassword}>Generar</Button>
                            <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />

                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={_(msg`Confirmar contraseña`)}
                      type={showConfirmPassword ? 'text' : 'password'}
                      error={!!errors.confirmPassword}
                      helperText={errors.confirmPassword?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            >
                              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            {!showUpdatePassword && (
              <Button onClick={handleDisableUserClick} color="error" variant="contained">
                <Trans>Deshabilitar cuenta</Trans>
              </Button>
            )}
            <Button onClick={handleCancelClick} color="info" variant="outlined">
              <Trans>Cancelar</Trans>
            </Button>
            <LoadingButton
              disabled={!isDirty || (showUpdatePassword && watch('password') === '')}
              loading={isSubmitting}
              color="success"
              type="submit"
              variant="contained"
            >
              <Trans>Guardar</Trans>
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default UserProfileUpdateModal;
