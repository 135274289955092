import { gql } from '@/__generated__';

const GET_DGA_TICKET_STATE = gql(`#graphql
  query GetDgaTicketState($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      profile {
        name
      }
      dgaTicket {
        id
        timestamp
      }
    }
  }
`);

export default GET_DGA_TICKET_STATE;
