import { gql } from '@/__generated__';

const UPDATE_ORGANIZATION_PREFERENCES = gql(`#graphql
  mutation UpdateOrganizationPreferences($input: UpdateOrganizationPreferencesInput!) {
    updateOrganizationPreferences(input: $input) {
      id
      hour12
    }
  }
`);

export default UPDATE_ORGANIZATION_PREFERENCES;
