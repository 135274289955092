import { DgaFrequency } from '@/__generated__/graphql';
import { useFormatters } from '@/hooks/useFormatters';
import { useSelector } from '@/store';
import { useSuspenseQuery } from '@apollo/client';
import ModalActions from '@components/modal/ModalActions';
import ModalTitle from '@components/modal/ModalTitle';
import { DGA_REPORTS_TABLE_GET_DEVICE } from '@features/dga/graphql/queries/getDGAReportsForTable';
import { setDGAReportsChartModal } from '@features/dga/slices/dgaSlice';
import { getHourlyChartOption, getCalendarChartOption } from '@features/dga/utils/chartOptions';
import Echarts from '@features/echarts';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Dialog, DialogContent, Skeleton } from '@mui/material';
import { Suspense, useMemo, type FC } from 'react';
import { useDispatch } from 'react-redux';

interface Props {
  deviceId: string;
  onClose: () => void;
}

const Component: FC<Props> = ({ deviceId, onClose }) => {
  const { i18n, _ } = useLingui();
  const formatters = useFormatters();
  const { data } = useSuspenseQuery(DGA_REPORTS_TABLE_GET_DEVICE, {
    variables: {
      deviceId,
    },
  });

  const option = useMemo(() => {
    if (!data.device.dga || !data.device.dgaConfiguration) return {};
    const reports = data.device.dga.reports.items;
    const { type, frequency } = data.device.dgaConfiguration;
    if (frequency === DgaFrequency.Hourly)
      return getHourlyChartOption(reports, type, formatters, i18n);
    if (frequency === DgaFrequency.Daily)
      return getCalendarChartOption(reports, type, formatters, i18n, 3, 'horizontal');
    if (frequency === DgaFrequency.Weekly)
      return getCalendarChartOption(reports, type, formatters, i18n, 12, 'vertical');
    if (frequency === DgaFrequency.Monthly)
      return getCalendarChartOption(reports, type, formatters, i18n, 12, 'vertical');
    return null;
  }, [data, formatters, i18n]);

  const frequency = data.device.dgaConfiguration?.frequency;

  const frequencyPropertiesMap = {
    [DgaFrequency.Hourly]: {
      subtitle: _(msg`Frecuencia de reporte cada una hora`),
      height: 500,
    },
    [DgaFrequency.Daily]: {
      subtitle: _(msg`Frecuencia de reporte diaria`),
      height: 300,
    },
    [DgaFrequency.Weekly]: {
      subtitle: _(msg`Frecuencia de reporte semanal`),
      height: 500,
    },
    [DgaFrequency.Monthly]: {
      subtitle: _(msg`Frecuencia de reporte mensual`),
      height: 500,
    },
  };

  const { subtitle, height } = (frequency && frequencyPropertiesMap[frequency]) || {
    subtitle: undefined,
    height: 500,
  };

  return (
    <>
      <ModalTitle
        onClose={onClose}
        title={_(msg`Gráfico de reportes DGA ${data.device.profile.name}`)}
        subtitle={subtitle}
      />
      <DialogContent dividers>
        <Box height={height} overflow="hidden">
          {option && <Echarts option={option} />}
        </Box>
      </DialogContent>
    </>
  );
};

const Fallback: FC<{ onClose: () => void }> = ({ onClose }) => (
  <>
    <ModalTitle
      onClose={onClose}
      title={
        <Box display="flex" gap={1}>
          <Trans>Gráfico de reportes DGA</Trans> <Skeleton variant="text" width={200} />
        </Box>
      }
    />
    <DialogContent dividers>
      <Skeleton variant="rounded" height={500} />
    </DialogContent>
  </>
);

const DGAReportsChartModal: FC = () => {
  const dispatch = useDispatch();
  const { reportsChartModal } = useSelector((state) => state.dga_store);

  const onClose = () => {
    dispatch(
      setDGAReportsChartModal({
        open: false,
      }),
    );
  };

  return (
    <Dialog onClose={onClose} open={reportsChartModal.open} maxWidth="md" fullWidth>
      <Suspense fallback={<Fallback onClose={onClose} />}>
        {reportsChartModal.open && (
          <Component onClose={onClose} deviceId={reportsChartModal.deviceId} />
        )}
      </Suspense>
      <ModalActions onClose={onClose} />
    </Dialog>
  );
};

export default DGAReportsChartModal;
