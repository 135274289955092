import { gql } from '@/__generated__';

const GET_DEVICE_DATA = gql(`#graphql
  query MathModeling_GetDeviceData($input: DeviceInput!) {
    device(input: $input) {
      id
      frankieSensors {
        id
        deviceId
        messageType
        fields {
          accessor
          name
        }
      }
      dataConfiguration {
        deviceId
        mathModeling {
          flowModel {
            expression
          }
        }
      }
    }
  }
`);

export default GET_DEVICE_DATA;
