import echarts from 'echarts';
import { theme } from '@/theme';

export interface EChartsInitOptions {
  xAxis?: echarts.XAXisComponentOption | echarts.XAXisComponentOption[];
  tooltip?: echarts.TooltipComponentOption;
  toolbox?: {
    disabled?: boolean;
  };
  brush?: {
    horizontal?: boolean;
    vertical?: boolean;
  };
  grid?: {
    variant?: 'simple' | 'double';
  };
  override?: echarts.EChartsOption | ((option: echarts.EChartsOption) => echarts.EChartsOption);
}

const enum Color {
  ToolboxIcon = '#888',
  ToolboxIconActive = '#eee',
}

export const defaultTooltip: echarts.TooltipComponentOption = {
  confine: true,
  trigger: 'axis',
  axisPointer: {
    crossStyle: {
      color: '#999',
    },
    animation: false,
  },
  backgroundColor: '#222b36',
  textStyle: {
    color: 'white',
  },
};

export const defaultLegend: echarts.LegendComponentOption = {
  type: 'scroll',
  left: 0,
  width: '80%',
  pageIconColor: theme.palette.info.main,
  pageIconInactiveColor: theme.palette.divider,
  pageTextStyle: {
    color: theme.palette.text.primary,
  },
  textStyle: {
    fontWeight: 'normal',
    fontSize: '14px',
    color: 'white',
  },
  itemWidth: 16,
  itemHeight: 12,
};

export const defaultTextStyle = {
  fontFamily: 'Barlow',
  fontSize: 16,
} as const;

export const createBaseOptionEChart = (
  initOptions: EChartsInitOptions = {},
): echarts.EChartsOption => {
  const { xAxis, brush, tooltip, grid, override } = initOptions;

  const option: echarts.EChartsOption = {
    tooltip: tooltip ?? defaultTooltip,
    grid:
      grid?.variant === 'double'
        ? [
            {
              id: 'top',
              left: 2,
              bottom: brush?.horizontal ? 158 : 102,
              right: brush?.vertical ? 52 : 2,
              containLabel: true,
            },
            {
              id: 'bottom',
              left: 2,
              right: brush?.vertical ? 52 : 2,
              height: 52,
              bottom: brush?.horizontal ? 78 : 22,
              containLabel: true,
            },
          ]
        : {
            left: 2,
            bottom: brush?.horizontal ? 78 : 22,
            right: brush?.vertical ? 52 : 2,
            containLabel: true,
          },
    axisPointer: {
      link: [
        {
          xAxisIndex: 'all',
        },
      ],
      label: {
        backgroundColor: '#303b49',
      },
    },
    legend: defaultLegend,
    textStyle: defaultTextStyle,
    toolbox: initOptions.toolbox?.disabled
      ? {}
      : {
          itemSize: 20,
          itemGap: 15,
          feature: {
            dataZoom: {
              yAxisIndex: 'none',
              icon: {
                zoom: 'M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14m.5-7H9v2H7v1h2v2h1v-2h2V9h-2z',
                back: 'm15 3 2.3 2.3-2.89 2.87 1.42 1.42L18.7 6.7 21 9V3zM3 9l2.3-2.3 2.87 2.89 1.42-1.42L6.7 5.3 9 3H3zm6 12-2.3-2.3 2.89-2.87-1.42-1.42L5.3 17.3 3 15v6zm12-6-2.3 2.3-2.87-2.89-1.42 1.42 2.89 2.87L15 21h6z',
              },
              iconStyle: {
                color: Color.ToolboxIcon,
                borderWidth: 0,
              },
              emphasis: {
                iconStyle: {
                  color: Color.ToolboxIconActive,
                },
              },
            },
            saveAsImage: {
              icon: 'm20.5,24H3.5c-1.93,0-3.5-1.57-3.5-3.5V3.5C0,1.57,1.57,0,3.5,0h17c1.93,0,3.5,1.57,3.5,3.5v17c0,1.93-1.57,3.5-3.5,3.5ZM3.5,2c-.83,0-1.5.67-1.5,1.5v17c0,.83.67,1.5,1.5,1.5h17c.83,0,1.5-.67,1.5-1.5V3.5c0-.83-.67-1.5-1.5-1.5H3.5Z M16.71,10.29c-.39-.39-1.02-.39-1.41,0l-2.29,2.29v-7.59c0-.55-.45-1-1-1s-1,.45-1,1v7.59l-2.29-2.29c-.39-.39-1.02-.39-1.41,0s-.39,1.02,0,1.41l4,4c.09.09.2.17.33.22.12.05.25.08.38.08s.26-.03.38-.08c.12-.05.23-.12.33-.22l4-4c.39-.39.39-1.02,0-1.41Z M18.5,20H5.5c-.55,0-1-.45-1-1s.45-1,1-1h13c.55,0,1,.45,1,1s-.45,1-1,1Z',
              backgroundColor: 'black',
              connectedBackgroundColor: 'red',
              type: 'png',
              excludeComponents: ['toolbox', 'brush'],
              iconStyle: {
                color: Color.ToolboxIcon,
                borderWidth: 0,
              },
              emphasis: {
                iconStyle: {
                  color: Color.ToolboxIconActive,
                },
              },
            },
          },
        },

    xAxis: xAxis ?? {
      type: 'time',
      axisPointer: {
        type: 'shadow',
        show: true,
        snap: true,
        lineStyle: {
          color: 'grey',
        },
      },
      axisLabel: {
        formatter: {
          year: '{yyyy}',
          month: '{MMM}',
          day: '{d}. {MMM}',
          hour: '{HH}:{mm}',
          minute: '{HH}:{mm}',
          second: '{HH}:{mm}:{ss}',
          millisecond: '{HH}:{mm}:{ss} {SSS}',
        },
      },
      splitLine: {
        show: false,
      },
    },
    dataZoom: [
      {
        type: 'slider',
        show: !!brush?.vertical,
        orient: 'vertical',
        start: 0,
        end: 100,
        filterMode: 'none',
        textStyle: {
          backgroundColor: '#171c24',
          borderRadius: 4,
          padding: 4,
        },
      },
      {
        type: 'slider',
        show: !!brush?.horizontal,
        orient: 'horizontal',
        start: 0,
        end: 100,
        filterMode: 'none',
        xAxisIndex: [0, 1, 2, 3],
        textStyle: {
          backgroundColor: '#171c24',
          borderRadius: 4,
          padding: 4,
        },
      },
      {
        type: 'inside',
        xAxisIndex: [0, 1, 2, 3],
        start: 0,
        end: 100,
      },
    ],
  };
  return override ? (typeof override === 'function' ? override(option) : override) : option;
};
