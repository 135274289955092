import { MetricUnit } from '@/__generated__/graphql';

type CartesianPosition = 'left' | 'right' | 'top' | 'bottom';

const OFFSET = 60;
const MAX_Y_AXIS = 4;

const Y_AXIS_PRIORITY_LIST = [
  'flow',
  MetricUnit.LiterPerSecond,
  MetricUnit.CubicMeterPerSecond,
  MetricUnit.CubicMeterPerHour,
  MetricUnit.Centimeter,
  'level',
  MetricUnit.Millimeter,
  MetricUnit.Meter,
  MetricUnit.CubicMeter,
  MetricUnit.Liter,
  MetricUnit.MillimeterPerSecond,
  MetricUnit.CentimeterPerSecond,
  MetricUnit.Degree,
  MetricUnit.Fahrenheit,
  MetricUnit.Volt,
];

const defaultLeftYAxisIds = [
  MetricUnit.Centimeter,
  MetricUnit.Millimeter,
  MetricUnit.Meter,
  MetricUnit.Degree,
  MetricUnit.Fahrenheit,
  MetricUnit.Volt,
  'level',
  'angle',
  'voltage',
];

export const generateYAxisPositions = (
  yAxisPositions: {
    id: string;
    position?: CartesianPosition | undefined | null;
    gridIndex: number;
    show: boolean;
  }[],
  includedSeries: string[],
): { id: string; position: CartesianPosition; offset: number; show: boolean }[] => {
  const recurrencePerGridIndex = new Map<number, Record<string, number>>([
    [0, { left: 0, right: 0 }],
  ]);

  yAxisPositions = yAxisPositions
    .map((axis, index) => ({
      ...axis,
      originalIndex: index,
    }))
    .sort((a, b) => {
      const aIndex = Y_AXIS_PRIORITY_LIST.indexOf(a.id as MetricUnit);
      const bIndex = Y_AXIS_PRIORITY_LIST.indexOf(b.id as MetricUnit);
      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });

  return yAxisPositions.map((yAxisPosition) => {
    const result = {
      id: yAxisPosition.id,
      position: 'right' as CartesianPosition,
      offset: 0,
      show: false,
    };
    if (!includedSeries.includes(yAxisPosition.id) || !yAxisPosition.show) return { ...result };
    const gridIndex = yAxisPosition.gridIndex;
    if (!recurrencePerGridIndex.has(gridIndex)) {
      recurrencePerGridIndex.set(gridIndex, { left: 0, right: 0 });
    }
    const recurrence = recurrencePerGridIndex.get(gridIndex)!;
    if (yAxisPosition.position) {
      if (recurrence[yAxisPosition.position] >= MAX_Y_AXIS) {
        return {
          ...result,
          position: yAxisPosition.position,
          offset: 0,
          show: false,
        };
      }
      return {
        ...result,
        position: yAxisPosition.position,
        offset: recurrence[yAxisPosition.position]++ * OFFSET,
        show: true,
      };
    } else {
      const position = defaultLeftYAxisIds.find((leftId) => leftId === yAxisPosition.id)
        ? 'left'
        : 'right';
      if (recurrence[position] >= MAX_Y_AXIS) {
        return {
          ...result,
          position: position,
          offset: 0,
          show: false,
        };
      }
      return {
        ...result,
        position,
        offset: recurrence[position]++ * OFFSET,
        show: true,
      };
    }
  });
};
