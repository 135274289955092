import { useApolloClient, useMutation } from '@apollo/client';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { get, isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { DeviceDialogProps } from 'src/components/optionsMenu/dialogInterfaces';
import UPDATE_STATION from 'src/features/stationUpdate/graphql/mutations/updateStation';
import CREATE_GATE_LINKED_STATION from 'src/graphql/mutations/sira/createGateLinkedStation';
import UPDATE_GATE from 'src/graphql/mutations/updateGate';
import FETCH_GATES from 'src/graphql/querys/fetchGates';
import { useAuth, useOrganizations, useModalStatusMessage, useIrrigations, useDeviceNaming } from 'src/hooks/';
import { setGates as setAllGates } from 'src/slices/gates';
import { setCoordinates } from 'src/slices/stations';
import { useDispatch, useSelector } from 'src/store';
import { Gate } from 'src/types/gates/gate';
import { checkCoordinatesObject } from 'src/utils/mapUtils';
import { Loading } from './Loading';
import MapWrapper from './Map';
import { TypeFilter } from 'src/components/filters';
import { isGate } from '../utils/deviceUtils';
import { Irrigation, Organization } from 'src/__generated__/graphql';

const defaultCoordinate = {
  lat: -33.69119577,
  lng: -70.63170049,
};

const UpdateStationComponent = ({ show, handleClose, device }: DeviceDialogProps) => {
  const auth = useAuth();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const { organizations } = useOrganizations();

  const { types: typesByUser } = useDeviceNaming(device?.serie);

  const { gates } = useSelector((state) => state.gates_store);

  const dispatch = useDispatch();

  const { lat, lng } = useSelector((state) => state.stations_store);

  const client = useApolloClient();

  const [callUpdateStation, { loading: loadingUpdateStation }] = useMutation(UPDATE_STATION, {
    onCompleted: (data) => {
      if (data?.UpdateStation?.success) {
        openModalSuccessMessage('Dispositivo actualizado exitosamente.');
        handleInternalClose(true);
      } else {
        openModalErrorMessage('Algo salió mal, por favor intente nuevamente.');
      }
    },
  });

  const { irrigations } = useIrrigations();

  const [name, setName] = useState('');
  const [deviceType, setDeviceType] = useState('');
  const [defaultCenter, setDefaultCenter] = useState({ lat, lng });
  const [defaultLocation, setDefaultLocation] = useState({ lat, lng });
  const [searchValue, setSearchValue] = useState('');
  const [valueStation, setValueStation] = useState(null);
  const [gateMin, setGateMin] = useState('');
  const [gateMax, setGateMax] = useState('');

  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
  const [loading, setLoading] = useState(false);

  const [selectedIrrigation, setSelectedIrrigation] = useState<Irrigation | null>(null);

  const [showUpdateCoordinates, setShowUpdateCoordinates] = useState<boolean>(false);

  const selectType = typesByUser.find((t) => t.type === deviceType) ?? { familyType: 0, type:deviceType };
  const updateGate = async () => {
    setLoading(true);
    const parentComponentId = device.parent_component_id;
    const variables = {
      name: name ? name : null,
      longitude: lng ? lng.toString() : null,
      latitude: lat ? lat.toString() : null,
      irrigationId: get(selectedIrrigation, 'id', null),
      organizationId: get(selectedOrganization, 'id', null),
      gateMin: !isNil(gateMin) && gateMin.length > 0 ? parseFloat(gateMin) : null,
      gateMax: !isNil(gateMax) && gateMax.length > 0 ? parseFloat(gateMax) : null,
      parentComponentId,
      topic_version: null,
    };
    const success = get(
      await client.mutate({
        mutation: UPDATE_GATE,
        variables,
      }),
      'data.UpdateGate.success',
      false,
    );
    if (success) {
      openModalSuccessMessage('Compuerta actualizada exitosamente.');
      handleInternalClose(true);
    } else {
      openModalErrorMessage('Algo salió mal, por favor intente nuevamente.');
    }
    setLoading(false);
  };

  const updateDevice = async () => {
    const componentId = device?.parent_component_id ?? null;
    if (isNil(selectedOrganization?.id ?? null)) {
      openModalErrorMessage('Debes seleccionar una organizacion.');
      return;
    }
    if (isNil(selectedIrrigation?.id ?? null)) {
      openModalErrorMessage('Debes seleccionar una red de canal.');
      return;
    }

    const variables = {
      componentId,
      name: name ? name : null,
      type: deviceType ?? null,
      longitude: lng ? lng.toString() : null,
      latitude: lat ? lat.toString() : null,
      irrigationChannelNetworkId: selectedIrrigation?.id,
      organizationId: selectedOrganization?.id,
    };
    callUpdateStation({ variables });
  };

  const updateStation = async () => {
    try {
      if (isGate(device)) {
        updateGate();
      } else {
        updateDevice();
      }
    } catch (error) {
      openModalErrorMessage('Error al actualizar el dispositivo.');
    }
  };

  useEffect(() => {
    setSelectedIrrigation(irrigations?.find((t) => t.id === device?.icn_id));
  }, [irrigations]);

  useEffect(() => {
    if (device) {
      if (organizations) {
        const organizationStation = organizations.filter(
          (t) => t.name === device.organization_name,
        )[0];
        setSelectedOrganization(organizationStation ? organizationStation : {});
      }
      setName(device && device.name ? device.name : '');
      setDeviceType(device && device.type ? device.type : '');
    }
  }, [device, show, organizations]);

  const handleResetLocation = () => {
    dispatch(setCoordinates(defaultLocation));
    setDefaultCenter(defaultLocation);
    // setZoomState(15);
  };

  const handleChangeLocation = (latitude, longitude) => {
    dispatch(
      setCoordinates({
        lat: typeof latitude === 'string' ? parseFloat(latitude) : latitude,
        lng: typeof longitude === 'string' ? parseFloat(longitude) : longitude,
      }),
    );
    if (latitude && longitude && parseFloat(latitude) && parseFloat(longitude)) {
      setDefaultCenter({
        lat: typeof latitude === 'string' ? parseFloat(latitude) : latitude,
        lng: typeof longitude === 'string' ? parseFloat(longitude) : longitude,
      });
    }
  };

  const handleSearchLocation = () => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${searchValue}&key=${
        import.meta.env.VITE_REACT_APP_MAP_API_KEY ?? ''
      }`,
    )
      .then((response) => response.json())
      .then((d) => {
        const { results } = d;
        if (results && results.length > 0) {
          const { geometry } = results[0];
          if (geometry && geometry.location) {
            const { lat: fetchedLat, lng: fetchedLng } = geometry.location;
            handleChangeLocation(fetchedLat, fetchedLng);
            dispatch(
              setCoordinates({
                lat: fetchedLat,
                lng: fetchedLng,
              }),
            );
            setDefaultCenter({
              lat: fetchedLat,
              lng: fetchedLng,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLatitudeAndLongitude = async () => {
    const { latitude, longitude } = device.profile.location ?? {};
    if (latitude && longitude) {
      dispatch(
        setCoordinates({
          lat: latitude,
          lng: longitude,
        }),
      );
      setDefaultLocation({
        lat: latitude,
        lng: longitude,
      });
      setDefaultCenter({
        lat: latitude,
        lng: longitude,
      });
    } else {
      dispatch(setCoordinates(defaultCoordinate));
      setDefaultCenter(defaultCoordinate);
    }
  };

  const setGateSelected = (getesSelected: Gate) => {
    const n = getesSelected?.name ?? '';
    const min = getesSelected?.gateMin ?? '';
    const max = getesSelected?.gateMax ?? '';

    if (!isNil(n)) setName(n);
    if (!isNil(min)) setGateMin(min.toString());
    if (!isNil(max)) setGateMax(max.toString());

    setValueStation(getesSelected);
  };

  useEffect(() => {
    if (device && gates && gates.length && isGate(device)) {
      const getesSelected = gates.filter((t) => get(t, 'id', null) === get(device, 'id', null))[0];

      setGateSelected(getesSelected);
    }
  }, [device, gates]);

  useEffect(() => {
    if (showUpdateCoordinates) {
      getLatitudeAndLongitude();
    }
  }, [showUpdateCoordinates]);

  const handleIrrigationChange = (e, value) => {
    setSelectedIrrigation(value);
  };

  // TODO: ivalderrama, extraer en un hooks
  const fetchGates = async () => {
    const newGates = get(
      await client.query({
        query: FETCH_GATES,
        fetchPolicy: 'no-cache',
      }),
      'data.gates.gates',
      [],
    );
    dispatch(setAllGates(newGates));
  };

  useEffect(() => {
    if (!gates) {
      fetchGates();
    }
  }, []);

  const handleInternalClose = (refetch?: boolean) => {
    handleClose(refetch);
    setName('');
    setDeviceType('');
    dispatch(setCoordinates({ lat: 0, lng: 0 }));
    setGateMax('');
    setGateMin('');
    setValueStation(null);
    setSearchValue('');
    setSelectedOrganization(null);
    setSelectedIrrigation(null);
  };

  return (
    <Dialog open={show} fullWidth maxWidth="md" onClose={() => handleInternalClose()}>
      <DialogTitle>{device ? `Editar Estación ${device.name}` : ''}</DialogTitle>
      <DialogContent
        sx={{
          overflowY: 'scroll',
          '::-webkit-scrollbar': {
            width: '5px',
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: '#2ECCFA',
            borderRadius: '10px',
          },
          scrollbarWidth: 'none',
        }}
      >
        {loading || loadingUpdateStation ? (
          <Loading />
        ) : (
          <Grid container>
            <Grid item xs={12} p={2}>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Nombre"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Box>
              <Box mb={2}>
                <TypeFilter
                  onChange={(v) => setDeviceType(v)}
                  value={deviceType}
                  types={selectType?.familyType === 0 ? [{ value: selectType?.type, label: selectType?.type }] : typesByUser
                    .filter(
                      (x) =>
                        selectType?.type === x.type ||
                        (selectType?.familyType !== 0 && x.familyType === selectType?.familyType),
                    )
                    .map((x) => {
                      return { value: x.type, label: x.type };
                    })}
                />
              </Box>

              <Box mb={2}>
                <Box>
                  <Autocomplete
                    options={organizations}
                    getOptionLabel={(option) => option.name}
                    value={selectedOrganization}
                    onChange={(e, newValue) => {
                      setSelectedOrganization(newValue);
                      setSelectedIrrigation(null);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Organización" variant="outlined" fullWidth />
                    )}
                  />
                </Box>
              </Box>
              <Box mb={2}>
                <Box>
                  <Autocomplete
                    options={irrigations}
                    getOptionLabel={(option) => option.id + ' - ' + option.name}
                    value={selectedIrrigation}
                    onChange={(e, newValue) => {
                      handleIrrigationChange(e, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Red de canales"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Box>
              </Box>
              {get(valueStation, 'type', null) === 'CFC' && (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        {/* <Grid item xs={4}> */}
                        {/*   <TextField */}
                        {/*     fullWidth */}
                        {/*     label="Calibración encoder" */}
                        {/*     type="number" */}
                        {/*     value={calibrationEncoder} */}
                        {/*     onChange={(e) => setCalibrationEncoder(e.target.value)} */}
                        {/*     InputProps={{ */}
                        {/*       endAdornment: <InputAdornment position="start">cm.</InputAdornment>, */}
                        {/*     }} */}
                        {/*   /> */}
                        {/* </Grid> */}
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            label="Nivel Mínimo"
                            type="number"
                            value={gateMin}
                            onChange={(e) => setGateMin(e.target.value)}
                            InputProps={{
                              endAdornment: <InputAdornment position="start">cm.</InputAdornment>,
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            label="Nivel Máximo"
                            type="number"
                            value={gateMax}
                            onChange={(e) => setGateMax(e.target.value)}
                            InputProps={{
                              endAdornment: <InputAdornment position="start">cm.</InputAdornment>,
                            }}
                          />
                        </Grid>
                      </Grid>
                      {/* <Grid item xs={12} mt={2}> */}
                      {/*   <FormControl fullWidth> */}
                      {/*     <InputLabel id="topic-version-select-label">Versión de tópico</InputLabel> */}
                      {/*     <Select */}
                      {/*       id="topic-version-select" */}
                      {/*       label="Versión de tópico" */}
                      {/*       labelId="topic-version-select-label" */}
                      {/*       variant="outlined" */}
                      {/*       value={topicVersion} */}
                      {/*       onChange={(e) => setTopicVersion(e.target.value)} */}
                      {/*       fullWidth */}
                      {/*       size="small" */}
                      {/*     > */}
                      {/*       <MenuItem value="none">Sin especificar</MenuItem> */}
                      {/*       <MenuItem value="v1">v1</MenuItem> */}
                      {/*       <MenuItem value="v2">v2</MenuItem> */}
                      {/*     </Select> */}
                      {/*   </FormControl> */}
                      {/* </Grid> */}
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="h6">Estaciones virtuales</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <SensorAdvancedEdit gate={valueStation} direction="upstream" />
                        </Grid>
                        <Grid item xs={12}>
                          <SensorAdvancedEdit gate={valueStation} direction="downstream" />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              px={2}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography>¿Desea cambiar la ubicación del dispositivo?</Typography>
              <Checkbox
                checked={showUpdateCoordinates}
                onChange={() => setShowUpdateCoordinates(!showUpdateCoordinates)}
              />
            </Grid>
            {showUpdateCoordinates && (
              <Grid item xs={12} p={2}>
                <Box>
                  <Box>
                    <Box display="flex" alignItems="center" mb={2}>
                      <TextField
                        label="Buscar ubicación"
                        sx={{
                          width: 400,
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleSearchLocation();
                          }
                        }}
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSearchLocation}
                        sx={{
                          ml: 2,
                        }}
                      >
                        Buscar
                      </Button>
                    </Box>
                    <Box mb={2} display="flex" alignItems="center">
                      <Button
                        onClick={handleResetLocation}
                        variant="contained"
                        color="secondary"
                        sx={{
                          mr: 2,
                        }}
                      >
                        Reiniciar ubicación
                      </Button>
                      <TextField
                        label="Latitud"
                        value={lat ? lat.toString() : 0}
                        type="number"
                        onChange={(e) => handleChangeLocation(e.target.value, lng)}
                        sx={{
                          mr: 2,
                        }}
                      />
                      <TextField
                        label="Longitud"
                        value={lng ? lng.toString() : 0}
                        type="number"
                        onChange={(e) => handleChangeLocation(lat, e.target.value)}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box pt={2} height={400}>
                  <MapWrapper
                    position={defaultCenter}
                    handleChangeMarkerPosition={(position) => {
                      const { lat, lng } = position;
                      if (checkCoordinatesObject(position)) {
                        handleChangeLocation(lat, lng);
                      }
                    }}
                    onMapClick={(position) => {
                      const { lat, lng } = position;
                      if (checkCoordinatesObject(position)) {
                        handleChangeLocation(lat, lng);
                      }
                    }}
                  />
                </Box>
              </Grid>
            )}
            <Grid item xs={12} p={2}>
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button
                  onClick={() => handleInternalClose()}
                  variant="outlined"
                  color="info"
                  sx={{
                    mr: 2,
                  }}
                >
                  Cancelar
                </Button>
                <Button onClick={() => updateStation()} variant="contained" color="success">
                  Guardar
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UpdateStationComponent;

const SensorAdvancedEdit = ({ gate, direction }) => {
  const [createLoading, setCreateLoading] = useState(false);
  if (createLoading || !gate) {
    return <Typography variant="body2">Cargando...</Typography>;
  }
  const calculatedId = `${gate.id}_${direction}`;
  const directionText = direction === 'upstream' ? 'Arriba' : 'Abajo';
  const client = useApolloClient();

  const createStation = async () => {
    setCreateLoading(true);
    await client.mutate({
      mutation: CREATE_GATE_LINKED_STATION,
      variables: {
        targetId: calculatedId,
        targetName: `${gate.name} - Sensor Aguas ${directionText}`,
        sourceId: gate.id,
        direction: direction,
      },
    });
    setCreateLoading(false);
  };

  return (
    <Grid container>
      {get(gate, `${direction}Device`) ? (
        <Grid item xs={12} display="flex" alignItems="center">
          <Typography variant="body2">
            {`Estacion aguas ${directionText}: ${get(gate, `${direction}Device.name`)}`}
          </Typography>
        </Grid>
      ) : (
        <Grid item xs={12}>
          {get(gate, `${direction}_date`) ? (
            <Typography variant="body2">
              {`Estacion aguas ${directionText}: No creada `}
              <Typography variant="overline">
                <Link component="button" variant="body2" onClick={() => createStation()}>
                  Click para crear
                </Link>
              </Typography>
            </Typography>
          ) : (
            <Typography variant="body2">{`Estacion aguas ${directionText}: No se han recibido datos`}</Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};
