import { gql } from '@/__generated__';

const GET_USER_PREFERENCES_METRICS = gql(`#graphql
  query GetUserPreferencesMetrics {
    user {
      id
      preferences {
        id
        metrics {
          id
          fields {
            id
            name
            defaultConfiguration {
              precision
              unit
            }
            additionalConfigurations {
              deviceType
              precision
              unit
            }
          }
        }
      }
    }
  }
`);

export default GET_USER_PREFERENCES_METRICS;
