import { gql } from 'src/__generated__';

const UPDATE_GATE = gql(`
  mutation UpdateGate(
    $name: String
    $parentComponentId: Int!
    $irrigationId: Int
    $gateMin: Float
    $gateMax: Float
    $alarmData: String
    $status: String
    $description: String
    $topic_version: String
    $longitude: String
    $latitude: String
    $organizationId: Int
  ) {
    UpdateGate(
      name: $name
      parent_component_id: $parentComponentId
      irrigationId: $irrigationId
      gateMin: $gateMin
      gateMax: $gateMax
      alarm_data: $alarmData
      status: $status
      description: $description
      topic_version: $topic_version
      latitude: $latitude
      longitude: $longitude
      organizationId: $organizationId
    ) {
      success
      message
    }
  }
`);
export default UPDATE_GATE;
