import { gql } from '@/__generated__';

const GET_ACCESS_GATE_OPTIONS = gql(`#graphql
  query GetAccessGateOptions($input: AuthorizationInput!) {
    authorization {
      authorizationDevice(input: $input) {
        deviceId
        update
        updateState
        updateAlarm
        viewStateHistorial
        viewUserAccess
        delete
      }
    }
  }
`);

export default GET_ACCESS_GATE_OPTIONS;
