import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Organization } from 'src/__generated__/graphql';

interface Tag {
  id: number;
  name: string;
  color: string;
}

interface Parameters {
  page: number;
  rowsPerPage: number;
  searchValue: string;
  statusValue: string;
  supportStatusValue: string;
  typeValue: string;
  organization: Organization | null;
  icnId: number;
  tagsValue: Tag[];
  dgaValue: string;
  order: string;
  sortField: string;
}

interface FieldConfig {
  organization?: boolean;
  id?: boolean;
  name?: boolean;
  type?: boolean;
  status?: boolean;
  support_status?: boolean;
  // { id: "last_metric_date", label: "Último Dato" },
  battery_soc?: boolean;
  operator_mobil?: boolean;
  etiquetas?: boolean;
  dga_active?: boolean;
  events_count?: boolean;
}

interface DevicesSupportState {
  showMenuSupport: boolean;
  showFilterConfig: boolean;
  showFilterAdvance: boolean;
  showFieldsConfig: boolean;
  parameters: Parameters;
  fieldConfig: FieldConfig;
}

const initialState: DevicesSupportState = {
  showMenuSupport: false,
  showFilterConfig: false,
  showFilterAdvance: false,
  showFieldsConfig: false,
  fieldConfig: {},
  parameters: {
    page: 0,
    rowsPerPage: 10,
    searchValue: '',
    statusValue: 'all',
    supportStatusValue: 'all',
    typeValue: 'all',
    organization: null,
    icnId: 0,
    tagsValue: [],
    dgaValue: '2',
    order: 'ASC',
    sortField: '',
  },
};

const { reducer, actions } = createSlice({
  name: 'devices_support_store',
  initialState,
  reducers: {
    setShowMenuSupport(
      state: DevicesSupportState,
      action: PayloadAction<boolean>,
    ): void {
      state.showMenuSupport = action.payload;
    },
    setShowFilterConfig(
      state: DevicesSupportState,
      action: PayloadAction<boolean>,
    ): void {
      state.showFilterConfig = action.payload;
      if (action.payload) state.showMenuSupport = false;
    },
    setShowFilterAdvance(
      state: DevicesSupportState,
      action: PayloadAction<boolean>,
    ): void {
      state.showFilterAdvance = action.payload;
      if (action.payload) state.showMenuSupport = false;
    },
    setShowFieldsConfig(
      state: DevicesSupportState,
      action: PayloadAction<boolean>,
    ): void {
      state.showFieldsConfig = action.payload;
      if (action.payload) state.showMenuSupport = false;
    },
    setParameters(
      state: DevicesSupportState,
      action: PayloadAction<Parameters>,
    ): void {
      state.parameters = action.payload;
    },
    setFieldConfig(
      state: DevicesSupportState,
      action: PayloadAction<FieldConfig>,
    ): void {
      state.fieldConfig = action.payload;
    },
  },
});

export default reducer;

export const {
  setShowMenuSupport,
  setShowFilterConfig,
  setShowFilterAdvance,
  setShowFieldsConfig,
  setParameters,
  setFieldConfig,
} = actions;
