import { useTheme } from '@mui/material';
import {
  MenuButtonAddTable,
  MenuButtonBlockquote,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonCode,
  MenuButtonCodeBlock,
  MenuButtonEditLink,
  MenuButtonHighlightColor,
  MenuButtonHorizontalRule,
  MenuButtonImageUpload,
  MenuButtonIndent,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonRedo,
  MenuButtonRemoveFormatting,
  MenuButtonStrikethrough,
  MenuButtonSubscript,
  MenuButtonSuperscript,
  MenuButtonTaskList,
  MenuButtonTextColor,
  MenuButtonUnderline,
  MenuButtonUndo,
  MenuButtonUnindent,
  MenuControlsContainer,
  MenuDivider,
  // MenuSelectFontFamily,
  MenuSelectFontSize,
  MenuSelectHeading,
  MenuSelectTextAlign,
  isTouchDevice,
} from 'mui-tiptap';
import { FC } from 'react';
import { ToolbarOptions } from './RichTextEditor';

interface Props {
  toolbarOptions: ToolbarOptions | null;
}

const EditorMenuControls: FC<Props> = ({ toolbarOptions }) => {
  const theme = useTheme();
  return (
    <MenuControlsContainer>
      {/* <MenuSelectFontFamily */}
      {/*   options={[ */}
      {/*     { label: 'Comic Sans', value: 'Comic Sans MS, Comic Sans' }, */}
      {/*     { label: 'Cursive', value: 'cursive' }, */}
      {/*     { label: 'Monospace', value: 'monospace' }, */}
      {/*     { label: 'Serif', value: 'serif' }, */}
      {/*   ]} */}
      {/* /> */}
      {/**/}
      {/* <MenuDivider /> */}

      {(toolbarOptions === null || toolbarOptions.selectHeading) && (
        <>
          <MenuSelectHeading />
          <MenuDivider />
        </>
      )}
      {(toolbarOptions === null || toolbarOptions.selectFontSize) && (
        <>
          <MenuSelectFontSize />
          <MenuDivider />
        </>
      )}

      {(toolbarOptions === null || toolbarOptions.buttonBold) && <MenuButtonBold />}
      {(toolbarOptions === null || toolbarOptions.buttonItalic) && <MenuButtonItalic />}
      {(toolbarOptions === null || toolbarOptions.buttonUnderline) && <MenuButtonUnderline />}
      {(toolbarOptions === null || toolbarOptions.buttonStrikethrough) && (
        <MenuButtonStrikethrough />
      )}
      {(toolbarOptions === null || toolbarOptions.buttonSubscript) && <MenuButtonSubscript />}
      {(toolbarOptions === null || toolbarOptions.buttonSuperscript) && <MenuButtonSuperscript />}

      {(toolbarOptions === null ||
        toolbarOptions.buttonBold ||
        toolbarOptions.buttonItalic ||
        toolbarOptions.buttonUnderline ||
        toolbarOptions.buttonStrikethrough ||
        toolbarOptions.buttonSubscript ||
        toolbarOptions.buttonSuperscript) && <MenuDivider />}

      {(toolbarOptions === null || toolbarOptions.selectTextColor) && (
        <MenuButtonTextColor
          defaultTextColor={theme.palette.text.primary}
          swatchColors={[
            { value: '#000000', label: 'Black' },
            { value: '#ffffff', label: 'White' },
            { value: '#888888', label: 'Grey' },
            { value: '#ff0000', label: 'Red' },
            { value: '#ff9900', label: 'Orange' },
            { value: '#ffff00', label: 'Yellow' },
            { value: '#00d000', label: 'Green' },
            { value: '#0000ff', label: 'Blue' },
          ]}
        />
      )}

      {(toolbarOptions === null || toolbarOptions.selectHighlightColor) && (
        <MenuButtonHighlightColor
          swatchColors={[
            { value: '#595959', label: 'Dark grey' },
            { value: '#dddddd', label: 'Light grey' },
            { value: '#ffa6a6', label: 'Light red' },
            { value: '#ffd699', label: 'Light orange' },
            // Plain yellow matches the browser default `mark` like when using Cmd+Shift+H
            { value: '#ffff00', label: 'Yellow' },
            { value: '#99cc99', label: 'Light green' },
            { value: '#90c6ff', label: 'Light blue' },
            { value: '#8085e9', label: 'Light purple' },
          ]}
        />
      )}
      {(toolbarOptions === null ||
        toolbarOptions.selectTextColor ||
        toolbarOptions.selectHighlightColor) && <MenuDivider />}

      {(toolbarOptions === null || toolbarOptions.buttonLink) && (
        <>
          <MenuButtonEditLink />
          <MenuDivider />
        </>
      )}

      {(toolbarOptions === null || toolbarOptions.selectAlignment) && (
        <>
          <MenuSelectTextAlign />
          <MenuDivider />
        </>
      )}

      {(toolbarOptions === null || toolbarOptions.buttonOrderedList) && <MenuButtonOrderedList />}
      {(toolbarOptions === null || toolbarOptions.buttonBulletList) && <MenuButtonBulletedList />}
      {(toolbarOptions === null || toolbarOptions.buttonTaskList) && <MenuButtonTaskList />}
      {(toolbarOptions === null ||
        toolbarOptions.buttonOrderedList ||
        toolbarOptions.buttonBulletList ||
        toolbarOptions.buttonTaskList) && <MenuDivider />}

      {/* On touch devices, we'll show indent/unindent buttons, since they're
      unlikely to have a keyboard that will allow for using Tab/Shift+Tab. These
      buttons probably aren't necessary for keyboard users and would add extra
      clutter. */}
      {isTouchDevice() && (
        <>
          <MenuButtonIndent />
          <MenuButtonUnindent />
          <MenuDivider />
        </>
      )}

      {(toolbarOptions === null || toolbarOptions.buttonBlockquote) && (
        <>
          <MenuButtonBlockquote />
          <MenuDivider />
        </>
      )}

      {(toolbarOptions === null || toolbarOptions.buttonCode) && <MenuButtonCode />}
      {(toolbarOptions === null || toolbarOptions.buttonCodeBlock) && <MenuButtonCodeBlock />}
      {(toolbarOptions === null || toolbarOptions.buttonCode || toolbarOptions.buttonCodeBlock) && (
        <MenuDivider />
      )}

      {(toolbarOptions === null || toolbarOptions.buttonUploadImages) && (
        <>
          <MenuButtonImageUpload
            onUploadFiles={(files) =>
              // For the sake of a demo, we don't have a server to upload the files
              // to, so we'll instead convert each one to a local "temporary" object
              // URL. This will not persist properly in a production setting. You
              // should instead upload the image files to your server, or perhaps
              // convert the images to bas64 if you would like to encode the image
              // data directly into the editor content, though that can make the
              // editor content very large.
              files.map((file) => ({
                src: URL.createObjectURL(file),
                alt: file.name,
              }))
            }
          />
          <MenuDivider />
        </>
      )}

      {(toolbarOptions === null || toolbarOptions.buttonHorizontalRule) && (
        <MenuButtonHorizontalRule />
      )}

      {(toolbarOptions === null || toolbarOptions.buttonInsertTable) && <MenuButtonAddTable />}

      {(toolbarOptions === null ||
        toolbarOptions.buttonHorizontalRule ||
        toolbarOptions.buttonInsertTable) && <MenuDivider />}

      {(toolbarOptions === null || toolbarOptions.buttonRemoveFormatting) && (
        <>
          <MenuButtonRemoveFormatting />
          <MenuDivider />
        </>
      )}

      <MenuButtonUndo />
      <MenuButtonRedo />
    </MenuControlsContainer>
  );
};

export default EditorMenuControls;
