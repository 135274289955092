import * as yup from 'yup';
import { CurveFunction, FormValues } from '../components/RadarParametersTab';
import { DeviceRadarParametersAreaCurveInput } from 'src/__generated__/graphql';

const emptyToNull = (value: string | number, originalValue: string | number) => {
  if (originalValue === '') return null;
  return value;
};

const validationMessages = {
  required: 'Este campo es obligatorio',
  number: {
    typeError: 'El valor debe ser un número',
    min: 'El valor debe ser menor al del intervalo final',
    max: 'El valor debe ser mayor al del intervalo inicial',
  },
  oneOf: (values: string[]) => `El valor debe ser uno de: ${values.join(', ')}`,
  testPiecewise:
    'Los elementos de Piecewise deben tener un valor mínimo que coincida con el valor máximo del elemento anterior',
  requiredCurveType: 'El tipo de curva es obligatorio y debe ser function, interval o piecewise',
};

const chiuSchema = yup.object().shape({
  functionType: yup.string().oneOf(['chiu']).required(validationMessages.required),
  parameters: yup.object().shape({
    vMaxDepthNorm: yup
      .number()
      .transform(emptyToNull)
      .typeError(validationMessages.number.typeError)
      .required(validationMessages.required),
    m: yup
      .number()
      .transform(emptyToNull)
      .typeError(validationMessages.number.typeError)
      .required(validationMessages.required),
  }),
});

const polynomialSchema = yup.object().shape({
  functionType: yup.string().oneOf(['polynomial']).required(validationMessages.required),
  parameters: yup.object().shape({
    a: yup
      .array()
      .of(
        yup.object().shape({
          value: yup
            .number()
            .transform(emptyToNull)
            .typeError(validationMessages.number.typeError)
            .required(validationMessages.required),
        }),
      )
      .min(1, 'Debe haber al menos un coeficiente')
      .required(validationMessages.required),
  }),
});

const trapezoidalSchema = yup.object().shape({
  functionType: yup.string().oneOf(['trapezoidal']).required(validationMessages.required),
  parameters: yup.object().shape({
    talud: yup
      .number()
      .transform(emptyToNull)
      .typeError(validationMessages.number.typeError)
      .required(validationMessages.required),
    baseWidth: yup
      .number()
      .transform(emptyToNull)
      .typeError(validationMessages.number.typeError)
      .required(validationMessages.required),
  }),
});

const powerSchema = yup.object().shape({
  functionType: yup.string().oneOf(['power']).required(validationMessages.required),
  parameters: yup.object().shape({
    A: yup
      .number()
      .transform(emptyToNull)
      .typeError(validationMessages.number.typeError)
      .required(validationMessages.required),
    B: yup
      .number()
      .transform(emptyToNull)
      .typeError(validationMessages.number.typeError)
      .required(validationMessages.required),
    C: yup
      .number()
      .transform(emptyToNull)
      .typeError(validationMessages.number.typeError)
      .required(validationMessages.required),
  }),
});

// Define schema for Curve type
const curveFunctionSchema = (section: 'area' | 'velocity', type: string, model?: string) =>
  yup.lazy((value) => {
    const intervalCurveSchema = yup.object().shape({
      min: yup
        .number()
        .transform(emptyToNull)
        .typeError(validationMessages.number.typeError)
        .required(validationMessages.required)
        .defined()
        .lessThan(yup.ref('max'), validationMessages.number.min),
      max: yup
        .number()
        .defined()
        .transform(emptyToNull)
        .typeError(validationMessages.number.typeError)
        .required(validationMessages.required)
        .moreThan(yup.ref('min'), validationMessages.number.max),
    });

    const errorType = (array: string[]) =>
      yup
        .object()
        .shape({ functionType: yup.string().oneOf(array).required(validationMessages.required) })
        .required(validationMessages.requiredCurveType);

    switch (value?.functionType) {
      case 'chiu':
        if (section === 'area') return errorType(['polynomial', 'power', 'trapezoidal']);
        if (type === 'piecewise') return errorType(['polynomial', 'power']);
        if (type === 'interval') {
          return chiuSchema.concat(intervalCurveSchema).required();
        } else {
          return chiuSchema.required();
        }
      case 'trapezoidal':
        if (section === 'velocity') return errorType(['polynomial', 'power', 'chiu']);
        if (model && model === 'N(h)') return errorType(['polynomial', 'power']);
        if (type === 'piecewise') return errorType(['polynomial', 'power']);
        if (type === 'interval') {
          return trapezoidalSchema.concat(intervalCurveSchema).required();
        } else {
          return trapezoidalSchema.required();
        }
      case 'polynomial':
        if (type === 'piecewise' || type === 'interval') {
          return polynomialSchema.concat(intervalCurveSchema).required();
        } else {
          return polynomialSchema.required();
        }
      case 'power':
        if (type === 'piecewise' || type === 'interval') {
          return powerSchema.concat(intervalCurveSchema).required();
        } else {
          return powerSchema.required();
        }
      default:
        if (section === 'area') return errorType(['polynomial', 'power', 'trapezoidal']);
        else return errorType(['polynomial', 'power', 'chiu']);
    }
  });

const checkPiecewise = (value?: (CurveFunction & { min: number; max: number })[]) => {
  if (!value || value.length < 2) return true;
  for (let i = 1; i < value.length; i++) {
    if (value[i].min !== value[i - 1].max) {
      return false;
    }
  }
  return true;
};

// Define schema for FormValues
export const radarFormValuesSchema = yup.object<FormValues>().shape({
  deviceId: yup.string().required(validationMessages.required),
  ratingCurve: yup.object().shape({
    active: yup.boolean().defined().nonNullable(),
    levelLimit: yup
      .number()
      .transform(emptyToNull)
      .typeError(validationMessages.number.typeError)
      .when('active', {
        is: true,
        then: (schema) => schema.required(validationMessages.required),
        otherwise: (schema) => schema.nullable(),
      }),
  }),
  areaCurve: yup.lazy((value: DeviceRadarParametersAreaCurveInput) => {
    if (!value) return yup.object().shape({}).required();
    if (value.type === 'piecewise') {
      return yup.object().shape({
        levelOffset: yup
          .number()
          .transform(emptyToNull)
          .typeError(validationMessages.number.typeError)
          .required(validationMessages.required),
        type: yup.string().oneOf(['piecewise']).defined().required(validationMessages.required),
        curves: yup
          .array()
          .of(curveFunctionSchema('area', 'piecewise'))
          .min(1, 'Debe haber al menos un intervalo')
          .test('check-piecewise', validationMessages.testPiecewise, checkPiecewise)
          .required(validationMessages.required),
      });
    }
    if (value.type === 'function' || value.type === 'interval') {
      return yup.object().shape({
        levelOffset: yup
          .number()
          .transform(emptyToNull)
          .typeError(validationMessages.number.typeError)
          .required(validationMessages.required),
        type: yup
          .string()
          .oneOf(['function', 'interval'])
          .required(validationMessages.requiredCurveType),
        curve: curveFunctionSchema('area', value.type),
      });
    }
    // throw new yup.ValidationError(validationMessages.requiredCurveType);
    return yup.object().shape({
      type: yup
        .string()
        .oneOf(['function', 'interval', 'piecewise'])
        .required(validationMessages.requiredCurveType),
    });
  }),
  velocityCurve: yup.lazy((value) => {
    if (!value) return yup.object().shape({}).required();
    if (value.type === 'piecewise') {
      return yup.object().shape({
        model: yup.string().oneOf(['N(h)', 'index-velocity']).required(validationMessages.required),
        type: yup.string().oneOf(['piecewise']).required(validationMessages.required),
        curves: yup
          .array()
          .of(curveFunctionSchema('velocity', 'piecewise'))
          .min(1, 'Debe haber al menos un intervalo')
          .test('check-piecewise', validationMessages.testPiecewise, checkPiecewise)
          .required(validationMessages.required),
      });
    }
    if (value.type === 'function' || value.type === 'interval') {
      return yup.object().shape({
        model: yup.string().oneOf(['N(h)', 'index-velocity']).required(validationMessages.required),
        type: yup
          .string()
          .oneOf(['function', 'interval'])
          .required(validationMessages.requiredCurveType),
        curve: curveFunctionSchema('velocity', value.type),
      });
    }
    // throw new yup.ValidationError(validationMessages.requiredCurveType);
    return yup.object().shape({
      type: yup
        .string()
        .oneOf(['function', 'interval', 'piecewise'])
        .required(validationMessages.requiredCurveType),
    });
  }),
});
