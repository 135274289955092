import { Drawer } from '@mui/material';
import { type FC, useState } from 'react';
import QueryOrganizationSetting from 'src/components/organizationConfiguration/QueryOrganizationSetting';
import OrganizationsAutocomplete from 'src/features/organizationsAutocomplete';
import { StandardAutocompleteOrganization } from 'src/features/standardDesign';

interface Props {
  open: boolean;
  handleClose: (e: any) => void;
}

const OrganizationSettingDrawer: FC<Props> = ({ open, handleClose }) => {
  const [organization, setOrganization] = useState<
    StandardAutocompleteOrganization | null | undefined
  >();

  return (
    <Drawer
      anchor="right"
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          p: 2,
          pt: 10,
          width: 300,
        },
      }}
    >
      <OrganizationsAutocomplete
        fullWidth
        size="small"
        value={organization}
        onChange={setOrganization}
      />
      {organization != null && <QueryOrganizationSetting organizationId={organization.id} />}
    </Drawer>
  );
};

export default OrganizationSettingDrawer;
