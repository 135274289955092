import { gql } from '@apollo/client';

const MOVE_GATE = gql`
  mutation MoveGate(
    $requestId: String!
    $requestedPosition: Int!
    $deviceId: String!
    $requestTimestamp: Float!
  ) {
    MoveGate(
      request_id: $requestId
      requested_position: $requestedPosition
      device_id: $deviceId
      request_timestamp: $requestTimestamp
    ) {
      success
    }
  }
`;

export default MOVE_GATE;
