import { useMutation } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import DISABLE_USER from '../graphql/mutations/disableUser';
import { type FC, useState, FormEvent } from 'react';
import { LoadingButton } from '@mui/lab';
import { Trans, msg } from '@lingui/macro';
import { enc, iv } from '@/utils/encrypt';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuth, useModalStatusMessage } from 'src/hooks';
import { useLingui } from '@lingui/react';

interface Props {
  userId: number;
  open: boolean;
  onClose: () => void;
}

const DisableUserModal: FC<Props> = ({ userId, open, onClose }) => {
  const { _ } = useLingui();
  const { logout } = useAuth();
  const { openModalSuccessMessage, openModalErrorMessage } = useModalStatusMessage();
  const [disableUser, { loading, error, reset }] = useMutation(DISABLE_USER, {
    onCompleted: () => {
      openModalSuccessMessage(_(msg`Usuario deshabilitado correctamente`));
      logout();
    },
    onError: (error) => {
      openModalErrorMessage(error.message);
    },
  });
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleConfirm = (event: FormEvent) => {
    event.preventDefault();
    disableUser({
      variables: {
        input: {
          userId: userId,
          hashedPassword: enc(password),
          iv: iv.toString('hex'),
        },
      },
    });
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    reset();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleConfirm}>
        <DialogTitle>
          <Trans>¿Deseas deshabilitar el usuario {userId}?</Trans>
        </DialogTitle>
        <DialogContent dividers>
          <Trans>Para confirmar la acción, ingresa tu contraseña y presiona "Confirmar"</Trans>
          <TextField
            fullWidth
            autoFocus
            sx={{ mt: 2 }}
            label={<Trans>Contraseña</Trans>}
            type={showPassword ? 'text' : 'password'}
            value={password}
            error={Boolean(error)}
            onChange={handlePasswordChange}
            helperText={error?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((showPassword) => !showPassword)}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="info" onClick={onClose}>
            <Trans>Cancelar</Trans>
          </Button>
          <LoadingButton
            loading={loading}
            disabled={password.length === 0}
            variant="contained"
            color="error"
            type="submit"
          >
            <Trans>Confirmar</Trans>
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DisableUserModal;
