import { useQuery, useSuspenseQuery } from '@apollo/client';
import STANDARD_AUTOCOMPLETE_DATA from '../queries/getStandardAutocompleteData';
import { DevicesOrderBy } from '@/__generated__/graphql';

export const useAutocompleteOptions = (
  dataType: 'devices' | 'irrigations' | 'organizations' | 'users',
  queryOptions?: {
    includeGates?: boolean;
    withDgaDevices?: boolean;
  },
) => {
  return useQuery(STANDARD_AUTOCOMPLETE_DATA, {
    variables: {
      includeOrganizations: dataType === 'organizations',
      includeDevices: dataType === 'devices',
      devicesInput: {
        orderBy: DevicesOrderBy.IdAsc,
        includeGates: queryOptions?.includeGates,
      },
      organizationsInput: {
        withDgaDevices: queryOptions?.withDgaDevices,
      },
      includeIrrigations: dataType === 'irrigations',
      includeUsers: dataType === 'users',
    },
  });
};

export const useSuspenseAutocompleteOptions = (
  dataType: 'devices' | 'irrigations' | 'organizations' | 'users',
  queryOptions?: {
    includeGates?: boolean;
    withDgaDevices?: boolean;
    users?: {
      fromUserOrganizations?: boolean;
    };
  },
) => {
  return useSuspenseQuery(STANDARD_AUTOCOMPLETE_DATA, {
    variables: {
      devicesInput: {
        orderBy: DevicesOrderBy.IdAsc,
        includeGates: queryOptions?.includeGates,
      },
      organizationsInput: {
        withDgaDevices: queryOptions?.withDgaDevices,
      },
      usersInput: {
        fromUserOrganizations: queryOptions?.users?.fromUserOrganizations,
      },
      includeOrganizations: dataType === 'organizations',
      includeDevices: dataType === 'devices',
      includeIrrigations: dataType === 'irrigations',
      includeUsers: dataType === 'users',
    },
  });
};
