import { gql } from 'src/__generated__';


const FETCH_EVENTS = gql(`
  query FetchEvents($deviceInput: DeviceInput!, $input: TelemetryParametersInput, $eventsFilter: EventFilterInput) {
    device(input: $deviceInput) {
      telemetry {
        events(eventsFilter: $eventsFilter, input: $input) {
          value
          source
          timestamp
          userId
        }
      }
    }
  }
`);

export default FETCH_EVENTS;
