import { gql } from '@/__generated__';

const UPDATE_DEVICE_CALIBRATION_POSITION_FROM_ANGLE = gql(`#graphql
  mutation DeviceCalibration_UpdatePositionFromAngle($input: UpdateDeviceCalibrationPositionFromAngleInput!) {
    updateDeviceCalibrationPositionFromAngle(input: $input) {
      deviceId
      calibration {
        level
        positionFromAngle {
          K
          R
          P
        }
      }
    }
  }
`);

export default UPDATE_DEVICE_CALIBRATION_POSITION_FROM_ANGLE;
