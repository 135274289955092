import useAuth from '@/hooks/useAuth';
import AnnouncementsViewModal from '@features/announcements/components/AnnouncementsViewModal';
import PicturePreview from './PicturePreview';
import GlobalSnackbar from './Snackbar';
import { useGatesMovementGlobalActions } from '@features/gateAdministration/hooks/useGatesMovementGlobalActions';
import {
  DebtsAlertBanner,
  DebtsAlertModal,
  DevicesAnalysisViewModal,
  GateMovementBanner,
  UserProfileUpdateModal,
  UserProfileModal,
  UserPreferencesModal,
  DeviceProfileModal,
} from '@/features/';
import { useI18n } from '@/hooks/useI18n';
import { DeviceConfigurationGlobalModals } from '@features/deviceConfigurationModal';
import DGAModals from '@features/dga/components/modals/DGAModals';
import UpdateDeviceAlertsModal from '@features/createDeviceAlerts/components/CreateDeviceAlerts';
import { DeviceObservationsModals } from '@features/deviceObservations';
import DeviceDataConfigurationModal from '@features/deviceDataConfigurationModal/components/DeviceDataConfigurationModal';
import CreateMeasurementAdapterModal from './optionsMenu/dialogs/CreateMeasurementAdapterModal';
import ManualDataDetailsAdapterModal from './optionsMenu/dialogs/ManualDataDetailsAdapterModal';
import { SmaUpsertModal } from 'src/features/sma';

const LoggedInGlobalComponents = () => {
  useGatesMovementGlobalActions();
  useI18n();

  return (
    <>
      {/* Place for global components that needs auth */}
      <UserPreferencesModal />
      <UserProfileModal />
      <UserProfileUpdateModal />
      <PicturePreview />
      <DebtsAlertModal />
      <DebtsAlertBanner />
      <GateMovementBanner />
      <AnnouncementsViewModal />
      <DevicesAnalysisViewModal />
      <DeviceProfileModal />
      <DeviceConfigurationGlobalModals />
      <DeviceDataConfigurationModal />
      <DGAModals />
      <UpdateDeviceAlertsModal />
      <DeviceObservationsModals />
      <CreateMeasurementAdapterModal />
      <ManualDataDetailsAdapterModal />
      <SmaUpsertModal />
    </>
  );
};

const GlobalComponents = () => {
  const { isAuthenticated } = useAuth();

  return (
    <>
      {/* No auth needed global components */}
      <GlobalSnackbar />
      {isAuthenticated && <LoggedInGlobalComponents />}
    </>
  );
};

export default GlobalComponents;
