import { useEffect, useState } from 'react';
import {
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination,
  Button,
} from '@mui/material';
import { uniqueId, get } from 'lodash';
import moment from 'moment';
import { useApolloClient } from '@apollo/client';
import FETCH_VIRTUAL_STATION_MEASUREMENTS from 'src/graphql/querys/fetchVirtualStationMeasurements';
import { useDispatch } from 'src/store';
import { setPicturePreview } from 'src/slices/components';
import { DeviceDialogProps } from '../dialogInterfaces';

const ManualDataDetails = ({
  show,
  handleClose,
  device,
}: DeviceDialogProps) => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const [manualDistributionData, setManualDistributionData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const fetchMeasurements = async () => {
    const {
      data: { FetchVirtualStationMeasurements: measurements },
    } = await client.query({
      query: FETCH_VIRTUAL_STATION_MEASUREMENTS,
      variables: {
        stationId: device.id,
      },
      fetchPolicy: 'no-cache',
    });
    if (measurements) {
      setManualDistributionData(measurements);
    }
  };

  useEffect(() => {
    if (show && device.id) fetchMeasurements();
  }, [show]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleShowPicture = ({ picture, flow, level, measured, name }) => {
    dispatch(
      setPicturePreview({
        show: true,
        picture,
        name,
        measurement: {
          flow,
          level,
          measured,
        },
      }),
    );
  };

  const handleCloseWithoutReloading = () => {
    handleClose(false);
  };

  return (
    <Dialog open={show} onClose={handleCloseWithoutReloading} maxWidth={false}>
      <DialogTitle>Detalles de entradas manuales</DialogTitle>
      <DialogContent>
        <Box width={800}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="subtitle1" color="textPrimary">
                      Nombre Responsable
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="subtitle1" color="textPrimary">
                      Nivel de Agua
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="subtitle1" color="textPrimary">
                      Caudal
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography>Fecha y Hora</Typography>
                  </TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {manualDistributionData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={uniqueId()}>
                      <TableCell align="left">{row.userName}</TableCell>
                      <TableCell align="left">{row.level}</TableCell>
                      <TableCell align="left">
                        {row.flow ? row.flow : '-'}
                      </TableCell>
                      <TableCell>
                        {moment(row.timestamp)
                          .local()
                          .format('DD/MM/YYYY hh:mm a')}
                      </TableCell>
                      <TableCell align="center">
                        {row.photo && row.photo.length > 0 ? (
                          <Button
                            onClick={() =>
                              handleShowPicture({
                                picture: get(row, 'photo', ''),
                                flow: get(row, 'flow', null),
                                level: get(row, 'level', null),
                                measured: get(row, 'timestamp', null),
                                name: get(row, 'name', null),
                              })
                            }
                          >
                            Ver foto
                          </Button>
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={manualDistributionData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}-${to} de ${
                count !== -1 ? count : `más que ${to}`
              }`;
            }}
            labelRowsPerPage="Filas por Página"
          />
          <Box display="flex" justifyContent="flex-end" my={2}>
            <Button variant="outlined" onClick={handleCloseWithoutReloading}>
              Cerrar
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ManualDataDetails;
