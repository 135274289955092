import { t } from '@lingui/macro';

export const validationMessages = {
  required: t`Este campo es obligatorio`,
  oneOf: (values: string[]) => t`El valor debe ser uno de: ${values.join(', ')}`,
  min: (min: number) => t`El valor debe ser mayor o igual a ${min}`,
};

export const emptyToNull = (value: string | number, originalValue: string | number) => {
  if (originalValue === '') return null;
  return value;
};
