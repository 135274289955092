import { EntityType } from "src/__generated__/graphql";
import { t } from "@lingui/macro";
import { I18n } from "@lingui/core";

export const getDisplayNameEntity = (entityType: EntityType, i18n: I18n) => {
  const entityTypeDict: { [id: string]: string; } = {
    [EntityType.User]: t(i18n)`usuario`,
    [EntityType.Organization]: t(i18n)`organización`,
    [EntityType.Device]: t(i18n)`dispositivo`,
    [EntityType.Irrigation]: t(i18n)`irrigación`,
    [EntityType.SiraDistribution]: t(i18n)`distribución de SIRA`,
    [EntityType.SiraCanal]: t(i18n)`canal de SIRA`,
  }

  return entityTypeDict.hasOwnProperty(entityType) ? entityTypeDict[entityType] : entityType;
}