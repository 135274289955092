import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import { Box, Button, Container, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import toast from 'react-hot-toast';
import { useDispatch } from 'src/store';
import { resetGlobalStore } from 'src/slices/config';
import { resetMapStore } from 'src/slices/map';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

const AuthorizationRequired: FC = () => {
  const { _ } = useLingui();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  const { logout } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async (): Promise<void> => {
    try {
      window.localStorage.removeItem('icn');
      window.localStorage.removeItem('accessToken');
      window.localStorage.removeItem('user');
      dispatch(resetGlobalStore());
      dispatch(resetMapStore());
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      toast.error(_(msg`No se pudo cerrar sesión`));
    }
  };

  return (
    <>
      <Helmet>
        <title>Amaru2 - CaptaHydro</title>
      </Helmet>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.paper',
          display: 'flex',
          minHeight: '100%',
          px: 3,
          py: '80px',
        }}
      >
        <Container maxWidth="lg">
          <Typography align="center" color="textPrimary" variant={mobileDevice ? 'h4' : 'h1'}>
            <Trans>No tienes permisos para acceder a esta página</Trans>
          </Typography>
          <Typography align="center" color="textSecondary" sx={{ mt: 0.5 }} variant="subtitle2">
            <Trans>
              Si necesitas acceder a esta página, ponte en contacto con dev@captahydro.com para
              habilitar tu cuenta
            </Trans>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6,
            }}
          >
            <Button color="primary" variant="outlined" onClick={() => handleLogout()}>
              <Trans>Volver a Login</Trans>
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AuthorizationRequired;
