import * as echarts from 'echarts';
import { EChartsInitOptions, createBaseOptionEChart } from './baseOption';
import { generateSeriesColors } from './defaultMetricsColors';
import { generateYAxisPositions } from './defaultYAxisPositions';
import { type Formatters } from '@/hooks/useFormatters';
import { MetricUnit } from 'src/__generated__/graphql';

export type SerieType = (
  | echarts.LineSeriesOption
  | echarts.BarSeriesOption
  | echarts.ScatterSeriesOption
  | echarts.CustomSeriesOption
) & {
  yAxisId: string;
  name: string;
  type: 'line' | 'bar' | 'scatter' | 'area' | 'custom' | 'heatmap';
};
export enum EChartsSymbolType {
  Circle = 'circle',
  Rect = 'rect',
  RoundRect = 'roundRect',
  Triangle = 'triangle',
  Diamond = 'diamond',
  Pin = 'pin',
  Arrow = 'arrow',
  None = 'none',
}

export type InternalSerie = {
  meta: {
    id?: string;
    name: string;
    type: 'line' | 'bar' | 'scatter' | 'area' | 'custom' | 'heatmap';
    data?: SerieType['data'];
    yAxisId: string;
    xAxisId?: string;
    dataType?: string;
    color?: string | null;
    skipFilter?: boolean;
  };
  value: SerieType;
};

type InternalYAxis = {
  meta: {
    id: string;
    type: echarts.YAXisComponentOption['type'];
    gridIndex?: number;
    unit?: string | MetricUnit | null;
    precision?: number | null;
  };
  value: echarts.YAXisComponentOption;
};

type InternalXAxis = {
  meta: {
    id: string;
    type: echarts.XAXisComponentOption['type'];
    gridIndex?: number;
  };
  value: echarts.XAXisComponentOption;
};

export default class EChartsOptionBuilder {
  private initOptions: EChartsInitOptions;
  private chartInstance: echarts.ECharts | null = null;
  private series: InternalSerie[] = [];
  private yAxis: InternalYAxis[] = [];
  private xAxis: InternalXAxis[] = [];
  private formatters: Formatters | null = null;

  constructor(initOptions: EChartsInitOptions = {}) {
    this.initOptions = initOptions;
  }

  setChartInstance(chartInstance: echarts.ECharts) {
    this.chartInstance = chartInstance;
  }

  setFormatters(formatters: Formatters) {
    this.formatters = formatters;
  }

  setInitOption(option: (base: EChartsInitOptions) => EChartsInitOptions) {
    this.initOptions = option(this.initOptions);
    return this;
  }

  getOption(): echarts.EChartsOption {
    // filter series with data
    const relevantSeries = this.series.filter(
      (serie) =>
        serie.meta.skipFilter ||
        serie.value.encode != null ||
        (serie.value.data != null && (serie.value.data as Array<unknown>).length > 0),
    );
    // filter yAxis that are being used
    const relevantYAxis = this.yAxis.filter(
      (yAxis) => relevantSeries.find((serie) => serie.meta.yAxisId === yAxis.meta.id) != null,
    );
    // filter xAxis that are being used
    const relevantXAxis = this.xAxis.filter((xAxis) =>
      relevantSeries.find((serie) => serie.meta.xAxisId === xAxis.meta.id),
    );
    const seriesColors = generateSeriesColors(
      relevantSeries.map((serie) => ({ dataType: serie.meta.dataType, color: serie.meta.color })),
    );
    const yAxisPositions = generateYAxisPositions(
      relevantYAxis.map((yAxis) => ({
        id: yAxis.meta.id,
        position: yAxis.value.position,
        gridIndex: yAxis.meta.gridIndex ?? 0,
        show: yAxis.value.show !== false,
      })),
      relevantSeries.map((serie) => serie.meta.yAxisId),
    );

    const option: echarts.EChartsOption = {
      ...createBaseOptionEChart(this.initOptions),
      yAxis:
        relevantYAxis.length > 0
          ? [
              ...relevantYAxis.map((yAxis) => {
                const { position, offset, show } =
                  yAxisPositions.find((pos) => pos.id === yAxis.meta.id) ?? {};
                return {
                  ...yAxis.value,
                  position,
                  offset,
                  ...(!show
                    ? ({
                        show: false,
                        axisLabel: {
                          show: false,
                        },
                        axisPointer: {
                          type: 'none',
                          show: false,
                        },
                      } as const)
                    : {
                        axisLabel: {
                          ...yAxis.value.axisLabel,
                          hideOverlap: true,
                          // rotate: 45,
                          margin: 20,
                        },
                      }),
                };
              }),
            ]
          : [{ type: 'value' }],
      xAxis:
        relevantXAxis?.length > 0 ? relevantXAxis.map((xAxis) => xAxis.value) : { type: 'time' },
      series: relevantSeries.map((serie, index) => ({
        ...serie.value,
        color: seriesColors[index],
      })),
    };

    return option;
  }

  getYAxisIdFromSerieId(serieId: string | undefined): string | null {
    return this.series.find((serie) => serie.meta.id === serieId)?.meta.yAxisId ?? null;
  }

  getSerieUnit(yAxisId: string): string | null {
    return this.yAxis.find((yAxis) => yAxis.meta.id === yAxisId)?.meta.unit ?? null;
  }

  getSeriePrecision(yAxisId: string): number {
    return this.yAxis.find((yAxis) => yAxis.meta.id === yAxisId)?.meta.precision ?? 1;
  }

  addXAxis(
    meta: InternalXAxis['meta'],
    value: ((base: InternalXAxis['value']) => InternalXAxis['value']) | InternalXAxis['value'] = (
      options,
    ) => options,
  ) {
    const xAxis =
      typeof value !== 'function'
        ? value
        : value({
            id: meta.id,
            type: meta.type ?? 'time',
            axisLabel: {},
            ...(meta.type === 'time' || !meta.type
              ? ({
                  type: 'time',
                  axisPointer: {
                    label: {
                      formatter: (params) => {
                        const date = new Date(params.value);
                        if (!this.formatters) return date.toLocaleString();
                        return this.formatters.formatDateTime(date);
                      },
                    },
                  },
                  axisLabel: {
                    formatter: (value) => {
                      const date = new Date(value);
                      if (!this.formatters) return date.toLocaleString();

                      // if seconds are 0 show minute format
                      if (date.getSeconds() === 0) {
                        // if minutes are 0 show hour format
                        if (date.getMinutes() === 0) {
                          // if hours are 0 show day format
                          if (date.getHours() === 0) {
                            // if days are 0 show month format
                            if (date.getDate() === 1) {
                              // if months are 0 show year format
                              if (date.getMonth() === 0) {
                                return this.formatters.formatDateTime(date, {
                                  year: 'numeric',
                                });
                              }

                              return this.formatters.formatDateTime(date, {
                                month: 'short',
                              });
                            }

                            return this.formatters.formatDateTime(date, {
                              day: 'numeric',
                              month: 'short',
                            });
                          }

                          return this.formatters.formatDateTime(date, {
                            hour: 'numeric',
                            minute: 'numeric',
                          });
                        }

                        return this.formatters.formatDateTime(date, {
                          hour: 'numeric',
                          minute: 'numeric',
                        });
                      }

                      return this.formatters.formatDateTime(date, {
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                      });
                    },
                  },
                } as const)
              : {}),
            ...(meta.gridIndex != null ? { gridIndex: meta.gridIndex } : {}),
          });
    this.xAxis.push({
      meta,
      value: xAxis,
    });
    this.xAxis.push({
      meta: {
        ...meta,
        id: `${meta.id}-bottom`,
        gridIndex: 1,
      },
      value: {
        ...xAxis,
        id: `${meta.id}-bottom`,
        gridIndex: 1,
      },
    });
    return this;
  }

  addYAxis(
    meta: InternalYAxis['meta'],
    value: ((base: InternalYAxis['value']) => InternalYAxis['value']) | InternalYAxis['value'] = (
      options,
    ) => options,
  ) {
    const { id, type, unit } = meta;
    const precision = meta.precision ?? 1;

    const yAxis =
      typeof value !== 'function'
        ? value
        : value({
            id,
            ...(meta.gridIndex ? { gridIndex: meta.gridIndex } : {}),
            type: type ?? 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(150,150,150, 0.1)',
              },
            },
            axisPointer: {
              snap: true,
            },
            alignTicks: true,
            boundaryGap: [0, '20%'],
            tooltip: {
              show: false,
            },
            axisLabel: {
              formatter: (value: number | string) => {
                if (typeof value === 'string') return value;
                if (value === null) return 'N/A';
                const formattedValue = Object.values(MetricUnit).includes(unit as MetricUnit)
                  ? this.formatters?.formatMetricValue(value, {
                      unit: unit as MetricUnit,
                      precision: precision ?? 1,
                    })
                  : this.formatters?.formatNumber(value, precision) + (unit ? ` ${unit}` : '');
                return formattedValue ?? '';
              },
              color: 'rgba(255,255,255, 0.7)',
              showMinLabel: false,
            },
          });
    this.yAxis.push({
      meta,
      value: yAxis,
    });
    this.yAxis.push({
      meta: {
        ...meta,
        id: `${id}-bottom`,
        gridIndex: 1,
        precision,
      },
      value: {
        ...yAxis,
        id: `${id}-bottom`,
        gridIndex: 1,
        boundaryGap: [0, '80%'],
        axisLabel: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
    });

    return this;
  }

  addSeries(
    series: {
      meta: InternalSerie['meta'];
      value?:
        | ((options: InternalSerie['value']) => InternalSerie['value'])
        | InternalSerie['value'];
    }[],
    replace?: boolean,
  ) {
    if (replace) {
      this.series = [];
    }
    for (const serie of series) {
      const serieBase: SerieType = {
        id: serie.meta.id ?? serie.meta.name,
        yAxisId: serie.meta.yAxisId,
        xAxisId: serie.meta.xAxisId,
        data: serie.meta.data,
        name: serie.meta.name,
        ...(serie.meta.color != null ? { color: serie.meta.color } : {}),
        type: serie.meta.type === 'area' ? 'line' : serie.meta.type,
        symbol: 'circle',
        emphasis: {
          focus: 'series',
        },
        tooltip: {
          valueFormatter: (value) => {
            if (value == null) return 'N/A';
            if (isNaN(Number(value)) || !this.formatters) return String(value);
            const unit = this.getSerieUnit(serie.meta.yAxisId) ?? MetricUnit.None;
            const precision = this.getSeriePrecision(serie.meta.yAxisId) ?? 1;
            if (Object.values(MetricUnit).includes(unit as MetricUnit))
              return this.formatters.formatMetricValue(value as number, {
                unit: unit as MetricUnit,
                precision,
              });
            return `${this.formatters?.formatNumber(value as number, precision)} ${unit}`;
          },
        },
        ...(serie.meta.type === 'area' ? { areaStyle: { opacity: 0.2 } } : {}),
      };
      this.series.push({
        meta: serie.meta,
        value: serie.value
          ? typeof serie.value === 'function'
            ? serie.value(serieBase)
            : serie.value
          : serieBase,
      });
    }
    return this;
  }

  // private addTooltip() {
  //   this.initOptions.tooltip = {
  //     confine: true,
  //     position: (pos, params, _, __, size) => {
  //       if (this.chartInstance == null) {
  //         return pos;
  //       }
  //       // console.log(pos, params, size);
  //
  //       let xValue: number;
  //       if (params instanceof Array) {
  //         xValue = params[0].value[0];
  //       } else {
  //         xValue = params.value[0];
  //       }
  //
  //       const xPixelCoord = this.chartInstance.convertToPixel({ xAxisIndex: 0 }, xValue);
  //       // if mouse is on the upper half of the chart, show tooltip on the bottom
  //       if (pos[1] < size.viewSize[1] / 2) {
  //         return [xPixelCoord - size.contentSize[0] / 2, size.viewSize[1] / 2];
  //       }
  //       return [xPixelCoord - size.contentSize[0] / 2, size.viewSize[1] / 6.8];
  //     },
  //     // valueFormatter: function (value) {
  //     //   return value + ' ml';
  //     // },
  //     formatter: (params) => {
  //       if (!(params instanceof Array)) {
  //         return '';
  //       }
  //       const res = params.reduce((prev, point) => {
  //         if (!(point.value instanceof Array)) {
  //           return prev;
  //         }
  //         return (
  //           prev +
  //           `${point.marker} ${point.seriesName} <b> ${point.value[1]} ${this.getSerieUnit(
  //             point.seriesName ?? '',
  //           )}</b><br />`
  //         );
  //       }, '');
  //       return `<div>${res}</div>`;
  //     },
  //     extraCssText:
  //       'border-color: grey; background-color: #222b36; color: white; font-size: 14px; padding:5px;border-width:2px',
  //   };
  //   return this;
  // }
}
