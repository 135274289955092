import { useSuspenseQuery } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
} from '@mui/material';
import { RichTextReadOnly } from '@features/richTextEditor';
import { useDispatch, useSelector } from 'src/store';
import GET_ANNOUNCEMENT from '../graphql/queries/getAnnouncement';
import { setModalDisplayedAnnouncement } from '../slices/announcementsSlice';
import { type FC, Suspense } from 'react';
import { Create } from '@mui/icons-material';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

const AnnouncementsViewModal = () => {
  const { modalDisplayedAnnouncementId: id } = useSelector((state) => state.announcements_store);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(setModalDisplayedAnnouncement(null));
  };

  return (
    <Dialog maxWidth="md" fullWidth open={id != null} onClose={closeModal}>
      {id != null && (
        <Suspense>
          <AnnouncementContent id={id} onClose={closeModal} />
        </Suspense>
      )}
    </Dialog>
  );
};

interface AnnouncementContentProps {
  id: number;
  onClose: () => void;
}

const AnnouncementContent: FC<AnnouncementContentProps> = ({ id, onClose }) => {
  const { i18n } = useLingui();
  const { data } = useSuspenseQuery(GET_ANNOUNCEMENT, {
    variables: {
      input: { id },
    },
  });

  const { title, description, content, createdAt, updatedAt, author } = data.announcement;

  return (
    <>
      <DialogTitle>
        {title ?? ''}
        <Typography
          variant="body2"
          color="textSecondary"
          alignItems="center"
          display="flex"
          gap={1}
        >
          <Trans>Publicado el</Trans>{' '}
          {new Date(Number(createdAt)).toLocaleString(i18n.locale, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })}{' '}
          <Trans>por {author}</Trans>{' '}
          {createdAt !== updatedAt && (
            <Tooltip
              title={
                <>
                  <Trans>Actualizado el</Trans>{' '}
                  {new Date(Number(updatedAt)).toLocaleString(i18n.locale, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })}
                </>
              }
            >
              <Create fontSize="small" />
            </Tooltip>
          )}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        {description && <RichTextReadOnly content={content} />}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          <Trans>Cerrar</Trans>
        </Button>
      </DialogActions>
    </>
  );
};

export default AnnouncementsViewModal;
