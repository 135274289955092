import { FC, useEffect, useRef } from 'react';
import { Root, createRoot } from 'react-dom/client';

interface Props {
  map: google.maps.Map;
  position: google.maps.LatLngLiteral;
  children: React.ReactNode;
  onClick?: (event: Event) => void;
  selected?: boolean;
  gmpDraggable?: boolean;
  onDragEnd?: (position: google.maps.LatLngLiteral) => void;
}

const MapMarker: FC<Props> = ({
  map,
  position,
  children,
  onClick,
  selected,
  gmpDraggable = false,
  onDragEnd,
}) => {
  const markerRef = useRef<google.maps.marker.AdvancedMarkerElement>();
  const rootRef = useRef<Root>();

  useEffect(() => {
    if (!rootRef.current) {
      const container = document.createElement('div');
      rootRef.current = createRoot(container);

      markerRef.current = new window.google.maps.marker.AdvancedMarkerElement({
        map,
        position,
        content: container,
        gmpDraggable,
      });

      if (gmpDraggable) {
        markerRef.current.gmpDraggable = true;
        if (onDragEnd === undefined) return;
        markerRef.current!.addListener('dragend', () => {
          const position = markerRef.current!.position;
          if (position != null)
            onDragEnd(position as google.maps.LatLngLiteral);
        });
      }
    }

    return () => {
      if (markerRef.current) {
        markerRef.current.map = null;
      }
    };
  }, [map, position, gmpDraggable, onDragEnd]);

  useEffect(() => {
    if (!rootRef.current || !markerRef.current) return;
    rootRef.current.render(children);
    markerRef.current.position = position;
    markerRef.current.map = map;
    if (selected !== undefined) markerRef.current.zIndex = selected ? 1 : 0;
    if (onClick) markerRef.current.addEventListener('gmp-click', onClick);
    return () => {
      if (markerRef.current && onClick)
        markerRef.current.removeEventListener('gmp-click', onClick);
    };
  }, [map, position, children, onClick, selected]);

  return <></>;
};

export default MapMarker;
