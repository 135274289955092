import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modalDisplayedAnnouncementId: null as number | null,
};

const announcementsSlice = createSlice({
  name: 'announcements_store',
  initialState,
  reducers: {
    setModalDisplayedAnnouncement(state, action: { payload: number | null }) {
      state.modalDisplayedAnnouncementId = action.payload;
    },
    toggleModalDisplayedAnnouncement(state, action: { payload: number }) {
      state.modalDisplayedAnnouncementId =
        state.modalDisplayedAnnouncementId === action.payload ? null : action.payload;
    },
  },
});

const { actions, reducer } = announcementsSlice;

export const { setModalDisplayedAnnouncement, toggleModalDisplayedAnnouncement } = actions;
export { reducer };
