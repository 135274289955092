import { Box, Typography } from '@mui/material';
import ActionButtons from 'src/components/ActionButtons';
import { useFormatters } from 'src/hooks/useFormatters';

interface ConfirmationProps {
  ticketTimestamp: number;
  ticketID: string;
  handleConfirmCreation: () => void;
  handleCloseConfirmation: () => void;
}

const Confirmation = ({
  ticketID,
  ticketTimestamp,
  handleConfirmCreation,
  handleCloseConfirmation,
}: ConfirmationProps) => {
  const { formatDateTime } = useFormatters();
  return (
    <Box>
      <Typography>Está seguro que desea configurar Ticket DGA para el dispositivo?</Typography>
      <Box display="flex" flexDirection="row" justifyContent="space-between" my={2}>
        <Typography>Ticket ID:</Typography>
        <Typography>{ticketID}</Typography>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between" my={2}>
        <Typography>Fecha y hora:</Typography>
        <Typography>{formatDateTime(ticketTimestamp)}</Typography>
      </Box>
      <ActionButtons
        closeFunction={handleCloseConfirmation}
        actionFunction={handleConfirmCreation}
        actionButtonText="Confirmar"
      />
    </Box>
  );
};

export default Confirmation;
