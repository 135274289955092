import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { Device } from 'src/types/device';
import { isNil } from 'lodash';

interface CurrentPointInformation {
  photo: string;
  level: number;
  flow: number;
  date: string;
}

interface StoreDevice extends Device {
  id: string;
  name: string;
  flow: number;
  last_level: number;
  last_level_measured: string;
  node_id: number;
  source: string;
  currentPointInformation: CurrentPointInformation;
  latitude: number;
  longitude: number;
  distribution_max_flow: number;
  showFlow: boolean;
  levelAlerts: {
    level_min: number;
    level_max: number;
    overflow_level: number;
  };
  last_connection_state: {
    disconnect_reason: string;
    new_state: string;
    timestamp: string;
  };
  sensorData: {
    flow: number[];
    level: number[];
    measured: string[];
    photo: string[];
  };
  batteryData: {
    batterySoc: number[];
    measured: string[];
  };
  connectionData: {
    disconnectReason: string;
    newState: string;
    timestamp: string;
  }[];
  sensorLevels: {
    levelMin: number;
    levelMax: number;
    overFlowLevel: number;
  };
  distribution: {
    isDistribution: boolean;
    flow: number;
    date: number;
  }[];
}

interface MapState {
  stations: StoreDevice[] | null;
  distributionStationIds: string[];
  showDistributionOptions: boolean;
}

const initialState: MapState = {
  stations: [],
  distributionStationIds: [],
  showDistributionOptions: false,
};

const slice = createSlice({
  name: 'map_store',
  initialState,
  reducers: {
    resetStore() {
      return initialState;
    },
    setStations(
      state: MapState,
      action: PayloadAction<StoreDevice[] | null>,
    ): void {
      if (
        !isNil(action.payload) &&
        Array.isArray(action.payload) &&
        action.payload.length > 0
      ) {
        state.stations = action.payload.filter(
          (o) => o && o !== undefined && o !== null,
        );
      } else {
        state.stations = null;
      }
    },
    setDistributionStationIds(
      state: MapState,
      action: PayloadAction<string[]>,
    ): void {
      state.distributionStationIds = action.payload;
    },
    setShowDistributionOptions(
      state: MapState,
      action: PayloadAction<boolean>,
    ): void {
      state.showDistributionOptions = action.payload;
    },
  },
});

export const { reducer } = slice;

export const resetMapStore =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.resetStore());
  };

export const setStations =
  (values: StoreDevice[]): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setStations(values));
  };

export const setDistributionStationIds =
  (values: string[]): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setDistributionStationIds(values));
  };

export const setShowDistributionOptions =
  (value: boolean): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setShowDistributionOptions(value));
  };
