import { gql } from '@/__generated__';

const GET_DGA_CONFIGURATION_SCHEDULE = gql(`#graphql
  query GetDGAConfigurationSchedule($deviceInput: DeviceInput!) {
    device(input: $deviceInput) {
      id
      dgaConfiguration {
        id
        frequency
        hour
        weekday
        day
        enabled
      }
    }
  }
`);

export default GET_DGA_CONFIGURATION_SCHEDULE;
