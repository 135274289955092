import { gql } from 'src/__generated__';

const FETCH_IRRIGATIONS = gql(/* GraphQL */ `
  query fetchIrrigations {
    FetchIrrigations {
      id
      organization_id
      name
      region
      watershed
      kml_file
    }
  }
`);

export default FETCH_IRRIGATIONS;
