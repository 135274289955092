import { useEffect, useState } from 'react';
import { useAuth, useOrganizations } from 'src/hooks';

/**
 * Check if the user organization has debts.
 *
 * @returns `undefined` if is still checking.
 * `true` if has debts.
 * `false` if doesn't have debts.
 */
const useHasDebts = () => {
  const [hasDebts, setHasDebts] = useState<boolean>();
  const { organizations } = useOrganizations();
  const { user } = useAuth();

  useEffect(() => {
    if (user.organization_id && organizations.length > 0) {
      const userOrganization = organizations.find(
        (org) => org.id === user.organization_id,
      );
      setHasDebts(userOrganization?.has_debt || false);
    }
  }, [user, organizations]);

  return hasDebts;
};

export default useHasDebts;
