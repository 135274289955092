import { SvgIcon, SvgIconProps } from '@mui/material';

function DGAReportIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="m7.15,12.98h-2.13c-.41,0-.75.34-.75.75s.34.75.75.75h2.13c.41,0,.75-.34.75-.75s-.34-.75-.75-.75Z" />
      <path d="m7.15,16.58h-2.13c-.41,0-.75.34-.75.75s.34.75.75.75h2.13c.41,0,.75-.34.75-.75s-.34-.75-.75-.75Z" />
      <path d="m4.84,10.68h5.5c.41,0,.75-.34.75-.75s-.34-.75-.75-.75h-5.5c-.41,0-.75.34-.75.75s.34.75.75.75Z" />
      <path d="m23.25,17.33l-4.88-1.81V7.15c0-.26-.1-.52-.28-.71L13.05.96c-.2-.22-.48-.34-.77-.34H1.61c-.58,0-1.05.47-1.05,1.05v19.76c0,.58.47,1.05,1.05,1.05h7.83c-.09.29-.03.61.16.86.18.23.46.36.75.36.11,0,.22-.02.32-.06l12.57-4.53c.38-.13.63-.49.63-.89,0-.4-.25-.76-.62-.89ZM15.18,6.38h-2.17v-2.36l2.17,2.36Zm-4.4,14H2.66V2.72h8.85v4.41c0,.41.34.75.75.75h4.01v6.86l-5.59-2.07c-.39-.14-.82-.02-1.08.3-.25.32-.27.77-.05,1.12l2.71,4.07-1.48,2.23Zm1.95.5l1.46-2.2c.21-.32.21-.73,0-1.05l-1.44-2.16,7.39,2.74-7.42,2.68Z" />
    </SvgIcon>
  );
}

export default DGAReportIcon;
