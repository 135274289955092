import { gql } from '@/__generated__';

const GET_DEVICE_PROFILE_METRICS = gql(`#graphql
  query DeviceProfile_GetDeviceProfileMetrics($deviceId: ID!, $metricsInput: MetricsParametersInput!) {
    device(input: { id: $deviceId }) {
      id
      metrics(input: $metricsInput) {
        availableFields
        FLOW: flow {
          lastData
          data
          unit
          precision
        }
        LEVEL: level {
          lastData
          data
          unit
          precision
        }
        VELOCITY: velocity {
          lastData
          data
          unit
          precision
        }
        ANGLE: angle {
          lastData
          data
          unit
          precision
        }
        POSITION: position {
          lastData
          data
          unit
          precision
        }
        ACCUMULATED_VOLUME: accumulatedVolume {
          lastData
          data
          unit
          precision
        }
        BATTERY_SOC: batterySoc {
          lastData
          data
          unit
          precision
        }
        ORIGINAL_DISTANCE: originalDistance {
          lastData
          data
          unit
          precision
        }
      }
    }
  }
`);

export default GET_DEVICE_PROFILE_METRICS;
