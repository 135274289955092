import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modalPreferencesUserId: null as number | null,
};

const slice = createSlice({
  name: 'user_preferences_store',
  initialState,
  reducers: {
    setModalPreferencesUserId(state, action: { payload: number | null }) {
      state.modalPreferencesUserId = action.payload;
    },
  },
});

const { actions, reducer } = slice;

export const { setModalPreferencesUserId } = actions;
export default reducer;
