import { Trans } from '@lingui/macro';
import { Launch, Phone } from '@mui/icons-material';
import {
  Box,
  List,
  Skeleton,
  Typography,
  Button,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
} from '@mui/material';
import UserProfileAuditLogsSkeleton from './UserProfileAuditLogsSkeleton';

const UserProfileSkeleton = () => {
  return (
    <Box>
      <Box display="flex" gap={4} alignItems="center" p={2}>
        <Skeleton variant="circular" width={128} height={128} />
        <Box>
          <Typography fontSize={24} fontWeight="bold">
            <Skeleton width={200} />
          </Typography>
          <Typography color="text.secondary">
            <Skeleton width={100} />
          </Typography>
          <Button
            disabled
            variant="contained"
            color="info"
            sx={{ mt: 2 }}
            size="small"
            endIcon={<Launch />}
          >
            <Trans>Editar perfil</Trans>
          </Button>
        </Box>
      </Box>
      <Divider />
      <Box p={2}>
        <Typography variant="h6">
          <Trans>Información general</Trans>
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <Phone />
            </ListItemIcon>
            <ListItemText primary={<Skeleton width={100} />} />
          </ListItem>
        </List>
        <Typography variant="h6" mt={1}>
          <Trans>Organizaciones</Trans>
        </Typography>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Skeleton variant="circular" width={40} height={40} />
            </ListItemAvatar>
            <ListItemText primary={<Skeleton width={100} />} secondary={<Skeleton width={50} />} />
          </ListItem>
        </List>
        <UserProfileAuditLogsSkeleton />
      </Box>
    </Box>
  );
};

export default UserProfileSkeleton;
