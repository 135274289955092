import { EventEmitter } from 'events';
import { client } from 'src/graphql';
import CREATE_ACTIVITY_LOGS from 'src/graphql/mutations/activityLogs/createActivityLog';
import UPDATE_USER_ACCOUNT_ACCESS from 'src/graphql/mutations/activityLogs/updateUserAccountAccess';

const eventEmitter = new EventEmitter();

const registerActivity = async (activity: string) => {
    try {
        await client.mutate({
            mutation: CREATE_ACTIVITY_LOGS,
            variables: {
                activity: activity
            }
        });
    }
    catch (e) {
        console.log(e);
    }
}

const updateAccountAccess = async () => {
    try {
        await client.mutate({
            mutation: UPDATE_USER_ACCOUNT_ACCESS,
        });
    }
    catch (e) {
        console.log(e);
    }
}

const login = async () => {
    registerActivity('login');
    updateAccountAccess();
};

const logout = async () => {
    registerActivity('logout');
};

const access = async (userID: string) => {
    if (!eventAlreadyEmitted('access', userID)) {
        markEventAsEmitted('access', userID);
        cleanOldEvents('access', userID);
        registerActivity('access');
        updateAccountAccess();
    }
};


const eventAlreadyEmitted = (eventName: string, userID: string): boolean => {
    const key = getLocalStorageKey(eventName, userID);
    const eventEmitted = localStorage.getItem(key);
    return eventEmitted === 'true';
};

const markEventAsEmitted = (eventName: string, userID: string) => {
    const key = getLocalStorageKey(eventName, userID);
    localStorage.setItem(key, 'true');
};

const getLocalStorageKey = (eventName: string, userID: string): string => {
    return `${eventName}_${userID}_${getCurrentDate()}`;
};

const getCurrentDate = (): string => {
    const now = new Date();
    return `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;
};

const cleanOldEvents = (eventName: string, userID: string) => {
    const currentDate = getCurrentDate();
    const keysToRemove = Object.keys(localStorage).filter(key => key.startsWith(`${eventName}_${userID}_`) && key.split('_')[2] !== currentDate);

    keysToRemove.forEach(key => {
        localStorage.removeItem(key);
    });
};


const events: { [key: string]: (userID?: string) => Promise<void> } = {
    'login': login,
    'logout': logout,
    'access': access,
};

Object.keys(events).forEach((event) => {
    eventEmitter.on(event, events[event]);
});

export default eventEmitter;
