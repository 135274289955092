import { Timezone } from '@/__generated__/graphql';

const availableTz = [
  {
    value: 'America/Santiago',
    enumValue: Timezone.AmericaSantiago,
  },
  {
    value: 'America/Argentina/Cordoba',
    enumValue: Timezone.AmericaArgentinaCordoba,
  },
  {
    value: 'America/Punta_Arenas',
    enumValue: Timezone.AmericaPuntaArenas,
  },
  {
    value: 'America/Bahia_Banderas',
    enumValue: Timezone.AmericaBahiaBanderas,
  },
  {
    value: 'America/Cancun',
    enumValue: Timezone.AmericaCancun,
  },
  {
    value: 'America/Chihuahua',
    enumValue: Timezone.AmericaChihuahua,
  },
  {
    value: 'America/Ciudad_Juarez',
    enumValue: Timezone.AmericaCiudadJuarez,
  },
  {
    value: 'America/Ensenada',
    enumValue: Timezone.AmericaEnsenada,
  },
  {
    value: 'America/Hermosillo',
    enumValue: Timezone.AmericaHermosillo,
  },
  {
    value: 'America/Matamoros',
    enumValue: Timezone.AmericaMatamoros,
  },
  {
    value: 'America/Mazatlan',
    enumValue: Timezone.AmericaMazatlan,
  },
  {
    value: 'America/Merida',
    enumValue: Timezone.AmericaMerida,
  },
  {
    value: 'America/Mexico_City',
    enumValue: Timezone.AmericaMexicoCity,
  },
  {
    value: 'America/Monterrey',
    enumValue: Timezone.AmericaMonterrey,
  },
  {
    value: 'America/Ojinaga',
    enumValue: Timezone.AmericaOjinaga,
  },
  {
    value: 'America/Santa_Isabel',
    enumValue: Timezone.AmericaSantaIsabel,
  },
  {
    value: 'America/Tijuana',
    enumValue: Timezone.AmericaTijuana,
  },
  {
    value: 'UTC',
    enumValue: Timezone.Utc,
  },
];

export const getAvailableTimeZones = () => {
  const defaultTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const allTimeZones = ['UTC', ...Intl.supportedValuesOf('timeZone')];
  const supportedTimeZones = availableTz.filter((tz) => allTimeZones.some((t) => tz.value === t));

  supportedTimeZones.sort((a, b) => {
    const aValue = a.value === defaultTz ? '_0' : a.value === 'UTC' ? '_1' : a.value;
    const bValue = b.value === defaultTz ? '_0' : b.value === 'UTC' ? '_1' : b.value;
    return aValue.localeCompare(bValue);
  });

  return supportedTimeZones;
};

export const getTimeZone = (timezone: Timezone) => {
  return availableTz.find((tz) => tz.enumValue === timezone)?.value;
};
