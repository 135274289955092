import { useSelector } from 'src/store';
import Event from './typeEvents';
import { usePermission } from 'src/hooks';

interface Params {
  label: string;
  value?: string | number | boolean;
}

/**
 * Custom hook for tracking analytics events.
 * @param section - The section of the application where the events are being tracked.
 * @returns An object containing functions for tracking different types of events.
 */
const useAnalyticsEventTracker = (section: string) => {
  const { isCaptaOrganization } = usePermission();
  // const { typeTelemetry } = useSelector((state) => state.telemetry_chart_store);
  // TODO: get type telemetry, the one above was not available
  const typeTelemetry = '';

  /**
   * Tracks a click event.
   * @param params - The parameters for the click event.
   */
  const clickEvent = (params: Params) => {
    const { label, value } = params;
    Event('click-button', {
      event_section: section,
      event_label: label,
      event_value: value,
      event_user_type: isCaptaOrganization() ? 'capta' : 'client',
    });
  };

  /**
   * Tracks a selector change event.
   * @param params - The parameters for the selector change event.
   */
  const selectorEvent = (params: Params) => {
    const { label, value } = params;
    Event('selector-change', {
      event_section: section,
      event_label: label,
      event_value: value,
      event_user_type: isCaptaOrganization() ? 'capta' : 'client',
    });
  };

  /**
   * Tracks a checkbox change event.
   * @param params - The parameters for the checkbox change event.
   */
  const checkBoxEvent = (params: Params) => {
    const { label, value } = params;
    Event('check-box', {
      event_section: section,
      event_label: label,
      event_value: value ? 1 : 0,
      event_user_type: isCaptaOrganization() ? 'capta' : 'client',
    });
  };

  return { clickEvent, selectorEvent, checkBoxEvent };
};
export default useAnalyticsEventTracker;
