import * as Yup from 'yup';

export const schemaLogin = Yup.object().shape({
  email: Yup.string()
    .email('El correo electrónico tiene que se válido.')
    .max(255)
    .required('El correo electrónico es requerido.'),
  password: Yup.string()
    .max(255)
    .required('La contraseña es requerida.'),
})

export const schemaLoginTwoFactor = Yup.object().shape({
  code: Yup.string()
    .min(
      4,
      'El código de autenticación contiene un mínimo de 4 caracteres.',
    )
    .required('El código de autenticación es requerido.'),
})