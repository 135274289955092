import { gql } from '@/__generated__';

const GET_USER_PREFERENCES_METRICS_FACTORY_DEFAULTS = gql(`#graphql
  query GetUserPreferencesMetricsFactoryDefaults {
    user {
      id
      preferences {
        id
        metrics(defaultValues: true) {
          id
          fields {
            id
            name
            defaultConfiguration {
              precision
              unit
            }
            additionalConfigurations {
              deviceType
              precision
              unit
            }
          }
        }
      }
    }
  }
`);

export default GET_USER_PREFERENCES_METRICS_FACTORY_DEFAULTS;
