import { emptyToNull, validationMessages } from '@/utils/yupValidation';
import * as yup from 'yup';

export const curveSchema = (curveForm = true) =>
  yup.object({
    active: yup.boolean().required(validationMessages.required),
    levelMin: yup.number().transform(emptyToNull).nullable(),
    levelMax: yup.number().transform(emptyToNull).nullable(),
    created: yup
      .date()
      .transform(function (value, originalValue) {
        return this.isType(value) ? value : new Date(originalValue);
      })
      .required(validationMessages.required),
    updated: yup
      .date()
      .transform(function (value, originalValue) {
        return this.isType(value) ? value : new Date(originalValue);
      })
      .required(validationMessages.required),
    realizationDate: yup
      .date()
      .transform(function (value, originalValue) {
        return this.isType(value) ? value : new Date(originalValue);
      })
      .required(validationMessages.required),
    rSquared: yup.number().transform(emptyToNull).required(validationMessages.required),
    method: yup.string().nullable(),
    notes: yup.string().nullable(),
    formula: yup.object({
      a: yup
        .number()
        .transform(emptyToNull)
        .when('type', {
          is: 'power',
          then: (schema) => schema.required(validationMessages.required),
          otherwise: (schema) => schema.optional().nullable(),
        }),
      b: yup
        .number()
        .transform(emptyToNull)
        .when('type', {
          is: 'power',
          then: (schema) => schema.required(validationMessages.required),
          otherwise: (schema) => schema.optional().nullable(),
        }),
      ...(curveForm
        ? {
            a0: yup.number().transform(emptyToNull).nullable(),
            a1: yup.number().transform(emptyToNull).nullable(),
            a2: yup.number().transform(emptyToNull).nullable(),
            a3: yup.number().transform(emptyToNull).nullable(),
            a4: yup.number().transform(emptyToNull).nullable(),
          }
        : {
            coef: yup
              .array()
              .of(yup.number().transform(emptyToNull).nullable())
              .length(5)
              .when('type', {
                is: 'polynomial',
                then: (schema) => schema.required(validationMessages.required),
                otherwise: (schema) => schema.optional().nullable(),
              }),
          }),
      constant: yup.number().transform(emptyToNull).nullable(),
      offset: yup.number().transform(emptyToNull).nullable(),
      type: yup
        .string()
        .oneOf(['power', 'polynomial'])
        .required(validationMessages.oneOf(['power', 'polynomial'])),
    }),
  });

export const schema = yup.object({
  deviceId: yup.string().required(validationMessages.required),
  ratingCurves: yup.object({
    curves: yup.array().of(curveSchema(false)),
  }),
});
