// material-ui
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

interface Props {
  name: string;
  id: string;
  value: string;
  options?: {
    value: string;
    label: string;
  }[];
  onChange: any;
}

const SelectCustom = ({ name, id, value, options, onChange }: Props) => {
  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel id={id + '-label'}>{name}</InputLabel>
      <Select
        labelId={id + '-label'}
        label={name}
        id={id}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {options &&
          options.map((o) => (
            <MenuItem id={`${o.value}`} key={o.value} value={o.value}>
              {o.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SelectCustom;
