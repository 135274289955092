import { t } from '@lingui/macro';
import * as yup from 'yup';

const validationMessages = () => ({
  required: t`Este campo es obligatorio`,
  number: {
    typeError: t`El valor debe ser un número`,
    min: t`El valor debe ser menor al del caudal máximo`,
    max: t`El valor debe ser mayor al del caudal mínimo`,
    moreThan: (number: number) => t`El valor debe ser mayor a ${number}`,
  },
  oneOf: (values: string[]) => t`El valor debe ser uno de: ${values.join(', ')}`,
  testPiecewise: t`Los elementos de Piecewise deben tener un valor mínimo que coincida con el valor máximo del elemento anterior`,
  requiredCurveType: t`El tipo de curva es obligatorio y debe ser function, interval o piecewise`,
});

const emptyToNull = (value: string | number, originalValue: string | number) => {
  if (originalValue === '') return null;
  return value;
};

export const schema = yup.object().shape({
  deviceId: yup.string().required(),
  state: yup.object().shape({
    isDry: yup.boolean().required(),
  }),
  flowLimits: yup.object().shape({
    min: yup
      .number()
      .transform(emptyToNull)
      .optional()
      .nullable()
      .typeError(validationMessages().number.typeError)
      .min(0, validationMessages().number.moreThan(0))
      .lessThan(yup.ref('max'), validationMessages().number.min),
    max: yup
      .number()
      .transform(emptyToNull)
      .optional()
      .nullable()
      .typeError(validationMessages().number.typeError)
      .min(0, validationMessages().number.moreThan(0))
      .moreThan(yup.ref('min'), validationMessages().number.max),
  }),
});
