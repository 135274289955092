import { Email, MoneyOff, Phone } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import useHasDebts from '../hooks/useHasDebts';
import { Trans } from '@lingui/macro';

const DebtsAlertPopover = () => {
  const hasDebts = useHasDebts();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);

  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('md'));

  if (!hasDebts) return <></>;

  return (
    <>
      <Tooltip title="Saldo pendiente" placement="top-end">
        <IconButton color="inherit" onClick={handleClick}>
          <MoneyOff
            sx={{
              color: 'yellow',
            }}
          />
        </IconButton>
      </Tooltip>
      <Popover
        id="debt-alert-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Box width={isXS ? 300 : 500} sx={{ p: 2 }}>
          <Trans>
            <Typography mb={2}>
              <Trans>Estimado cliente,</Trans>
            </Typography>
            <Typography>
              <Trans>
                Hemos detectado que la cuenta de AMARU presenta un saldo pendiente. Favor contactar
                al servicio de post-venta de Capta Hydro:
              </Trans>
            </Typography>
            <List>
              <ListItem>
                <ListItemText>
                  <Trans>Cristóbal Matamala</Trans>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Phone />
                </ListItemIcon>
                <ListItemText>
                  <Link href="tel:+56992221736" color="inherit">
                    +56 9 9222 1736
                  </Link>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Email />
                </ListItemIcon>
                <ListItemText>
                  <Link href="mailto:cs@captahydro.com" color="inherit">
                    cs@captahydro.com
                  </Link>
                </ListItemText>
              </ListItem>
            </List>
            <Typography mb={2}>
              <Trans>Regularice ahora y evite limitaciones en el servicio.</Trans>
            </Typography>
            <Typography>
              <Trans>Equipo Capta Hydro</Trans>
            </Typography>
          </Trans>
        </Box>
      </Popover>
    </>
  );
};

export default DebtsAlertPopover;
