import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from '@/store';
import { setDistributionSelectedIrrigation } from '@/slices/distribution';
import { StandardAutocomplete, useAutocompleteOptions } from '@features/standardDesign';

const DistributionIrrigationSelect = () => {
  const location = useLocation();
  const { data } = useAutocompleteOptions('irrigations');
  const dispatch = useDispatch();
  const { selectedIrrigation } = useSelector((state) => state.distribution_store);
  useEffect(() => {
    if (data?.irrigations?.length) {
      dispatch(setDistributionSelectedIrrigation(data.irrigations[0]));
    }
  }, [data]);

  const show =
    location.pathname === '/distribution-initialization' ||
    location.pathname === '/distribution-history' ||
    location.pathname === '/distribution/canals';

  return (
    show && (
      <Box display={['none', 'flex', 'flex', 'flex']} width={{ xs: 200, sm: 300, md: 400 }} my={1}>
        <StandardAutocomplete
          fullWidth
          dataType="irrigations"
          value={selectedIrrigation}
          onChange={(_, value) => dispatch(setDistributionSelectedIrrigation(value))}
          size="small"
        />
      </Box>
    )
  );
};

export default DistributionIrrigationSelect;
