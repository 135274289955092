import { SvgIcon, SvgIconProps } from '@mui/material';

function IntegrationsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="m8.51,8.54l2.84,2.2s.05.04.07.05c.13.08.27.14.41.16,0,0,0,0,0,0,.06,0,.11.01.17.01.01,0,.03,0,.04,0,.21,0,.42-.08.59-.22h0s0,0,0,0l2.85-2.21c.27-.21.41-.52.41-.83,0-.22-.07-.45-.22-.64-.36-.46-1.01-.54-1.47-.19l-1.16.9V1.4c0-.58-.47-1.05-1.05-1.05s-1.05.47-1.05,1.05v6.37l-1.16-.9c-.46-.36-1.12-.27-1.47.19-.35.46-.27,1.12.19,1.47Z" />
      <path d="m8.28,16.76c-.08.58.32,1.1.9,1.18.58.08,1.1-.32,1.18-.9l.49-3.56s0-.05,0-.08c.01-.17-.02-.33-.08-.47,0,0,0,0,0,0,0,0,0,0,0,0-.02-.05-.05-.1-.07-.15h0s0,0,0,0c-.11-.18-.27-.33-.48-.41,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0l-3.34-1.36c-.54-.22-1.15.04-1.37.57-.05.13-.08.26-.08.4,0,.41.25.81.65.97l1.35.55-5.51,3.18c-.5.29-.67.93-.38,1.43.19.34.55.52.91.52.18,0,.36-.04.52-.14l5.51-3.18-.2,1.45Z" />
      <path d="m21.98,16.68l-5.51-3.18,1.35-.55c.54-.22.79-.83.57-1.37-.22-.54-.83-.79-1.37-.57l-3.33,1.36s-.06.02-.09.04c-.13.07-.24.16-.33.27h0c-.05.06-.09.13-.13.2t0,0h0c-.09.18-.13.39-.1.59,0,0,0,0,0,0h0s0,0,0,0h0s0,0,0,0l.49,3.57c.08.57.61.98,1.18.9.53-.07.91-.52.91-1.04,0-.05,0-.1,0-.14l-.2-1.45,5.51,3.18c.17.1.35.14.52.14.36,0,.72-.19.91-.52.29-.5.12-1.14-.38-1.43Z" />
      <path d="m22.65,7.98c-.58-1.46-1.44-2.77-2.55-3.88-.99-.98-2.13-1.77-3.39-2.34-.53-.24-1.15,0-1.39.53-.24.53,0,1.15.53,1.39,1.03.47,1.97,1.11,2.77,1.91.91.91,1.61,1.98,2.08,3.17.44,1.1.66,2.26.66,3.44,0,.27-.01.54-.04.8-.05.58.38,1.09.95,1.14.03,0,.06,0,.09,0,.54,0,1-.41,1.04-.96.03-.33.04-.66.04-.99,0-1.45-.27-2.87-.8-4.22Z" />
      <path d="m1.73,14.14c.58-.05,1.01-.56.96-1.14-.02-.27-.04-.54-.04-.81,0-1.18.22-2.34.66-3.44.47-1.2,1.17-2.26,2.08-3.17.8-.8,1.73-1.45,2.77-1.91.53-.24.76-.86.53-1.39-.24-.53-.86-.77-1.39-.53-1.27.57-2.41,1.36-3.39,2.34-1.11,1.11-1.97,2.42-2.55,3.88-.53,1.35-.8,2.77-.8,4.22,0,.33.01.66.04.99.05.55.51.96,1.04.96.03,0,.06,0,.09,0Z" />
      <path d="m17.57,19.71c-.65.48-1.37.88-2.12,1.18-2.2.87-4.69.87-6.89,0-.73-.29-1.43-.67-2.06-1.13-.47-.34-1.13-.24-1.47.23s-.24,1.12.23,1.47c.78.57,1.63,1.04,2.53,1.39,1.35.53,2.77.8,4.22.8s2.87-.27,4.22-.8c.93-.37,1.8-.85,2.6-1.45.47-.35.56-1,.22-1.47s-1-.56-1.47-.22Z" />
    </SvgIcon>
  );
}

export default IntegrationsIcon;
