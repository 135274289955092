import { type FC } from 'react';
import { DeviceDataConfigurationModalProps } from './CalibrationTab';
import { useSuspenseQuery } from '@apollo/client';
import GET_MASTER_DEVICE_CALIBRATION from '../graphql/queries/getMasterDeviceCalibration';
import { Box, Button, DialogActions, DialogContent, Typography } from '@mui/material';
import { Trans } from '@lingui/macro';

const LGCalibrationDisplay: FC<DeviceDataConfigurationModalProps> = ({ deviceId, handleClose }) => {
  const { data } = useSuspenseQuery(GET_MASTER_DEVICE_CALIBRATION, { variables: { deviceId } });
  const levelSensors =
    data.device.frankieSensors?.filter((sensor) => sensor.device.profile.sensor === 'L') ?? [];
  const gateSensors =
    data.device.frankieSensors?.filter((sensor) => sensor.device.profile.sensor === 'G') ?? [];

  return (
    <>
      <DialogContent
        sx={{
          maxWidth: 'md',
          minHeight: 500,
          margin: 'auto',
          borderBottom: '1px solid',
          borderColor: 'divider',
          pb: 0,
        }}
      >
        <Typography variant="h5" mb={2}>
          <Trans>Calibración dispositivos hijos</Trans>
        </Typography>

        <Box>
          {levelSensors.map((sensor) => (
            <Box key={sensor.device.id} mb={2}>
              <Typography variant="h6" mb={1}>
                <Trans>
                  Calibración {sensor.device.id} <i>(sensor: {sensor.device.profile.sensor})</i>
                </Trans>
              </Typography>
              <Box>
                <b>
                  <Trans>Nivel</Trans>:
                </b>{' '}
                {sensor.device.dataConfiguration.calibration?.level ?? 'N/A'} cm
              </Box>
            </Box>
          ))}
          {gateSensors.map((sensor) => (
            <Box key={sensor.device.id} mb={2}>
              <Typography variant="h6" mb={1}>
                <Trans>
                  Calibración {sensor.device.id} <i>(sensor: {sensor.device.profile.sensor})</i>
                </Trans>
              </Typography>
              <Box>
                <b>K</b>:{' '}
                {sensor.device.dataConfiguration.calibration?.positionFromAngle?.K ?? 'N/A'}
              </Box>
              <Box>
                <b>P</b>:{' '}
                {sensor.device.dataConfiguration.calibration?.positionFromAngle?.P ?? 'N/A'}
              </Box>
              <Box>
                <b>R</b>:{' '}
                {sensor.device.dataConfiguration.calibration?.positionFromAngle?.R ?? 'N/A'}
              </Box>
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions sx={{ maxWidth: 'md', margin: 'auto', py: 2 }}>
        <Box flexGrow={1} />
        <Button color="info" variant="outlined" onClick={() => handleClose()}>
          <Trans>Cerrar</Trans>
        </Button>
      </DialogActions>
    </>
  );
};

export default LGCalibrationDisplay;
