import moment from 'moment';

export const getNextExecutionDateMessage = (timestamp) => {
  if (timestamp) {
    const stringTime = moment
      .utc()
      .local()
      .add(1, 'day')
      .hour(6)
      .minutes(0)
      .format('DD/MM/yyyy HH:mm');
    return `Próxima fecha de ejecución: ${stringTime}<br>`;
  }
  return '';
};
