import { Close } from '@mui/icons-material';
import { Box, DialogTitle, IconButton, Typography } from '@mui/material';
import { FC } from 'react';

type Props = {
  onClose?: () => void;
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
};

const ModalTitle: FC<Props> = ({ onClose, title, subtitle }) => {
  return (
    <DialogTitle
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
        <Close />
      </IconButton>
      <Box>
        {typeof title === 'string' ? <Typography variant="h6">{title}</Typography> : title}
        {typeof subtitle === 'string' ? (
          <Typography variant="subtitle1" color="text.secondary">
            {subtitle}
          </Typography>
        ) : (
          subtitle
        )}
      </Box>
    </DialogTitle>
  );
};

export default ModalTitle;
