import { gql } from '@/__generated__';

const GET_DEVICE_RATING_CURVES = gql(`
  query GetDeviceRatingCurves($deviceId: String!) {
    deviceDataConfiguration(deviceId: $deviceId) {
      deviceId
      ratingCurves {
        curves {
          created
          updated
          realizationDate
          rSquared
          method
          notes
          formula {
            type: type
            a
            b
            offset
            constant
            coef
          }
          active
          levelMin
          levelMax
        }
      }
    }
  }
`);

export default GET_DEVICE_RATING_CURVES;
