import { gql } from '@/__generated__';

const UPDATE_DGA_MAX_FLOW = gql(`#graphql
  mutation UpdateDgaMaxFlow($deviceId: String!, $maxFlow: Float!) {
    UpdateMaxFlowConfiguration(deviceId: $deviceId, maxFlow: $maxFlow) {
      message
      success
    }
  }
`);

export default UPDATE_DGA_MAX_FLOW;
