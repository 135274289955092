import { Dialog, DialogContent } from '@mui/material';
import { type FC } from 'react';
import ModalTitle from './modal/ModalTitle';
import { useLingui } from '@lingui/react';
import { msg } from '@lingui/macro';
import ModalActions from './modal/ModalActions';

interface Props {
  url: string;
  title?: string;
  open: boolean;
  onClose: () => void;
}

const ImagePreviewModal: FC<Props> = ({ url, title, open, onClose }) => {
  const { _ } = useLingui();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <ModalTitle title={title ?? _(msg`Previsualización de imagen`)} onClose={onClose} />
      <DialogContent dividers>
        <img
          src={url}
          alt={_(msg`Imagen de perfil`)}
          style={{ width: '100%', height: 'auto', maxHeight: '80vh' }}
        />
      </DialogContent>
      <ModalActions onClose={onClose} />
    </Dialog>
  );
};

export default ImagePreviewModal;
