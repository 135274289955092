import { gql } from '@apollo/client';
import { isNil } from 'lodash';

// TODO: naguilar, Remove this function as it serves no purpose and causes confusion, is better to use raw graphql query strings
const genGQL = (
  type: string,
  name: string,
  params: {
    name: string;
    type: string;
  }[],
  attributes: string[],
  subAttributes?: any,
) => {
  let attributesString = attributes.join('\n');

  if (subAttributes) {
    attributesString.split('\n').forEach((father) => {
      if (subAttributes[father] && subAttributes[father].attributes) {
        attributesString = attributesString.replace(
          father,
          `${father} { ${subAttributes[father].attributes.join('\n')}}`,
        );
      }
    });
  }

  let paramsString = '';
  if (!isNil(params) && params.length > 0) {
    paramsString = `(${params.map(({ name: n, type: t }) => `$${n}: ${t}`)})`;
  }

  let subParamsString = '';
  if (!isNil(params) && params.length > 0) {
    subParamsString = `(${params.map(({ name: n }) => `${n}: $${n}`)})`;
  }

  return gql`
  ${type} ${name}${paramsString} {
    ${name}${subParamsString} {
      ${attributesString}
    }
  }
  `;
};

export default genGQL;
