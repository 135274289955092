import { gql } from '@/__generated__';

const GET_SHOULD_AUTH = gql(`
  query shouldAuth($userId: Int!) {
    shouldAuth(userId: $userId) {
      should_auth
    }
  }
`);

export default GET_SHOULD_AUTH;
