import { gql } from '@apollo/client';

const UPDATE_ALIASES_NAME = gql`
  mutation UpdateAliasesName(
    $name: String
    $device_id: String
    $organizationId: Int
  ) {
    UpdateAliasesName(
      name: $name
      device_id: $device_id
      organizationId: $organizationId
    ) {
      success
      message
    }
  }
`;
export default UPDATE_ALIASES_NAME;
