import { TextField, type TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';

interface Props extends Omit<TextFieldProps, 'type' | 'inputMode'> {
  inputMode?: 'decimal' | 'numeric';
}

const NumericTextField = forwardRef<HTMLInputElement, Props>((props, ref) => {
  return (
    <TextField
      {...props}
      ref={ref}
      inputMode={props.inputMode || 'decimal'}
      onBlur={(e) => {
        // replace value to valid float, replace comma to dot
        try {
          const value = parseFloat(e.target.value.replace(',', '.'));
          e.target.value = isNaN(value) ? '' : value.toString();
          props.onChange && props.onChange(e);
          props.onBlur && props.onBlur(e);
        } catch {
          e.target.value = '';
          props.onChange && props.onChange(e);
          props.onBlur && props.onBlur(e);
        }
      }}
    />
  );
});

export default NumericTextField;
