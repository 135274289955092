import { gql } from '@/__generated__';

const UPDATE_DEVICE_CALIBRATION_LEVEL = gql(`#graphql
  mutation DeviceCalibration_UpdateLevel($input: UpdateDeviceCalibrationLevelInput!) {
    updateDeviceCalibrationLevel(input: $input) {
      deviceId
      calibration {
        level
        positionFromAngle {
          K
          R
          P
        }
      }
    }
  }
`);

export default UPDATE_DEVICE_CALIBRATION_LEVEL;
