import { SvgIcon, SvgIconProps } from '@mui/material';

function DeviceAnalysisIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="m22.4,20.99H2.97v-3.45l2.81-3.21,2.56,3.45c.22.3.58.46.95.42.37-.04.69-.27.84-.6l3.58-7.75,2.9,3.75c.23.3.6.45.98.4.37-.05.69-.3.83-.66l3.41-8.68c.21-.54-.05-1.15-.59-1.36-.54-.21-1.15.05-1.36.59l-2.74,6.96-2.83-3.66c-.22-.29-.58-.45-.95-.4-.36.04-.68.27-.83.6l-3.57,7.71-2.29-3.07c-.19-.25-.48-.41-.8-.42-.32-.03-.62.12-.83.36l-2.08,2.38V1.55c0-.58-.47-1.05-1.05-1.05S.87.97.87,1.55v20.48c0,.58.47,1.05,1.05,1.05h20.48c.58,0,1.05-.47,1.05-1.05s-.47-1.05-1.05-1.05Z" />
    </SvgIcon>
  );
}

export default DeviceAnalysisIcon;
