import { gql } from '@/__generated__';

const UPDATE_BASIC_DEVICE_ALERT_CONFIG = gql(`#graphql
  mutation UpdateBasicDeviceAlertConfig(
    $deviceId: String!
    $alertType: AlertTypes!
    $min: Float!
    $max: Float!
    $overflow: Float!
    $unit: String!
) {
    updateBasicDeviceAlertConfig(
      deviceId: $deviceId
      alertType: $alertType
      min: $min
      max: $max
      overflow: $overflow
      unit: $unit
  )
  }
`);

export default UPDATE_BASIC_DEVICE_ALERT_CONFIG;
