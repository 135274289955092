import { Canal } from './Canal';

class ContributingCanal extends Canal {
  useMinDotacion: boolean = false;

  minDotacion: number = 0;

  constructor(
    stocks: number,
    useMinDotacion: boolean = false,
    minDotacion: number = 0,
  ) {
    super();
    this.stocks = stocks;
    this.useMinDotacion = useMinDotacion;
    this.minDotacion = minDotacion;
  }

  getContributionsWithMinDotacion(dotacion: number) {
    const { useMinDotacion, minDotacion } = this;
    if (!useMinDotacion) {
      return 0;
    }
    const flowMinDotacion = this.getFlowWithDotacion(minDotacion);
    const flowRights = this.getFlowWithDotacion(dotacion);
    return Math.max(flowRights - flowMinDotacion, 0);
  }

  getObligatoryContribution(
    dotacion: number,
    obligatoryDotacion: number,
  ): number {
    if (obligatoryDotacion > 0) {
      return (
        this.getFlowWithDotacion(dotacion) - this.stocks * obligatoryDotacion
      );
    }
    return 0;
  }

  compareCaseObligatory(obligatoryDotacion: number): boolean {
    const { useMinDotacion, minDotacion } = this;
    if (!useMinDotacion) {
      return false;
    }
    return minDotacion < obligatoryDotacion;
  }
}

export { ContributingCanal };
