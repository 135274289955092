import { Canal } from './Canal';

class CanalAA extends Canal {
  requiredFlow: number;

  extraFlow: number;

  constructor(stocks: number, requiredFlow: number, extraFlow: number) {
    super();
    this.stocks = stocks;
    this.requiredFlow = requiredFlow;
    this.extraFlow = extraFlow;
  }

  hasEnoughFlow(dotacion: number): boolean {
    return this.requiredFlow <= this.getFlowWithDotacion(dotacion);
  }

  flowNeeded(dotacion: number): number {
    return this.requiredFlow - this.getFlowWithDotacion(dotacion);
  }

  getFlowWithDotacion(dotacion: number): number {
    return this.stocks * dotacion + this.extraFlow;
  }
}

export { CanalAA };
