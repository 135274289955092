import { useQuery } from '@apollo/client';
import FETCH_ORGANIZATIONS from 'src/graphql/querys/fetchOrganizations';

const useOrganizations = () => {
  const { data = {}, loading, refetch } = useQuery(FETCH_ORGANIZATIONS);
  const { FetchOrganizations = null } = data;
  const organizations = FetchOrganizations ? FetchOrganizations : [];
  return { organizations, loading, refetch };
};

export default useOrganizations;
