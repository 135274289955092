import { useDispatch, useSelector } from 'src/store';
import CheckboxList from './CheckboxList';
import CheckboxListItem from './CheckboxListItem';
import { useAnalyticsEventTracker } from 'src/gaEvents';
import { TelemetryKey, toggleTelemetry } from '../../../slices/devicesAnalysisSlice';
import { useLingui } from '@lingui/react';
import { msg } from '@lingui/macro';

const SidebarEnergyCheckboxList = () => {
  const { _ } = useLingui();
  const dispatch = useDispatch();
  const { telemetry } = useSelector((state) => state.devices_analysis_store);
  const { checkBoxEvent } = useAnalyticsEventTracker('Station Analysis');

  return (
    <CheckboxList>
      <CheckboxListItem
        checked={telemetry[TelemetryKey.BatterySoc]}
        onClick={() => {
          checkBoxEvent({ label: 'battery-soc', value: !telemetry[TelemetryKey.BatterySoc] });
          dispatch(toggleTelemetry(TelemetryKey.BatterySoc));
        }}
        label={_(msg`Porcentaje batería`)}
      />
      <CheckboxListItem
        checked={telemetry[TelemetryKey.BatteryV]}
        onClick={() => {
          checkBoxEvent({ label: 'battery-voltage', value: !telemetry[TelemetryKey.BatteryV] });
          dispatch(toggleTelemetry(TelemetryKey.BatteryV));
        }}
        label={_(msg`Voltaje batería`)}
      />
      <CheckboxListItem
        checked={telemetry[TelemetryKey.ChargeP]}
        onClick={() => {
          checkBoxEvent({ label: 'charge-power', value: !telemetry[TelemetryKey.ChargeP] });
          dispatch(toggleTelemetry(TelemetryKey.ChargeP));
        }}
        label={_(msg`Potencia carga`)}
      />
      <CheckboxListItem
        checked={telemetry[TelemetryKey.ChargeI]}
        onClick={() => {
          checkBoxEvent({ label: 'charge-current', value: !telemetry[TelemetryKey.ChargeI] });
          dispatch(toggleTelemetry(TelemetryKey.ChargeI));
        }}
        label={_(msg`Corriente carga`)}
      />
      <CheckboxListItem
        checked={telemetry[TelemetryKey.SolarV]}
        onClick={() => {
          checkBoxEvent({ label: 'solar-voltage', value: !telemetry[TelemetryKey.SolarV] });
          dispatch(toggleTelemetry(TelemetryKey.SolarV));
        }}
        label={_(msg`Voltaje Panel`)}
      />
      <CheckboxListItem
        checked={telemetry[TelemetryKey.SolarI]}
        onClick={() => {
          checkBoxEvent({ label: 'solar-current', value: !telemetry[TelemetryKey.SolarI] });
          dispatch(toggleTelemetry(TelemetryKey.SolarI));
        }}
        label={_(msg`Corriente Panel`)}
      />
      <CheckboxListItem
        checked={telemetry[TelemetryKey.SolarP]}
        onClick={() => {
          checkBoxEvent({ label: 'solar-power', value: !telemetry[TelemetryKey.SolarP] });
          dispatch(toggleTelemetry(TelemetryKey.SolarP));
        }}
        label={_(msg`Potencia Panel`)}
      />
    </CheckboxList>
  );
};

export default SidebarEnergyCheckboxList;
