import { gql } from '@/__generated__';

const FETCH_GATES = gql(`
  query Gates(
    $search: String
    $order: String
    $orderBy: String
    $offset: Int
    $limit: Int
    $deviceId: String
    $organizations: [Int]
    $status: String
    $irrigationId: Int
  ) {
    gates(
      order: $order
      orderBy: $orderBy
      search: $search
      offset: $offset
      limit: $limit
      device_id: $deviceId
      organizations: $organizations
      status: $status
      irrigationId: $irrigationId
    ) {
      count
      gates {
        id
        logical_gate_id
        name
        type: type
        source
        logical_device_id
        node_id
        organization_id
        organization_name
        calibration_encoder
        extra_info
        gateMin
        gateMax
        upstream
        upstream_date
        downstream
        downstream_date
        position
        position_date
        parent_component_id
        has_alarm
        alarm_data
        alarm_armed
        longitude
        latitude
        icn_id
        status
        topic_version
        inclinometer
        lastMovement {
          timestamp
          startTimestamp
          endTimestamp
          startPosition
          finalPosition
          requestedPosition
          completed
          message
          requestId
          userName
        }
        timeseries {
          batterySoc {
            x
            y
          }
          solarV {
            x
            y
          }
        }
        downstreamDevice {
          id
          name
          type: type
          status
        }
        upstreamDevice {
          id
          name
          type: type
          status
        }
      }
    }
  }
`);

export default FETCH_GATES;
