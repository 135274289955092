import { gql } from 'src/__generated__';

const UPDATE_USER_ACCOUNT_ACCESS = gql(`
  mutation UpdateUserAccountAccess {
    updateUserAccountAccess {
      success
      message
    }
  }
`);

export default UPDATE_USER_ACCOUNT_ACCESS;
