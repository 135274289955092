import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { type PropsWithChildren, type FC, useState } from 'react';
import ModalDirtyConfirmation, { ConfirmationDialog } from './StandardModalDirtyConfirmation';
import ModalTabs from '@components/ModalTabs';

type Props = {
  title: JSX.Element | string;
  subtitle?: JSX.Element | string;
  open: boolean;
  onClose?: () => void;
  onSubmit?: (() => void) | 'submit';
  submitLoading?: boolean;
  dirtyForm?: boolean;
  onResetForm?: () => void;
  maxWidth?: DialogProps['maxWidth'];
  customButtons?: {
    label: string;
    onClick: () => void;
    color?: 'inherit' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
    disabled?: boolean;
    loading?: boolean;
  }[];
} & (
  | PropsWithChildren<{
      /** @default 'simple' */
      variant?: 'simple' | 'empty';
    }>
  | {
      variant: 'tabs';
      panels: Parameters<typeof ModalTabs>[0]['panels'];
    }
);

const StandardModal: FC<Props> = ({
  title,
  subtitle,
  open,
  onClose = () => {},
  onSubmit,
  submitLoading,
  dirtyForm,
  ...props
}) => {
  const noActions =
    onSubmit == null && (props.customButtons == null || props.customButtons.length === 0);
  const [confirmationDialog, setConfirmationDialog] = useState<ConfirmationDialog | null>(null);

  const handleClose = () => {
    if (dirtyForm) {
      setConfirmationDialog({
        open: true,
        title: '¿Estás seguro que deseas salir?',
        content: 'Los cambios que hayas realizado no se guardarán.',
        action: () => {
          setConfirmationDialog((prevState) => ({ ...prevState, open: false }));
          onClose();
        },
        actionButtonText: 'Salir sin guardar',
      });
    } else {
      onClose();
    }
  };

  const handleChangeTab = (changeTabAction: () => void) => {
    if (dirtyForm) {
      setConfirmationDialog({
        open: true,
        title: '¿Estás seguro que deseas cambiar de pestaña?',
        content: 'Los cambios que hayas realizado no se guardarán.',
        action: () => {
          setConfirmationDialog((prevState) => ({ ...prevState, open: false }));
          changeTabAction();
        },
        actionButtonText: 'Cambiar de pestaña',
      });
    } else {
      changeTabAction();
    }
  };

  const ModalButton: FC<ButtonProps & { loading?: boolean }> = ({ loading, ...props }) => {
    if (loading)
      return (
        <LoadingButton loading={loading} {...props}>
          {props.children}
        </LoadingButton>
      );
    else return <Button {...props}>{props.children}</Button>;
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth={props.maxWidth} fullWidth>
      <ModalDirtyConfirmation
        dirtyForm={!!dirtyForm}
        confirmationDialog={confirmationDialog}
        onClose={() => setConfirmationDialog(({ ...prevState }) => ({ ...prevState, open: false }))}
      />
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          ...(props.variant === 'tabs' && {
            backgroundColor: '#2a3542',
          }),
        }}
      >
        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
          <Close />
        </IconButton>
        <Box>
          {typeof title === 'string' ? <Typography variant="h6">{title}</Typography> : title}
          {typeof subtitle === 'string' ? (
            <Typography variant="subtitle1" color="text.secondary">
              {subtitle}
            </Typography>
          ) : (
            subtitle
          )}
        </Box>
      </DialogTitle>
      {props.variant !== 'tabs' ? (
        <DialogContent
          dividers
          sx={{
            ...(noActions && { borderBottom: 'none' }),
          }}
        >
          {props.children}
        </DialogContent>
      ) : (
        <ModalTabs
          changeTabMiddleware={handleChangeTab}
          panels={props.panels.map((panel) => ({
            ...panel,
            component: (
              <DialogContent
                dividers
                sx={{ borderTop: 'none', ...(noActions && { borderBottom: 'none' }) }}
              >
                {panel.component}
              </DialogContent>
            ),
          }))}
        />
      )}
      {!noActions && (
        <DialogActions
          sx={{ alignItems: 'center', justifyContent: 'space-between', gap: 2, px: 1 }}
        >
          <Box display="flex" gap={1}>
            {props.onResetForm != null && (
              <Button
                disabled={dirtyForm === false}
                onClick={props.onResetForm}
                variant="outlined"
                color="info"
              >
                Restablecer formulario
              </Button>
            )}
            {props.customButtons?.map(({ label, ...props }) => (
              <ModalButton variant="outlined" color="info" key={label} {...props}>
                {label}
              </ModalButton>
            ))}
          </Box>
          <Box display="flex" gap={1}>
            <Button onClick={handleClose} variant="outlined" color="info">
              Cerrar
            </Button>
            {onSubmit != null && (
              <ModalButton
                loading={submitLoading}
                {...(typeof onSubmit === 'function' ? { onClick: onSubmit } : { type: 'submit' })}
                color="primary"
                variant="contained"
                disabled={dirtyForm === false}
              >
                Guardar
              </ModalButton>
            )}
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default StandardModal;
