import { useEffect, useState, type FC } from 'react';
import { useFormatters } from '@/hooks/useFormatters';

interface Props {
  timestamp: number;
}

const TimeElapsed: FC<Props> = ({ timestamp }) => {
  const { formatRelativeTime } = useFormatters();
  const [now, setNow] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setNow(Date.now()), 1000);
    return () => clearInterval(interval);
  }, []);

  const relativeTime = formatRelativeTime(timestamp, null, null, now);
  return relativeTime;
};

export default TimeElapsed;
