import { TableBody, TableCell, TableRow } from '@mui/material';
import { sourceTypes } from '../data/types';
import { Maybe } from '@/__generated__/graphql';
import { useFormatters } from '@/hooks/useFormatters';

const RowTable = ({
  grouped,
  timestamp,
  deviceId,
  value,
  userId,
  source,
  count,
}: {
  grouped: boolean;
  timestamp?: number;
  deviceId?: string;
  value: string;
  userId?: Maybe<string>;
  source?: Maybe<string>;
  count?: number;
}) => {
  const { formatDateTime } = useFormatters();
  const sourceLabel = sourceTypes.find((item) => item.value === source)?.label;
  return (
    <TableBody>
      {grouped ? (
        <TableRow>
          <TableCell align="left">{value}</TableCell>
          <TableCell align="left">{count}</TableCell>
        </TableRow>
      ) : (
        <TableRow>
          <TableCell align="left">{timestamp ? formatDateTime(timestamp) : '-'}</TableCell>
          <TableCell align="left">{deviceId}</TableCell>
          <TableCell align="left">{value}</TableCell>
          <TableCell align="left">{sourceLabel}</TableCell>
          <TableCell align="left">{userId}</TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default RowTable;
