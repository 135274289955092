import { CircularProgress, Grid } from '@mui/material';

interface Props { height?: number }

const Loading = ({ height = 250 }: Props) => {
  return (
    <Grid container height={height}>
      <Grid
        item
        xs={12}
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export default Loading;
