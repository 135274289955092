import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import TextFields from '@mui/icons-material/TextFields';
import { Box, FormHelperText, Stack } from '@mui/material';
import type { Content, EditorOptions } from '@tiptap/core';
import {
  LinkBubbleMenu,
  MenuButton,
  RichTextEditorRef,
  RichTextEditor as RichTextEditorTipTap,
  TableBubbleMenu,
  insertImages,
} from 'mui-tiptap';
import { useCallback, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import useExtensions from '../hooks/useExtensions';
import '../styles/global.css';
import EditorMenuControls from './EditorMenuControls';

function fileListToImageFiles(fileList: FileList): File[] {
  return Array.from(fileList).filter((file) => {
    const mimeType = (file.type || '').toLowerCase();
    return mimeType.startsWith('image/');
  });
}

export interface ToolbarOptions {
  selectHeading?: boolean;
  selectFontSize?: boolean;
  buttonBold?: boolean;
  buttonItalic?: boolean;
  buttonUnderline?: boolean;
  buttonStrikethrough?: boolean;
  buttonSubscript?: boolean;
  buttonSuperscript?: boolean;
  selectTextColor?: boolean;
  selectHighlightColor?: boolean;
  buttonLink?: boolean;
  selectAlignment?: boolean;
  buttonOrderedList?: boolean;
  buttonBulletList?: boolean;
  buttonTaskList?: boolean;
  buttonBlockquote?: boolean;
  buttonCode?: boolean;
  buttonCodeBlock?: boolean;
  buttonUploadImages?: boolean;
  buttonHorizontalRule?: boolean;
  buttonInsertTable?: boolean;
  buttonRemoveFormatting?: boolean;
}

interface Props {
  onChange?: (value: string) => void;
  onBlur?: () => void;
  value: string;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  toolbarOptions?: ToolbarOptions | null;
}

export interface RichTextEditorMethods {
  setContent: (content: Content, emitUpdate?: boolean) => void;
}

const RichTextEditor = forwardRef<RichTextEditorMethods, Props>(
  (
    {
      onChange = () => {},
      onBlur = () => {},
      value,
      error = false,
      helperText = '',
      placeholder = 'Escribe aquí...',
      toolbarOptions = null,
    },
    ref,
  ) => {
    const editorRef = useRef<RichTextEditorRef>(null);
    const extensions = useExtensions({ placeholder });
    const [isEditable, setIsEditable] = useState(true);
    const [showMenuBar, setShowMenuBar] = useState(true);

    useImperativeHandle(ref, () => ({
      setContent: (content, emitUpdate) => {
        editorRef?.current?.editor?.commands.setContent(content, emitUpdate);
      },
    }));

    const handleNewImageFiles = useCallback(
      (files: File[], insertPosition?: number): void => {
        if (!editorRef?.current?.editor) return;

        const attributesForImageFiles = files.map((file) => ({
          src: URL.createObjectURL(file),
          alt: file.name,
        }));

        insertImages({
          images: attributesForImageFiles,
          editor: editorRef.current.editor,
          position: insertPosition,
        });
      },
      [editorRef],
    );

    // Allow for dropping images into the editor
    const handleDrop: NonNullable<EditorOptions['editorProps']['handleDrop']> = useCallback(
      (view, event) => {
        if (!(event instanceof DragEvent) || !event.dataTransfer) {
          return false;
        }

        const imageFiles = fileListToImageFiles(event.dataTransfer.files);
        if (imageFiles.length > 0) {
          const insertPosition = view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          })?.pos;

          handleNewImageFiles(imageFiles, insertPosition);

          // Return true to treat the event as handled. We call preventDefault
          // ourselves for good measure.
          event.preventDefault();
          return true;
        }

        return false;
      },
      [handleNewImageFiles],
    );

    // Allow for pasting images
    const handlePaste: NonNullable<EditorOptions['editorProps']['handlePaste']> = useCallback(
      (_view, event) => {
        if (!event.clipboardData) {
          return false;
        }

        const pastedImageFiles = fileListToImageFiles(event.clipboardData.files);
        if (pastedImageFiles.length > 0) {
          handleNewImageFiles(pastedImageFiles);
          // Return true to mark the paste event as handled. This can for
          // instance prevent redundant copies of the same image showing up,
          // like if you right-click and copy an image from within the editor
          // (in which case it will be added to the clipboard both as a file and
          // as HTML, which Tiptap would otherwise separately parse.)
          return true;
        }

        // We return false here to allow the standard paste-handler to run.
        return false;
      },
      [handleNewImageFiles],
    );

    return (
      <Box
        sx={{
          // An example of how editor styles can be overridden. In this case,
          // setting where the scroll anchors to when jumping to headings. The
          // scroll margin isn't built in since it will likely vary depending on
          // where the editor itself is rendered (e.g. if there's a sticky nav
          // bar on your site).
          '& .ProseMirror': {
            '& h1, & h2, & h3, & h4, & h5, & h6': {
              scrollMarginTop: showMenuBar ? 50 : 0,
            },
            '& .is-empty.is-editor-empty::before': {
              color: (theme) => `${theme.palette.text.secondary} !important`,
              ...(error && {
                color: (theme) => `${theme.palette.error.main} !important`,
              }),
            },
          },
          '& .MuiTiptap-FieldContainer-notchedOutline': {
            ...(error && {
              borderColor: (theme) => `${theme.palette.error.main} !important`,
            }),
          },
        }}
      >
        <RichTextEditorTipTap
          ref={editorRef}
          extensions={extensions}
          content={value}
          editable={isEditable}
          onUpdate={({ editor: _editor }) => {
            onChange(_editor.isEmpty ? '' : _editor.getHTML());
          }}
          onBlur={onBlur}
          editorProps={{
            handleDrop: handleDrop,
            handlePaste: handlePaste,
          }}
          renderControls={() => <EditorMenuControls toolbarOptions={toolbarOptions} />}
          RichTextFieldProps={{
            // The "outlined" variant is the default (shown here only as
            // example), but can be changed to "standard" to remove the outlined
            // field border from the editor
            variant: 'outlined',
            MenuBarProps: {
              hide: !showMenuBar,
            },
            // Below is an example of adding a toggle within the outlined field
            // for showing/hiding the editor menu bar, and a "submit" button for
            // saving/viewing the HTML content
            footer: (
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  borderTopStyle: 'solid',
                  borderTopWidth: 1,
                  borderTopColor: (theme) => theme.palette.divider,
                  py: 1,
                  px: 1.5,
                }}
              >
                <MenuButton
                  value="formatting"
                  tooltipLabel={showMenuBar ? 'Hide formatting' : 'Show formatting'}
                  size="small"
                  onClick={() => setShowMenuBar((currentState) => !currentState)}
                  selected={showMenuBar}
                  IconComponent={TextFields}
                />

                <MenuButton
                  value="formatting"
                  tooltipLabel={isEditable ? 'Prevent edits (use read-only mode)' : 'Allow edits'}
                  size="small"
                  onClick={() => setIsEditable((currentState) => !currentState)}
                  selected={!isEditable}
                  IconComponent={isEditable ? Lock : LockOpen}
                />
              </Stack>
            ),
          }}
        >
          {() => (
            <>
              <LinkBubbleMenu />
              <TableBubbleMenu />
            </>
          )}
        </RichTextEditorTipTap>
        <FormHelperText variant="outlined" error={error}>
          {helperText}
        </FormHelperText>
      </Box>
    );
  },
);

export default RichTextEditor;
