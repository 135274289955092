import { t } from '@lingui/macro';

export const getStatusSelectAdmin = () => [
  {
    key: 1,
    name: t`Activo`,
    status: 'active',
  },
  {
    key: 2,
    name: t`En Mantenimiento`,
    status: 'maintenance',
  },
  {
    key: 3,
    name: t`En prueba`,
    status: 'testing',
  },
];

export const getStatusSelectAccount = () => [
  {
    key: 1,
    name: t`Activo`,
    status: 'active',
  },
  {
    key: 2,
    name: t`En Mantenimiento`,
    status: 'maintenance',
  },
];
