import { UnitFlow, convertFlow } from 'src/utils/unitConverterValues';
import { ContributingDeviceOrganizationConfig } from '../../types/dataTypes';
import { CanalAA } from './CanalAA';
import { ContributingCanal } from './ContributingCanal';
import { CanalManager } from './CanalManager';

function generateCanalManager(
  requiredFlowAA: number,
  stocksAA: number,
  inputContributingCanals: ContributingDeviceOrganizationConfig[],
  riverFlow: number,
  extraFlowAA: number,
): CanalManager {
  const canalAA = new CanalAA(stocksAA, requiredFlowAA, extraFlowAA);
  const canals = inputContributingCanals.map((inputCanal) => {
    const { min_dotacion } = inputCanal;
    const useMinDotacion = min_dotacion !== null;
    return new ContributingCanal(
      inputCanal.stocks,
      useMinDotacion,
      min_dotacion,
    );
  });

  return new CanalManager(riverFlow, canalAA, canals);
}

export { generateCanalManager };
