export enum DialogEnum {
  edit = 'edit',
  delete = 'delete',
  null = 'none',
  changeStatus = 'changeStatus',
  updateFlowRange = 'updateFlowRange',
  updateIsDry = 'updateIsDry',
  manualData = 'manualData',
  userList = 'userList',
  filterParams = 'filterParams',
  updateCalibrationLevel = 'updateCalibrationLevel',
  aforosConfiguration = 'aforosConfiguration',
  createMeasurement = 'createMeasurement',
  createAlerts = 'createAlerts',
  updateTopicVersion = 'updateTopicVersion',
  deviceNameAliases = 'deviceNameAliases',
  radarParams = 'radarParams',
  gateMovementHistory = 'gateMovementHistory',
  updateAlarm = 'updateAlarm',
  clone = 'clone',
  configurationFilter = 'configurationFilter',
  configurations = 'configurations',
}
