import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { type FC } from 'react';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => void;
}

const DeleteModal: FC<Props> = ({ open, handleClose, handleDelete }) => {
  const onClose = () => {
    handleClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Trans>¿Estás seguro que deseas eliminar esta curva de aforo?</Trans>
      </DialogTitle>
      {/* <DialogContent dividers>Esta acción no se puede deshacer.</DialogContent> */}
      <DialogContent dividers>
        <Trans>
          Si quieres deshacer esta acción, presiona <b>Restablecer Formulario</b>
        </Trans>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          <Trans>Cancelar</Trans>
        </Button>
        <Button variant="contained" color="error" onClick={() => handleDelete()}>
          <Trans>Eliminar</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
