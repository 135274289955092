import { FC } from 'react';
import { Controller } from 'react-hook-form';
import FileInput from 'src/components/FileInput';

interface Props {
  name: string;
  label: string;
  control: any;
}

const FileInputController: FC<Props> = ({ name, label, control }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return <FileInput {...field} label={label} />;
      }}
    />
  );
};

export default FileInputController;
