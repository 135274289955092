import { gql } from 'src/__generated__';

const UPDATE_DEVICE_FLOW_LIMITS = gql(`
  mutation UpdateDeviceFlowLimits($input: UpdateDeviceFlowLimitsInput!) {
    updateDeviceFlowLimits(input: $input) {
      deviceId
      state {
        isDry
      }
      flowLimits {
        max
        min
      }
    }
  }
`);

export default UPDATE_DEVICE_FLOW_LIMITS;
