import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { parsePhoneNumber } from 'libphonenumber-js';
import { Currency, Language, MetricUnit, MonetaryValue, Timezone } from '@/__generated__/graphql';
import { useQuery } from '@apollo/client';
import GET_ORGANIZATION_PREFERENCES from '@/graphql/querys/getOrganizationPreferences';
import GET_USER_PREFERENCES from '@/graphql/querys/getUserPreferences';
import { getLocaleOptions } from '@/utils/i18n/getLanguageOptions';
import { getTimeZone } from '@/utils/i18n/timezone';
import { getRelativeTimeOptions } from '@/utils/i18n/getRelativeTimeOptions';
import { getUnit } from '@/utils/units';
import useAuth from './useAuth';

export type Formatters = ReturnType<typeof useFormatters>;

export type FormattersOptions = {
  locale?: string;
  timezone?: Timezone;
  numberLocale?: string | null;
  dateLocale?: string | null;
  hour12?: boolean | null;
  dateMonthFormat?: Intl.DateTimeFormatOptions['month'] | null;
};

export const useFormatters = (options?: FormattersOptions) => {
  const { _, i18n } = useLingui();
  const { user } = useAuth();
  const { data: organizationPreferencesData } = useQuery(GET_ORGANIZATION_PREFERENCES, {
    skip: !user,
  });
  const { data: userPreferencesData } = useQuery(GET_USER_PREFERENCES, { skip: !user });

  const defaultPreferences = {
    hour12: null,
    locale: Language.Es,
    timezone: Timezone.AmericaSantiago,
    numberLocale: null,
    dateLocale: null,
    dateMonthFormat: 'short',
  };

  const regionPreferences = userPreferencesData?.user.preferences.region ?? defaultPreferences;
  const organizationRegionPreferences =
    organizationPreferencesData?.organization.preferences ?? defaultPreferences;
  const localeOptions = getLocaleOptions(i18n.locale);

  let numberLocale =
    localeOptions.find((locale) => locale.enumValue === regionPreferences?.numberLocale)?.value ??
    i18n.locale;

  let dateLocale =
    localeOptions.find((locale) => locale.enumValue === regionPreferences?.dateLocale)?.value ??
    i18n.locale;
  const timezoneEnum = regionPreferences?.timezone;
  let timezone = timezoneEnum ? getTimeZone(timezoneEnum) : undefined;
  let hour12 = regionPreferences?.hour12 ?? organizationRegionPreferences?.hour12 ?? undefined;
  let dateMonthFormat = regionPreferences?.dateMonthFormat as Intl.DateTimeFormatOptions['month'];

  if (options) {
    const locale = options.locale ?? i18n.locale;
    if (options.numberLocale === null) numberLocale = locale;
    if (options.numberLocale != null)
      numberLocale =
        localeOptions.find((locale) => locale.enumValue === options.numberLocale)?.value ?? locale;
    if (options.dateLocale === null) dateLocale = locale;
    if (options.dateLocale != null)
      dateLocale =
        localeOptions.find((locale) => locale.enumValue === options.dateLocale)?.value ?? locale;
    if (options.timezone === null) timezone = undefined;
    if (options.timezone != null) timezone = getTimeZone(options.timezone);
    if (options.hour12 === null) hour12 = undefined;
    if (options.hour12 != null) hour12 = options.hour12;
    if (options.dateMonthFormat === null) dateMonthFormat = undefined;
    if (options.dateMonthFormat != null) dateMonthFormat = options.dateMonthFormat;
  }

  function formatNumber(number: number): string;
  function formatNumber(number: number, precision: number): string;
  function formatNumber(number: number, options: Intl.NumberFormatOptions): string;
  function formatNumber(number: number, options?: Intl.NumberFormatOptions | number) {
    if (typeof options === 'number') options = { maximumFractionDigits: options };
    const locale = Intl.NumberFormat.supportedLocalesOf([numberLocale, i18n.locale])[0];
    const defaultOptions = { maximumFractionDigits: 1 };
    return new Intl.NumberFormat(locale, options ?? defaultOptions).format(number);
  }

  const formatters = {
    formatNumber,
    formatDate(
      date: Date | number,
      options?:
        | Intl.DateTimeFormatOptions
        | ((options: Intl.DateTimeFormatOptions) => Intl.DateTimeFormatOptions),
    ) {
      if (date == null) throw new Error('Invalid date');
      const locale = Intl.DateTimeFormat.supportedLocalesOf([dateLocale, i18n.locale])[0];
      const defaultOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: dateMonthFormat,
        day: 'numeric',
        hour12,
        timeZone: timezone,
      };
      options ??= defaultOptions;
      if (typeof options === 'function') options = options(defaultOptions);
      return new Intl.DateTimeFormat(locale, options).format(date);
    },
    formatDateTime(
      date: Date | number,
      options?:
        | Intl.DateTimeFormatOptions
        | ((options: Intl.DateTimeFormatOptions) => Intl.DateTimeFormatOptions),
    ) {
      if (date == null) throw new Error('Invalid date');
      const locale = Intl.DateTimeFormat.supportedLocalesOf([dateLocale, i18n.locale])[0];
      const defaultOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: dateMonthFormat,
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timezone,
      };
      options ??= defaultOptions;
      if (typeof options === 'function') options = options(defaultOptions);
      return new Intl.DateTimeFormat(locale, options).format(date);
    },
    formatDateTimeRange(
      startDate: Date | number,
      endDate?: Date | number | null,
      options?:
        | Intl.DateTimeFormatOptions
        | ((options: Intl.DateTimeFormatOptions) => Intl.DateTimeFormatOptions),
    ) {
      if (startDate == null) throw new Error('Invalid start date');
      const locale = Intl.DateTimeFormat.supportedLocalesOf([dateLocale, i18n.locale])[0];
      const defaultOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: dateMonthFormat,
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12,
        timeZone: timezone,
      };
      options ??= defaultOptions;
      if (typeof options === 'function') options = options(defaultOptions);
      if (!endDate)
        return new Intl.DateTimeFormat(locale, options).format(startDate) + ' - ' + _(msg`ahora`);
      return new Intl.DateTimeFormat(locale, options).formatRange(startDate, endDate);
    },
    formatRelativeTime(
      date: Date | number,
      unit?: Intl.RelativeTimeFormatUnit | null,
      options?:
        | Intl.RelativeTimeFormatOptions
        | ((options: Intl.RelativeTimeFormatOptions) => Intl.RelativeTimeFormatOptions)
        | null,
      now?: number | null,
    ) {
      const locale = Intl.RelativeTimeFormat.supportedLocalesOf([dateLocale, i18n.locale])[0];
      now ??= Date.now();
      const defaultOptions: Intl.RelativeTimeFormatOptions = { numeric: 'auto' };
      options ??= defaultOptions;
      if (typeof options === 'function') options = options(defaultOptions);
      const timestamp = date instanceof Date ? date.getTime() : date;
      if (unit != null) return new Intl.RelativeTimeFormat(locale, options).format(timestamp, unit);
      const [value, _unit] = getRelativeTimeOptions(timestamp, now);
      return new Intl.RelativeTimeFormat(locale, options).format(value, _unit);
    },
    formatPhoneNumber(phoneNumber: string) {
      try {
        const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
        return parsedPhoneNumber ? parsedPhoneNumber.formatInternational() : phoneNumber;
      } catch {
        return phoneNumber;
      }
    },
    formatCurrency(
      value: number,
      currency: string | undefined,
      signDisplay?: Intl.NumberFormatOptions['signDisplay'],
    ) {
      const locale = Intl.NumberFormat.supportedLocalesOf([numberLocale, i18n.locale])[0];
      const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        signDisplay,
      });
      return formatter.format(value);
    },
    formatMonetaryValue(
      monetaryValue: MonetaryValue,
      signDisplay?: Intl.NumberFormatOptions['signDisplay'],
    ) {
      if (monetaryValue.currency === Currency.Clp)
        return formatters.formatCurrency(monetaryValue.value, 'CLP', signDisplay);
      if (monetaryValue.currency === Currency.Uf)
        return `UF ${formatters.formatNumber(monetaryValue.value, { maximumFractionDigits: 4 })}`;
      return `${monetaryValue.currency} ${formatters.formatNumber(monetaryValue.value, {
        maximumFractionDigits: 4,
        signDisplay,
      })}`;
    },
    formatMetricValue(
      value: number,
      {
        unit,
        precision,
        unitDisplay,
      }: {
        unit: MetricUnit;
        precision: number;
        unitDisplay?: Intl.NumberFormatOptions['unitDisplay'] | null;
      },
    ) {
      if (unit === MetricUnit.None) return formatters.formatNumber(value, precision);
      const unitObject = getUnit(unit, i18n);
      try {
        if (unitObject?.value.includes('liter') && unitDisplay !== 'long') throw new Error();
        return formatters.formatNumber(value, {
          style: 'unit',
          unit: unitObject?.value,
          unitDisplay: unitDisplay ?? 'short',
          maximumFractionDigits: precision,
        });
      } catch {
        const translatedUnit = unitDisplay === 'long' ? unitObject?.long : unitObject?.short;
        const separator = unitDisplay === 'narrow' ? '' : ' ';
        return formatters.formatNumber(value, precision) + separator + translatedUnit;
      }
    },
  };
  return formatters;
};
