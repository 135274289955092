import { useLingui } from '@lingui/react';
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import { getLanguageOptions, getLocaleOptions } from '@/utils/i18n/getLanguageOptions';
import { getAvailableTimeZones } from '@/utils/i18n/timezone';
import { useFormatters } from '@/hooks/useFormatters';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import UPDATE_USER_PREFERENCES_REGION from '../graphql/mutation/updateUserPreferencesRegion';
import { MetricUnit, type UpdateUserPreferencesRegionInput } from '@/__generated__/graphql';
import GET_USER_PREFERENCES_REGION from '../graphql/queries/getUserPreferencesRegion';
import { LoadingButton } from '@mui/lab';
import { useModalStatusMessage } from 'src/hooks';
import { useEffect } from 'react';
import { Trans, msg } from '@lingui/macro';
import GET_USER_PREFERENCES_REGION_FACTORY_DEFAULTS from '../graphql/queries/getUserPreferencesRegionDefaultValues';

type FieldValues = UpdateUserPreferencesRegionInput;

const RegionTab = () => {
  const { i18n, _ } = useLingui();
  const { openModalSuccessMessage, openModalErrorMessage } = useModalStatusMessage();
  const { data } = useSuspenseQuery(GET_USER_PREFERENCES_REGION, {
    fetchPolicy: 'no-cache',
  });
  const { data: factoryDefaultsData } = useSuspenseQuery(
    GET_USER_PREFERENCES_REGION_FACTORY_DEFAULTS,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const availableTimeZones = getAvailableTimeZones();
  const [update] = useMutation(UPDATE_USER_PREFERENCES_REGION, {
    onCompleted: (data) => {
      openModalSuccessMessage(_(msg`Preferencias actualizadas correctamente`));
      const { id, ...defaultValues } = data.updateUserPreferencesRegion;
      methods.reset({ ...defaultValues, userId: id });
    },
    onError: (error) => {
      openModalErrorMessage(error.message);
    },
  });

  const defaultValues: FieldValues = {
    userId: data.user.id,
    locale: data.user.preferences.region.locale,
    timezone: data.user.preferences.region.timezone,
    numberLocale: data.user.preferences.region.numberLocale,
    dateLocale: data.user.preferences.region.dateLocale,
    hour12: data.user.preferences.region.hour12,
    dateMonthFormat: data.user.preferences.region.dateMonthFormat,
  };

  const methods = useForm<FieldValues>({
    defaultValues,
  });

  const {
    formatNumber,
    formatDateTime,
    formatDate,
    formatCurrency,
    formatMetricValue,
    formatRelativeTime,
    formatDateTimeRange,
  } = useFormatters({
    locale: methods.watch('locale') ?? null,
    timezone: methods.watch('timezone') ?? null,
    hour12: methods.watch('hour12') ?? null,
    dateLocale: methods.watch('dateLocale') ?? null,
    numberLocale: methods.watch('numberLocale') ?? null,
    dateMonthFormat:
      (methods.watch('dateMonthFormat') as Intl.DateTimeFormatOptions['month'] | undefined) ?? null,
  });
  const localeOptions = getLocaleOptions(methods.watch('locale') ?? i18n.locale);
  const languageOptions = getLanguageOptions(methods.watch('locale') ?? i18n.locale);

  const submitHandler: SubmitHandler<FieldValues> = async (data) => {
    await update({
      variables: {
        input: data,
      },
    });
  };

  const resetHandler = () => {
    methods.reset(defaultValues);
  };

  const factoryDefaultsHandler = () => {
    methods.reset(
      {
        ...factoryDefaultsData.user.preferences.region,
        userId: factoryDefaultsData.user.id,
      },
      {
        keepDefaultValues: true,
      },
    );
  };

  useEffect(() => {
    methods.reset(defaultValues);
  }, [data]);

  const { formState } = methods;

  return (
    <form style={{ height: '100%' }} onSubmit={methods.handleSubmit(submitHandler)}>
      <Box
        height="100%"
        gap={2}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box width="100%" display="flex" flexDirection="column" gap={2}>
          <Controller
            name="locale"
            control={methods.control}
            render={({ field }) => (
              <Autocomplete
                fullWidth
                size="small"
                value={field.value}
                onChange={(_, newValue) => {
                  field.onChange(newValue);
                }}
                options={languageOptions.map((option) => option.enumValue)}
                getOptionLabel={(option) => {
                  const label =
                    languageOptions.find((lang) => lang.enumValue === option)?.label ?? option;
                  return label.charAt(0).toUpperCase() + label.slice(1);
                }}
                renderInput={(params) => <TextField {...params} label={<Trans>Idioma</Trans>} />}
              />
            )}
          />
          <Controller
            name="timezone"
            control={methods.control}
            render={({ field }) => (
              <Autocomplete
                fullWidth
                size="small"
                value={field.value}
                onChange={(_, newValue) => {
                  field.onChange(newValue);
                }}
                options={availableTimeZones.map((option) => option.enumValue)}
                getOptionLabel={(option) => {
                  const timezone = availableTimeZones.find((tz) => tz.enumValue === option)?.value;
                  if (!timezone) return option;
                  const date = formatDate(new Date(), (options) => ({
                    ...options,
                    month: '2-digit',
                    year: '2-digit',
                    timeZoneName: 'longOffset',
                    timeZone: timezone,
                  }));
                  const offset = date.split(' ')[1];

                  return `(${offset}) ${timezone}`;
                }}
                renderInput={(params) => (
                  <TextField {...params} label={<Trans>Zona horaria</Trans>} />
                )}
              />
            )}
          />

          <Typography variant="h6">
            <Trans>Formato de números</Trans>
          </Typography>

          <Box display="grid" gap={4} gridTemplateColumns="1fr 1fr">
            <Controller
              name="numberLocale"
              control={methods.control}
              render={({ field }) => (
                <Autocomplete
                  fullWidth
                  size="small"
                  value={field.value}
                  onChange={(_, newValue) => {
                    field.onChange(newValue);
                  }}
                  options={localeOptions.map((option) => option.enumValue)}
                  getOptionLabel={(option) => {
                    return localeOptions.find((loc) => loc.enumValue === option)?.label ?? option;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label={<Trans>Lenguaje de números</Trans>} />
                  )}
                />
              )}
            />
            <Box>
              <Typography fontSize="large" fontWeight="medium">
                {formatNumber(123456789.12, { maximumFractionDigits: 2 })}
              </Typography>
              <Typography fontSize="large" fontWeight="medium">
                {formatCurrency(400000, 'CLP')}
              </Typography>
              <Typography fontSize="large" fontWeight="medium">
                {formatMetricValue(400000, {
                  unit: MetricUnit.LiterPerSecond,
                  precision: 2,
                })}
              </Typography>
            </Box>
          </Box>

          <Typography variant="h6">
            <Trans>Formato de fecha</Trans>
          </Typography>
          <Box display="grid" gap={4} gridTemplateColumns="1fr 1fr">
            <Box display="flex" flexDirection="column" gap={2}>
              <Controller
                name="dateLocale"
                control={methods.control}
                render={({ field }) => (
                  <Autocomplete
                    fullWidth
                    size="small"
                    value={field.value}
                    onChange={(_, newValue) => {
                      field.onChange(newValue);
                    }}
                    options={localeOptions.map((option) => option.enumValue)}
                    getOptionLabel={(option) => {
                      return localeOptions.find((loc) => loc.enumValue === option)?.label ?? option;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label={<Trans>Lenguaje de fecha</Trans>} />
                    )}
                  />
                )}
              />
              <Controller
                name="hour12"
                control={methods.control}
                render={({ field }) => (
                  <Autocomplete
                    fullWidth
                    size="small"
                    value={field.value}
                    onChange={(_, newValue) => {
                      field.onChange(newValue);
                    }}
                    options={[true, false]}
                    getOptionLabel={(option) => (option ? _(msg`12 horas`) : _(msg`24 horas`))}
                    renderInput={(params) => (
                      <TextField {...params} label={<Trans>Formato de hora</Trans>} />
                    )}
                  />
                )}
              />
              <Controller
                name="dateMonthFormat"
                control={methods.control}
                render={({ field }) => (
                  <Autocomplete
                    fullWidth
                    size="small"
                    value={field.value}
                    onChange={(_, newValue) => {
                      field.onChange(newValue);
                    }}
                    options={['long', 'short', 'narrow', 'numeric', '2-digit']}
                    getOptionLabel={(option) => {
                      if (option === 'long') return _(msg`Largo`);
                      if (option === 'short') return _(msg`Corto`);
                      if (option === 'narrow') return _(msg`Angosto`);
                      if (option === 'numeric') return _(msg`Numérico`);
                      if (option === '2-digit') return _(msg`2 dígitos`);
                      return option;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label={<Trans>Formato de mes</Trans>} />
                    )}
                  />
                )}
              />
            </Box>
            <Box>
              <Typography fontSize="large" fontWeight="medium">
                {formatDateTime(new Date())}
                <br />
                {formatDate(new Date())}
                <br />
                {formatRelativeTime(Date.now() - 1000 * 60 * 60 * 1)}
                <br />
                {formatRelativeTime(Date.now() - 1000 * 60 * 60 * 24)}
                <br />
                {formatDateTimeRange(Date.now() - 1000 * 60 * 60 * 1)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="end" gap={1}>
          <Button onClick={factoryDefaultsHandler} variant="outlined" color="warning">
            <Trans>Restablecer valores por defecto</Trans>
          </Button>
          <Box flexGrow={1}></Box>
          <Button
            onClick={resetHandler}
            disabled={!formState.isDirty}
            variant="outlined"
            color="info"
          >
            <Trans>Restablecer formulario</Trans>
          </Button>
          <LoadingButton
            type="submit"
            loading={formState.isSubmitting}
            disabled={!formState.isDirty}
            variant="contained"
          >
            <Trans>Guardar</Trans>
          </LoadingButton>
        </Box>
      </Box>
    </form>
  );
};

export default RegionTab;
