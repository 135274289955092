import { ListItem, ListItemButton, ListItemIcon, Checkbox, ListItemText } from '@mui/material';
import { type FC } from 'react';

interface Props {
  secondaryAction?: React.ReactNode;
  checked?: boolean;
  onClick?: () => void;
  label?: string;
  name?: string;
}

const CheckboxListItem: FC<Props> = ({
  secondaryAction,
  checked = false,
  onClick = () => {},
  label = '',
  name = '',
}) => {
  return (
    <ListItem secondaryAction={secondaryAction} disablePadding>
      <ListItemButton role={undefined} onClick={onClick} dense>
        <ListItemIcon>
          <Checkbox
            name={name}
            edge="start"
            checked={checked}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': label }}
          />
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </ListItem>
  );
};

export default CheckboxListItem;
