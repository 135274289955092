// DEPRECATED ENCRYPTION
//
// ONCE CHANGED THIS ENCRYPTION METHOD REMOVE NEXT MODULES:
// yarn remove stream-browserify browserify-zlib events process util buffer crypto-browserify
// also remove scripts on index.html and lines on vite.config.ts

import crypto from 'crypto-browserify';
import { Buffer } from 'buffer/'; // <-- no typo here ("/")

const secretKey =
  import.meta.env.VITE_CRYPTO_SECRET_KEY ?? '4hv7e32D3njG6nPF7GnGpKJaJW4MNzUB';
export const iv = crypto.randomBytes(16);
const getD = (ivs: Buffer | crypto.BinaryLike | null) =>
  crypto.createDecipheriv('aes-256-ctr', secretKey, ivs);
export const enc = (str: string) => {
  const ci = crypto.createCipheriv('aes-256-ctr', secretKey, iv);
  return Buffer.concat([ci.update(str), ci.final()]).toString('hex');
};
export const dec = (str: string, ivs: string) => {
  const di = getD(Buffer.from(ivs, 'hex'));
  return Buffer.concat([
    di.update(Buffer.from(str, 'hex')),
    di.final(),
  ]).toString();
};
