import { Autocomplete, type ChipTypeMap, TextField, type AutocompleteProps } from '@mui/material';
import { PropsWithRef, forwardRef } from 'react';
import { type StandardAutocompleteDataQuery } from '@/__generated__/graphql';
import { useAutocompleteOptions } from './hooks/useAutocompleteOptions';
import { useLingui } from '@lingui/react';
import { msg, plural } from '@lingui/macro';

type DataType = 'devices' | 'organizations' | 'irrigations' | 'users';
export type StandardAutocompleteValue<T extends DataType> = NonNullable<
  StandardAutocompleteDataQuery[T]
>[number];

interface Props<
  T extends DataType,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> extends Omit<
    AutocompleteProps<
      StandardAutocompleteValue<T>,
      Multiple,
      DisableClearable,
      FreeSolo,
      ChipComponent
    >,
    'options' | 'renderInput'
  > {
  dataType: T;
  label?: string;
  error?: boolean;
  helperText?: string;
  fallback?: React.ReactNode;
  queryOptions?: Parameters<typeof useAutocompleteOptions>[1];
}
const Component = <
  T extends DataType,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  // FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType,
>(
  props: Props<T, Multiple, DisableClearable, false, ChipComponent>,
  ref: React.ForwardedRef<
    typeof Autocomplete<StandardAutocompleteValue<T>, Multiple, DisableClearable>
  >,
) => {
  const { _ } = useLingui();
  const { error, helperText, label, dataType, queryOptions, ...autocompleteProps } = props;
  const { data, loading } = useAutocompleteOptions(dataType, queryOptions);
  
  const getLabel = () => {
    if (label) return label;
    const count = props.multiple ? 2 : 1;
    if (dataType === 'devices')
      return _(
        plural(count, {
          one: 'Dispositivo',
          other: 'Dispositivos',
        }),
      );
    if (dataType === 'organizations')
      return _(
        plural(count, {
          one: 'Organización',
          other: 'Organizaciones',
        }),
      );
    if (dataType === 'irrigations')
      return _(
        plural(count, {
          one: 'Red de canales',
          other: 'Redes de canales',
        }),
      );
    if (dataType === 'users')
      return _(
        plural(count, {
          one: 'Usuario',
          other: 'Usuarios',
        }),
      );
    return '';
  };

  const getOptionLabel = (value: StandardAutocompleteValue<T>) => {
    if (!value) return '';
    if (value.__typename === 'Device') return `${value.id} - ${value.profile.name}`;
    if (value.__typename === 'Organization') return value.name;
    if (value.__typename === 'Irrigation') return `${value.id} - ${value.name}`;
    if (value.__typename === 'User2') return `${value.id} - ${value.name} ${value.lastnames}`;
    return '';
  };

  return (
    <Autocomplete
      {...autocompleteProps}
      ref={ref}
      loading={loading}
      loadingText={_(msg`Cargando...`)}
      options={data?.[dataType] ?? []}
      getOptionLabel={autocompleteProps.getOptionLabel ?? getOptionLabel}
      renderInput={(params) => (
        <TextField {...params} label={label ?? getLabel()} error={error} helperText={helperText} />
      )}
    />
  );
};

const StandardAutocomplete = forwardRef(Component) as <
  T extends DataType,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  // FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>(
  props: PropsWithRef<Props<T, Multiple, DisableClearable, false, ChipComponent>>,
) => ReturnType<typeof Component>;

export default StandardAutocomplete;
