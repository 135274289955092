// react
import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useReducer } from 'react';

// logrocket
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

// types
import _ from 'lodash';
import type { User } from '../types/user';

// utils
import eventEmitter from '../utils/eventEmitter';

// logrocket init
const LogRocketKey = import.meta.env.VITE_REACT_APP_LOGROCKET_ID;

if (LogRocketKey) {
  LogRocket.init(LogRocketKey);
  setupLogRocketReact(LogRocket);
}

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  isSimulating: boolean;
  user: User | null;
}

interface AuthContextValue extends State {
  platform: 'JWT';
  login: (user: User) => Promise<void>;
  simulate: (user: User) => void;
  stopUserSimulation: () => void;
  logout: () => Promise<void>;
  updateUser: (user: User) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    isSimulating: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type SimulateAction = {
  type: 'SIMULATE';
  payload: {
    user: User;
  };
};

type StopSimulationAction = {
  type: 'STOP_SIMULATION';
  payload: {
    user: User;
  };
};

type UpdateAction = {
  type: 'UPDATE_USER';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | SimulateAction
  | StopSimulationAction
  | LogoutAction
  | RegisterAction
  | UpdateAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  isSimulating: false,
  user: null,
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, isSimulating, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isSimulating,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  SIMULATE: (state: State, action: SimulateAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isSimulating: true,
      user,
    };
  },
  STOP_SIMULATION: (state: State, action: StopSimulationAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isSimulating: false,
      user,
    };
  },
  UPDATE_USER: (state: State, action: UpdateAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      user,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  simulate: () => {},
  stopUserSimulation: () => {},
  updateUser: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    const localUser = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user')!)
      : null;
    const impersonatorToken = localStorage.getItem('impersonatorToken');
    const impersonatorUser = localStorage.getItem('impersonatorUser');
    if (token && localUser) {
      LogRocket.identify(localUser.id, {
        name: `${localUser.name ? localUser.name + ' ' : ''}${
          localUser.lastnames ? localUser.lastnames : ''
        }`,
        email: _.get(localUser, 'username', ''),
      });

      const user = {
        ...localUser,
        token,
      };
      if (!impersonatorToken && !impersonatorUser) eventEmitter.emit('access', user.id);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          user,
          isAuthenticated: true,
          isSimulating: !!impersonatorToken && !!impersonatorUser,
        },
      });
    } else {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          user: null,
          isAuthenticated: false,
          isSimulating: false,
        },
      });
    }
  }, []);

  const login = async (user: User): Promise<void> => {
    if (LogRocketKey) {
      LogRocket.identify(user.id.toString(), {
        name: `${_.get(user, 'name', '')} ${_.get(user, 'lastnames', '')}`,
        email: _.get(user, 'username', ''),
      });
    }

    localStorage.setItem('accessToken', user.token);
    localStorage.setItem('user', JSON.stringify(user));
    eventEmitter.emit('login', user.id);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const simulate = (susUser: User) => {
    const token = window.localStorage.getItem('accessToken');
    const user = window.localStorage.getItem('user');
    if (!token || !user) {
      console.error('No se puede simular sin un usuario autenticado');
      return;
    }
    localStorage.setItem('accessToken', susUser.token);
    localStorage.setItem('user', JSON.stringify(susUser));
    localStorage.setItem('impersonatorToken', token);
    localStorage.setItem('impersonatorUser', user);
    dispatch({
      type: 'SIMULATE',
      payload: {
        user: susUser,
      },
    });
  };

  const stopUserSimulation = () => {
    const impersonatorToken = window.localStorage.getItem('impersonatorToken');
    const impersonatorUser = window.localStorage.getItem('impersonatorUser');
    if (!impersonatorToken || !impersonatorUser) {
      console.error('No se puede detener la simulación sin un usuario autenticado');
      return;
    }
    localStorage.setItem('accessToken', impersonatorToken);
    localStorage.setItem('user', impersonatorUser);
    localStorage.removeItem('impersonatorToken');
    localStorage.removeItem('impersonatorUser');
    dispatch({
      type: 'STOP_SIMULATION',
      payload: {
        user: JSON.parse(impersonatorUser),
      },
    });
  };

  const updateUser = async (user: User): Promise<void> => {
    localStorage.setItem('user', JSON.stringify(user));
    dispatch({
      type: 'UPDATE_USER',
      payload: {
        user,
      },
    });
  };

  const logout = async (): Promise<void> => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    localStorage.removeItem('icn');
    eventEmitter.emit('logout', state.user?.id);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        simulate,
        stopUserSimulation,
        logout,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
