import { gql } from '@apollo/client';

const DELETE_STATION = gql`
  mutation DeleteStationMutation($deviceId: String!, $permanent: Boolean) {
    DeleteStation(deviceId: $deviceId, permanent: $permanent) {
      success
    }
  }
`;

export default DELETE_STATION;
