import { useEffect, useRef } from 'react';
import katex from 'katex';
import 'katex/dist/katex.min.css';

interface KaTeXComponentProps {
  latex: string;
  displayMode?: boolean;
}

const KaTeXComponent: React.FC<KaTeXComponentProps> = ({ latex, displayMode }) => {
  const mathContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mathContainer.current) {
      try {
        katex.render(latex, mathContainer.current, {
          displayMode: displayMode ?? true,
          output: 'mathml', // Use 'mathml' to get MathML output
          fleqn: true,
        });
      } catch (error) {
        console.error('KaTeX rendering error:', error);
        mathContainer.current.innerHTML = 'Error rendering math.' + error;
      }
    }
  }, [latex]);

  return <div ref={mathContainer}></div>;
};

export default KaTeXComponent;
