import { ActionType } from "src/__generated__/graphql";
import { Add, Create, Delete, Settings } from '@mui/icons-material';

enum PrimitiveActionType {
  Create,
  Update,
  Delete,
  Setting,
}

const getPrimitiveActionType = (actionType: ActionType) => {
  if (actionType.startsWith('CREATE')) return PrimitiveActionType.Create;
  if (actionType.startsWith('UPDATE')) return PrimitiveActionType.Update;
  if (actionType.startsWith('DELETE')) return PrimitiveActionType.Delete;
  return PrimitiveActionType.Setting;
};

const getColorByPrimitiveActionType = (action : PrimitiveActionType) => {
  switch (action) {
    case PrimitiveActionType.Create:
      return 'success';
    case PrimitiveActionType.Update:
      return 'primary';
    case PrimitiveActionType.Delete:
      return 'error';
    case PrimitiveActionType.Setting:
      return 'info';
    default:
      return 'info'
  }
}
  
const getIconByPrimitiveActionType = (action: PrimitiveActionType) => {
  switch (action) {
    case PrimitiveActionType.Create:
      return <Add fontSize="small" />;
    case PrimitiveActionType.Update:
      return <Create fontSize="small" />;
    case PrimitiveActionType.Delete:
      return <Delete fontSize="small" />;
    case PrimitiveActionType.Setting:
      return <Settings fontSize="small" />;
    default:
      return <Settings fontSize="small" />;
  }
}

export {getPrimitiveActionType, getColorByPrimitiveActionType, getIconByPrimitiveActionType}