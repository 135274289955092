const isRegis = (device: any) => {
  return device?.source === 'virtual' && device?.source_info === 'REGIS';
};

export const newIsRegis = (device: {
  profile: { source?: string | null; sourceInfo?: string | null };
}) => {
  return device.profile.source === 'virtual' && device.profile.sourceInfo === 'REGIS';
};

export default isRegis;
