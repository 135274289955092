import { gql } from '@/__generated__';

const UPDATE_STATION = gql(`
  mutation UpdateStationMutation(
    $name: String
    $type: String
    $componentId: Int!
    $longitude: String
    $latitude: String
    $irrigationChannelNetworkId: Int
    $organizationId: Int
  ) {
    UpdateStation(
      name: $name
      type: $type
      componentId: $componentId
      longitude: $longitude
      latitude: $latitude
      irrigationChannelNetworkId: $irrigationChannelNetworkId
      organizationId: $organizationId
    ) {
      success
      message
    }
  }
`);

export default UPDATE_STATION;
