import { gql } from 'src/__generated__';

const CREATE_ACTIVITY_LOGS = gql(`
  mutation CreateActivityLog(
    $activity: String
  ) {
    createActivityLog(
      activity: $activity
    ) {
      success
      message
    }
  }
`);

export default CREATE_ACTIVITY_LOGS;
