import { useSuspenseQuery } from '@apollo/client';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, ButtonBase, Typography } from '@mui/material';
import { useMemo, type FC } from 'react';
import GET_DEVICE_PROFILE_METRICS from '../../graphql/queries/getDeviceProfileMetrics';
import { SectionTitle } from './General';
import { DeviceProfile_GetDeviceProfileMetricsQuery, MetricField } from 'src/__generated__/graphql';
import { useFormatters } from 'src/hooks/useFormatters';
import Echarts from 'src/features/echarts';
import { getMetricDisplayName } from 'src/utils/i18n/getMetricDisplayName';
import { getMetricColor } from 'src/utils/getMetricColor';
import TimeElapsed from 'src/components/TimeElapsed';

interface Props {
  deviceId: string;
}

type Metric = DeviceProfile_GetDeviceProfileMetricsQuery['device']['metrics']['FLOW'];

const DATA_DAYS = 14;
const DATA_WINDOW = '2d';

const MetricBox: FC<{
  field: MetricField;
  metric: Metric;
  deviceId: string;
}> = ({ field, metric, deviceId }) => {
  const { i18n } = useLingui();
  const { formatMetricValue, formatDateTime } = useFormatters();

  const option = useMemo(() => {
    const option: echarts.EChartsOption = {
      animation: true,
      textStyle: {
        fontFamily: 'Barlow',
      },
      tooltip: {
        padding: 0,
        backgroundColor: 'transparent',
        borderWidth: 0,
        position: ['130%', 2],
        align: 'left',
        renderMode: 'html',
        show: true,
        confine: false,
        axisPointer: {
          type: 'shadow',
          animation: true,
        },
        trigger: 'axis',
        formatter(params) {
          const _params = Array.isArray(params) ? params[0] : params;
          const [timestamp, value] = _params.value as [number, number];

          const formattedValue = formatMetricValue(value, {
            unit: metric.unit,
            precision: metric.precision,
          });

          const formattedTime = formatDateTime(timestamp);

          return `
<div 
  style="color: #fff; line-height: 1.5; background-color: rgba(97, 97, 97, 0.92); font-size: 11px; padding: 4px 8px; border-radius: 4px">
  ${formattedTime}</br>
  <b style="font-size: 13px">${formattedValue}</b>
</div>
`;
        },
      },
      grid: {
        top: 8,
        bottom: 8,
        left: 2,
        right: 2,
      },
      xAxis: {
        show: false,
        type: 'time',
        axisLabel: {},
        splitLine: {},
      },
      yAxis: {
        show: false,
        min: 'dataMin',
        max: 'dataMax',
      },
      series: {
        type: 'line',
        data: metric.data,
        color: getMetricColor(field),
        symbol: 'none',
        smooth: true,
        lineStyle: {
          width: 2,
        },
      },
    };

    return option;
  }, [metric, formatDateTime, formatMetricValue, field]);

  const dataStatus =
    metric.lastData == null
      ? 'no-data'
      : metric.lastData[0] < start.getTime()
      ? 'no-recent-data'
      : 'online';

  return (
    <ButtonBase
      {...{
        href: `/devices-analysis/${deviceId}?flow=true`,
        target: '_blank',
      }}
      sx={{
        display: 'grid',
        width: '100%',
        p: 1,
        px: 2,
        border: 1,
        borderColor: 'divider',
        textAlign: 'left',
        gap: 4,
        gridTemplateColumns: dataStatus === 'online' ? '1fr auto' : '1fr 0',
      }}
    >
      <Box>
        <Typography fontSize="14px" lineHeight="20px">
          {getMetricDisplayName(field, i18n)}
        </Typography>
        {dataStatus === 'no-data' && (
          <Typography noWrap fontStyle="italic" color="text.secondary">
            Sin datos registrados
          </Typography>
        )}
        {dataStatus === 'no-recent-data' && (
          <Typography noWrap fontStyle="italic" color="text.secondary">
            Sin datos hace 2 semanas
          </Typography>
        )}

        {dataStatus === 'online' && metric.lastData && (
          <>
            <Typography noWrap fontWeight="bold" lineHeight="22px">
              {formatMetricValue(metric.lastData[1], {
                unit: metric.unit,
                precision: metric.precision,
              })}
            </Typography>
            <Typography noWrap fontSize="12px" color="text.secondary" lineHeight="13px">
              <TimeElapsed timestamp={metric.lastData[0]} />
            </Typography>
          </>
        )}
      </Box>
      <Box height={56} width={80}>
        {dataStatus === 'online' && <Echarts option={option} />}
      </Box>
    </ButtonBase>
  );
};

const start = new Date();
start.setDate(start.getDate() - DATA_DAYS);
start.setHours(0, 0, 0, 0);

const DeviceProfileMetrics: FC<Props> = ({ deviceId }) => {
  const { _ } = useLingui();
  const { data } = useSuspenseQuery(GET_DEVICE_PROFILE_METRICS, {
    variables: {
      deviceId: deviceId,
      metricsInput: {
        start: start.toISOString(),
        window: DATA_WINDOW,
      },
    },
  });

  const metrics = data.device.metrics.availableFields.flatMap((field) => {
    const metric =
      data.device.metrics[
        field as keyof Omit<typeof data.device.metrics, 'availableFields' | '__typename'>
      ];
    if (!metric) return [];

    return {
      field,
      metric,
    };
  });

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <SectionTitle id="metrics" title={_(msg`Métricas disponibles`)} />
      <Box display="grid" gridTemplateColumns="280px auto" alignItems="center" gap={8}>
        <Box display="grid" gridTemplateColumns="repeat(3, 280px)" gap={4}>
          {metrics.map((metric, index) => (
            <MetricBox
              field={metric.field}
              metric={metric.metric}
              key={index}
              deviceId={deviceId}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default DeviceProfileMetrics;
