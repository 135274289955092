import { useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useDispatch, useSelector } from 'src/store';
import { useApolloClient } from '@apollo/client';
import SEND_DATA_MUTATION from 'src/graphql/mutations/sendDataMutation';
import putS3 from 'src/controllers/aws/putS3';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import _ from 'lodash';
import SimplePicturePreview from 'src/components/telemetry/regis/SimplePicturePreview';
import genGQL from 'src/graphql/querys/gqlGenerator';
import { DeviceDialogProps } from '../dialogInterfaces';

const CreateMeasurement = ({
  show,
  device,
  handleClose,
}: DeviceDialogProps) => {
  const client = useApolloClient();

  const [height, setHeight] = useState<string>('');
  const [flow, setFlow] = useState<string>('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [showPicturePreview, setShowPicturePreview] = useState(false);
  const [error, setError] = useState('');

  const createMeasurement = async () => {
    if (height.length === 0 && flow.length === 0) {
      setError('Debe proporcionar al menos un valor de nivel o caudal');
      return;
    }

    if (_.isNil(_.get(device, 'id', null))) {
      setError('Algo salió mal, por favor recargue la página.');
      return;
    }

    setError('');
    setLoading(true);

    const imageName = uuidv4();

    if (selectedImage) {
      setImageLoading(true);
      const params = [
        {
          name: 'key',
          type: 'String',
        },
      ];

      const attributes = {
        presignedURL: null,
      };

      const presignedURL = _.get(
        await client.mutate({
          mutation: genGQL(
            'mutation',
            'preSignS3URL',
            params,
            Object.keys(attributes),
          ),
          variables: {
            key: imageName,
          },
        }),
        'data.preSignS3URL.presignedURL',
        null,
      );
      if (presignedURL) {
        await putS3(presignedURL, selectedImage)
          .then((result) => {
            setImageLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setImageLoading(false);
          });
      }
    }

    const baseUrl = 'https://amaru-mobile-data.s3-accelerate.amazonaws.com/';

    const variables = {
      deviceId: device.id,
      dt: selectedDate.toISOString(),
    };

    if (height.length > 0) {
      variables['h'] = parseFloat(height);
    }

    if (flow.length > 0) {
      variables['flow'] = parseFloat(flow);
    }

    if (selectedImage && imageName) {
      variables['photo'] = baseUrl.concat(imageName);
    }

    const { data } = await client.mutate({
      mutation: SEND_DATA_MUTATION,
      variables,
    });

    const isSuccess = _.get(data, 'createMeasurement.success', false);
    if (isSuccess) {
      handleInternalClose(true);
    } else {
      setError('Algo salió mal, por favor intente nuevamente');
    }
    setLoading(false);
    setImageLoading(false);
  };

  const handleShowPicturePreview = () => {
    setShowPicturePreview(true);
  };

  const handleInternalClose = (refetch: boolean) => {
    setHeight('');
    setFlow('');
    setSelectedImage('');
    setSelectedDate(moment());
    setError('');
    handleClose(refetch);
  };

  const handleCloseWithoutLoading = () => {
    handleInternalClose(false);
  };

  return (
    <Dialog open={show} onClose={handleCloseWithoutLoading}>
      <DialogTitle>
        Ingresar nueva medición para {_.get(device, 'name', '')}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Altura de regleta"
              autoFocus
              value={height}
              onChange={(event) => setHeight(event.target.value)}
              fullWidth
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">cm.</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Caudal"
              type="number"
              value={flow}
              onChange={(event) => setFlow(event.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">L/s</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="file-textfield"
              type="file"
              sx={{
                display: 'none',
              }}
              onChange={(event: any) => setSelectedImage(event.target.files[0])}
            />
            {selectedImage && (
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                onClick={handleShowPicturePreview}
              >
                Foto seleccionada: {selectedImage.name}
              </Typography>
            )}
          </Grid>
          <Grid item container xs={12} flexDirection="column">
            <Button
              variant="outlined"
              onClick={() => {
                const fileInput = document.getElementById('file-textfield');
                fileInput.click();
              }}
              sx={{
                mb: 2,
              }}
            >
              Agregar Foto
            </Button>
            <Box>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Box>
                    <DesktopDatePicker
                      label="Fecha"
                      inputFormat="DD/MM/YYYY"
                      value={selectedDate}
                      onChange={(e) => setSelectedDate(e)}
                      renderInput={(params) => (
                        <TextField
                          sx={{ width: '100%' }}
                          {...params}
                          helperText=""
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <TimePicker
                      label="Hora"
                      value={selectedDate}
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(e) => setSelectedDate(e)}
                    />
                  </Box>
                </Box>
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item xs={6}>
            {selectedImage && (
              <Box onClick={handleShowPicturePreview}>
                <img
                  src={URL.createObjectURL(selectedImage)}
                  alt="Nueva imagen de medición"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} container display="flex" justifyContent="flex-end">
            <Button
              color="info"
              variant="outlined"
              sx={{
                width: 120,
                mr: 2,
              }}
              onClick={handleCloseWithoutLoading}
            >
              Cancelar
            </Button>
            <Button
              color="primary"
              variant="contained"
              sx={{
                width: 120,
              }}
              onClick={createMeasurement}
            >
              Enviar dato
            </Button>
            {error.length > 0 ||
              (true && (
                <Box width="100%">
                  <Typography color="error" variant="caption">
                    {error}
                  </Typography>
                </Box>
              ))}
          </Grid>
          {/* Backdrops */}
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
            onClick={handleCloseWithoutLoading}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                variant="h6"
                color="textPrimary"
                sx={{
                  mb: 2,
                }}
              >
                {imageLoading ? 'Subiendo imagen...' : 'Enviando dato...'}
              </Typography>
              <CircularProgress color="inherit" />
            </Box>
          </Backdrop>
          <SimplePicturePreview
            show={showPicturePreview}
            handleClose={() => setShowPicturePreview(false)}
            src={selectedImage ? URL.createObjectURL(selectedImage) : ''}
            alt="Nueva imagen de medición"
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CreateMeasurement;
