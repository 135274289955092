import { t } from '@lingui/macro';
import _ from 'lodash';

export const ratingFactory = (formula) => {
  try {
    switch (formula.type) {
      case 'power': {
        const { a, b, offset = 0, constant = 0 } = formula;
        return {
          type: 'power',
          q: (level: number) => constant + a * (level - offset) ** b,
          qinv: (q: number) => (q / a) ** (1 / b) + offset - constant,
        };
      }
      case 'polynomial': {
        const { coef, offset = 0 } = formula;
        const q = (level: number) =>
          coef.map((c, i) => c * (level - offset) ** i).reduce((t, cur) => t + cur, 0);
        const qValues = Array.from(Array(5000).keys()).map((x) => [x, q(x)]);
        const qinv = (flow: number) => {
          const i1 = qValues.findIndex((e) => e[1] >= flow);
          if (i1 < qValues.length - 1) {
            const x1 = qValues[i1];
            const x2 = qValues[i1 + 1];
            return (x1[0] + x2[0]) / 2;
          }
          throw new Error(t`Valor fuera de rango`);
        };
        return { type: 'polynomial', q, qinv };
      }
      default:
        throw new Error(t`Curva de aforo inválida`);
    }
  } catch (error) {
    console.error(error);
    return {
      q: (level: number) => level,
      qinv: (q: number) => q,
    };
  }
};
