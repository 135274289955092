import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AADeviceOrganizationConfig,
  ContributingDeviceOrganizationConfig,
  DeviceConfig,
  DeviceOrganizationConfig,
  MaipoSiraStore,
  MeasureOptionsConfigString,
  MeausureOptionsConfig,
  MenuType,
} from '../types/dataTypes';
import {
  getAADevOrgConfig,
  getActiveContributingCanals,
  getBIAA,
  getDescargaEEY,
  getDescargaESN,
  getFlowFromDeviceConfig,
  getPlantsAAStocks,
  getPrimerRepartible,
  getRiverFlow,
} from '../utils/maipoStoreUtils';
import { getTotalFlowRequired } from '../utils/stepUtils';
import { convertFlow, convertLevel } from 'src/utils/unitConverterValues';
import { generateCanalManager } from '../utils/canalManager/factoryCanalManager';
import { schemaFlowRequired } from '../utils/validators';

const initialState: MaipoSiraStore = {
  showMenu: 'mainMenu',
  editInstanceId: null,
  unitDotacion: 'l/s',
  unitFlow: 'm3/s',
  unitLevel: 'm',
  unitVolume: 'm3',
  newDistributionInfo: {
    deviceConfig: [],
    remoteDevOrgConfig: [],
    contributionCanalsConfig: [],
    aaConfig: [],
    isLlanoOn: false,
    firstStep: {
      selectedDialogDeviceConfig: null,
    },
    flowRightsAA: 0,
    obligatoryDotacion: 0,
    dotacionPrimerRepartible: 0,
    validDistributionCalculation: true,
    organizationAA: null,
  },
};

function validateFlowRequiredAA(state: MaipoSiraStore) {
  const { newDistributionInfo } = state;
  const { aaConfig } = newDistributionInfo;
  const totalFlowRequiredAA = getTotalFlowRequired(aaConfig);
  const riverFlow = getRiverFlow(state);

  let newAAConfig: AADeviceOrganizationConfig[];
  if (totalFlowRequiredAA > riverFlow) {
    newAAConfig = aaConfig.map((item) => {
      const newFlowRequiredInfo = { ...item.flowRequiredInfo, isValid: false };
      return { ...item, flowRequiredInfo: newFlowRequiredInfo };
    });
  } else {
    newAAConfig = aaConfig.map((item) => {
      const newFlowRequiredInfo = {
        ...item.flowRequiredInfo,
        isValid: schemaFlowRequired.isValidSync(item.flowRequiredInfo),
      };
      return { ...item, flowRequiredInfo: newFlowRequiredInfo };
    });
  }
  state.newDistributionInfo.aaConfig = newAAConfig;
}

function validateDistributionInput(state: MaipoSiraStore): boolean {
  const { newDistributionInfo } = state;
  const { aaConfig } = newDistributionInfo;
  const validFlowRequired = aaConfig.reduce(
    (acc, curr) => acc && curr.flowRequiredInfo.isValid,
    true,
  );
  return validFlowRequired;
}

function setDistributionInfoInvalid(state: MaipoSiraStore) {
  const { newDistributionInfo } = state;
  const { contributionCanalsConfig } = newDistributionInfo;

  const newContributionCanalsConfig = contributionCanalsConfig.map((item) => {
    const distributionInfo = {
      ...item.distributionInfo,
      distributedFlow: null,
      flowRights: null,
      obligatoryContribution: null,
      volunteerContribution: null,
      distributedDotacion: null,
    };

    return {
      ...item,
      distributionInfo: distributionInfo,
    };
  });
  state.newDistributionInfo.contributionCanalsConfig =
    newContributionCanalsConfig;
}

function updateStateForEditInstance(state: MaipoSiraStore, data: any) {
  const {
    siraMaipoConfigFromInstance,
    siraMaipoOrganizationAA,
    siraMaipoMeasuresOfDevicesOfInstance,
  } = data;

  const {
    is_center_llano_on,
    sira_maipo_dev_instances,
    sira_maipo_dev_org_instances,
  } = siraMaipoConfigFromInstance;

  state.newDistributionInfo.organizationAA = siraMaipoOrganizationAA;
  state.newDistributionInfo.isLlanoOn = is_center_llano_on;

  const deviceConfigs = [];
  const remoteDevOrgConfig = [];

  const measuresByDevice = siraMaipoMeasuresOfDevicesOfInstance.reduce(
    (acc, curr) => {
      const initialCustomMeasures = {
        level: 0,
        flow: 0,
        level_timestamp: null,
        flow_timestamp: null,
      };
      acc[curr.id_device] = { ...curr.measures, custom: initialCustomMeasures };
      return acc;
    },
    {},
  );

  for (const config of sira_maipo_dev_instances) {
    const {
      device,
      is_EEY,
      is_ESN,
      is_descarga_lonquen,
      is_part_of_river_sum,
      on_when_llano_is_on,
      is_cepillo,
      is_pasante,
      is_san_carlos,
      is_clarillo,
      is_totalizador_ach,
      is_canal_tronco,

      level_measured,
      flow_measured,
      level_timestamp,
      flow_timestamp,

      source,
      source_info,
    } = config;

    const measureDevice = measuresByDevice[config.device.id];
    const measureConfig = JSON.parse(JSON.stringify(measureDevice)); // for shallow copy. spread sintax is not enough

    let useWhichValues: MeasureOptionsConfigString;
    let comment: string = '';
    if (source === 'custom') {
      useWhichValues = 'custom';
      comment = source_info;
      measureConfig.custom.level = level_measured;
      measureConfig.custom.flow = flow_measured;
    } else if (source === 'device') {
      useWhichValues = source_info;
      measureConfig[source_info].level = level_measured;
      measureConfig[source_info].flow = flow_measured;
      measureConfig[source_info].level_timestamp = level_timestamp;
      measureConfig[source_info].flow_timestamp = flow_timestamp;
    }

    const newDeviceConfig: DeviceConfig = {
      device: device,
      is_EEY: is_EEY,
      is_ESN: is_ESN,
      is_descarga_lonquen: is_descarga_lonquen,
      is_part_of_river_sum: is_part_of_river_sum,
      on_when_llano_is_on: on_when_llano_is_on,
      is_pasante: is_pasante,
      is_cepillo: is_cepillo,
      is_san_carlos: is_san_carlos,
      is_clarillo: is_clarillo,
      is_totalizador_ach: is_totalizador_ach,
      is_canal_tronco: is_canal_tronco,

      measures: measureConfig,
      useWhichValues: useWhichValues,
      customComment: comment,
    };

    deviceConfigs.push(newDeviceConfig);
  }

  for (const item of sira_maipo_dev_org_instances) {
    const {
      is_plant_AA,
      is_BI_AA,
      is_ACM,
      is_ACLH,
      is_reporting,
      is_part_of_cepillo,
      stocks,
      organization,
      name,
      id_device,
      is_SCM,
      excel_order,
      is_casas_viejas,
      is_part_of_canal_tronco,
      is_el_toro,
      is_ACH,
      min_dotacion,
      assigned_flow,
    } = item;

    const deviceConfig = deviceConfigs.find((e) => e.device.id === id_device);

    const initialDistributionInfo = {
      flowRights: 0,
      distributedFlow: assigned_flow,
      distributedDotacion: 0,
      volunteerContribution: 0,
      obligatoryContribution: 0,
    };

    const newRemoteDevOrgConfig: ContributingDeviceOrganizationConfig = {
      is_plant_AA: is_plant_AA,
      is_BI_AA: is_BI_AA,
      is_ACM: is_ACM,
      is_ACLH: is_ACLH,
      is_reporting: is_reporting,
      is_part_of_cepillo: is_part_of_cepillo,
      stocks: stocks,
      organization: organization,
      on_when_llano_is_on: deviceConfig?.on_when_llano_is_on ?? null,
      name: name,
      id_device: id_device,
      is_SCM: is_SCM,
      excel_order: excel_order,
      is_casas_viejas: is_casas_viejas,
      is_part_of_canal_tronco: is_part_of_canal_tronco,
      is_el_toro: is_el_toro,
      is_ACH: is_ACH,
      min_dotacion: min_dotacion,
      distributionInfo: initialDistributionInfo,
    };

    remoteDevOrgConfig.push(newRemoteDevOrgConfig);
  }

  const aaConfig = remoteDevOrgConfig
    .filter(
      (e) =>
        e.is_plant_AA ||
        e.is_BI_AA ||
        e.organization.id === siraMaipoOrganizationAA.id,
    )
    .map((e) => {
      return {
        ...e,
        flowRequiredInfo: {
          valueString: convertFlow(
            e.distributionInfo.distributedFlow,
            'l/s',
            state.unitFlow,
          ).toString(),
          value: e.distributionInfo.distributedFlow,
          isValid: true,
        },
      };
    });

  state.newDistributionInfo.deviceConfig = deviceConfigs;
  state.newDistributionInfo.remoteDevOrgConfig = remoteDevOrgConfig;
  state.newDistributionInfo.aaConfig = aaConfig;
  state.newDistributionInfo.contributionCanalsConfig =
    getActiveContributingCanals(state);
}

function mergeRemoteConfigToReducer(state: MaipoSiraStore, data: any) {
  const { siraMaipoConfig, siraMaipoConfigDev, siraMaipoMeasuresOfDevices } =
    data;

  state.newDistributionInfo.organizationAA = siraMaipoConfig.AAOrganization;

  const deviceConfigs = [];
  const remoteDevOrgConfig = [];

  const measuresByDevice = siraMaipoMeasuresOfDevices.reduce((acc, curr) => {
    const initialCustomMeasures = {
      level: 0,
      flow: 0,
      level_timestamp: null,
      flow_timestamp: null,
    };
    acc[curr.id_device] = { ...curr.measures, custom: initialCustomMeasures };
    return acc;
  }, {});

  const { sira_maipo_dev, sira_maipo_dev_org } = siraMaipoConfigDev;

  for (const config of sira_maipo_dev) {
    const meausureDevice = measuresByDevice[config.device.id];
    const deviceConfig = {
      ...config,
      measures: meausureDevice,
      useWhichValues: 'last',
      customComment: null,
    };
    deviceConfigs.push(deviceConfig);
  }

  const initialDistributionInfo = {
    flowRights: 0,
    distributedFlow: 0,
    distributedDotacion: 0,
    volunteerContribution: 0,
    obligatoryContribution: 0,
  };

  for (const item of sira_maipo_dev_org) {
    const deviceConfig = deviceConfigs.find(
      (e) => e.device.id === item.id_device,
    );
    remoteDevOrgConfig.push({
      ...item,
      on_when_llano_is_on: deviceConfig?.on_when_llano_is_on ?? null,
      distributionInfo: initialDistributionInfo,
    });
  }

  state.newDistributionInfo.deviceConfig = deviceConfigs;
  state.newDistributionInfo.remoteDevOrgConfig = remoteDevOrgConfig;
  state.newDistributionInfo.aaConfig = getAADevOrgConfig(state);
  state.newDistributionInfo.contributionCanalsConfig =
    getActiveContributingCanals(state);
}

function updateDeviceValuesConfig(
  state: MaipoSiraStore,
  newVal: { value: MeasureOptionsConfigString; deviceId: string },
) {
  const { value, deviceId } = newVal;
  const configOfDevice = state.newDistributionInfo.deviceConfig.find(
    (e) => e.device.id === deviceId,
  );
  configOfDevice.useWhichValues = value;
}

function updateCustomValuesOfDevice(
  state: MaipoSiraStore,
  newVal: {
    valueLevel: number;
    valueFlow: number;
    deviceId: string;
    comment: string;
  },
) {
  const { unitFlow, unitLevel } = state;
  const { valueLevel, valueFlow, comment, deviceId } = newVal;
  const configOfDevice = state.newDistributionInfo.deviceConfig.find(
    (e) => e.device.id === deviceId,
  );
  configOfDevice.measures.custom.level = convertLevel(
    valueLevel,
    unitLevel,
    'cm',
  );
  configOfDevice.measures.custom.flow = convertFlow(valueFlow, unitFlow, 'l/s');
  configOfDevice.customComment = comment;
}

function calculateDistribution(state: MaipoSiraStore) {
  const isValidValues = validateDistributionInput(state);

  if (!isValidValues) {
    setDistributionInfoInvalid(state);
    state.newDistributionInfo.validDistributionCalculation = false;
    return;
  }

  state.newDistributionInfo.validDistributionCalculation = true;

  const { deviceConfig, contributionCanalsConfig, aaConfig } =
    state.newDistributionInfo;
  const EEY = getDescargaEEY(deviceConfig);
  const ESN = getDescargaESN(deviceConfig);
  const flowEEY = getFlowFromDeviceConfig(EEY);
  const flowESN = getFlowFromDeviceConfig(ESN);
  const extraFlowAA = flowEEY + flowESN;

  const totalFlowRequiredAA = getTotalFlowRequired(aaConfig);
  const stocksBIAA = getBIAA(aaConfig).stocks;
  const stocksPlantsAA = getPlantsAAStocks(aaConfig);
  const totalStocksAA = stocksBIAA + stocksPlantsAA;

  const riverFlow = getRiverFlow(state);
  const primerRepartible = getPrimerRepartible(state, riverFlow);

  const newContributionCanalsConfig = [...contributionCanalsConfig];

  const canalManager = generateCanalManager(
    totalFlowRequiredAA,
    totalStocksAA,
    newContributionCanalsConfig,
    primerRepartible,
    extraFlowAA,
  );

  const response = canalManager.calculateDistribution();

  response.forEach((element, index) => {
    const { stocks } = newContributionCanalsConfig[index];
    const newDistributedDotacion = element.distributedFlow / stocks;
    const newDistributionInfo = {
      ...newContributionCanalsConfig[index].distributionInfo,
      ...element,
      distributedDotacion: newDistributedDotacion,
    };
    newContributionCanalsConfig[index] = {
      ...newContributionCanalsConfig[index],
      distributionInfo: newDistributionInfo,
    };
  });

  state.newDistributionInfo.contributionCanalsConfig =
    newContributionCanalsConfig;
  state.newDistributionInfo.dotacionPrimerRepartible =
    canalManager.getDotacion();
  state.newDistributionInfo.obligatoryDotacion =
    canalManager.getObligatoryDotacion();
  state.newDistributionInfo.flowRightsAA = canalManager.getFlowRightsAA();
}

const maipoSiraSlice = createSlice({
  name: 'maipoSira',
  initialState: initialState,
  reducers: {
    updateWithRemoteConfig(state, action: PayloadAction<any>) {
      mergeRemoteConfigToReducer(state, action.payload);
    },
    toggleLlano(state) {
      state.newDistributionInfo.isLlanoOn =
        !state.newDistributionInfo.isLlanoOn;
      state.newDistributionInfo.contributionCanalsConfig =
        getActiveContributingCanals(state);
      calculateDistribution(state);
    },
    updateDeviceValuesToUse(state, action: PayloadAction<any>) {
      updateDeviceValuesConfig(state, action.payload);
    },
    showDialogDevice(state, action: PayloadAction<any>) {
      const newDevice = action.payload;
      state.newDistributionInfo.firstStep.selectedDialogDeviceConfig =
        newDevice;
    },
    hideDialogDevice(state) {
      state.newDistributionInfo.firstStep.selectedDialogDeviceConfig = null;
    },
    updateCustomValuesAndHideDialog(state, action) {
      updateCustomValuesOfDevice(state, action.payload);
      maipoSiraSlice.caseReducers.hideDialogDevice(state);
    },
    updateFlowRequiredAA(state, action: PayloadAction<any>) {
      const { index, value } = action.payload;
      state.newDistributionInfo.aaConfig[index].flowRequiredInfo.valueString =
        value;
      state.newDistributionInfo.aaConfig[index].flowRequiredInfo.value =
        convertFlow(parseFloat(value), state.unitFlow, 'l/s');
      validateFlowRequiredAA(state);
      calculateDistribution(state);
    },

    updateDistribtution(state) {
      calculateDistribution(state);
    },

    goToMenu(state, action: PayloadAction<MenuType>) {
      const { payload } = action;
      state.showMenu = payload;
    },
    goToEditInstanceOfId(state, action: PayloadAction<number>) {
      const { payload } = action;
      state.editInstanceId = payload;
      state.showMenu = 'editDistribution';
    },
    allCustomValueDevices(state) {
      for (const item of state.newDistributionInfo.deviceConfig) {
        item.useWhichValues = 'custom';
      }
    },
    updateForEditInstance(state, action: PayloadAction<any>) {
      updateStateForEditInstance(state, action.payload);
    },
  },
});

export const {
  updateWithRemoteConfig,
  updateDeviceValuesToUse,
  toggleLlano,
  showDialogDevice,
  hideDialogDevice,
  updateCustomValuesAndHideDialog,
  updateFlowRequiredAA,
  updateDistribtution,
  goToMenu,
  goToEditInstanceOfId,
  allCustomValueDevices,
  updateForEditInstance,
} = maipoSiraSlice.actions;

export const { reducer } = maipoSiraSlice;
export { maipoSiraSlice };
