import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface GlobalConfig {
  intervalId: any | null;
}

const initialState: GlobalConfig = {
  intervalId: null,
};

const slice = createSlice({
  name: 'global_config',
  initialState,
  reducers: {
    resetStore() {
      return initialState;
    },
    setIntervalIdConfig(state: GlobalConfig, action: PayloadAction<boolean>): void {
      state.intervalId = action.payload;
    },
  },
});

export const { reducer } = slice;

export const { resetStore: resetGlobalStore, setIntervalIdConfig: setIntervalId } = slice.actions;
