import { useModalStatusMessage } from '@/hooks';
import { useSelector } from '@/store';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import ModalActions from '@components/modal/ModalActions';
import ModalTitle from '@components/modal/ModalTitle';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Dialog, DialogContent, Skeleton, TextField } from '@mui/material';
import { Suspense, type FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import UPDATE_DGA_MAX_FLOW from '../../graphql/mutations/updateDgaMaxFlow';
import GET_DGA_MAX_FLOW from '../../graphql/queries/getDGAMaxFlow';
import { setUpdateDGAMaxFlowModal } from '../../slices/dgaSlice';
import NumericTextField from '@components/NumericTextField';

interface Props {
  onClose: () => void;
  deviceId: string;
}

interface FormValues {
  maxFlow: string;
}

const Component: FC<Props> = ({ deviceId, onClose }) => {
  const { _ } = useLingui();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const [updateMaxFlow] = useMutation(UPDATE_DGA_MAX_FLOW, {
    onCompleted(data) {
      const success = data?.UpdateMaxFlowConfiguration?.success || false;
      if (success) {
        openModalSuccessMessage(_(msg`Caudal máximo actualizado correctamente`));
        onClose();
      } else {
        openModalErrorMessage(_(msg`No se pudo actualizar el caudal máximo`));
      }
    },
    onError(error) {
      openModalErrorMessage(_(msg`No se pudo actualizar el caudal máximo`) + ' ' + error.message);
    },
  });
  const { data } = useSuspenseQuery(GET_DGA_MAX_FLOW, {
    fetchPolicy: 'no-cache',
    variables: {
      deviceId,
      deviceIdString: deviceId,
    },
  });

  const methods = useForm<FormValues>({
    defaultValues: {
      maxFlow: data?.DGADeviceConfiguration?.maxFlow?.toString() ?? '',
    },
  });

  const onSubmit = async (data: FormValues) => {
    try {
      await updateMaxFlow({
        variables: {
          deviceId,
          maxFlow: parseFloat(data.maxFlow),
        },
      });
    } catch {}
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <ModalTitle
        onClose={onClose}
        title={<Trans>Configuración de caudal máximo para {deviceId}</Trans>}
      />
      <DialogContent dividers>
        <Controller
          control={methods.control}
          name="maxFlow"
          render={({ field, fieldState }) => (
            <NumericTextField
              {...field}
              label={_(msg`Caudal máximo en L/s`)}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              required
              fullWidth
              autoFocus
            />
          )}
        />
      </DialogContent>
      <ModalActions
        onClose={onClose}
        onSubmit="submit"
        submitLoading={methods.formState.isLoading}
        dirtyForm={methods.formState.isDirty}
        onResetForm={() => methods.reset()}
      />
    </form>
  );
};

const Fallback: FC<{ onClose: () => void }> = ({ onClose }) => {
  const deviceName = <Skeleton variant="text" width={200} />;
  return (
    <>
      <ModalTitle
        onClose={onClose}
        title={
          <Box display="flex" gap={1}>
            <Trans>Configuración de caudal máximo para {deviceName}</Trans>
          </Box>
        }
      />
      <DialogContent dividers>
        <Skeleton variant="rounded" width="100%">
          <TextField fullWidth />
        </Skeleton>
      </DialogContent>
      <ModalActions onClose={onClose} />
    </>
  );
};

const DGAUpdateMaxFlowModal: FC = () => {
  const dispatch = useDispatch();
  const { updateDGAMaxFlowModal } = useSelector((state) => state.dga_store);

  const onClose = () => {
    dispatch(
      setUpdateDGAMaxFlowModal({
        open: false,
      }),
    );
  };

  return (
    <Dialog onClose={onClose} open={updateDGAMaxFlowModal.open} maxWidth="sm" fullWidth>
      <Suspense fallback={<Fallback onClose={onClose} />}>
        {updateDGAMaxFlowModal.open && (
          <Component onClose={onClose} deviceId={updateDGAMaxFlowModal.deviceId} />
        )}
      </Suspense>
    </Dialog>
  );
};

export default DGAUpdateMaxFlowModal;
