import QueryComponent from './QueryComponent';
import { StandardModal } from 'src/features/standardDesign';
import { useLingui } from '@lingui/react';
import { msg } from '@lingui/macro';

type Props = {
  show: boolean;
  gate: {
    id: string;
    name: string;
  };
  handleClose: () => void;
};

const GateMovementHistory = ({ gate, show, handleClose }: Props) => {
  const { _ } = useLingui();
  const title = _(msg`Historial de movimientos`);
  return (
    <StandardModal open={show} onClose={handleClose} title={`${title} ${gate.name}`} maxWidth="lg">
      {gate != null && show && <QueryComponent gate={gate} />}
    </StandardModal>
  );
};

export default GateMovementHistory;
