import { gql } from '@/__generated__';

const UPDATE_DEVICE_RADAR_PARAMETERS = gql(`
  mutation Mutation($input: UpdateDeviceRadarParametersInput!) {
    updateDeviceRadarParameters(input: $input) {
      deviceId
      radarParameters {
        ratingCurve {
          active
          levelLimit
        }
        velocityCurve {
          model
          type
          curve {
            functionType
            parameters {
              vMaxDepthNorm
              m
              a
              talud
              baseWidth
              A
              B
              C
            }
            min
            max
          }
          curves {
            functionType
            parameters {
              vMaxDepthNorm
              m
              a
              talud
              baseWidth
              A
              B
              C
            }
            min
            max
          }
        }
        areaCurve {
          levelOffset
          type
          curve {
            functionType
            parameters {
              vMaxDepthNorm
              m
              a
              talud
              baseWidth
              A
              B
              C
            }
            min
            max
          }
          curves {
            functionType
            parameters {
              vMaxDepthNorm
              m
              a
              talud
              baseWidth
              A
              B
              C
            }
            min
            max
          }
        }
      }
    }
  }
`);

export default UPDATE_DEVICE_RADAR_PARAMETERS;
