import { GateInMovement } from '../slices/reducer';

export const enum GateMovementState {
  Static = 'static',
  MovingUp = 'moving-up',
  MovingDown = 'moving-down',
}

export const gateMovementState = (
  gatesInMovement: GateInMovement[],
  gateId: string,
) => {
  const gate = gatesInMovement.find((gate) => gate.gateId === gateId);
  if (!gate) return GateMovementState.Static;

  return gate.startPosition > gate.finishPosition
    ? GateMovementState.MovingDown
    : GateMovementState.MovingUp;
};

/**
 * Returns the position of a gate if it is moving, otherwise null.
 * Also returns the start position and the requested position of the gate movement.
 * @param gatesInMovement - array of gates in movement
 * @param gateId - id of the gate
 * @returns - position of the gate
 **/
export const gateMovementPosition = (
  gatesInMovement: GateInMovement[],
  gateId: string,
) => {
  const gate = gatesInMovement.find((gate) => gate.gateId === gateId);
  if (!gate) return null;
  return {
    startPosition: gate.startPosition,
    currentPosition: gate.position,
    finishPosition: gate.finishPosition,
  };
};

/**
 * Returns the gate moving state. (STARTED_MOVING, MOVING, FINISHED_MOVING)
 * @param gatesInMovement - array of gates in movement
 * @param gateId - id of the gate
 * @returns - state of the gate movement
 * */
export const gateMovementOperationState = (
  gatesInMovement: GateInMovement[],
  gateId: string,
) => {
  const gate = gatesInMovement.find((gate) => gate.gateId === gateId);
  if (!gate) return null;
  return gate.state;
} 