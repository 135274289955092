import { DeviceType } from '@/__generated__/graphql';

export const getDeviceTypeOptions = (): {
  enumValue: DeviceType;
  value: string;
  label: string;
}[] => {
  const options = Object.values(DeviceType).map((deviceType) => {
    if (deviceType === DeviceType.IF) {
      return {
        enumValue: deviceType,
        value: 'I-F',
        label: `I-F`,
      };
    }
    if (deviceType === DeviceType.IL) {
      return {
        enumValue: deviceType,
        value: 'I-L',
        label: `I-L`,
      };
    }
    if (deviceType === DeviceType.TG) {
      return {
        enumValue: deviceType,
        value: 'T-G',
        label: `T-G`,
      };
    }
    if (deviceType === DeviceType.TL) {
      return {
        enumValue: deviceType,
        value: 'T-L',
        label: `T-L`,
      };
    }
    if (deviceType === DeviceType.Regis) {
      return {
        enumValue: deviceType,
        value: 'Regis',
        label: `Regis`,
      };
    }
    if (deviceType === DeviceType.T_5L) {
      return {
        enumValue: deviceType,
        value: 'T5-L',
        label: `T5-L`,
      };
    }
    if (deviceType === DeviceType.T_5Lg) {
      return {
        enumValue: deviceType,
        value: 'T5-LG',
        label: `T5-LG`,
      };
    }
    if (deviceType === DeviceType.T_5Lv) {
      return {
        enumValue: deviceType,
        value: 'T5-LV',
        label: `T5-LV`,
      };
    }
    if (deviceType === DeviceType.TCore) {
      return {
        enumValue: deviceType,
        value: 'Tcore',
        label: `Tcore`,
      };
    }
    if (deviceType === DeviceType.TCoreF) {
      return {
        enumValue: deviceType,
        value: 'Tcore-F',
        label: `Tcore-F`,
      };
    }
    if (deviceType === DeviceType.TCoreL) {
      return {
        enumValue: deviceType,
        value: 'Tcore-L',
        label: `Tcore-L`,
      };
    }
    if (deviceType === DeviceType.TCoreLf) {
      return {
        enumValue: deviceType,
        value: 'Tcore-LF',
        label: `Tcore-LF`,
      };
    }
    if (deviceType === DeviceType.TCoreLv) {
      return {
        enumValue: deviceType,
        value: 'Tcore-LV',
        label: `Tcore-LV`,
      };
    }
    if (deviceType === DeviceType.TCoreLg) {
      return {
        enumValue: deviceType,
        value: 'Tcore-LG',
        label: `Tcore-LG`,
      };
    }
    if (deviceType === DeviceType.TMicroL) {
      return {
        enumValue: deviceType,
        value: 'Tmicro-L',
        label: `Tmicro-L`,
      };
    }
    if (deviceType === DeviceType.TMicroLg) {
      return {
        enumValue: deviceType,
        value: 'Tmicro-LG',
        label: `Tmicro-LG`,
      };
    }
    if (deviceType === DeviceType.TMicroLv) {
      return {
        enumValue: deviceType,
        value: 'Tmicro-LV',
        label: `Tmicro-LV`,
      };
    }
    if (deviceType === DeviceType.TNanoL) {
      return {
        enumValue: deviceType,
        value: 'Tnano-L',
        label: `Tnano-L`,
      };
    }
    if (deviceType === DeviceType.Cfc) {
      return {
        enumValue: deviceType,
        value: 'CFC',
        label: `CFC`,
      };
    }
    if (deviceType === DeviceType.TCoreNv) {
      return {
        enumValue: deviceType,
        value: 'Tcore-NV',
        label: `Tcore-NV`,
      };
    }
    if (deviceType === DeviceType.TCoreWq) {
      return {
        enumValue: deviceType,
        value: 'Tcore-Wq',
        label: `Tcore-WQ`,
      };
    }

    return {
      enumValue: deviceType,
      value: deviceType,
      label: deviceType,
    };
  });

  return options;
};
