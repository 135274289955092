import {
  green,
  grey,
  teal,
  lightGreen,
  yellow,
  pink,
  blue,
  cyan,
  orange,
} from '@mui/material/colors';

const shades = [500, 700, 800, 900];

const metricsDefaults: Record<string, { chartType: string; color: Record<string, string> }> = {
  flow: {
    chartType: 'area',
    color: blue,
  },
  level: {
    chartType: 'line',
    color: lightGreen,
  },
  angle: {
    chartType: 'line',
    color: yellow,
  },
  connectionState: {
    chartType: 'bar',
    color: green,
  },
  connectionStateMarkArea: {
    chartType: 'line',
    color: green,
  },
  solarV: {
    chartType: 'line',
    color: pink,
  },
  batterySoc: {
    chartType: 'line',
    color: teal,
  },
  position: {
    chartType: 'area',
    color: cyan,
  },
  default: {
    chartType: 'line',
    color: grey,
  },
  originalDistance: {
    chartType: 'line',
    color: orange,
  },
  events: {
    chartType: 'scatter',
    color: yellow,
  },
  accumulatedVolume: {
    chartType: 'line',
    color: teal,
  },
};

export const generateSeriesColors = (
  series: {
    dataType?: string | null;
    color?: string | null;
  }[],
) => {
  const recurrence: Record<string, number> = Object.keys(metricsDefaults).reduce(
    (acc, curr) => {
      acc[curr] = 0;
      return acc;
    },
    {} as Record<string, number>,
  );
  return series.map((serie) => {
    if (serie.color) return serie.color;
    else {
      const dataType = serie.dataType ?? 'default';
      const serieDefaults = metricsDefaults[dataType] || metricsDefaults.default;
      const color = serieDefaults.color[shades[recurrence[dataType]]];
      recurrence[dataType] = Math.min(recurrence[dataType] + 1, shades.length - 1);
      return color;
    }
  });
};
