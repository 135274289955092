import { Language, Locale } from '@/__generated__/graphql';

export const getLanguageOptions = (locale?: string) => {
  const languageNameInSelectedLocale = new Intl.DisplayNames([locale ?? 'es'], {
    type: 'language',
  });
  const options = [
    {
      value: 'es',
      enumValue: Language.Es,
      label: languageNameInSelectedLocale.of('es') ?? 'es',
      icon: '/static/flags/cl.svg',
    },
    {
      value: 'en',
      enumValue: Language.En,
      label: languageNameInSelectedLocale.of('en') ?? 'en',
      icon: '/static/flags/us.svg',
    },
  ] as const;

  return options;
};

export const getLocaleOptions = (locale: string) => {
  const languageNameInSelectedLocale = new Intl.DisplayNames([locale], {
    type: 'language',
    languageDisplay: 'dialect',
  });
  return [
    {
      value: 'es-CL',
      enumValue: Locale.EsCl,
      label: languageNameInSelectedLocale.of('es-CL') ?? 'es-CL',
    },
    {
      value: 'en-US',
      enumValue: Locale.EnUs,
      label: languageNameInSelectedLocale.of('en-US') ?? 'en-US',
    },
    {
      value: 'es-AR',
      enumValue: Locale.EsAr,
      label: languageNameInSelectedLocale.of('es-AR') ?? 'es-AR',
    },
    {
      value: 'es-MX',
      enumValue: Locale.EsMx,
      label: languageNameInSelectedLocale.of('es-MX') ?? 'es-MX',
    },
  ];
};
