import { gql } from 'src/__generated__';

const UPDATE_USER = gql(`
  mutation updateUser(
    $userId: Int!,
    $organizations: String,
    $name: String,
    $lastnames: String,
    $username: String,
    $phone_number: String,
    $calling_code: String,
    $password: String,
    $picture: String
    $iv: String
    $verify_auth: Boolean
    $status: String
  ) {
    updateUser(
      userId: $userId
      organizations: $organizations
      name: $name
      phone_number: $phone_number 
      lastnames: $lastnames
      username: $username
      calling_code: $calling_code
      password: $password
      picture: $picture
      verify_auth: $verify_auth
      iv: $iv
      status: $status
    ) {
      id
      name
      lastnames
      username
      phone
      phone_number
      calling_code
      token
      picture
      verify_auth
      organization_id
      updatedPassword
      status
      access {
        controlCenter
        allDevices
        stations
        users
        virtualStations
        integrations
        dataAnalytics
        dataAdvanceAnalytics
        gates
        systems
        distribution
        dgaReports
        dataExtraction
        deviceConfiguration
        faq
        dataTables
        offLogin
        permits
        apiCapta
        distributionMaipo
        metricView
        deviceDiagnostics
        sendCommand
        channelNetwork
        audit
        standardDesign
        announcements
        notifications
        organizations
        billing
        alerts
        scheduledReports
        smaReports
        alertNotification
      }
      role {
        id
        name
      }
      organizations {
        id
        name
        role {
          id
          name
        }
      }
      organizationsArray
    }
  }
`);

export default UPDATE_USER;
