import { gql } from 'src/__generated__';

const UPDATE_NOTIFICATION_STATUS = gql(/* GraphQL */ `
  mutation UpdateNotificationStatus($input: UpdateNotificationStatusInput!) {
    updateNotificationStatus(input: $input) {
      notification {
        id
        status
      }
    }
  }
`);

export default UPDATE_NOTIFICATION_STATUS;
