import { gql } from '@/__generated__';

const UPDATE_DEVICE_RATING_CURVES = gql(`
  mutation UpdateDeviceRatingCurves($input: UpdateDeviceRatingCurvesInput!) {
    updateDeviceRatingCurves(input: $input) {
      deviceId
      ratingCurves {
        curves {
          created
          updated
          realizationDate
          rSquared
          method
          notes
          formula {
            type
            a
            b
            offset
            constant
            coef
          }
          active
          levelMin
          levelMax
        }
      }
    }
  }
`);

export default UPDATE_DEVICE_RATING_CURVES;
