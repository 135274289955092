import { gql } from '@/__generated__';

const GATE_FINISHED_MOVING = gql(/* GraphQL */ `
  subscription GateFinishedMoving {
    gateFinishedMoving {
      gateId
      gateName
      startPosition
      position
      finishPosition
    }
  }
`);

export default GATE_FINISHED_MOVING;
