import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import SelectCustom from 'src/components/SelectCustom';

interface Props {
  value: string;
  types?: {
    value: string;
    label: string;
  }[];
  onChange: (value: string) => void;
}

const TypeFilter = ({ value, types, onChange }: Props) => {
  const { _ } = useLingui();
  return (
    <SelectCustom
      id="type-filter"
      name={_(msg`Tipo`)}
      value={value}
      options={types}
      onChange={onChange}
    />
  );
};

export default TypeFilter;
