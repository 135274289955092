import { gql } from '@/__generated__';

const GET_UPDATE_DEVICE_ACCESS_FIELDS = gql(`#graphql
  query DeviceConfiguration_GetAccessFields($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      profile {
        id
        organization {
          id
          name
        }
      }
      users(input:{
        ignoreAccess: true
      }) {
        hasAccess
        user {
          id
          email
          fullName
          mainOrganization {
            organization {
              id
            }
            role {
              id
              name
            }
          }
          additionalOrganizations {
            organization {
              id
            }
            role {
              id
              name
            }
          }
        }
      }
    }
  }
`);

export default GET_UPDATE_DEVICE_ACCESS_FIELDS;
