import {
  Box,
  ClickAwayListener,
  FormControl,
  InputLabel,
  OutlinedInput,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Button,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, type FC, useRef, useState, useCallback } from 'react';
import { CalendarMonth } from '@mui/icons-material';
import { DateCalendar, DateTimeField, LocalizationProvider } from '@mui/x-date-pickers-latest';
import { AdapterDayjs } from '@mui/x-date-pickers-latest/AdapterDayjs';
import { I18n } from '@lingui/core';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Formatters, useFormatters } from '@/hooks/useFormatters';

type RelativeTimeOption = {
  label: ReturnType<typeof msg>;
  value: string;
  numericValue: number;
};

export const relativeTimeOptions = [
  {
    label: msg`Personalizado`,
    value: 'absolute',
    numericValue: 0,
  },
  {
    label: msg`Últimos 15 minutos`,
    value: '-15m',
    numericValue: -15 * 60 * 1000,
  },
  {
    label: msg`Última hora`,
    value: '-1h',
    numericValue: -1 * 60 * 60 * 1000,
  },
  {
    label: msg`Últimas 4 horas`,
    value: '-4h',
    numericValue: -4 * 60 * 60 * 1000,
  },
  {
    label: msg`Últimas 8 horas`,
    value: '-8h',
    numericValue: -8 * 60 * 60 * 1000,
  },
  {
    label: msg`Últimas 12 horas`,
    value: '-12h',
    numericValue: -12 * 60 * 60 * 1000,
  },
  {
    label: msg`Último día`,
    value: '-1d',
    numericValue: -24 * 60 * 60 * 1000,
  },
  {
    label: msg`Últimos 3 días`,
    value: '-3d',
    numericValue: -3 * 24 * 60 * 60 * 1000,
  },
  {
    label: msg`Últimos 7 días`,
    value: '-7d',
    numericValue: -7 * 24 * 60 * 60 * 1000,
  },
  {
    label: msg`Últimos 10 días`,
    value: '-10d',
    numericValue: -10 * 24 * 60 * 60 * 1000,
  },
  {
    label: msg`Últimos 15 días`,
    value: '-15d',
    numericValue: -15 * 24 * 60 * 60 * 1000,
  },
  {
    label: msg`Últimos 30 días`,
    value: '-30d',
    numericValue: -30 * 24 * 60 * 60 * 1000,
  },
  {
    label: msg`Últimos 60 días`,
    value: '-60d',
    numericValue: -60 * 24 * 60 * 60 * 1000,
  },
  {
    label: msg`Últimos 90 días`,
    value: '-90d',
    numericValue: -90 * 24 * 60 * 60 * 1000,
  },
];

const windowRanges = [
  {
    max: 12 * 60 * 60 * 1000,
    window: null,
  },
  {
    max: 1 * 24 * 60 * 60 * 1000,
    window: '15m',
  },
  {
    max: 3 * 24 * 60 * 60 * 1000,
    window: '30m',
  },
  {
    max: 7 * 24 * 60 * 60 * 1000,
    window: '1h',
  },
  {
    max: 15 * 24 * 60 * 60 * 1000,
    window: '6h',
  },
  {
    max: 60 * 24 * 60 * 60 * 1000,
    window: '12h',
  },
  {
    max: 90 * 24 * 60 * 60 * 1000,
    window: '1d',
  },
  {
    max: Infinity,
    window: '3d',
  },
];

export const formatDateTimeRangeLabel = (
  value: Props['value'],
  i18n: I18n,
  formatDateTimeRange: Formatters['formatDateTimeRange'],
) => {
  const { start, stop } = value;

  const relativeStart = relativeTimeOptions.find((option) => option.value === start);
  if (relativeStart) return i18n._(relativeStart.label);
  const relativeStop = relativeTimeOptions.find((option) => option.value === stop);
  if (relativeStop) throw new Error(`Stop time can't be relative`);

  return formatDateTimeRange(new Date(start), stop ? new Date(stop) : null);
};

const getRelativeTimeValue = (value: Props['value']) => {
  const { start, stop } = value;
  if (stop !== null) return 'absolute';
  const relativeStart = relativeTimeOptions.find((option) => option.value === start);
  if (relativeStart) return relativeStart.value;
  else return 'absolute';
};

export type RangeTimeValue = {
  start: string;
  stop: string | null;
};

export function isRelativeTimeRange(value: RangeTimeValue): boolean {
  return relativeTimeOptions.some((option) => option.value === value.start);
}

export function convertTimeRangeToTs(value: RangeTimeValue): { start: number; stop: number } {
  const relative = relativeTimeOptions.find((option) => option.value === value.start);
  if (relative == null) {
    return {
      start: Math.floor(dayjs(value.start).valueOf() / 1000),
      stop: Math.floor(value.stop ? dayjs(value.stop).valueOf() / 1000 : dayjs().valueOf() / 1000),
    };
  }
  const now = dayjs().valueOf() / 1000;
  return {
    start: Math.floor(now + relative.numericValue / 1000),
    stop: Math.floor(now),
  };
}

interface Props {
  value: RangeTimeValue;
  onChange: (value: RangeTimeValue) => void;
  // used to change the window on large time ranges
  onChangeWindow?: (value: string | null) => void;
  error?: string;
  fullWidth?: boolean;
}

const DateTimeRangePicker: FC<Props> = ({ value, onChange, onChangeWindow, error, fullWidth }) => {
  const { _, i18n } = useLingui();
  const { formatDateTimeRange } = useFormatters();
  const [openPopper, setOpenPopper] = useState(false);
  const [absoluteDateError, setAbsoluteDateError] = useState<string | null>();
  const selectedRelativeTime = getRelativeTimeValue(value);
  const [openAbsolute, setOpenAbsolute] = useState(selectedRelativeTime === 'absolute');
  const anchorRef = useRef();
  const [absoluteDate, setAbsoluteDate] = useState({
    start: dayjs(),
    stop: null as dayjs.Dayjs | null,
  });

  const recentRangesJSON = localStorage.getItem('dataValue');
  const [recentRanges, setRecentRanges] = useState<RangeTimeValue[]>(
    recentRangesJSON
      ? (JSON.parse(recentRangesJSON) as { startDate: string; endDate: string }[]).map(
          ({ startDate, endDate }) => ({
            start: startDate,
            stop: endDate,
          }),
        )
      : [],
  );

  const setSelectedRelativeTime = (relativeTime: RelativeTimeOption) => {
    if (relativeTime.value === 'absolute') {
      setOpenAbsolute(true);
    } else {
      onChange({ start: relativeTime.value, stop: null });
      const { window } = windowRanges.find((range) => range.max >= -relativeTime.numericValue)!;
      onChangeWindow?.(window);
      setOpenAbsolute(false);
    }
  };

  const applyAbsoluteRange = useCallback(() => {
    const { start, stop } = absoluteDate;
    onChange({
      start: start.set('seconds', 0).set('millisecond', 0).toISOString(),
      stop: stop ? stop.toISOString() : null,
    });
    const { window } = windowRanges.find(
      (range) => range.max >= Math.abs(start.diff(stop ?? undefined)),
    )!;
    onChangeWindow?.(window);
    setRecentRanges((ranges) => {
      const newRanges = ranges.filter(
        (range) =>
          range.start !== start.toISOString() && range.stop !== (stop ? stop.toISOString() : null),
      );
      newRanges.unshift({
        start: start.toISOString(),
        stop: stop ? stop.toISOString() : null,
      });
      return newRanges;
    });
    setOpenPopper(false);
  }, [absoluteDate]);

  useEffect(() => {
    localStorage.setItem(
      'dataValue',
      JSON.stringify(
        recentRanges
          .map(({ start, stop }) => ({
            startDate: start,
            endDate: stop ?? null,
          }))
          .slice(0, 5),
      ),
    );
  }, [recentRanges]);

  useEffect(() => {
    const selectedRelativeTime = getRelativeTimeValue(value);
    if (selectedRelativeTime === 'absolute') {
      setOpenAbsolute(true);
    } else {
      setOpenAbsolute(false);
    }
  }, [openPopper]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.locale}>
        <ClickAwayListener
          onClickAway={() => {
            setOpenPopper(false);
          }}
        >
          <FormControl focused={openPopper} size="small" fullWidth={fullWidth}>
            <InputLabel htmlFor="time-period" error={Boolean(error)}>
              <Trans comment="Label de selector general de rango de tiempos">Período</Trans>
            </InputLabel>
            <OutlinedInput
              ref={anchorRef}
              error={Boolean(error)}
              readOnly
              value={formatDateTimeRangeLabel(value, i18n, formatDateTimeRange)}
              onFocus={() => {
                setOpenPopper(true);
              }}
              id="time-period"
            />
            <Popper
              open={openPopper}
              anchorEl={anchorRef.current}
              placement="bottom-start"
              sx={{ zIndex: 1400 }}
            >
              <Paper
                variant="outlined"
                sx={{
                  display: 'flex',
                  mt: 0.5,
                  width: '100%',
                  height: 440,
                  alignItems: 'stretch',
                }}
              >
                <List sx={{ display: 'flex', flexDirection: 'column', py: 0 }}>
                  <ListItem
                    disableGutters
                    disablePadding
                    sx={
                      openAbsolute
                        ? {
                            border: 1,
                            borderRight: 0,
                            borderColor: 'text.secondary',
                            borderRadius: '4px 0 0 4px',
                            backgroundColor: 'background.paper',
                            my: '-1px',
                            mx: 0,
                          }
                        : undefined
                    }
                  >
                    <ListItemButton
                      onClick={() => {
                        setSelectedRelativeTime(relativeTimeOptions[0]);
                      }}
                      autoFocus={openAbsolute || selectedRelativeTime === 'absolute'}
                      selected={openAbsolute || selectedRelativeTime === 'absolute'}
                    >
                      <ListItemText primary={_(relativeTimeOptions[0].label)} />
                      <ListItemIcon sx={{ mr: 0, ml: 4 }}>
                        <CalendarMonth color="info" />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                  <Box sx={{ overflowY: 'auto' }} flexGrow={1} className="custom-scrollbar">
                    {/* <ListSubheader>Rango relativo</ListSubheader> */}
                    {relativeTimeOptions
                      .filter((option) => option.value !== 'absolute')
                      .map((option) => (
                        <ListItem key={option.value} disableGutters disablePadding>
                          <ListItemButton
                            onClick={() => {
                              setSelectedRelativeTime(option);
                              if (option.value !== 'absolute') setOpenPopper(false);
                            }}
                            autoFocus={!openAbsolute && selectedRelativeTime === option.value}
                            selected={!openAbsolute && selectedRelativeTime === option.value}
                          >
                            <ListItemText primary={_(option.label)} />
                            {option.value === 'absolute' && (
                              <ListItemIcon sx={{ mr: 0, ml: 4 }}>
                                <CalendarMonth color="info" />
                              </ListItemIcon>
                            )}
                          </ListItemButton>
                        </ListItem>
                      ))}
                  </Box>
                </List>
                {openAbsolute && (
                  <Box
                    border="1px solid"
                    borderColor="text.secondary"
                    m="-1px"
                    borderRadius="0 4px 4px 4px"
                  >
                    <Box display="flex">
                      <Box pt={1.5}>
                        <Box px={3}>
                          <DateTimeField
                            required
                            value={absoluteDate.start}
                            maxDateTime={absoluteDate.stop}
                            onChange={(start) =>
                              start && setAbsoluteDate((value) => ({ ...value, start }))
                            }
                            label="Desde"
                            fullWidth
                            size="small"
                            autoFocus
                            onError={setAbsoluteDateError}
                          />
                        </Box>
                        <DateCalendar
                          showDaysOutsideCurrentMonth
                          disableFuture
                          value={absoluteDate.start}
                          maxDate={absoluteDate.stop}
                          onChange={(start) => setAbsoluteDate((value) => ({ ...value, start }))}
                        />
                      </Box>
                      <Box pt={1.5}>
                        <Box px={3}>
                          <DateTimeField
                            clearable
                            value={absoluteDate.stop}
                            minDateTime={absoluteDate.start}
                            onChange={(stop) => setAbsoluteDate((value) => ({ ...value, stop }))}
                            ampm={false}
                            label={_(msg`Hasta (vacío para 'ahora')`)}
                            fullWidth
                            size="small"
                            onError={setAbsoluteDateError}
                          />
                        </Box>
                        <DateCalendar
                          showDaysOutsideCurrentMonth
                          disableFuture
                          value={absoluteDate.stop}
                          minDate={absoluteDate.start}
                          onChange={(stop) => setAbsoluteDate((value) => ({ ...value, stop }))}
                        />
                      </Box>
                    </Box>
                    <Box display="flex" justifyContent="end" p={1} gap={2} pr={2.5}>
                      <SelectableMenu
                        label={_(
                          msg({
                            message: 'Rangos recientes',
                            comment: 'Selector de tiempo - botón de rangos de tiempo recientes',
                          }),
                        )}
                        options={recentRanges.map((range) => ({
                          label: formatDateTimeRangeLabel(range, i18n, formatDateTimeRange),
                          value: range,
                        }))}
                        onSelect={(value) => {
                          setAbsoluteDate({
                            start: dayjs(value.start),
                            stop: value.stop ? dayjs(value.stop) : null,
                          });
                        }}
                      />
                      <Button
                        size="small"
                        variant="contained"
                        onClick={applyAbsoluteRange}
                        disabled={Boolean(absoluteDateError)}
                      >
                        <Trans>Aplicar rango</Trans>
                      </Button>
                    </Box>
                  </Box>
                )}
              </Paper>
            </Popper>
          </FormControl>
        </ClickAwayListener>
      </LocalizationProvider>
      {error && (
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      )}
    </>
  );
};

interface SelectableMenuProps {
  label: string;
  options: { label: string; value: RangeTimeValue }[];
  onSelect: (value: RangeTimeValue) => void;
}

const SelectableMenu: FC<SelectableMenuProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disabled={props.options.length === 0}
        variant="outlined"
      >
        {props.label}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ zIndex: 2001 }}>
        {props.options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => {
              props.onSelect(option.value);
              handleClose();
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DateTimeRangePicker;
