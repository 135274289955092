import { Email, Phone } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'src/store';
import useHasDebts from '../hooks/useHasDebts';
import { closeModal, openModal } from '../slices/debtsAlertSlice';
import { Trans } from '@lingui/macro';

/** Time frequency on which the modal will appear. */
const MODAL_DISPLAY_FREQUENCY = 1000 * 60 * 60 * 6; // 6 hours

/**
 * Modal that appears when the user has debts.
 * It will appear every 6 hours.
 */
const DebtsAlertModal = () => {
  const { openedModal } = useSelector((state) => state.debts_alert_store);
  const dispatch = useDispatch();
  const hasDebts = useHasDebts();

  useEffect(() => {
    if (hasDebts && isDisplayModalTime()) {
      dispatch(openModal());
    } else if (hasDebts === false) {
      localStorage.removeItem('debtsNextModalDate');
    }
  }, [hasDebts]);

  /**
   * Returns true if the date saved on LocalStorage has passed
   * or if there is no time info on LocalStorage.
   */
  const isDisplayModalTime = () => {
    const now = new Date();
    const nextModalDateStringified = localStorage.getItem('debtsNextModalDate');
    if (!nextModalDateStringified) return true;
    const nextModalDate = new Date(nextModalDateStringified);
    return now > nextModalDate;
  };

  /**
   * Saves on LocalStorage the date on which the modal will be displayed again.
   */
  const setNextModalDate = () => {
    const now = new Date();
    const nextModalDate = new Date(now.getTime() + MODAL_DISPLAY_FREQUENCY);
    localStorage.setItem('debtsNextModalDate', nextModalDate.toISOString());
  };

  const handleClose = () => {
    setNextModalDate();
    dispatch(closeModal());
  };

  return (
    <Dialog open={openedModal}>
      <DialogContent>
        <Typography mb={2}>
          <Trans>Estimado cliente,</Trans>
        </Typography>
        <Typography>
          <Trans>
            Hemos detectado que la cuenta de AMARU presenta un saldo pendiente. Favor contactar al
            servicio de post-venta de Capta Hydro:
          </Trans>
        </Typography>
        <List>
          <ListItem>
            <ListItemText>
              <Trans>Cristóbal Matamala</Trans>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Phone />
            </ListItemIcon>
            <ListItemText>
              <Link href="tel:+56992221736" color="inherit">
                +56 9 9222 1736
              </Link>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Email />
            </ListItemIcon>
            <ListItemText>
              <Link href="mailto:cs@captahydro.com" color="inherit">
                cs@captahydro.com
              </Link>
            </ListItemText>
          </ListItem>
        </List>
        <Typography mb={2}>
          <Trans>Regularice ahora y evite limitaciones en el servicio.</Trans>
        </Typography>
        <Typography>
          <Trans>Equipo Capta Hydro</Trans>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          <Trans>Cerrar</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DebtsAlertModal;
