import { gql } from '@apollo/client';

const COMMENTS = gql`
  subscription CommentsSubscription($accountId: Int!) {
    comments(accountId: $accountId) {
      contain
      component_id
      modified
      created
      account {
        id
        name
        lastnames
        username
      }
      component {
        id
        name
        node_id
      }
    }
  }
`;

export default COMMENTS;
