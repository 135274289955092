import { useMutation } from '@apollo/client';
import { Box, Dialog, DialogContent, Skeleton, Typography } from '@mui/material';
import { type FC, Suspense, useState, FormEventHandler } from 'react';
import RETRY_DGA_REPORTS_FOR_DEVICE from '@features/dga/graphql/mutations/retryReportsForDevice';
import { useModalStatusMessage } from '@/hooks';
import { useDispatch } from 'react-redux';
import { useSelector } from '@/store';
import { setRetryReportsModal } from '../../slices/dgaSlice';
import ModalActions from '@components/modal/ModalActions';
import ModalTitle from '@components/modal/ModalTitle';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

interface Props {
  onClose: () => void;
  deviceId: string;
}

const Component: FC<Props> = ({ deviceId, onClose }) => {
  const [retryReportsForDevice] = useMutation(RETRY_DGA_REPORTS_FOR_DEVICE, {
    onCompleted: (data) => {
      const success = data?.RetryDGAReportsForDevice?.success;
      const message = data?.RetryDGAReportsForDevice?.message;
      if (success === true) {
        openModalSuccessMessage(message || _(msg`Se intentará publicar los datos nuevamente.`));
        onClose();
      } else {
        openModalErrorMessage(message || _(msg`Algo salió mal, por favor intente nuevamente.`));
      }
      setLoading(false);
    },
    onError: (error) => {
      openModalErrorMessage(error.message);
      setLoading(false);
    },
  });
  const [loading, setLoading] = useState(false);
  const { _ } = useLingui();

  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();

  const handleConfirmation: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setLoading(true);
    retryReportsForDevice({
      variables: { deviceId },
    });
  };

  return (
    <form onSubmit={handleConfirmation}>
      <ModalTitle
        onClose={onClose}
        title={
          <Trans>
            Reintentar la publicación de todos los reportes fallidos del dispositivo {deviceId}
          </Trans>
        }
      />
      <DialogContent dividers>
        <Typography>
          <Trans>¿Está seguro que desea continuar?</Trans>
        </Typography>
      </DialogContent>
      <ModalActions
        onClose={onClose}
        onSubmit="submit"
        submitLabel={_(msg`Confirmar`)}
        submitLoading={loading}
      />
    </form>
  );
};

const Fallback: FC<{ onClose: () => void }> = ({ onClose }) => {
  const deviceName = <Skeleton variant="text" width={200} />;
  return (
    <>
      <ModalTitle
        onClose={onClose}
        title={
          <Box display="flex" gap={1}>
            <Trans>
              Reintentar la publicación de todos los reportes fallidos del dispositivo {deviceName}
            </Trans>
          </Box>
        }
      />
      <DialogContent dividers>
        <Skeleton variant="rounded" height={200} />
      </DialogContent>
      <ModalActions onClose={onClose} />
    </>
  );
};

const DGAReportsRetryModal: FC = () => {
  const dispatch = useDispatch();
  const { retryReportsModal } = useSelector((state) => state.dga_store);

  const onClose = () => {
    dispatch(
      setRetryReportsModal({
        open: false,
      }),
    );
  };

  return (
    <Dialog onClose={onClose} open={retryReportsModal.open} maxWidth="md" fullWidth>
      <Suspense fallback={<Fallback onClose={onClose} />}>
        {retryReportsModal.open && (
          <Component onClose={onClose} deviceId={retryReportsModal.deviceId} />
        )}
      </Suspense>
    </Dialog>
  );
};

export default DGAReportsRetryModal;
