import { useLingui } from '@lingui/react';
import { Autocomplete, Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { DeviceType, UpdateUserPreferencesMetricsInput } from '@/__generated__/graphql';
import { getMetricDisplayName } from '@/utils/i18n/getMetricDisplayName';
import { Trans, msg } from '@lingui/macro';
import NumericTextField from '@components/NumericTextField';
import { AddCircle, Close } from '@mui/icons-material';
import GET_USER_PREFERENCES_METRICS from '../graphql/queries/getUserPreferencesMetrics';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { getUnitsPerMetric } from '@/utils/getUnitsPerMetric';
import { zodResolver } from '@hookform/resolvers/zod';
import { getMetricsValidationSchema } from '../utils/metricsValidationSchema';
import { useModalStatusMessage } from '@/hooks';
import UPDATE_USER_PREFERENCES_METRICS from '../graphql/mutation/updateUserPreferencesMetrics';
import { getDeviceTypeOptions } from '@/utils/i18n/getDeviceTypeOptions';
import DEVICES_ANALYSIS_GET_DEVICES from '@features/devicesAnalysis/graphql/queries/getDevices';
import GET_USER_PREFERENCES_METRICS_FACTORY_DEFAULTS from '../graphql/queries/getUserPreferencesMetricsDefaultValues';

type FieldValues = UpdateUserPreferencesMetricsInput;

const MetricsTab = () => {
  const { i18n, _ } = useLingui();
  const { data } = useSuspenseQuery(GET_USER_PREFERENCES_METRICS, { fetchPolicy: 'no-cache' });
  const { data: factoryDefaultsData } = useSuspenseQuery(
    GET_USER_PREFERENCES_METRICS_FACTORY_DEFAULTS,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const { openModalSuccessMessage, openModalErrorMessage } = useModalStatusMessage();
  const deviceTypeOptions = getDeviceTypeOptions();
  const [update] = useMutation(UPDATE_USER_PREFERENCES_METRICS, {
    onCompleted(data) {
      openModalSuccessMessage(_(msg`Preferencias actualizadas correctamente`));
      const defaultValues: FieldValues = {
        ...data.updateUserPreferencesMetrics,
        userId: data.updateUserPreferencesMetrics.id,
      };
      methods.reset(defaultValues);
    },
    onError(error) {
      openModalErrorMessage(error.message);
    },
    refetchQueries: [DEVICES_ANALYSIS_GET_DEVICES],
  });

  const defaultValues: FieldValues = {
    ...data.user.preferences.metrics,
    userId: data.user.id,
  };

  const methods = useForm<FieldValues>({
    defaultValues,
    resolver: zodResolver(getMetricsValidationSchema(i18n)),
  });

  const { fields: metricFields } = useFieldArray({
    control: methods.control,
    name: 'fields',
  });

  const submitHandler: SubmitHandler<FieldValues> = (data) => {
    update({
      variables: {
        input: data,
      },
    });
  };

  const resetHandler = () => {
    methods.reset(defaultValues);
  };

  const factoryDefaultsHandler = () => {
    methods.reset(
      {
        ...factoryDefaultsData.user.preferences.metrics,
        userId: factoryDefaultsData.user.id,
      },
      {
        keepDefaultValues: true,
      },
    );
  };

  useEffect(() => {
    const defaultValues: FieldValues = {
      ...data.user.preferences.metrics,
      userId: data.user.id,
    };
    methods.reset(defaultValues);
  }, [data]);

  const { formState } = methods;
  return (
    <form style={{ height: '100%' }} onSubmit={methods.handleSubmit(submitHandler)}>
      <Box
        height="100%"
        gap={2}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box overflow="auto">
          <Typography variant="h5" mb={2}>
            <Trans>Unidad de medida y precisión</Trans>
          </Typography>
          {metricFields.map((metric, index) => {
            const {
              fields: additionalFields,
              append,
              remove,
            } = useFieldArray({
              control: methods.control,
              name: `fields.${index}.additionalConfigurations`,
            });
            const unitOptions = getUnitsPerMetric(metric.name, i18n);

            const onAddAdditionalField = () => {
              append({
                deviceType: DeviceType.T,
                unit: unitOptions[0].enumValue,
                precision: 0,
              });
            };

            const onRemoveAdditionalField = (index: number) => {
              remove(index);
            };

            return (
              <Box key={metric.id + metric.name} my={2}>
                <Typography variant="h6">{getMetricDisplayName(metric.name, i18n)}</Typography>
                <Box
                  display="grid"
                  gridTemplateColumns="1fr 1fr 1fr 40px"
                  gap={1}
                  alignItems="center"
                >
                  <Typography color="text.disabled" fontStyle="italic" px={1.5}>
                    <Trans>Por defecto</Trans>
                  </Typography>
                  <Controller
                    control={methods.control}
                    name={`fields.${index}.defaultConfiguration.unit`}
                    render={({ field, fieldState: { error } }) => (
                      <Autocomplete
                        {...field}
                        onChange={(_, newValue) => {
                          field.onChange(newValue);
                        }}
                        fullWidth
                        options={unitOptions.map((option) => option.enumValue)}
                        getOptionLabel={(option) =>
                          unitOptions.find((unit) => unit.enumValue === option)?.label ?? option
                        }
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            error={Boolean(error)}
                            helperText={error?.message}
                            label={<Trans>Unidad de medida</Trans>}
                            {...params}
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    control={methods.control}
                    name={`fields.${index}.defaultConfiguration.precision`}
                    render={({ field, fieldState: { error } }) => (
                      <NumericTextField
                        value={field.value}
                        error={Boolean(error)}
                        helperText={error?.message}
                        onChange={field.onChange}
                        onBlur={(e) => {
                          const value = e.target.value;
                          field.onChange(!isNaN(parseInt(value)) ? parseInt(value) : '');
                        }}
                        inputMode="numeric"
                        fullWidth
                        label={<Trans>Decimales de precisión</Trans>}
                        size="small"
                      />
                    )}
                  />
                </Box>
                {additionalFields.map((additionalField, additionalFieldIndex) => {
                  return (
                    <Box
                      key={additionalField.id}
                      display="grid"
                      gridTemplateColumns="1fr 1fr 1fr 40px"
                      gap={1}
                      mt={1}
                      alignItems="center"
                    >
                      <Controller
                        control={methods.control}
                        name={`fields.${index}.additionalConfigurations.${additionalFieldIndex}.deviceType`}
                        render={({ field, fieldState: { error } }) => (
                          <Autocomplete
                            {...field}
                            onChange={(_, newValue) => {
                              field.onChange(newValue);
                            }}
                            disableClearable
                            fullWidth
                            options={deviceTypeOptions.map((option) => option.enumValue)}
                            getOptionLabel={(option) =>
                              deviceTypeOptions.find(
                                (deviceType) => deviceType.enumValue === option,
                              )?.label ?? option
                            }
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                error={Boolean(error)}
                                helperText={error?.message}
                                label={<Trans>Tipo de dispositivo</Trans>}
                                {...params}
                              />
                            )}
                          />
                        )}
                      />
                      <Controller
                        control={methods.control}
                        name={`fields.${index}.additionalConfigurations.${additionalFieldIndex}.unit`}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            onChange={(_, newValue) => {
                              field.onChange(newValue);
                            }}
                            disableClearable
                            fullWidth
                            options={unitOptions.map((option) => option.enumValue)}
                            getOptionLabel={(option) =>
                              unitOptions.find((unit) => unit.enumValue === option)?.label ?? option
                            }
                            size="small"
                            renderInput={(params) => (
                              <TextField label={<Trans>Unidad de medida</Trans>} {...params} />
                            )}
                          />
                        )}
                      />
                      <Controller
                        control={methods.control}
                        name={`fields.${index}.additionalConfigurations.${additionalFieldIndex}.precision`}
                        render={({ field }) => (
                          <NumericTextField
                            value={field.value}
                            onChange={(e) => {
                              const value = e.target.value;
                              field.onChange(value ? parseInt(value) : '');
                            }}
                            inputMode="numeric"
                            fullWidth
                            label={<Trans>Decimales de precisión</Trans>}
                            size="small"
                          />
                        )}
                      />

                      <IconButton onClick={() => onRemoveAdditionalField(additionalFieldIndex)}>
                        <Close />
                      </IconButton>
                    </Box>
                  );
                })}

                <Button
                  onClick={onAddAdditionalField}
                  size="small"
                  startIcon={<AddCircle />}
                  sx={{ mt: 1 }}
                  color="info"
                >
                  <Trans>Configurar otro tipo de dispositivo</Trans>
                </Button>
              </Box>
            );
          })}
        </Box>
        <Box display="flex" justifyContent="end" gap={1}>
          <Button onClick={factoryDefaultsHandler} variant="outlined" color="warning">
            <Trans>Restablecer valores por defecto</Trans>
          </Button>
          <Box flexGrow={1}></Box>
          <Button
            onClick={resetHandler}
            disabled={!formState.isDirty}
            variant="outlined"
            color="info"
          >
            <Trans>Restablecer formulario</Trans>
          </Button>
          <LoadingButton
            type="submit"
            loading={formState.isSubmitting}
            disabled={!formState.isDirty}
            variant="contained"
          >
            <Trans>Guardar</Trans>
          </LoadingButton>
        </Box>
      </Box>
    </form>
  );
};

export default MetricsTab;
