import { gql } from '@/__generated__';

const GET_ORGANIZATION_PREFERENCES = gql(`#graphql
  query GetOrganizationPreferences {
    organization {
      id
      preferences {
        id
        hour12
      }
    }
  }
`);

export default GET_ORGANIZATION_PREFERENCES;
