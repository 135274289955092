import { gql } from '@/__generated__';

export const DGA_REPORTS_TABLE_GET_DEVICE = gql(`#graphql
  query DGAReportsTableModal_GetReports(
    $deviceId: ID!,
    $reportsInput: DGAReportsInput,
    $reportsPagination: DynamoPaginationInput

  ) {
    device(input: { id: $deviceId }) {
      id
      profile {
        id
        name
      }
      dgaConfiguration {
        id
        type
        frequency
      }
      dga {
        id
        reports(input: $reportsInput, pagination: $reportsPagination) {
          items {
            data {
              flow
              accumulatedVolume
              level
            }
            timestamp
            executionTimestamp
            success
            message
            confirmationId
          }
          pageInfo {
            count
            lastEvaluatedKey
          }
        }
      }
    }
  }
`);
