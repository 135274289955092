import { gql } from '@/__generated__';

const GET_DEVICE_FILTERS = gql(`
  query GetDeviceFilters($deviceId: String!) {
    deviceDataConfiguration(deviceId: $deviceId) {
      deviceId
      topicVersion
      filters {
        outOfRangeVelocity
        outliers {
          version
          wh {
            name
            windowSize
            sdThreshold
            smoothing
          }
          wf {
            name
            windowSize
            sdThreshold
            smoothing
          }
          radar {
            name
            windowSize
            sdThreshold
            smoothing
          }
          dp {
            name
            windowSize
            sdThreshold
            smoothing
          }
        }
      }
    }
  }
`);

export default GET_DEVICE_FILTERS;
