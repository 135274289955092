import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'nprogress/nprogress.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'react-perfect-scrollbar/dist/css/styles.css';
import App from './App';
import './index.css';
import ReactGA from 'react-ga4';

const sentryDSN = import.meta.env.VITE_REACT_APP_SENTRY_DSN;

if (sentryDSN) {
  Sentry.init({
    dsn: sentryDSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const root = createRoot(document.getElementById('root')!);

//Initialize GA4
ReactGA.initialize('G-L26BDXFLXF');

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
