import { gql } from '@apollo/client';

const FETCH_ORGANIZATIONS = gql`
  query FetchOrganizations($organizationId: Int) {
    FetchOrganizations(organizationId: $organizationId) {
      id
      name
      website
      address
      phone
      phone_number
      photo
      dga_user
      dga_password
      verify_auth_orgs
      calling_code
      access
      created
      has_debt
      status
      irrigations {
        id
        name
      }
    }
  }
`;

export default FETCH_ORGANIZATIONS;
