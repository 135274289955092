import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { get, isNil, isNull } from 'lodash';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from '@apollo/client';
import UPDATE_ALIASES_NAME from 'src/graphql/mutations/updateAliasesName';
import { DeviceDialogProps } from '../dialogInterfaces';
import { Organization } from 'src/__generated__/graphql';
import OrganizationsAutocomplete from 'src/features/organizationsAutocomplete';
import GET_ACCESS_NAME_ALIASES from 'src/graphql/querys/access/getAccessNameAliases';

const DeviceNameAliases = ({
  device,
  show,
  handleClose,
}: DeviceDialogProps) => {
  const { data: access } = useQuery(GET_ACCESS_NAME_ALIASES);
  const [UpdateAliasesName, { loading }] = useMutation(UPDATE_ALIASES_NAME, {
    onCompleted: (data) => {
      if (!isNull(get(data, 'UpdateAliasesName', null))) {
        if (get(data, 'UpdateAliasesName.success', null)) {
          toast.success('Dispositivo actualizado exitosamente.');
          handleInternalClose(true);
        } else {
          toast.error('Algo salió mal, por favor intente nuevamente.');
        }
      }
    },
  });
  const [name, setName] = useState('');
  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization | null>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleInternalClose = (refetch: boolean) => {
    setErrors({});
    setSelectedOrganization(null);
    handleClose(refetch);
  };

  useEffect(() => {
    if (device && device.id) {
      setName(get(device, 'name', ''));
    }
  }, [device]);

  const handleSave = () => {
    const temp: Record<string, string> = {};

    !name.trim() ? (temp.name = 'El Alias es requerido') : (temp.name = '');
    isNil(selectedOrganization)
      ? ((temp.organization = 'La organizacion es requerido'), '')
      : (temp.organization = '');
    if (access?.authorization.authorizationGeneral?.showOrganizationFilter) temp.organization = '';

    if (
      selectedOrganization?.id.toString() === get(device, 'organization_id')
    ) {
      temp.organization =
        'No puedes agregar alias a la organización del dispositivo.';
    }

    setErrors(temp);

    if (!Object.values(temp).every((x) => x === '')) {
      return null;
    }

    UpdateAliasesName({
      variables: {
        name: name,
        device_id: get(device, 'id', '') as string,
        organizationId: selectedOrganization!.id,
      },
    });

    return null;
  };

  const handleCloseWithoutLoading = () => {
    handleInternalClose(false);
  };

  return (
    <Dialog
      open={show}
      maxWidth="sm"
      fullWidth
      onClose={handleCloseWithoutLoading}
    >
      <DialogTitle>
        Agregar alias al dispositivo {get(device, 'name')}
      </DialogTitle>
      {show && (
        <DialogContent>
          <Grid container>
            {access?.authorization.authorizationGeneral?.showOrganizationFilter && (
              <Grid item xs={12} mt={1}>
                <OrganizationsAutocomplete
                  value={selectedOrganization}
                  onChange={(organization) =>
                    setSelectedOrganization(organization)
                  }
                  error={!!errors?.organization}
                  helperText={errors?.organization}
                />
              </Grid>
            )}
            <Grid item xs={12} mt={1}>
              <TextField
                label="Alias del dispositivo"
                value={name}
                fullWidth
                onChange={(e) => setName(e.target.value)}
                {...(errors &&
                  errors.name && { error: true, helperText: errors.name })}
              />
            </Grid>
            <Grid item xs={12} mt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                color="info"
                onClick={handleCloseWithoutLoading}
                sx={{ width: 100 }}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleSave}
                disabled={loading}
                sx={{
                  ml: 2,
                  width: 100,
                }}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default DeviceNameAliases;
