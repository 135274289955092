import { useEffect, useState } from 'react';
import { Typography, Box, Button, Grid, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import { get } from 'lodash';
import { useApolloClient } from '@apollo/client';
import UPDATE_GATE from 'src/graphql/mutations/updateGate';
import FETCH_GATES from 'src/graphql/querys/fetchGates';
import CreateNewUser from './CreateNewUser';
import ListUserAlarm from './ListUsersAlarm';
import { setSnackbar } from 'src/slices/components';
import { useDispatch } from 'src/store';
import {
  setSelectedGate,
  updateGates,
  setGates as setAllGates,
} from 'src/slices/gates';
import { GateDialogProps } from '../../dialogInterfaces';

const initialUser = {
  name: '',
  phone: '',
  notification: true,
  type: 'created',
  index: 0,
};

const UpdateAlarm = ({ gate, show, handleClose }: GateDialogProps) => {
  const dispatch = useDispatch();
  const client = useApolloClient();

  const [users, setUsers] = useState([]);
  const [showAddNewUser, setShowAddNewUser] = useState(false);
  const [values, setValues] = useState(initialUser);
  const [errors, setErrors] = useState({});
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [searchUserValue, setSearchUserValue] = useState('');

  // initial
  const usersAlarm = get(gate, 'alarm_data', null);
  useEffect(() => {
    if (usersAlarm && typeof usersAlarm === 'string') {
      const parse = JSON.parse(usersAlarm);
      setUsers(parse);
    } else if (usersAlarm) {
      setUsers(usersAlarm);
    } else {
      setUsers([]);
    }
  }, [usersAlarm]);

  // validation
  const validate = ({ fieldValues = values }: any) => {
    const temp: any = { ...errors };
    const regexPhone = /^\+?\d{7,20}$/;

    if (!fieldValues.name.trim()) {
      temp.name = 'El nombre es requerido.';
    } else {
      temp.name = '';
    }
    if (!fieldValues.phone.trim()) {
      temp.phone = 'El número de telefono es requerido.';
    } else if (!regexPhone.test(fieldValues.phone.trim())) {
      temp.phone = 'El número es incorrecto.';
    } else {
      temp.phone = '';
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
    return false;
  };

  // filter users
  useEffect(() => {
    let searchUser = get(gate, 'alarm_data', null);
    if (searchUser && typeof searchUser === 'string') {
      searchUser = JSON.parse(searchUser);
    }
    if (searchUser && searchUserValue !== '') {
      setUsers(
        searchUser.filter(({ name }) =>
          name.toLowerCase().includes(searchUserValue.toLowerCase()),
        ),
      );
    } else if (searchUser) {
      setUsers(searchUser);
    }
  }, [searchUserValue]);

  const handleSearchUserValueChange = (
    event: React.ChangeEvent<{ value: string }>,
  ) => {
    const { value } = event.target;
    setSearchUserValue(value);
  };

  // new user
  const handleSaveNewUser = async () => {
    const validateFrom = validate({ ...values });
    if (!validateFrom) return;
    setShowAddNewUser(false);
    let alarmData: any = '';
    const { phone } = values;
    let phoneV = '';
    if (!phone.includes('+')) {
      phoneV = `+${phone}`;
    } else {
      phoneV = phone;
    }

    if (users && users.length) {
      alarmData = [
        ...users,
        { name: values.name, phone: phoneV, notification: true },
      ];
      alarmData = JSON.stringify(alarmData);
    } else {
      alarmData = JSON.stringify([
        { name: values.name, phone: phoneV, notification: true },
      ]);
    }

    try {
      const { data } = await client.mutate({
        mutation: UPDATE_GATE,
        variables: {
          name: get(gate, 'name', ''),
          parentComponentId: get(gate, 'parent_component_id', ''),
          alarmData,
        },
      });

      setTimeout(() => {
        dispatch(
          setSnackbar({
            open: false,
            message: '',
          }),
        );
      }, 4000);

      if (data && get(data, `UpdateGate.success`, null)) {
        setValues(initialUser);
        dispatch(
          setSnackbar({
            open: true,
            message: 'Usuario nuevo agregado con éxito',
            severity: 'success',
          }),
        );
        setUsers(JSON.parse(alarmData));
        const newGates = get(
          await client.query({
            query: FETCH_GATES,
            fetchPolicy: 'no-cache',
          }),
          'data.gates.gates',
          [],
        );
        dispatch(setAllGates(newGates));
      } else {
        dispatch(
          setSnackbar({
            open: true,
            message: 'Error, fallo la solicitud inténtelo nuevamente.',
            severity: 'error',
          }),
        );
      }
    } catch (error) {
      dispatch(
        setSnackbar({
          open: true,
          message: 'Error, fallo la solicitud inténtelo nuevamente.',
          severity: 'error',
        }),
      );
    }
  };

  // select
  const handleSelectAll = async () => {
    if (users) {
      const usersState = users?.map((t) => {
        return {
          name: t.name,
          phone: t.phone,
          notification: !isAllSelected,
        };
      });
      setIsAllSelected(!isAllSelected);
      setUsers(usersState);
      await client.mutate({
        mutation: UPDATE_GATE,
        variables: {
          name: get(gate, 'name', ''),
          parentComponentId: get(gate, 'parent_component_id', ''),
          alarmData: JSON.stringify(usersState),
        },
      });
      const gates: any = { ...gate, alarm_data: usersState };
      dispatch(updateGates(gates));
      dispatch(
        setSnackbar({
          open: true,
          message: 'Operación realizada con éxito',
          severity: 'success',
        }),
      );
    }
  };

  // checkout notification
  const hanldecheckedUsers = async (phone: string) => {
    if (users && users.length) {
      const usersState = users?.map((t: any) => {
        return {
          name: t.name,
          phone: t.phone,
          notification: t.phone === phone ? !t.notification : t.notification,
        };
      });
      setUsers(usersState);
      await client.mutate({
        mutation: UPDATE_GATE,
        variables: {
          name: get(gate, 'name', ''),
          parentComponentId: get(gate, 'parent_component_id', ''),
          alarmData: JSON.stringify(usersState),
        },
      });
      const gates: any = { ...gate, alarm_data: usersState };
      dispatch(updateGates(gates));
      dispatch(
        setSnackbar({
          open: true,
          message: 'Operación de actualizar realizado con éxito',
          severity: 'success',
        }),
      );
    }
  };

  // delate users
  const handleDeleteUser = async (phone: string) => {
    const usersState = users?.filter((t) => t.phone !== phone);
    setUsers(usersState);
    await client.mutate({
      mutation: UPDATE_GATE,
      variables: {
        name: get(gate, 'name', ''),
        parentComponentId: get(gate, 'parent_component_id', ''),
        alarmData: JSON.stringify(usersState),
      },
    });
    const gates: any = { ...gate, alarm_data: usersState };
    dispatch(updateGates(gates));
    dispatch(
      setSnackbar({
        open: true,
        message: 'Operación de eliminar realizado con éxito',
        severity: 'success',
      }),
    );
  };

  const handleUpdatedTypeUser = async (user: any) => {
    const index = users.indexOf(user);
    setValues({
      ...user,
      type: 'updated',
      index: index,
    });
    setShowAddNewUser(true);
  };

  const handleUpdatedUser = async (user: any) => {
    const usersUpdated = users;
    usersUpdated.splice(get(values, 'index'), 1, {
      name: get(values, 'name'),
      phone: get(values, 'phone'),
      notification: get(values, 'notification'),
    });
    try {
      await client.mutate({
        mutation: UPDATE_GATE,
        variables: {
          name: get(gate, 'name', ''),
          parentComponentId: get(gate, 'parent_component_id', ''),
          alarmData: JSON.stringify(usersUpdated),
        },
      });
      setUsers(usersUpdated);
      dispatch(
        setSnackbar({
          open: true,
          message: 'Usuario editado con éxito.',
          severity: 'success',
        }),
      );
      setValues(initialUser);
      setShowAddNewUser(false);
      const gates: any = { ...gate, alarm_data: usersUpdated };
      dispatch(updateGates(gates));
    } catch (error) {
      dispatch(
        setSnackbar({
          open: true,
          message: 'Error, vuelve a intentarlo!',
          severity: 'error',
        }),
      );
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone' && value.trim()) {
      const regn = value.replace(' ', '');
      setValues({
        ...values,
        [name]: regn,
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const handleSaveStatus = async () => {
    dispatch(
      setSnackbar({
        open: true,
        message: 'Todos tus cambios fueron guardado con éxito',
        severity: 'success',
      }),
    );
    setTimeout(() => {
      dispatch(
        setSnackbar({
          open: false,
          message: '',
        }),
      );
    }, 3000);
    setValues(initialUser);
    handleClose(false);
  };

  const handleCloseNewUser = () => {
    setShowAddNewUser(false);
    setValues(initialUser);
  };

  const handleInternalClose = () => {
    setValues(initialUser);
    setSearchUserValue('');
    handleClose(false);
  };

  return (
    <Dialog open={show} maxWidth="md" fullWidth onClose={handleInternalClose}>
      <Box display="flex" flexDirection="column" width="100%" height="100%">
        <Box display="flex">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            p={2}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                visibility: !setSelectedGate ? 'hidden' : null,
              }}
            >
              <Box mb={2}>
                <Typography variant="h6">
                  Usuarios a notificar alarma en {get(gate, 'name', '')}
                </Typography>
              </Box>
              <Grid container display="flex" flexDirection="row" spacing={1}>
                <Grid item xs={12}>
                  <Box>
                    <TextField
                      fullWidth
                      label="Buscar Usuario"
                      value={searchUserValue}
                      onChange={handleSearchUserValueChange}
                    />
                  </Box>
                </Grid>
                {users && users.length ? (
                  <Grid item xs={12}>
                    {isAllSelected ? (
                      <Button
                        startIcon={<RemoveIcon />}
                        onClick={handleSelectAll}
                      >
                        Deseleccionar todos los usuarios
                      </Button>
                    ) : (
                      <Button
                        startIcon={<CheckIcon />}
                        onClick={handleSelectAll}
                      >
                        Seleccionar todos los usuarios
                      </Button>
                    )}
                  </Grid>
                ) : (
                  <></>
                )}
                {!users || !users.length ? (
                  <Grid
                    item
                    xs={12}
                    p={2}
                    height={250}
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      sx={{
                        mb: 2,
                      }}
                    >
                      No hay usuarios para notificar alarma...
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <ListUserAlarm
                      users={users}
                      hanldecheckedUsers={hanldecheckedUsers}
                      handleDeleteUser={handleDeleteUser}
                      handleUpdatedTypeUser={handleUpdatedTypeUser}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box my={2}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            sx={{
              gap: '20px',
            }}
          >
            <Box>
              <Button
                sx={{ ml: 2 }}
                variant="outlined"
                onClick={handleInternalClose}
              >
                Cerrar
              </Button>
            </Box>
            <Button variant="contained" onClick={() => setShowAddNewUser(true)}>
              Agregar nuevo usuario
            </Button>
          </Box>
        </Box>
      </Box>
      <CreateNewUser
        show={showAddNewUser}
        handleClose={handleCloseNewUser}
        handleSave={handleSaveNewUser}
        handleUpdatedUser={handleUpdatedUser}
        handleInputChange={handleInputChange}
        values={values}
        errors={errors}
      />
    </Dialog>
  );
};

export default UpdateAlarm;
