import {
  DeviceConfig,
  ContributingDeviceOrganizationConfig,
  MaipoSiraStore,
  DeviceOrganizationConfig,
  AADeviceOrganizationConfig,
} from 'src/features/distributionMaipo/types/dataTypes';
import {
  UnitFlow,
  UnitLevel,
  convertFromCmTo,
  convertFromLsTo,
} from 'src/utils/unitConverterValues';

function getFlowFromDeviceConfig(config: DeviceConfig) {
  const { measures, useWhichValues } = config;
  return measures[useWhichValues].flow;
}

function getFlowFromDeviceConfigUnit(config: DeviceConfig, unitFlow: UnitFlow) {
  return convertFromLsTo(getFlowFromDeviceConfig(config), unitFlow);
}

function getLevelFromDeviceConfig(config: DeviceConfig) {
  const { measures, useWhichValues } = config;
  return measures[useWhichValues].level;
}

function getLevelFromDeviceConfigUnit(
  config: DeviceConfig,
  unitLevel: UnitLevel,
) {
  return convertFromCmTo(getLevelFromDeviceConfig(config), unitLevel);
}

function getRiverFlow(store: MaipoSiraStore): number {
  const { deviceConfig } = store.newDistributionInfo;

  const riverReduceFunction = (accumulator: number, currentConfig) => {
    const { is_part_of_river_sum, is_descarga_lonquen } = currentConfig;

    if (!is_part_of_river_sum && !is_descarga_lonquen) {
      return accumulator;
    }
    const deviceFlow = getFlowFromDeviceConfig(currentConfig);

    // Case lonquen
    if (is_descarga_lonquen) {
      return accumulator - deviceFlow / 2;
    }

    // Normal case
    return accumulator + deviceFlow;
  };

  return deviceConfig.reduce(riverReduceFunction, 0);
}

function getRiverInfo(store: MaipoSiraStore) {
  const riverFlow = getRiverFlow(store);
  const riverActions = getRiverActions(store);
  const riverDotacion = riverFlow / riverActions;
  return { flow: riverFlow, dotacion: riverDotacion };
}

function getPrimerRepartible(
  store: MaipoSiraStore,
  riverValue: number,
): number {
  const { deviceConfig } = store.newDistributionInfo;

  let descargaESNFlow = 0;
  let descargaEEYFlow = 0;

  for (const element of deviceConfig) {
    const meanFlow = getFlowFromDeviceConfig(element);
    if (element.is_EEY) {
      descargaEEYFlow = meanFlow;
    }
    if (element.is_ESN) {
      descargaESNFlow = meanFlow;
    }
  }
  return riverValue - descargaEEYFlow - descargaESNFlow;
}

function getPrimerRepartibleInfo(store: MaipoSiraStore) {
  const { dotacionPrimerRepartible } = store.newDistributionInfo;
  const riverFlow = getRiverFlow(store);
  const primerRepartibleFlow = getPrimerRepartible(store, riverFlow);
  return { flow: primerRepartibleFlow, dotacion: dotacionPrimerRepartible };
}

function getSegundoRepartibleInfo(store: MaipoSiraStore) {
  const { contributionCanalsConfig } = store.newDistributionInfo;
  const totalsContribution = getTotalsContributingCanals(
    contributionCanalsConfig,
  );
  const segundoRepartibleFlow =
    totalsContribution.distributedFlow +
    totalsContribution.volunteerContribution;
  const segundoRepartibleDotacion =
    segundoRepartibleFlow / totalsContribution.stocks;
  return { flow: segundoRepartibleFlow, dotacion: segundoRepartibleDotacion };
}

function splitDevices(store: MaipoSiraStore) {
  const { deviceConfig, isLlanoOn } = store.newDistributionInfo;
  const riverDevicesConfig = [];
  const nonRiverDevicesConfig = [];
  for (const config of deviceConfig) {
    const {
      is_EEY,
      is_ESN,
      is_descarga_lonquen,
      is_part_of_river_sum,
      on_when_llano_is_on,
    } = config;
    const isRiverDeviceCondition =
      is_EEY || is_ESN || is_descarga_lonquen || is_part_of_river_sum;
    const llanoCondition =
      on_when_llano_is_on === null || on_when_llano_is_on === isLlanoOn;

    if (isRiverDeviceCondition) {
      riverDevicesConfig.push(config);
    } else if (llanoCondition) {
      nonRiverDevicesConfig.push(config);
    }
  }
  return {
    riverDevicesConfig: riverDevicesConfig,
    nonRiverDevicesConfig: nonRiverDevicesConfig,
  };
}

function getRiverActions(store: MaipoSiraStore) {
  const { remoteDevOrgConfig, isLlanoOn } = store.newDistributionInfo;
  const orgs = remoteDevOrgConfig.reduce((acc, item) => {
    const { on_when_llano_is_on } = item;
    const llanoCondition =
      on_when_llano_is_on === null || on_when_llano_is_on === isLlanoOn;
    if (llanoCondition) {
      return [...acc, item];
    }
    return acc;
  }, []);
  return orgs.reduce((acc, item) => acc + item.stocks, 0);
}

function getDescargaEEY(deviceConfig: DeviceConfig[]) {
  return deviceConfig.find((e) => e.is_EEY);
}

function getDescargaESN(deviceConfig: DeviceConfig[]) {
  return deviceConfig.find((e) => e.is_ESN);
}

function getAADevOrgConfig(
  state: MaipoSiraStore,
): AADeviceOrganizationConfig[] {
  const { remoteDevOrgConfig, organizationAA } = state.newDistributionInfo;

  return remoteDevOrgConfig
    .filter(
      (e) =>
        e.is_plant_AA || e.is_BI_AA || e.organization.id === organizationAA.id,
    )
    .map((e) => {
      return {
        ...e,
        flowRequiredInfo: { valueString: '0', value: 0, isValid: true },
      };
    });
}

function getActiveContributingCanals(
  state: MaipoSiraStore,
): ContributingDeviceOrganizationConfig[] {
  const { remoteDevOrgConfig, organizationAA, isLlanoOn } =
    state.newDistributionInfo;

  console.log('the organization aa is: ', organizationAA);

  const activeContributingOrgs: ContributingDeviceOrganizationConfig[] = [];

  for (const item of remoteDevOrgConfig) {
    const { on_when_llano_is_on } = item;
    const { id } = item.organization;
    const llanoCondition =
      on_when_llano_is_on === null || on_when_llano_is_on === isLlanoOn;
    if (id !== organizationAA.id && llanoCondition) {
      activeContributingOrgs.push(item);
    }
  }

  return activeContributingOrgs.sort((a, b) => a.excel_order - b.excel_order);
}

function getAllStocksFromOrgs(orgs): number {
  return orgs.reduce((acc: number, c) => acc + c.stocks, 0);
}

function getBIAA(aaConfig: DeviceOrganizationConfig[]) {
  return aaConfig.find((e) => e.is_BI_AA);
}

function getPlantsAAStocks(aaConfig: AADeviceOrganizationConfig[]) {
  return aaConfig.reduce(
    (acc: number, c) => (c.is_plant_AA ? acc + c.stocks : acc),
    0,
  );
}

function getPlantsAAFlowRequired(aaConfig: AADeviceOrganizationConfig[]) {
  return aaConfig.reduce(
    (acc: number, c) => (c.is_plant_AA ? acc + c.flowRequiredInfo.value : acc),
    0,
  );
}

function getTotalsContributingCanals(canals: any[]) {
  const accumulator = {
    stocks: 0,
    flowRights: 0,
    volunteerContribution: 0,
    obligatoryContribution: 0,
    distributedFlow: 0,
  };

  canals.forEach((canal) => {
    const { distributionInfo } = canal;
    accumulator.stocks += canal.stocks;
    accumulator.flowRights += distributionInfo.flowRights;
    accumulator.volunteerContribution += distributionInfo.volunteerContribution;
    accumulator.obligatoryContribution +=
      distributionInfo.obligatoryContribution;
    accumulator.distributedFlow += distributionInfo.distributedFlow;
  });
  return accumulator;
}

export {
  getFlowFromDeviceConfig,
  getLevelFromDeviceConfig,
  getRiverFlow,
  getRiverInfo,
  getPrimerRepartible,
  getPrimerRepartibleInfo,
  splitDevices,
  getRiverActions,
  getDescargaEEY,
  getDescargaESN,
  getAllStocksFromOrgs,
  getBIAA,
  getPlantsAAStocks,
  getPlantsAAFlowRequired,
  getActiveContributingCanals,
  getAADevOrgConfig,
  getTotalsContributingCanals,
  getSegundoRepartibleInfo,
  getFlowFromDeviceConfigUnit,
  getLevelFromDeviceConfigUnit,
};
