import { MetricUnit } from '@/__generated__/graphql';
import { useFormatters } from '@/hooks/useFormatters';
import { useSelector } from '@/store';
import { useSuspenseQuery } from '@apollo/client';
import ModalTitle from '@components/modal/ModalTitle';
import { DGA_REPORTS_TABLE_GET_DEVICE } from '@features/dga/graphql/queries/getDGAReportsForTable';
import { setDGAReportsTableModal } from '@features/dga/slices/dgaSlice';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { CheckCircle, ContentCopy, Error } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Suspense, useEffect, useState, useTransition, type FC } from 'react';
import { useDispatch } from 'react-redux';
import { useModalStatusMessage } from 'src/hooks';
import SendReportsByEmailModal from './SendReportsByEmailModal';

interface Props {
  deviceId: string;
  onClose: () => void;
}

const PAGE_LIMIT = 10;

const Component: FC<Props> = ({ deviceId, onClose }) => {
  const { _ } = useLingui();
  const [openSendReportsModal, setOpenSendReportsModal] = useState(false);
  const { formatDateTime, formatMetricValue } = useFormatters();
  const { openModalSuccessMessage } = useModalStatusMessage();
  const [pending, startTransition] = useTransition();
  const [pagination, setPagination] = useState({
    limit: PAGE_LIMIT,
    page: 1,
    loadedPages: 0,
    hasNextPage: true,
  });

  const { data, fetchMore } = useSuspenseQuery(DGA_REPORTS_TABLE_GET_DEVICE, {
    variables: {
      deviceId,
      reportsPagination: {
        limit: pagination.limit,
      },
    },
  });

  useEffect(() => {
    if (pending) return;
    if (!data.device.dga) return;

    const totalCount = data.device.dga.reports.items.length ?? 0;
    const hasNextPage = data.device.dga.reports.pageInfo.lastEvaluatedKey != null;

    if (totalCount === 0 && !hasNextPage) {
      setPagination((prev) => ({ ...prev, loadedPages: 0, hasNextPage: false }));
      return;
    }

    if (Math.ceil(totalCount) / pagination.limit > pagination.loadedPages) {
      setPagination((prev) => ({
        ...prev,
        loadedPages: Math.ceil(totalCount / prev.limit),
        hasNextPage,
      }));
      return;
    }

    if (pagination.page > pagination.loadedPages && pagination.hasNextPage) {
      startTransition(() => {
        fetchMore({
          variables: {
            deviceId,
            reportsPagination: {
              limit: pagination.limit,
              exclusiveStartKey: data.device.dga?.reports.pageInfo.lastEvaluatedKey,
            },
          },
          updateQuery(previousResult, { fetchMoreResult }) {
            if (!fetchMoreResult) return previousResult;
            if (!fetchMoreResult.device.dga || !previousResult.device.dga) {
              return {
                device: fetchMoreResult.device,
              };
            }
            return {
              device: {
                ...previousResult.device,
                dga: {
                  ...previousResult.device.dga,
                  reports: {
                    ...previousResult.device.dga.reports,
                    items: [
                      ...previousResult.device.dga.reports.items,
                      ...fetchMoreResult.device.dga.reports.items,
                    ],
                    pageInfo: fetchMoreResult.device.dga?.reports.pageInfo,
                  },
                },
              },
            };
          },
        });
      });
    }
  }, [pending, startTransition, data, pagination, setPagination, fetchMore, deviceId]);

  const rows = data.device.dga?.reports.items ?? [];

  return (
    <>
      <SendReportsByEmailModal
        deviceId={deviceId}
        open={openSendReportsModal}
        onClose={() => setOpenSendReportsModal(false)}
      />
      <ModalTitle
        onClose={onClose}
        title={
          <Box display="flex" gap={1}>
            <Trans>Tabla de reportes DGA</Trans> {data.device.profile.name}
          </Box>
        }
      />
      <DialogContent>
        <TableContainer
          sx={{
            height: 440,
            border: 1,
            borderColor: 'divider',
            borderRadius: 1,
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell width={50}></TableCell>
                <TableCell width={200}>
                  <Trans>Fecha</Trans>
                </TableCell>
                <TableCell width={200}>
                  <Trans>Métricas</Trans>
                </TableCell>
                <TableCell width={220}>
                  <Trans>Mensaje</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 && (
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell colSpan={5}>
                    <Box height={295} display="grid" sx={{ placeItems: 'center' }}>
                      <Trans>No hay datos</Trans>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {pending &&
                Array.from({ length: pagination.limit }, (_, i) => (
                  <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>
                      <Box>
                        <Skeleton variant="circular" width={22} height={22} />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" width={200} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" width={200} />
                      <Skeleton variant="text" width={200} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" width={220} />
                    </TableCell>
                  </TableRow>
                ))}
              {rows
                .slice((pagination.page - 1) * pagination.limit, pagination.page * pagination.limit)
                .map((row) => {
                  return (
                    <TableRow
                      key={row.timestamp}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>
                        {row.success ? (
                          <Tooltip
                            placement="right"
                            title={
                              <span>
                                <Trans>Número comprobante: {row.confirmationId}</Trans>
                              </span>
                            }
                          >
                            <CheckCircle color="success" sx={{ display: 'block' }} />
                          </Tooltip>
                        ) : (
                          <Error color="error" sx={{ display: 'block' }} />
                        )}
                      </TableCell>
                      <TableCell>
                        <Box>
                          {/* <Box display="flex" gap={1} alignItems="center"> */}
                          <Typography fontSize="inherit">
                            {formatDateTime(row.timestamp)}
                          </Typography>
                          <Typography fontSize="inherit" color="text.secondary">
                            Ejecución: {formatDateTime(row.executionTimestamp)}
                          </Typography>
                          {/* <Tooltip */}
                          {/*   title={ */}
                          {/*     <Typography fontSize="inherit"> */}
                          {/*       <Trans> */}
                          {/*         Fecha de ejecución: {formatDateTime(row.executionTimestamp)} */}
                          {/*       </Trans> */}
                          {/*     </Typography> */}
                          {/*   } */}
                          {/* > */}
                          {/*   <Info fontSize="small" /> */}
                          {/* </Tooltip> */}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          {row.data.flow != null && (
                            <Typography fontSize="inherit">
                              <Trans>Caudal</Trans>:{' '}
                              <b>
                                {formatMetricValue(row.data.flow, {
                                  unit: MetricUnit.LiterPerSecond,
                                  precision: 1,
                                })}
                              </b>
                            </Typography>
                          )}
                          {row.data.level != null && (
                            <Typography fontSize="inherit">
                              <Trans>Nivel</Trans>:{' '}
                              <b>
                                {formatMetricValue(row.data.level, {
                                  unit: MetricUnit.Meter,
                                  precision: 1,
                                })}
                              </b>
                            </Typography>
                          )}
                          {row.data.accumulatedVolume != null && (
                            <Typography fontSize="inherit">
                              <Trans>Volumen ac.</Trans>:{' '}
                              <b>
                                {formatMetricValue(row.data.accumulatedVolume, {
                                  unit: MetricUnit.CubicMeter,
                                  precision: 1,
                                })}
                              </b>
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {!row.success ? (
                          row.message
                        ) : (
                          <Box display="flex" gap={1} alignItems="center">
                            <span>
                              <Trans>Comprobante: {row.confirmationId}</Trans>
                            </span>
                            <Tooltip title={<Trans>Copiar comprobante</Trans>}>
                              <IconButton
                                onClick={() => {
                                  navigator.clipboard.writeText(row.confirmationId ?? '');
                                  openModalSuccessMessage(_(msg`¡Comprobante copiado!`));
                                }}
                              >
                                <ContentCopy fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button color="info" variant="outlined" onClick={() => setOpenSendReportsModal(true)}>
            <Trans>Descargar</Trans>
          </Button>
          <Pagination
            page={pagination.page}
            onChange={(_, page) => setPagination((prev) => ({ ...prev, page }))}
            count={pagination.loadedPages + Number(pagination.hasNextPage)}
            showFirstButton
          />
        </Box>
      </DialogContent>
    </>
  );
};

const Fallback: FC<{ onClose: () => void }> = ({ onClose }) => (
  <>
    <ModalTitle
      onClose={onClose}
      title={
        <Box display="flex" gap={1}>
          <Trans>Tabla de reportes DGA</Trans> <Skeleton variant="text" width={200} />
        </Box>
      }
    />
    <DialogContent dividers>
      <Skeleton variant="rounded" height={500} />
    </DialogContent>
  </>
);

const DGAReportsTableModal = () => {
  const dispatch = useDispatch();
  const { reportsTableModal } = useSelector((state) => state.dga_store);

  const onClose = () => {
    dispatch(
      setDGAReportsTableModal({
        open: false,
      }),
    );
  };

  return (
    <Dialog onClose={onClose} open={reportsTableModal.open} maxWidth="lg" fullWidth>
      <Suspense fallback={<Fallback onClose={onClose} />}>
        {reportsTableModal.open && (
          <Component onClose={onClose} deviceId={reportsTableModal.deviceId} />
        )}
      </Suspense>
    </Dialog>
  );
};

export default DGAReportsTableModal;
