import { Box, Typography, Checkbox, Divider, IconButton } from '@mui/material';
import { uniqueId } from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const ListUserAlarm = ({
  users,
  hanldecheckedUsers,
  handleDeleteUser,
  handleUpdatedTypeUser,
}) => {
  return (
    <Box p={2}>
      <Box
        sx={{
          overflowY: 'scroll',
          maxHeight: 250,
        }}
      >
        {users.map((user) => (
          <>
            <Box key={uniqueId()} mb={1}>
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex" alignItems="center">
                  <Box>
                    <Checkbox
                      checked={user.notification}
                      onChange={() => hanldecheckedUsers(user.phone)}
                    />
                  </Box>
                  <Box>
                    <Typography>{user.name}</Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Box mr={2} display="flex" flexDirection="row">
                    <Typography color="textSecondary" fontSize={14}>
                      {user.phone
                        ? user.phone
                        : 'No hay número de teléfono registrado'}
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton size="large">
                      <EditIcon
                        onClick={() => handleUpdatedTypeUser(user)}
                        sx={{ color: '#00e393' }}
                      />
                    </IconButton>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={() => handleDeleteUser(user.phone)}
                      sx={{ color: '#fa5c5ce0' }}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Divider />
            </Box>
          </>
        ))}
      </Box>
    </Box>
  );
};

export default ListUserAlarm;
