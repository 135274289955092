import { gql } from '@apollo/client';

const CREATE_GATE_LINKED_STATION = gql`
  mutation CreateGateLinkedStation(
    $targetId: String
    $targetName: String
    $sourceId: String
    $direction: String
  ) {
    Sira {
      CreateGateLinkedStation(
        targetId: $targetId
        targetName: $targetName
        sourceId: $sourceId
        direction: $direction
      ) {
        id
        name
      }
    }
  }
`;
export default CREATE_GATE_LINKED_STATION;
