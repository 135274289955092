import { ActionType } from '@/__generated__/graphql';
import { t } from '@lingui/macro';
import { I18n } from '@lingui/core';

export const getActionTypeOptions = (i18n: I18n) =>
  Object.values(ActionType).map((value) => {
    let label: string = value;
    switch (value) {
      case ActionType.CreateApiForOrganization:
        label = t(i18n)`Crear API para Organización`;
        break;
      case ActionType.CreateDevice:
        label = t(i18n)`Crear Dispositivo`;
        break;
      case ActionType.CreateDeviceCalendarConfiguration:
        label = t(i18n)`Crear Configuración de Calendario para Dispositivo`;
        break;
      case ActionType.CreateIrrigation:
        label = t(i18n)`Crear Canal de Irrigación`;
        break;
      case ActionType.CreateNewSiraDistribution:
        label = t(i18n)`Crear Nueva Distribución de SIRA`;
        break;
      case ActionType.CreateNewSiraMaipoDistribution:
        label = t(i18n)`Crear Nueva Distribución de SIRA Maipo`;
        break;
      case ActionType.CreateOrganization:
        label = t(i18n)`Crear Organización`;
        break;
      case ActionType.CreateSiraCanalClass:
        label = t(i18n)`Crear Clase de Canal de SIRA`;
        break;
      case ActionType.CreateSiraMaipoConfig:
        label = t(i18n)`Crear Configuración de SIRA Maipo`;
        break;
      case ActionType.CreateSiraMaipoConfigDevice:
        label = t(i18n)`Crear Dispositivo de Configuración de SIRA Maipo`;
        break;
      case ActionType.CreateSiraMaipoConfigOrg:
        label = t(i18n)`Crear Org de Configuración de SIRA Maipo`;
        break;
      case ActionType.CreateSiraMaipoDevOrgConfig:
        label = t(i18n)`Crear Configuración de Org de Dispositivo de SIRA Maipo`;
        break;
      case ActionType.CreateSiraUserToNotify:
        label = t(i18n)`Crear Usuario de SIRA a Notificar`;
        break;
      case ActionType.CreateUser:
        label = t(i18n)`Crear Usuario`;
        break;
      case ActionType.DeleteApiForOrganization:
        label = t(i18n)`Eliminar API para Organización`;
        break;
      case ActionType.DeleteDevice:
        label = t(i18n)`Eliminar Dispositivo`;
        break;
      case ActionType.DeleteDeviceCalendarConfiguration:
        label = t(i18n)`Eliminar Configuración de Calendario para Dispositivo`;
        break;
      case ActionType.DeleteIrrigation:
        label = t(i18n)`Eliminar Canal de Irrigación`;
        break;
      case ActionType.DeleteOrganization:
        label = t(i18n)`Eliminar Organización`;
        break;
      case ActionType.DeleteSiraCanalClass:
        label = t(i18n)`Eliminar Clase de Canal de SIRA`;
        break;
      case ActionType.DeleteSiraMaipoDistribution:
        label = t(i18n)`Eliminar Distribución de SIRA Maipo`;
        break;
      case ActionType.DeleteSiraUserToNotify:
        label = t(i18n)`Eliminar Usuario de SIRA a Notificar`;
        break;
      case ActionType.DeleteUser:
        label = t(i18n)`Eliminar Usuario`;
        break;
      case ActionType.UpdateApiForOrganization:
        label = t(i18n)`Actualizar API para Organización`;
        break;
      case ActionType.UpdateApiPasswordForOrganization:
        label = t(i18n)`Actualizar Contraseña de API para Organización`;
        break;
      case ActionType.UpdateCalibration:
        label = t(i18n)`Actualizar Calibración`;
        break;
      case ActionType.UpdateDeviceAccess:
        label = t(i18n)`Actualizar Acceso de Dispositivo`;
        break;
      case ActionType.UpdateDeviceAlarms:
        label = t(i18n)`Actualizar Alarmas de Dispositivo`;
        break;
      case ActionType.UpdateDeviceAlerts:
        label = t(i18n)`Actualizar Alertas de Dispositivo`;
        break;
      case ActionType.DisableDeviceAlerts:
        label = t(i18n)`Desactivar Alertas de Dispositivo`;
        break;
      case ActionType.UpdateDeviceCalendarConfiguration:
        label = t(i18n)`Actualizar Configuración de Calendario para Dispositivo`;
        break;
      case ActionType.UpdateDeviceFilters:
        label = t(i18n)`Actualizar Filtros de Dispositivo`;
        break;
      case ActionType.UpdateDeviceStatus:
        label = t(i18n)`Actualizar Estado de Dispositivo`;
        break;
      case ActionType.UpdateDgaInfo:
        label = t(i18n)`Actualizar Información de DGA (deprecado)`;
        break;
      case ActionType.UpdateFlowLimits:
        label = t(i18n)`Actualizar Límites de Flujo`;
        break;
      case ActionType.UpdateInfo:
        label = t(i18n)`Actualizar Información`;
        break;
      case ActionType.UpdateIrrigation:
        label = t(i18n)`Actualizar Canal de Irrigación`;
        break;
      case ActionType.UpdateOrganization:
        label = t(i18n)`Actualizar Organización`;
        break;
      case ActionType.UpdateOrganizationAccess:
        label = t(i18n)`Actualizar Acceso de Organización`;
        break;
      case ActionType.UpdateOrganizationSetting:
        label = t(i18n)`Actualizar Configuración de Organización`;
        break;
      case ActionType.UpdateRadarParameters:
        label = t(i18n)`Actualizar Parámetros de Radar`;
        break;
      case ActionType.UpdateRatingCurves:
        label = t(i18n)`Actualizar Curvas de Aforo`;
        break;
      case ActionType.UpdateSiraCanalClass:
        label = t(i18n)`Actualizar Clase de Canal de SIRA`;
        break;
      case ActionType.UpdateSiraDistribution:
        label = t(i18n)`Actualizar Distribución de SIRA`;
        break;
      case ActionType.UpdateSiraMaipoDistribution:
        label = t(i18n)`Actualizar Distribución de SIRA Maipo`;
        break;
      case ActionType.UpdateSiraMaipoDistributionMinDotation:
        label = t(i18n)`Actualizar Dotación Mínima de Distribución de SIRA Maipo`;
        break;
      case ActionType.UpdateSiraMaipoDistributionShares:
        label = t(i18n)`Actualizar Participaciones de Distribución de SIRA Maipo`;
        break;
      case ActionType.UpdateSiraUserToNotify:
        label = t(i18n)`Actualizar Usuario de SIRA a Notificar`;
        break;
      case ActionType.UpdateUser:
        label = t(i18n)`Actualizar Usuario`;
        break;
      case ActionType.UpdateUserAccess:
        label = t(i18n)`Actualizar Acceso de Usuario`;
        break;
      case ActionType.UpdateUserAlarms:
        label = t(i18n)`Actualizar Alarmas de Usuario`;
        break;
      case ActionType.UpdateUserAlerts:
        label = t(i18n)`Actualizar Alertas de Usuario`;
        break;
      case ActionType.SendMovementCommandToGate:
        label = t(i18n)`Enviar Comando de Movimiento a Compuerta`;
        break;
      case ActionType.SendStopMovementCommandToGate:
        label = t(i18n)`Enviar Comando de Detención de Movimiento a Compuerta`;
        break;
      case ActionType.UpdateDeviceHardwareConfiguration:
        label = t(i18n)`Actualizar Configuración de Hardware de Dispositivo`;
        break;
      case ActionType.CreateDeviceHardwareConfiguration:
        label = t(i18n)`Crear Configuración de Hardware de Dispositivo`;
        break;
      case ActionType.DeleteDeviceHardwareConfiguration:
        label = t(i18n)`Eliminar Configuración de Hardware de Dispositivo`;
        break;
      case ActionType.SendConfigCommandToDevice:
        label = t(i18n)`Enviar Comando de Configuración a Dispositivo`;
        break;
      case ActionType.DeleteGateEnqueueMoveRequest:
        label = t(i18n)`Eliminar Solicitud de Movimiento en Cola de Compuerta`;
        break;
      case ActionType.UpdateStatusSupport:
        label = t(i18n)`Actualizar Estado de Soporte del Dispositivo`;
        break;
      case ActionType.UpdateUserSupportFilters:
        label = t(i18n)`Actualizar Filtros de Configuración de Soporte del Usuario`;
        break;
      case ActionType.UpdateUserSupportFields:
        label = t(i18n)`Actualizar Campos de Configuración de Soporte del Usuario`;
        break;
      case ActionType.ImpersonateUser:
        label = t(i18n)`Suplantar Usuario`;
        break;
      case ActionType.CreateDeviceAliases:
        label = t(i18n)`Crear Alias de Dispositivo`;
        break;
      case ActionType.UpdateDeviceAliases:
        label = t(i18n)`Actualizar Alias de Dispositivo`;
        break;
      case ActionType.CloneDevice:
        label = t(i18n)`Clonar Dispositivo`;
        break;
      case ActionType.UpdateMathModeling:
        label = t(i18n)`Actualizar Modelado Matemático`;
        break;
      case ActionType.CreateReport:
        label = t(i18n)`Crear Reporte`;
        break;
      case ActionType.UpdateReport:
        label = t(i18n)`Actualizar Reporte`;
        break;
      case ActionType.DeleteReport:
        label = t(i18n)`Eliminar Reporte`;
        break;
      case ActionType.CreateReportSchedule:
        label = t(i18n)`Crear Programación de Reporte`;
        break;
      case ActionType.UpdateReportSchedule:
        label = t(i18n)`Actualizar Programación de Reporte`;
        break;
      case ActionType.DeleteReportSchedule:
        label = t(i18n)`Eliminar Programación de Reporte`;
        break;
      case ActionType.UpdateUserPreferences:
        label = t(i18n)`Actualizar Preferencias de Usuario`;
        break;
      case ActionType.CreateDgaConfiguration:
        label = t(i18n)`Crear Configuración de DGA`;
        break;
      case ActionType.UpdateDgaConfiguration:
        label = t(i18n)`Actualizar Configuración de DGA`;
        break;
      case ActionType.DeleteDgaConfiguration:
        label = t(i18n)`Eliminar Configuración de DGA`;
        break;
      case ActionType.UpdateDgaSchedule:
        label = t(i18n)`Actualizar Configuración de Recurrencia de Envío de DGA`;
        break;
      case ActionType.EnableDgaSchedule:
        label = t(i18n)`Habilitar Envío de DGA`;
        break;
      case ActionType.DisableDgaSchedule:
        label = t(i18n)`Deshabilitar Envío de DGA`;
        break;
      case ActionType.DeleteDgaSchedule:
        label = t(i18n)`Eliminar Configuración de Recurrencia de Envío de DGA`;
        break;
      case ActionType.UpdateDgaNotificationConfiguration:
        label = t(i18n)`Actualizar Configuración de Notificación de DGA`;
        break;
    }

    return {
      label,
      value,
    };
  });
