import { gql } from '@/__generated__';

const SEND_DGA_REPORTS_EXCEL_BY_EMAIL = gql(`#graphql
  mutation SendDgaReportsExcelByEmail($deviceId: ID!, $start: Date!, $stop: Date!) {
    sendDgaReportsExcelByEmail(deviceId: $deviceId, start: $start, stop: $stop) {
      device {
        id
        profile {
          id
          name
        }
      }
      start
      stop
    }
  }
`);

export default SEND_DGA_REPORTS_EXCEL_BY_EMAIL;
