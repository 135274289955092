import { gql } from '@/__generated__';

const GET_ACCESS_DGA = gql(`#graphql
  query GetAccessDGA {
    authorization {
      authorizationGeneral {
        showOrganizationFilter
      }
      authorizationDGA {
        updateInfoDGA
        retrySending
        setMaxFlow
        enableDevices
      }
    }
  }
`);

export default GET_ACCESS_DGA;
