import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';

export const FormInputText = ({
  name,
  control,
  label,
  type = 'text',
  rules = {},
  multiline = false,
  minRows = 0,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={error ? error.message : null}
          error={!!error}
          onChange={(event) =>
            type === 'number'
              ? onChange(+event.target.value)
              : onChange(event.target.value)
          }
          value={value}
          fullWidth
          label={label}
          variant="outlined"
          type={type}
          multiline={multiline}
          minRows={minRows}
        />
      )}
      rules={rules}
    />
  );
};
