import { gql } from '@apollo/client';

const UPDATE_STATUS_DEVICE = gql`
  mutation updateStatusDevice(
    $parentComponentId: Int!
    $description: String
    $status: String
    $public: Boolean
  ) {
    UpdateStatusDevice(
      parent_component_id: $parentComponentId
      description: $description
      status: $status
      public: $public
    ) {
      success
      message
    }
  }
`;

export default UPDATE_STATUS_DEVICE;
