import { UnitFlow, convertFlow } from 'src/utils/unitConverterValues';
import {
  AADeviceOrganizationConfig,
  InputFlowRequiredAA,
} from '../types/dataTypes';

function getTotalFlowRequired(aaConfig: AADeviceOrganizationConfig[]) {
  let accumulator = 0;
  aaConfig.forEach(
    (element) => (accumulator += element.flowRequiredInfo.value),
  );
  return accumulator;
}

function convertDotacionFromM3ToLs(value) {
  return value * 1000;
}

function convertDotacionFromLsToM3(value) {
  return value / 1000;
}

function getCmpcFlow(
  primerRepartible: number,
  riverActions: number,
  unitFlow: UnitFlow,
) {
  let valuem3s;
  if (primerRepartible < 44.4) {
    valuem3s = ((0.6 / 18.6) * 3394.0037) / riverActions;
  } else {
    valuem3s = 0.6;
  }
  return convertFlow(valuem3s, 'm3/s', unitFlow);
}

export {
  getTotalFlowRequired,
  convertDotacionFromM3ToLs,
  convertDotacionFromLsToM3,
  getCmpcFlow,
};
