import useAuth from './useAuth';

const defaultAccess = {
  controlCenter: false,
  stations: false,
  virtualStations: false,
  integrations: false,
  dataAnalytics: false,
  dataAdvanceAnalytics: false,
  gates: false,
  systems: false,
  distribution: false,
  users: false,
  dgaReports: false,
  dataExtraction: false,
  deviceConfiguration: false,
  allDevices: false,
  dataTables: false,
  faq: false,
  offLogin: false,
  permits: false,
  apiCapta: false,
  distributionMaipo: false,
  metricView: false,
  deviceDiagnostics: false,
  sendCommand: false,
  channelNetwork: false,
  audit: false,
  standardDesign: false,
  announcements: false,
  notifications: false,
  organizations: false,
  billing: false,
  alerts: false,
  scheduledReports: false,
  smaReports: false,
  alertNotification: false,
};

const useAccess = () => {
  const auth = useAuth();

  const role = auth?.user?.role?.id ?? null;
  const isCaptaUser = role === 1;

  const accessNullable = auth && auth.user ? auth.user.access : null;

  const access = accessNullable ?? defaultAccess;

  const isAuthenticated = () => {
    return auth.isAuthenticated;
  };

  const hasAccess = (permission: string) => {
    switch (permission) {
      case 'Sec:CentroControl':
        return access.controlCenter;
      case 'Sec:Usuarios':
        return access.users;
      case 'Sec:Dispositivos':
        return access.allDevices || access.stations;
      case 'Sec:AnalisisEstaciones':
        return access.dataAnalytics;
      case 'Sec:AnalisisAvanzado':
        return access.dataAdvanceAnalytics;
      case 'Sec:Sistemas':
        return access.systems;
      case 'Sec:Anuncios':
        return access.announcements;
      case 'Sec:Notificaciones':
        return access.notifications;
      case 'Sec:Organizacion':
        return access.organizations;
      case 'Sec:RedDeCanales':
        return access.channelNetwork;
      case 'Sec:ReportesDGA':
        return access.dgaReports;
      case 'Sec:Integraciones':
        return access.integrations;
      case 'Sec:Canales':
        return access.channelNetwork;
      case 'Sec:CentroAyuda':
        return access.faq;
      case 'Sec:ConfiguracionEquipos':
        return access.deviceConfiguration;
      case 'Sec:PermisoAccesos':
        return access.permits;
      case 'Sec:API':
        return access.apiCapta;
      case 'Sec:DispositivosPublicos':
        return access.offLogin;
      case 'Sec:EnvioComandos':
        return access.sendCommand;
      case 'Sec:Compuertas':
        return access.gates;
      case 'Sec:Reparto':
        return access.distribution;
      case 'Sec:CalculadoraMaipo':
        return access.distributionMaipo;
      case 'Sec:CalculadoraMaipoMenu':
        return access.distributionMaipo;
      case 'Sec:Soporte':
        return access.deviceDiagnostics;
      case 'Sec:StandardDesign':
        return access.standardDesign;
      case 'Sec:MetricsViews':
        return access.metricView;
      // TODO: ivalderrama eliminar esta sección completa
      case 'Sec:CalidadAgua':
        return isCaptaUser;
      case 'Sec:Auditoría':
        return access.audit;
      case 'Sec:Billing':
        return access.billing;
      case 'Sec:Reports':
        return access.dataExtraction;
      case 'Sec:ConfiguracionAlertas':
        return access.alerts;
      case 'Sec:HistorialNotificacionesAlertas':
        return access.alertNotification;
      case 'Sec:ScheduledReports':
        return access.scheduledReports;
      case 'Sec:Sma':
        return access.smaReports;
      default:
        return false;
    }
  };

  return {
    hasAccess,
    isAuthenticated,
    isCaptaUser,
  };
};

export default useAccess;
