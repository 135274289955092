import { setSnackbar } from '@/slices/components';
import { useDispatch } from '@/store';

const useModalStatusMessage = () => {
  const dispatch = useDispatch();

  const openModalSuccessMessage = (message: string) => {
    dispatch(
      setSnackbar({
        open: true,
        message: message,
        severity: 'success',
      }),
    );
  };

  const openModalWarningMessage = (message: string) => {
    dispatch(
      setSnackbar({
        open: true,
        message: message,
        severity: 'warning',
      }),
    );
  };

  const openModalErrorMessage = (message: string) => {
    dispatch(
      setSnackbar({
        open: true,
        message: message,
        severity: 'error',
      }),
    );
  };

  return {
    openModalSuccessMessage,
    openModalErrorMessage,
    openModalWarningMessage,
  };
};

export default useModalStatusMessage;
