import { skipToken, useSuspenseQuery } from '@apollo/client';
import {
  useTransition,
  useMemo,
  useDeferredValue,
  useEffect,
  TransitionStartFunction,
  useState,
} from 'react';
import DEVICES_ANALYSIS_GET_DEVICES, { Device, Gate } from '../graphql/queries/getDevices';
import { useDispatch, useSelector } from '@/store';
import { MetricField } from '@/__generated__/graphql';
import { TelemetryKey, toggleTelemetry } from '../slices/devicesAnalysisSlice';
import { DistanceUnit, FlowUnit } from '@/utils/units';

export type DevicesAnalysisDevice = Device & {
  profile: Device['profile'] & {
    type: 'CFC';
  };
  metrics: Device['metrics'] & {
    upstreamLevel: NonNullable<Gate['upstreamDevice']>['metrics']['level'];
    downstreamLevel: NonNullable<Gate['downstreamDevice']>['metrics']['level'];
  };
  hasAlarm: Gate['hasAlarm'];
  alarmData: Gate['alarmData'];
};

interface Props {
  children: (props: {
    selectedDevice: DevicesAnalysisDevice | null;
    devices: DevicesAnalysisDevice[];
    handleRefetch: () => void;
    isPending: boolean;
    startTransition: TransitionStartFunction;
    showEventsTable: boolean;
    isTestingDevice: boolean;
  }) => React.ReactNode;
}

const DevicesAnalysis = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [isPending, startTransition] = useTransition();
  const [isSelectedDeviceLoaded, setIsSelectedDeviceLoaded] = useState(false);
  const { parameters, telemetry, events } = useSelector((state) => state.devices_analysis_store);
  const { variables } = useDeferredValue({
    variables: {
      input: {
        ids: parameters.deviceId
          ? [
              parameters.deviceId,
              ...parameters.comparisonDeviceIds.filter((id) => id !== parameters.deviceId),
            ]
          : null,
      },
      gatesInput: {
        ids: parameters.deviceId
          ? [
              parameters.deviceId,
              ...parameters.comparisonDeviceIds.filter((id) => id !== parameters.deviceId),
            ]
          : null,
      },
      metricsInput: {
        start: parameters.start,
        stop: parameters.stop,
        window: parameters.window,
      },
      telemetryInput: {
        start: parameters.start,
        stop: parameters.stop,
        window: parameters.window,
      },
      flowUnit: telemetry.flowUnit,
      levelUnit: telemetry.levelUnit,
      connectionStateInput: {
        window: null,
      },
      volumeHour: {
        window: '1h',
      },
      volumeDay: {
        window: '1d',
      },
      volumeMonth: {
        window: '1mo',
      },
      includeFlow: telemetry.flow,
      includeFlowRaw: telemetry.flowRaw,
      includeLevel: telemetry.level,
      includeLevelRaw: telemetry.levelRaw,
      includeVelocity: telemetry.velocity,
      includeVelocityRaw: telemetry.velocityRaw,
      includePosition: telemetry.position,
      includeUpstreamLevel: telemetry.upstreamLevel,
      includeDownstreamLevel: telemetry.downstreamLevel,
      includeAngle: telemetry.angle,
      includeOriginalDistance: telemetry.originalDistance,
      includeSignalQuality: telemetry.signalQuality,
      includeConnectionState: telemetry.connectionState,
      includeAccumulatedVolume: telemetry.accumulatedVol,
      includeVolumeHour: telemetry.volumeHour,
      includeVolumeDay: telemetry.volumeDay,
      includeVolumeMonth: telemetry.volumeMonth,
      includeBatterySoc: telemetry.batterySoc,
      includeBatteryV: telemetry.batteryV,
      includeChargeI: telemetry.chargeI,
      includeChargeP: telemetry.chargeP,
      includeSolarI: telemetry.solarI,
      includeSolarP: telemetry.solarP,
      includeSolarV: telemetry.solarV,
      includeFlowAlerts: telemetry.flowAlerts,
      includeLevelAlerts: telemetry.levelAlerts,
      includeDistributionAlerts: telemetry.distributionAlerts,
      includeRatingCurvesLimits: telemetry.ratingCurvesLimits,
      includeTemperature: telemetry[TelemetryKey.Temperature],
      includeTemperatureRaw: telemetry[TelemetryKey.TemperatureRaw],
      includeConductivity: telemetry[TelemetryKey.Conductivity],
      includeConductivityRaw: telemetry[TelemetryKey.ConductivityRaw],
      includePh: telemetry[TelemetryKey.Ph],
      includePhRaw: telemetry[TelemetryKey.PhRaw],
      includeOrp: telemetry[TelemetryKey.Orp],
      includeOrpRaw: telemetry[TelemetryKey.OrpRaw],
      includeDissolvedOxygen: telemetry[TelemetryKey.DissolvedOxygen],
      includeDissolvedOxygenRaw: telemetry[TelemetryKey.DissolvedOxygenRaw],
      includeDissolvedOxygenSaturation: telemetry[TelemetryKey.DissolvedOxygenSaturation],
      includeDissolvedOxygenSaturationRaw: telemetry[TelemetryKey.DissolvedOxygenSaturationRaw],
      includeTurbidityNtu: telemetry[TelemetryKey.TurbidityNtu],
      includeTurbidityNtuRaw: telemetry[TelemetryKey.TurbidityNtuRaw],
      includeTurbidityFnu: telemetry[TelemetryKey.TurbidityFnu],
      includeTurbidityFnuRaw: telemetry[TelemetryKey.TurbidityFnuRaw],
      includeSnowLevel: telemetry[TelemetryKey.SnowLevel],
      includeSnowLevelRaw: telemetry[TelemetryKey.SnowLevelRaw],
      includeSnowTemperature: telemetry[TelemetryKey.SnowTemperature],
      includeSnowTemperatureRaw: telemetry[TelemetryKey.SnowTemperatureRaw],
      includeSnowRadiation: telemetry[TelemetryKey.SnowRadiation],
      includeSnowRadiationRaw: telemetry[TelemetryKey.SnowRadiationRaw],
      includeSnowWaterEquivalent: telemetry[TelemetryKey.SnowWaterEquivalent],
      includeSnowWaterEquivalentRaw: telemetry[TelemetryKey.SnowWaterEquivalentRaw],
      includeSnowWeight: telemetry[TelemetryKey.SnowWeight],
      includeSweNonCalibrated: telemetry[TelemetryKey.SweNonCalibrated],
      includeSnowLevelNonCalibrated: telemetry[TelemetryKey.SnowLevelNonCalibrated],
      // includeDgaFlow: telemetry[TelemetryKey.DGAFlow],
      // includeDgaAccumulatedVol: telemetry[TelemetryKey.DGAAccumulatedVol],
      // includeDgaLevel: telemetry[TelemetryKey.DGALevel],
      includeEvents: events.show,
    },
  });

  const { data, refetch } = useSuspenseQuery(
    DEVICES_ANALYSIS_GET_DEVICES,
    variables.input.ids && variables.includeFlow != null
      ? {
          variables,
        }
      : skipToken,
  );

  const handleRefetch = () => {
    startTransition(() => {
      refetch();
    });
  };

  // polling interval
  useEffect(() => {
    if (isNaN(parameters.pollingInterval)) return;
    const interval = setInterval(() => {
      startTransition(() => {
        refetch();
      });
    }, parameters.pollingInterval);
    return () => clearInterval(interval);
  }, [refetch, startTransition, data, parameters.pollingInterval]);

  const devices = useMemo(() => {
    const devices =
      data?.devices?.map((device) => {
        const isCFC = device.profile.type === 'CFC';
        if (isCFC) {
          const gate = data.gates?.find((gate) => gate.id === device.id);
          return {
            ...device,
            metrics: {
              ...device.metrics,
              upstreamLevel: gate?.upstreamDevice?.metrics?.level,
              downstreamLevel: gate?.downstreamDevice?.metrics?.level,
            },
            hasAlarm: gate?.hasAlarm,
            alarmData: gate?.alarmData,
          };
        }
        return device;
      }) ?? [];

    return devices as DevicesAnalysisDevice[];
  }, [data]);

  const selectedDevice = devices.find((device) => device?.id === parameters.deviceId) ?? null;

  useEffect(() => {
    setIsSelectedDeviceLoaded(false);
  }, [parameters.deviceId]);

  useEffect(() => {
    if (isSelectedDeviceLoaded) return;
    const selectedDevice = devices.find((device) => device?.id === parameters.deviceId);
    if (!selectedDevice) return;

    if (selectedDevice.id === parameters.deviceId) setIsSelectedDeviceLoaded(true);
    else return;

    const availableFields = selectedDevice.metrics.availableFields;
    if (availableFields.includes(MetricField.Flow))
      dispatch(
        toggleTelemetry({
          [TelemetryKey.Flow]: true,
        }),
      );
    else if (availableFields.includes(MetricField.Level))
      dispatch(
        toggleTelemetry({
          [TelemetryKey.Level]: true,
        }),
      );
    else if (availableFields.includes(MetricField.Position))
      dispatch(toggleTelemetry({ [TelemetryKey.Position]: true }));
    else if (availableFields.includes(MetricField.Temperature))
      dispatch(toggleTelemetry({ [TelemetryKey.Temperature]: true }));
    else if (availableFields.includes(MetricField.SnowLevel))
      dispatch(toggleTelemetry({ [TelemetryKey.SnowLevel]: true }));

    dispatch(
      toggleTelemetry({
        [TelemetryKey.FlowUnit]: selectedDevice.metrics.flow.unit as FlowUnit,
        [TelemetryKey.LevelUnit]: selectedDevice.metrics.level.unit as DistanceUnit,
      }),
    );

    if (availableFields.includes(MetricField.DgaFlow))
      dispatch(toggleTelemetry({ [TelemetryKey.DGAFlow]: true }));
    if (availableFields.includes(MetricField.DgaAccumulatedVolume))
      dispatch(toggleTelemetry({ [TelemetryKey.DGAAccumulatedVol]: true }));
    if (availableFields.includes(MetricField.DgaLevel))
      dispatch(toggleTelemetry({ [TelemetryKey.DGALevel]: true }));
  }, [parameters.deviceId, devices, isSelectedDeviceLoaded]);

  return (
    typeof children === 'function' &&
    children({
      selectedDevice,
      devices,
      handleRefetch,
      isPending,
      startTransition,
      showEventsTable: events.show,
      isTestingDevice: selectedDevice?.profile?.status === 'testing',
    })
  );
};

export default DevicesAnalysis;
