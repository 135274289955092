import { useDispatch, useSelector } from '@/store';
import CheckboxList from './CheckboxList';
import CheckboxListItem from './CheckboxListItem';
import { useAnalyticsEventTracker } from '@/gaEvents';
import { TelemetryKey, toggleTelemetry } from '../../../slices/devicesAnalysisSlice';
import { type FC } from 'react';
import { MetricField } from '@/__generated__/graphql';
import { useLingui } from '@lingui/react';
import { msg } from '@lingui/macro';

const SidebarVolumeCheckboxList: FC = () => {
  const { _ } = useLingui();
  const dispatch = useDispatch();
  const { checkBoxEvent } = useAnalyticsEventTracker('Station Analysis');
  const { telemetry } = useSelector((state) => state.devices_analysis_store);

  return (
    <CheckboxList>
      {telemetry.availableFields.includes(MetricField.AccumulatedVolume) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.AccumulatedVol]}
          onClick={() => {
            checkBoxEvent({
              label: 'accumulated-vol',
              value: !telemetry[TelemetryKey.AccumulatedVol],
            });
            dispatch(toggleTelemetry(TelemetryKey.AccumulatedVol));
          }}
          label={_(msg`Volumen acumulado`)}
        />
      )}
      <CheckboxListItem
        checked={telemetry[TelemetryKey.VolumeHour]}
        onClick={() => {
          checkBoxEvent({ label: 'vol-hour', value: !telemetry[TelemetryKey.VolumeHour] });
          dispatch(toggleTelemetry(TelemetryKey.VolumeHour));
        }}
        label={_(msg`Volumen hora`)}
      />
      <CheckboxListItem
        checked={telemetry[TelemetryKey.VolumeDay]}
        onClick={() => {
          checkBoxEvent({ label: 'vol-day', value: !telemetry[TelemetryKey.VolumeDay] });
          dispatch(toggleTelemetry(TelemetryKey.VolumeDay));
        }}
        label={_(msg`Volumen día`)}
      />
      <CheckboxListItem
        checked={telemetry[TelemetryKey.VolumeMonth]}
        onClick={() => {
          checkBoxEvent({ label: 'vol-month', value: !telemetry[TelemetryKey.VolumeMonth] });
          dispatch(toggleTelemetry(TelemetryKey.VolumeMonth));
        }}
        label={_(msg`Volumen mes`)}
      />
    </CheckboxList>
  );
};

export default SidebarVolumeCheckboxList;
