import { AmaruSnackbar } from '@/types/components/AmaruSnackbar';
import { PicturePreview } from '@/types/components/PicturePreview';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface Components {
  snackbar: AmaruSnackbar;
  picturePreview: PicturePreview;
}

const initialState: Components = {
  snackbar: {
    open: false,
    message: null,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    severity: 'info',
  },
  picturePreview: {
    show: false,
    picture: '',
    title: 'Foto',
  },
};

const { reducer, actions } = createSlice({
  name: 'global_config',
  initialState,
  reducers: {
    setSnackbar(state: Components, action: PayloadAction<AmaruSnackbar>): void {
      state.snackbar = {
        ...action.payload,
        ...(!action.payload.anchorOrigin && {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        }),
        ...(!action.payload.severity && { severity: state.snackbar.severity }),
      };
    },
    setPicturePreview(state: Components, action: PayloadAction<PicturePreview>): void {
      if (!action.payload.show) {
        action.payload.picture = '';
        action.payload.title = 'Foto';
      }
      state.picturePreview = action.payload;
    },
  },
});

export default reducer;

export const { setSnackbar, setPicturePreview } = actions;
