import { gql } from '@/__generated__';

const NOTIFICATION_CREATED = gql(`#graphql
  subscription NotificationCreated {
    notificationCreated {
      notification {
        id
        notificationObject {
          id
          status
          entity {
            ... on Announcement {
              __typename
              id
              title
              createdAt
              description
            }
          }
        }
        status
      }
    }
  }
`);

export default NOTIFICATION_CREATED;
