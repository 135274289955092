import { gql } from '@/__generated__';

const UPDATE_ACCOUNT_ACCESS_AND_ALERTS = gql(`#graphql
  mutation updateAccountAccessAndAlertsConfig(
    $input: AccountAccessAndAlertsConfigInput!
  ) {
    updateAccountAccessAndAlertsConfig(
      input: $input
    ) {
      success
      message
    }
  }
`);
export default UPDATE_ACCOUNT_ACCESS_AND_ALERTS;

