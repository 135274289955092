import {
  Dialog,
  DialogContent,
  Button,
  Box,
  Typography,
  TextField,
} from '@mui/material';
import DELETE_STATION from 'src/graphql/mutations/deleteStation';
import { useApolloClient } from '@apollo/client';
import { useDispatch } from 'src/store';
import { setSnackbar } from 'src/slices/components';
import { GateDialogProps } from '../dialogInterfaces';
import { useState } from 'react';
import Loading from 'src/components/Loading';

const DeleteGate = ({ show, handleClose, gate }: GateDialogProps) => {
  const [type, setType] = useState<string>('');

  const handleCloseWithoutLoading = () => {
    handleClose(false);
  };

  return (
    <Dialog open={show} onClose={handleCloseWithoutLoading}>
      <DialogContent>
        {!type ? (
          <Box>
            <Box mb={2} mt={1}>
              <Typography variant="h6">
                Puede archivar o eliminar definitivamente el dispositivo{' '}
                {gate?.name}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                onClick={handleCloseWithoutLoading}
                color="info"
                variant="outlined"
                sx={{
                  mr: 2,
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={() => setType('archive')}
                variant="contained"
                color="secondary"
                sx={{
                  mr: 2,
                }}
              >
                Archivar
              </Button>
              <Button
                onClick={() => setType('delete')}
                variant="contained"
                color="error"
                disabled
              >
                Eliminar Definitivamente
              </Button>
            </Box>
          </Box>
        ) : (
          <WriteConfirmation
            type={type}
            setType={setType}
            handleClose={handleClose}
            gate={gate}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DeleteGate;

const WriteConfirmation = ({ type, setType, handleClose, gate }) => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const [value, setValue] = useState('');
  const [error, setError] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleAction = async () => {
    const typeSpanish =
      type === 'archive' ? 'archivar' : 'eliminar definitivamente';
    const deviceId = gate?.id;

    if (deviceId && typeSpanish === value) {
      const variables = {
        deviceId,
      };
      if (type === 'delete') {
        variables['permanent'] = true;
      }
      setLoading(true);
      const {
        data: { DeleteStation },
      } = await client.mutate({
        mutation: DELETE_STATION,
        variables,
      });

      if (DeleteStation?.success) {
        handleClose(true);
        dispatch(
          setSnackbar({
            message: `Dispositivo ${gate?.name ?? ''} ${
              type === 'archive'
                ? 'archivado correctamente!'
                : 'eliminado definitivamente!'
            }`,
            open: true,
            severity: 'success',
          }),
        );
      } else {
        dispatch(
          setSnackbar({
            message: `Error al ${typeSpanish} el dispositivo ${
              gate?.name ?? ''
            }`,
            open: true,
            severity: 'error',
          }),
        );
      }
      setLoading(false);
      setType(null);
      setError(null);
    } else {
      console.log('error');
      if (typeSpanish !== value) {
        setError('La palabra clave es incorrecta');
      } else {
        dispatch(
          setSnackbar({
            message: 'Algo salió mal.',
            open: true,
            severity: 'error',
          }),
        );
      }
    }
  };

  if (loading) {
    return (
      <Box p={5} width={300}>
        <Loading />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6">
        Escriba "{type === 'delete' ? 'eliminar definitivamente' : 'archivar'}"
        para confirmar
      </Typography>
      <TextField
        label={type === 'delete' ? 'eliminar definitivamente' : 'archivar'}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        fullWidth
        sx={{
          my: 2,
        }}
      />
      {error && <Typography color="error">{error}</Typography>}
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
          onClick={() => {
            setType(null);
            setError(null);
          }}
          variant="outlined"
          color="info"
          sx={{
            mr: 2,
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={() => handleAction()}
          color={type === 'delete' ? 'error' : 'secondary'}
          variant="contained"
        >
          {type === 'delete' ? 'Eliminar definitivamente' : 'Archivar'}
        </Button>
      </Box>
    </Box>
  );
};
