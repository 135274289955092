import { useSuspenseQuery } from '@apollo/client';
import { Select, FormControl, InputLabel, MenuItem, SelectChangeEvent } from '@mui/material';
import { type FC } from 'react';
import GET_ORGANIZATION_PREFERENCES from '@/graphql/querys/getOrganizationPreferences';

interface Props {
  value: 'auto' | 'hours12' | 'hours24' | undefined;
  onChange: (event: SelectChangeEvent<'auto' | 'hours12' | 'hours24'>) => void;
}

const TimeFormatSelector: FC<Props> = ({ value, onChange }) => {
  const {
    data: {
      organization: { preferences },
    },
  } = useSuspenseQuery(GET_ORGANIZATION_PREFERENCES);

  const defaultValue =
    typeof preferences?.hour12 === 'boolean'
      ? preferences.hour12
        ? 'hours12'
        : 'hours24'
      : 'auto';

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel id="organization-time-format-label">Formato de hora</InputLabel>
      <Select
        labelId="organization-time-format-label"
        id="organization-time-format-selector"
        value={value ?? defaultValue}
        label="Formato de hora"
        onChange={onChange}
        size="small"
      >
        <MenuItem value="auto">Detectar automáticamente</MenuItem>
        <MenuItem value="hours12">AM/PM</MenuItem>
        <MenuItem value="hours24">24 horas</MenuItem>
      </Select>
    </FormControl>
  );
};

export default TimeFormatSelector;
