type UnitFlow = 'l/s' | 'm3/s' | 'm3/h';

type UnitLevel = 'cm' | 'm';

type UnitVolume = 'l' | 'm3';

const LS_CONVERSION_PROPORTION = {
  'l/s': 1,
  'm3/s': 0.001,
  'm3/h': 3.6,
};

const LS_CONVERSION_PROPORTION_m3s = {
  'm3/s': 1,
  'l/s': 1000,
  'm3/h': 3600,
};

const CM_CONVERSION_PROPORTION = {
  cm: 1,
  m: 0.01,
};

const L_CONVERSION_PROPORTION = {
  l: 1,
  m3: 0.001,
};

function convertFlow(value: number, fromUnit: UnitFlow, toUnit: UnitFlow) {
  return (
    (value / LS_CONVERSION_PROPORTION[fromUnit]) *
    LS_CONVERSION_PROPORTION[toUnit]
  );
}

function convertLevel(value: number, fromUnit: UnitLevel, toUnit: UnitLevel) {
  return (
    (value / CM_CONVERSION_PROPORTION[fromUnit]) *
    CM_CONVERSION_PROPORTION[toUnit]
  );
}

function convertVolume(
  value: number,
  fromUnit: UnitVolume,
  toUnit: UnitVolume,
) {
  return (
    (value / L_CONVERSION_PROPORTION[fromUnit]) *
    L_CONVERSION_PROPORTION[toUnit]
  );
}

function convertFromLsTo(value: number, unit: UnitFlow) {
  return value * LS_CONVERSION_PROPORTION[unit];
}

function convertFromM3sTo(value: number, unit: UnitFlow) {
  return value * LS_CONVERSION_PROPORTION_m3s[unit];
}

function convertFromCmTo(value: number, unit: UnitLevel) {
  return value * CM_CONVERSION_PROPORTION[unit];
}

function convertFromLTo(value: number, unit: UnitVolume) {
  return convertVolume(value, 'l', unit);
}

export {
  convertLevel,
  convertFlow,
  convertFromCmTo,
  convertFromLsTo,
  convertVolume,
  convertFromLTo,
  convertFromM3sTo,
};
export type { UnitFlow, UnitLevel, UnitVolume };
