import {
  amber,
  blue,
  cyan,
  deepPurple,
  grey,
  lightGreen,
  orange,
  teal,
  yellow,
} from '@mui/material/colors';
import { MetricField } from 'src/__generated__/graphql';

export const getMetricColor = (metric: MetricField, shade: keyof typeof blue = 500) => {
  if (metric === MetricField.Flow) return blue[shade];
  if (metric === MetricField.Level) return lightGreen[shade];
  if (metric === MetricField.Position) return cyan[shade];
  if (metric === MetricField.Angle) return yellow[shade];
  if (metric === MetricField.Velocity) return amber[shade];
  if (metric === MetricField.AccumulatedVolume) return deepPurple[shade];
  if (metric === MetricField.BatterySoc) return teal[shade];
  // if (metric === MetricField.VolumeDay) return t(i18n)`Volumen día`;
  // if (metric === MetricField.VolumeHour) return t(i18n)`Volumen hora`;
  // if (metric === MetricField.VolumeMonth) return t(i18n)`Volumen mes`;
  if (metric === MetricField.OriginalDistance) return orange[shade];

  return grey[shade];
};
