// apollo client
import { gql } from 'src/__generated__';

const FETCH_DEVICE_NAMING = gql(`#graphql
  query FetchDeviceNaming($serie: String) {
    deviceNaming(serie: $serie) {
      type: type
      familyType
    }
  }
`);

export default FETCH_DEVICE_NAMING;
