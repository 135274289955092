import { gql } from '@apollo/client';

const UPLOAD_FILE = gql`
  mutation UploadS3file($key: String, $bucket: String!) {
    UploadS3file(key: $key, bucket: $bucket) {
      fileName
      signedURL
      uuid
    }
  }
`;

export default UPLOAD_FILE;
