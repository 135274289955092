import { gql } from '@/__generated__';

const UPDATE_USER_PREFERENCES_METRICS = gql(`#graphql
  mutation UpdateUserPreferencesMetrics($input: UpdateUserPreferencesMetricsInput!) {
    updateUserPreferencesMetrics(input: $input) {
      id
      fields {
        id
        userId
        name
        defaultConfiguration {
          precision
          unit
        }
        additionalConfigurations {
          deviceType
          precision
          unit
        }
      }
    }
  }
`);

export default UPDATE_USER_PREFERENCES_METRICS;
