import { useQuery } from '@apollo/client';
import FETCH_IRRIGATIONS from '@/graphql/querys/irrigations';
import { Irrigation } from 'src/__generated__/graphql';

type ReturnType = {
  loading: boolean;
  error: any;
  irrigations: Irrigation[];
  refetch: () => void;
};

const useIrrigations = (): ReturnType => {
  const { loading, error, data, refetch } = useQuery(FETCH_IRRIGATIONS);
  return { loading, error, irrigations: data?.FetchIrrigations ?? [], refetch };
};

export default useIrrigations;
