import { gql } from '@/__generated__';

const GET_DGA_CONFIGURATION = gql(`#graphql
  query GetDGAConfiguration($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      dgaConfiguration {
        id
        code
        rut
        password
        type
      }
    }
  }
`);

export default GET_DGA_CONFIGURATION;
