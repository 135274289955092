import { gql } from '@/__generated__';

const GATE_STARTED_MOVING = gql(/* GraphQL */ `
  subscription GateStartedMoving {
    gateStartedMoving {
      gateId
      gateName
      startPosition
      position
      finishPosition
    }
  }
`);

export default GATE_STARTED_MOVING;
