import { gql } from '@apollo/client';

const SEND_DATA_MUTATION = gql`
  mutation CreateMeasurementMutation(
    $deviceId: String!
    $h: Float
    $flow: Float
    $dt: String
    $photo: String
  ) {
    createMeasurement(
      deviceId: $deviceId
      h: $h
      flow: $flow
      dt: $dt
      photo: $photo
    ) {
      success
    }
  }
`;

export default SEND_DATA_MUTATION;
