import { Trans } from '@lingui/macro';
import { Typography, Chip } from '@mui/material';
import getRoleColor from 'src/utils/roles';

export const InfoOrganization = ({ info }) => {
  return (
    <>
      <Typography fontSize={14} color="textSecondary">
        {info?.name ?? ''}
      </Typography>
      <Typography fontSize={14} color="textSecondary">
        <Trans>Dirección</Trans>: {info?.org?.address ?? ''}
      </Typography>
      <Typography fontSize={14} color="textSecondary">
        <Trans>Teléfono</Trans>: {info?.org?.phone ?? ''}
      </Typography>
      {info?.org?.website && (
        <Typography fontSize={14} color="textSecondary">
          <Trans>Sitio web</Trans>:{' '}
          <a
            style={{ textDecoration: 'none', color: '#ddd' }}
            target="_blank"
            rel="noreferrer"
            href={`${info?.org?.website ?? '/'}`}
          >
            {info?.org?.website ?? ''}
          </a>
        </Typography>
      )}
      <Chip
        label={info?.role?.name ?? ''}
        sx={{
          backgroundColor: getRoleColor(info?.role?.id ?? 0),
          fontWeight: 'bold',
          color: 'black',
        }}
      />
    </>
  );
};
