import type { FC } from 'react';
import { useState } from 'react';

import { Box, Button, Card, CardContent, Container, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';

import RecoveryPassword from '@features/recoveryPassword/';
import Login from '@features/login';
import { LogoCapta } from 'src/components/logo/';
import { useLingui } from '@lingui/react';
import { msg, Trans } from '@lingui/macro';

const initialState = {
  iv: null,
  code: null,
  name: '',
  lastnames: '',
  login: false,
  email: '',
};

export const Component: FC = () => {
  const { _ } = useLingui();
  const [state, setState] = useState(initialState);
  const [step, setStep] = useState(false);
  const handleClose = () => {
    setStep(!step);
  };

  return (
    <>
      <Helmet>
        <title>{_(msg`Iniciar sesión`)} | Capta Hydro | Amaru</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Container maxWidth="sm" sx={{ py: '80px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8,
            }}
          >
            <RouterLink to="/">
              <LogoCapta />
            </RouterLink>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
              }}
            >
              <Box>
                <Typography color="textPrimary" gutterBottom variant="h4">
                  {state?.login ? (
                    <Trans>Autenticación en dos pasos</Trans>
                  ) : (
                    <Trans>Iniciar sesión</Trans>
                  )}
                </Typography>
              </Box>
              {(state?.login ?? null) && (
                <Box>
                  <Typography color="textSecondary" gutterBottom variant="body1">
                    <Trans>
                      Hola {state?.name} {state?.lastnames}, hemos enviado un correo a tu email con
                      el código de Autenticación en dos pasos, debes ingresar el código para iniciar
                      sesión de forma segura. En ocasiones los correos se encuentra en la capeta
                      SPAM, no olvides revisarla.
                    </Trans>
                  </Typography>
                </Box>
              )}
              <Box paddingTop={2}>
                <Login state={state} setState={setState} />
              </Box>
              {!(state?.login ?? null) && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    marginTop: 2,
                  }}
                >
                  <Button onClick={handleClose}>
                    <Trans>¿Olvidaste tu contraseña?</Trans>
                  </Button>
                </Box>
              )}
            </CardContent>
          </Card>
          {step && (
            <RecoveryPassword
              open={step}
              handleClose={handleClose}
              emailValue={state?.email ?? ''}
            />
          )}
        </Container>
      </Box>
    </>
  );
};
