import { gql } from '@/__generated__';

const UPDATE_DEVICE_FILTERS = gql(`
  mutation UpdateDeviceFilters($input: UpdateDeviceFiltersInput!) {
    updateDeviceFilters(input: $input) {
      deviceId
      topicVersion
      filters {
        outOfRangeVelocity
        outliers {
          version
          wh {
            name
            windowSize
            sdThreshold
            smoothing
          }
          wf {
            name
            windowSize
            sdThreshold
            smoothing
          }
          radar {
            name
            windowSize
            sdThreshold
            smoothing
          }
          dp {
            name
            windowSize
            sdThreshold
            smoothing
          }
        }
      }
    }
  }
`);

export default UPDATE_DEVICE_FILTERS;
