import { gql } from '@apollo/client';

const DISTRIBUTION_CLASS = gql`
  query DistributionClass($irrigation_channel_network_id: Int!) {
    Sira {
      distributionClass(irrigation_channel_network_id: $irrigation_channel_network_id) {
        id
        title
        source
        owner_firstname
        owner_lastname
        expected_lossvalue
        expected_lossunit
        last_distribution_id
        irrigation_channel_network_id
        day_interval
        per_day_interval
        canals {
          id
          name
          shares
          config_type
          inlet_device_id
          max_flow_alert
          max_flow
          additional_emails
          additional_phone_numbers
          index
          distribution_sum
          account_canals {
            username
            name
            lastnames
            id
          }
          inlet_device {
            id
            name
            flow
            last_level_measured
            source
          }
        }
      }
    }
  }
`;

export default DISTRIBUTION_CLASS;
