import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Grid,
} from '@mui/material';
import { formatSourceColor } from 'src/utils/devices';
import ActionButtons from '@components/ActionButtons';
import { FormInputText, FormInputDate } from '@components/form';
import Loading from '@components/Loading';
import { useModalStatusMessage } from '@/hooks';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import CREATE_DEVICE_REPORT from '@features/reportProblem/graphql/mutations/createDeviceReport';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Trans, msg, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import GET_ACCESS_AUDIT from 'src/graphql/querys/access/getAccessAudit';

interface Props {
  device: {
    id: string;
    name: string;
    organization_name?: string | null;
    type: string;
  };
  show: boolean;
  handleClose: () => void;
}

const getDefaultValues = () => ({
  messageReport: '',
  dateReport: new Date(),
  sendJiraTicket: false,
});

const formSchema = yup
  .object()
  .shape({
    messageReport: yup.string().required(t`El nombre no puede estar vacío`),
    dateReport: yup.date().required(),
    sendJiraTicket: yup.boolean(),
  })
  .required();

const ReportProblemDialog = ({ device, show, handleClose }: Props) => {
  const { _ } = useLingui();
  const { data: access } = useQuery(GET_ACCESS_AUDIT);
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(formSchema),
  });
  const [createDeviceReport, { loading }] = useMutation(CREATE_DEVICE_REPORT, {
    onCompleted: (data) => {
      if (data?.createDeviceReport?.success) {
        openModalSuccessMessage(
          _(
            msg`Reporte enviado correctamente. Espera unos minutos para poder visualizar el evento`,
          ),
        );
        handleClose();
      } else {
        openModalErrorMessage(_(msg`Error al enviar el reporte`));
      }
    },
  });
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  // TODO: device le falta el campo sensor para recuperar el color
  const typeColor = formatSourceColor(device);

  useEffect(() => {
    if (!show) {
      reset(getDefaultValues());
    }
  }, [show, reset]);

  const onClose = () => {
    handleClose();
  };
  const onSubmit = async (data: any) => {
    createDeviceReport({
      variables: {
        deviceId: device?.id,
        messageReport: data.messageReport,
        dateReport: data.dateReport,
      },
    });
  };
  if (loading) {
    return (
      <Dialog open={show} fullWidth maxWidth="md">
        <DialogContent>
          <Loading />
        </DialogContent>
      </Dialog>
    );
  }
  return (
    <Dialog fullWidth maxWidth="md" open={show} onClose={onClose}>
      <DialogTitle sx={{ backgroundColor: '#2a3542' }}>
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="h5">{device?.name}</Typography>
            <Typography>-</Typography>
            <Typography>
              ID <b>{device?.id}</b>
            </Typography>
            <Typography>-</Typography>
            <Typography color={typeColor}>
              <b>{device?.type}</b>
            </Typography>
          </Box>
          <Box>
            <Typography align="right">
              <Trans>Organización</Trans>: <b>{device?.organization_name}</b>
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          // height: isCapta ? '20vh' : '15vh',
          justifyContent: 'center',
          marginTop: 3,
          marginBottom: 0,
          paddingBottom: 0,
        }}
      >
        <Grid item xs={12} sm={6} mt={2}>
          <FormInputText name="messageReport" control={control} label={_(msg`Evento a reportar`)} />
        </Grid>
        {access?.authorization.authorizationGeneral?.showAuditAdminUsers && (
          <>
            <Grid item xs={12} sm={6} mt={2}>
              <FormInputDate
                name="dateReport"
                control={control}
                label={_(msg`Fecha del reporte`)}
                dateFormat="DD/MM/YYYY HH:mm"
              />
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{}}>
        <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Grid item xs={12} sm={12} p={2}>
            <ActionButtons
              closeFunction={onClose}
              actionFunction={handleSubmit(onSubmit)}
              actionButtonText={_(msg`Enviar`)}
              actionButtonColor="success"
              disabledAction={!isValid || !isDirty}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ReportProblemDialog;
