import { useFormatters } from '@/hooks/useFormatters';
import { useSuspenseQuery } from '@apollo/client';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Chip, Link, Tooltip, Typography } from '@mui/material';
import { type FC } from 'react';
import GET_DEVICE_PROFILE_GENERAL from '../../graphql/queries/getDeviceProfileGeneral';

interface Props {
  deviceId: string;
}

export const SectionTitle: FC<{ title: string; id: string }> = ({ title, id }) => {
  return (
    <Typography id={id} variant="h2" sx={{ fontSize: '20px !important' }}>
      {title}
    </Typography>
  );
};

const DeviceProfileGeneral: FC<Props> = ({ deviceId }) => {
  const { _ } = useLingui();
  const { formatDate, formatDateTime } = useFormatters();
  const { data } = useSuspenseQuery(GET_DEVICE_PROFILE_GENERAL, {
    variables: {
      deviceId: deviceId,
    },
  });

  const deviceFields = [
    {
      label: _(msg`Nombre`),
      value: data.device.profile.originalName,
    },
    {
      label: _(msg`Alias`),
      value: data.device.profile.alias,
    },
    {
      label: _(msg`Tipo`),
      value: data.device.profile.type,
    },
    {
      label: _(msg`Organización principal`),
      value: data.device.profile.organization?.name ? (
        <Link fontWeight="medium" sx={{ textDecoration: 'none' }}>
          {data.device.profile.organization.name}
        </Link>
      ) : null,
      tooltip: `ID: ${data.device.profile.organization?.id ?? '-'}`,
    },
    {
      label: _(msg`Fecha de creación`),
      value: formatDate(data.device.profile.created),
      tooltip: formatDateTime(data.device.profile.created),
    },
    {
      label: _(msg`Canal de irrigación`),
      value:
        data.device.profile.irrigation?.name != null ? (
          <Link fontWeight="medium" sx={{ textDecoration: 'none' }}>
            {data.device.profile.irrigation.name}
          </Link>
        ) : null,
      tooltip: `ID: ${data.device.profile.irrigation?.id ?? '-'}`,
    },
    {
      label: _(msg`Estado`),
      value: <Chip size="small" label={data.device.profile.status} />,
      tooltip: data.device.profile.status,
    },
    {
      label: _(msg`Ubicación`),
      value:
        data.device.profile.location != null ? (
          <Link
            href={`https://www.google.com/maps/search/?api=1&query=${data.device.profile.location.latitude},${data.device.profile.location.longitude}`}
            fontWeight="medium"
            sx={{ textDecoration: 'none' }}
          >
            {data.device.profile.location.latitude}, {data.device.profile.location.longitude}
          </Link>
        ) : null,
      tooltip: _(msg`Abrir en Google Maps`),
    },
  ];

  const hwConfiguration = data.device.hardwareConfiguration;

  const hardwareFields = hwConfiguration
    ? [
        {
          label: _(msg`Número de proyecto`),
          value: hwConfiguration.nameProject,
        },
        {
          label: _(msg`Energización`),
          value: hwConfiguration.energizationOption?.name,
        },
        {
          label: _(msg`CPU`),
          value: hwConfiguration.cpuOption?.name,
        },
        {
          label: _(msg`Conexión a internet`),
          value: hwConfiguration.dataConnectionOption?.name,
        },
        {
          label: _(msg`Fecha de activación`),
          value: hwConfiguration.dateAction ? formatDate(hwConfiguration.dateAction) : null,
          tooltip: hwConfiguration.dateAction ? formatDateTime(hwConfiguration.dateAction) : null,
        },
        {
          label: _(msg`Operador móvil`),
          value: hwConfiguration.mobileOperatorOption?.name,
        },
        {
          label: _(msg`ID de Emnify`),
          value: hwConfiguration.idEmnify ? (
            <Link
              target="_blank"
              href={`https://portal.emnify.com/connected-devices/${hwConfiguration.idEmnify}`}
              fontWeight="medium"
              sx={{ textDecoration: 'none' }}
            >
              {hwConfiguration.idEmnify}
            </Link>
          ) : null,
          tooltip: hwConfiguration.idEmnify || null,
          show: hwConfiguration.mobileOperatorOption?.name === 'Emnify',
        },
      ]
    : null;

  return (
    <>
      {[
        {
          label: _(msg`Información general`),
          fields: deviceFields,
        },
        {
          label: _(msg`Información de hardware`),
          fields: hardwareFields,
        },
      ].map(({ fields, label }, index) => (
        <Box key={label} display="flex" flexDirection="column" gap={3}>
          <SectionTitle id={index === 0 ? 'general' : 'hardware'} title={label} />
          {fields == null ? (
            <Typography fontStyle="italic">{_(msg`Sin datos`)}</Typography>
          ) : (
            <Box
              display="grid"
              gridTemplateColumns="repeat(4, 240px)"
              gridTemplateRows="repeat(2, auto)"
              gridAutoFlow="column"
              gap="24px 48px"
            >
              {fields?.map((field, index) => {
                return (
                  <Box key={index} display="flex" flexDirection="column" gap={1}>
                    <Typography fontSize="14px" color="text.secondary">
                      {field.label}
                    </Typography>
                    {field.value == null ? (
                      <Typography fontStyle="italic">{_(msg`No asignado`)}</Typography>
                    ) : (
                      <Tooltip
                        placement="bottom-start"
                        title={field.tooltip ? field.tooltip : field.value}
                      >
                        <Typography noWrap>{field.value}</Typography>
                      </Tooltip>
                    )}
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      ))}
    </>
  );
};

export default DeviceProfileGeneral;
