import { gql } from '@/__generated__';

const GET_ACCESS_NAME_ALIASES = gql(`#graphql
  query GetAccessNameAliases {
    authorization {
      authorizationGeneral {
        showOrganizationFilter
      }
    }
  }
`);

export default GET_ACCESS_NAME_ALIASES;
