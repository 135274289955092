import { gql } from '@/__generated__';

const GET_DEVICE_INFO = gql(`#graphql
query GetOrgFromDevice_Sma($input: DeviceInput! ) {
  device(input: $input) {
    id
    profile {
      id
      organization {
        id
        name
      }
    }
    metrics {
      id
      availableFields
    }
  }
}`);

export default GET_DEVICE_INFO;
