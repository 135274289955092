import useAuth from './useAuth';

const usePermission = () => {
  const auth = useAuth();
  
  const isCaptaOrganization = (): boolean => {
    return (auth?.user?.organization_id ?? 0) === 2;
  };

  const isCaptaRol = (): boolean => {
    return (auth?.user?.role?.id ?? 0) === 1;
  };

  const isAdminRol = (): boolean => {
    return (auth?.user?.role?.id ?? 0) === 2;
  };

  return {
    isCaptaOrganization,
    isCaptaRol,
    isAdminRol,
  };
};

export default usePermission;
