import { useEffect, useState } from 'react';
import { Box, Dialog, Divider, Grid } from '@mui/material';

// redux
import { setShowAccount } from 'src/slices/stations';
import { useDispatch, useSelector } from 'src/store';
// graphql
import useOrganizations from 'src/hooks/useOrganization';
// components
import { InfoOrganization, InfoUser } from '.';

type Props = {};

export const AccountGeneral = (props: Props) => {
  const dispatch = useDispatch();

  const { organizations } = useOrganizations();
  const [organizationsAccount, setOrganizationsAccount] = useState([]);
  const { showAccount, selectedAccount } = useSelector((state) => state.stations_store);

  useEffect(() => {
    if (organizations && organizations.length > 0) {
      const organizationArray = selectedAccount?.organizations ?? [];

      if (organizationArray && organizationArray.length > 0) {
        setOrganizationsAccount(
          organizationArray.map((itm) => {
            const org = organizations.find((t: any) => t.id === itm.id);
            return {
              ...itm,
              org,
            };
          }),
        );
      }
    }
  }, [organizations, selectedAccount]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={showAccount}
      onClose={() => dispatch(setShowAccount(false))}
    >
      <Box
        sx={{
          p: 3,
        }}
      >
        <Grid container mb={5}>
          <Grid item xs={12} md={6} display="flex">
            <InfoUser account={selectedAccount} />
          </Grid>
          {organizationsAccount &&
            organizationsAccount.length > 0 &&
            organizationsAccount.map((item: any) => (
              <Grid key={`key-${item.id}`} item xs={12} md={6}>
                <InfoOrganization info={item} />
              </Grid>
            ))}
        </Grid>
        <Divider />
      </Box>
    </Dialog>
  );
};
