import { Dialog, DialogContent, DialogTitle } from '@mui/material';

interface Props {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
}

const DialogWrapper = ({ children, open, onClose }: Props) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Configurar Ticket DGA</DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default DialogWrapper;
