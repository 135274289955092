import { Trans } from '@lingui/macro';
import { Avatar, Box, Typography } from '@mui/material';

export const InfoUser = ({ account }) => {
  return (
    <>
      {account?.picture ? (
        <Avatar sx={{ width: '60px', height: '60px' }} src={account?.picture ?? ''} />
      ) : (
        <Avatar
          sx={{
            color: 'white',
            fontSize: '13px',
            textTransform: 'uppercase',
            width: '60px',
            height: '60px',
          }}
        >
          {(account?.username ?? '').slice(0, 2)}
        </Avatar>
      )}
      <Box
        sx={{
          paddingLeft: '20px',
        }}
      >
        <Typography color="textPrimary" variant="h6">
          {account?.name ?? ''} {account?.lastnames ?? ''}
        </Typography>
        <Typography fontSize={14} color="textSecondary">
          <Trans>Email</Trans>: {account?.username ?? ''}
        </Typography>
        <Typography fontSize={14} color="textSecondary">
          <Trans>Teléfono</Trans>: {account?.phone ?? ''}
        </Typography>
      </Box>
    </>
  );
};
