import zod from 'zod';
import { type I18n } from '@lingui/core';
import {
  DeviceType,
  MetricField,
  MetricUnit,
  UpdateUserPreferencesMetricsInput,
} from '@/__generated__/graphql';
import { t } from '@lingui/macro';

export const getMetricsValidationSchema = (i18n: I18n) => {
  return zod.object({
    userId: zod.number(),
    fields: zod.array(
      zod.object({
        name: zod.nativeEnum(MetricField),
        defaultConfiguration: zod.object({
          unit: zod.nativeEnum(MetricUnit),
          precision: zod
            .number({ invalid_type_error: t(i18n)`Debe ser un número` })
            .min(0)
            .max(10),
        }),
        additionalConfigurations: zod
          .array(
            zod.object({
              deviceType: zod.nativeEnum(DeviceType),
              unit: zod.nativeEnum(MetricUnit),
              precision: zod
                .number({ invalid_type_error: t(i18n)`Debe ser un número` })
                .min(0)
                .max(10),
            }),
          )
          .superRefine((array, ctx) => {
            const repeatedDeviceTypes = new Set<number>();
            for (let i = 0; i < array.length; i++) {
              for (let j = i + 1; j < array.length; j++) {
                if (array[i].deviceType === array[j].deviceType) {
                  repeatedDeviceTypes.add(i);
                  repeatedDeviceTypes.add(j);
                }
              }
            }
            if (repeatedDeviceTypes.size > 0) {
              console.log(repeatedDeviceTypes);
              repeatedDeviceTypes.forEach((index) => {
                ctx.addIssue({
                  code: zod.ZodIssueCode.custom,
                  path: [index.toString(), 'deviceType'],
                  message: t(i18n)`Tipo de dispositivo repetido`,
                });
              });
              return zod.NEVER;
            }
          }),
      }),
    ),
  }) satisfies zod.ZodType<UpdateUserPreferencesMetricsInput>;
};
