import { gql } from '@/__generated__';

const GATE_MOVED = gql(/* GraphQL */ `
  subscription GateMoved {
    gateMoved {
      gateId
      gateName
      startPosition
      position
      finishPosition
    }
  }
`);

export default GATE_MOVED;
