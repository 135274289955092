import { MetricField, SmaMetricName, SmaMetricUnit } from '@/__generated__/graphql';

const smaMetricMap: Partial<Record<MetricField, SmaMetricName[]>> = {
  [MetricField.Flow]: [SmaMetricName.Q],
};

function getSmaMetricMap(metricField: MetricField) {
  return smaMetricMap[metricField]?.map((i) => ({ label: i, value: i })) ?? [];
}

const smaLabelMetricUnitMap: Record<SmaMetricUnit, string> = {
  [SmaMetricUnit.LiterPerSecond]: 'l/s',
};

const smaMetricUnitOptionsMap: Record<SmaMetricName, { label: string; value: SmaMetricUnit }[]> = {
  [SmaMetricName.Q]: [
    {
      label: smaLabelMetricUnitMap[SmaMetricUnit.LiterPerSecond],
      value: SmaMetricUnit.LiterPerSecond,
    },
  ],
};

function getSmaMetricUnitLabel(unit: SmaMetricUnit) {
  return smaLabelMetricUnitMap[unit];
}

function getSmaMetricUnitOptions(metric: SmaMetricName) {
  return smaMetricUnitOptionsMap[metric] ?? [];
}

export { getSmaMetricUnitOptions, getSmaMetricMap, getSmaMetricUnitLabel };
