const putS3 = async (presignedURL, file) => {
  return new Promise((resolve, reject) => {
    fetch(presignedURL, {
      method: 'PUT',
      body: file,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export default putS3;
