import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';

// components
import TableLoading from 'src/components/TableLoading';

// import { DeviceDialogProps } from '../dialogInterfaces';
// graphql
import { useMutation } from '@apollo/client';
import CLONE_DEVICE from 'src/features/deviceClone/graphql/mutations/cloneDevice';

// hooks
import { useModalStatusMessage } from 'src/hooks';
import { useState } from 'react';
import { get } from 'lodash';

export const CloneDevice = ({ show, device, handleClose }) => {
  const { openModalErrorMessage, openModalSuccessMessage } =
    useModalStatusMessage();

  const [callCloneDevice, { loading }] = useMutation(CLONE_DEVICE, {
    onCompleted: (data) => {
      const {
        CloneDevice: { success, message },
      } = data;
      if (success) {
        handleClose(true);
        openModalSuccessMessage('Dispositivo clonado con éxito');
      } else {
        openModalErrorMessage(
          'Hubo un problema para clonar dispositivo: ' + message,
        );
      }
    },
  });

  const initialName = 'Clon ' + get(device, 'name', '');

  const [name, setName] = useState(initialName);

  const applyClone = ({ id }) => {
    const variables = {
      id: id,
      name: name,
    };
    callCloneDevice({ variables });
  };

  return (
    <Dialog open={show} onClose={() => handleClose(false)}>
      <DialogTitle>Clonar dispositivo</DialogTitle>
      <DialogContent>
        {loading ? (
          <TableLoading withCard={false} />
        ) : (
          <Grid container>
            <Grid item xs={12} p={2}>
              <Box>
                <TextField
                  fullWidth
                  label="Nombre"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={6} p={2}>
              <Button
                variant="outlined"
                color="info"
                fullWidth
                onClick={() => handleClose(false)}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6} p={2}>
              <Button
                variant="contained"
                color="success"
                fullWidth
                onClick={() => applyClone(device)}
              >
                Clonar
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};
