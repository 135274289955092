import { gql } from '@apollo/client';

const FETCH_VIRTUAL_STATION_MEASUREMENTS = gql`
  query FetchVirtualStationMeasurements(
    $stationId: String!
    $startDate: String
    $endDate: String
  ) {
    FetchVirtualStationMeasurements(
      stationId: $stationId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      userId
      userName
      level
      flow
      photo
      stationId
      stationName
      timestamp
    }
  }
`;

export default FETCH_VIRTUAL_STATION_MEASUREMENTS;
