import { I18n } from '@lingui/core';
import { MetricField } from '@/__generated__/graphql';
import { t } from '@lingui/macro';

export const getMetricDisplayName = (metric: MetricField, i18n: I18n) => {
  if (metric === MetricField.Flow) return t(i18n)`Caudal`;
  if (metric === MetricField.Level) return t(i18n)`Nivel`;
  if (metric === MetricField.Position) return t(i18n)`Posición`;
  if (metric === MetricField.Angle) return t(i18n)`Ángulo`;
  if (metric === MetricField.Velocity) return t(i18n)`Velocidad`;
  if (metric === MetricField.AccumulatedVolume) return t(i18n)`Volumen acumulado`;
  if (metric === MetricField.VolumeDay) return t(i18n)`Volumen día`;
  if (metric === MetricField.VolumeHour) return t(i18n)`Volumen hora`;
  if (metric === MetricField.VolumeMonth) return t(i18n)`Volumen mes`;
  if (metric === MetricField.OriginalDistance) return t(i18n)`Distancia del sensor`;
  if (metric === MetricField.BatterySoc) return t(i18n)`Batería`;

  return metric;
};
