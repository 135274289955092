import { Box, Button, Dialog, DialogTitle, TextField } from '@mui/material';
import _ from 'lodash';

const CreateNewUser = ({
  show,
  handleClose,
  handleSave,
  handleInputChange,
  handleUpdatedUser,
  values,
  errors,
}) => {
  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle>
        {_.get(values, 'type', '') === 'updated'
          ? 'Editar usuario'
          : 'Agregar Usuario'}
      </DialogTitle>
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width={500}
        height={300}
      >
        <TextField
          fullWidth
          label="Usuario"
          name="name"
          value={values.name}
          onChange={(e) => handleInputChange(e)}
          {...(errors.name && { error: true, helperText: errors.name })}
        />
        <TextField
          fullWidth
          label="Teléfono"
          name="phone"
          value={values.phone}
          onChange={(e) => handleInputChange(e)}
          {...(errors.phone && { error: true, helperText: errors.phone })}
        />
        <Box
          width={200}
          mt={2}
          display="flex"
          justifyContent="space-between"
          alignSelf="flex-end"
        >
          <Button variant="outlined" color="info" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={
              _.get(values, 'type', '') === 'updated'
                ? handleUpdatedUser
                : handleSave
            }
          >
            {_.get(values, 'type', '') === 'updated' ? 'Editar' : 'Guardar'}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateNewUser;
