import { gql } from '@apollo/client';

const CHANGE_DGA_TICKET_STATE_TO_CLOSE = gql`
  mutation ChangeDGATicketStateToClose(
    $deviceId: String!
    $ticketID: String!
    $ticketTimestamp: Float!
  ) {
    ChangeDGATicketStateToClose(
      deviceId: $deviceId
      ticketID: $ticketID
      ticketTimestamp: $ticketTimestamp
    ) {
      message
      success
    }
  }
`;

export default CHANGE_DGA_TICKET_STATE_TO_CLOSE;
