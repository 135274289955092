import { SvgIcon, SvgIconProps } from '@mui/material';

function DistributionsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="m23.23,12.89c-.33-.48-.98-.59-1.46-.26l-.7.49v-4.32c0-.58-.47-1.05-1.05-1.05H4.02c-.58,0-1.05.47-1.05,1.05v4.32l-.7-.48c-.48-.33-1.13-.21-1.46.26-.13.18-.19.39-.19.6,0,.33.16.66.45.86l2.35,1.63s0,0,0,0c.17.11.35.18.55.18.02,0,.03,0,.05,0,.07,0,.13,0,.2-.02,0,0,0,0,0,0h0c.12-.02.23-.07.34-.13,0,0,0,0,0,0,.02,0,.03-.02.05-.03l2.42-1.61c.48-.32.61-.97.29-1.46-.32-.48-.97-.61-1.46-.29l-.79.53v-3.31h13.9v3.31l-.79-.53c-.48-.32-1.13-.19-1.46.29-.32.48-.19,1.13.29,1.46l2.41,1.61s.03.02.04.03h0c.12.07.24.12.37.14h0c.07.01.15.02.22.01h0c.19,0,.38-.07.54-.18,0,0,0,0,0,0,0,0,0,0,0,0l2.35-1.63c.29-.2.45-.53.45-.86,0-.21-.06-.42-.19-.6Z" />
      <path d="m8.03,4.92c1.22,0,2.21-.99,2.21-2.21s-.99-2.21-2.21-2.21-2.21.99-2.21,2.21.99,2.21,2.21,2.21Z" />
      <path d="m16.07,4.92c1.22,0,2.21-.99,2.21-2.21s-.99-2.21-2.21-2.21-2.21.99-2.21,2.21.99,2.21,2.21,2.21Z" />
      <path d="m3.96,18.99c-1.22,0-2.21.99-2.21,2.21s.99,2.21,2.21,2.21,2.21-.99,2.21-2.21-.99-2.21-2.21-2.21Z" />
      <path d="m19.68,18.99c-1.22,0-2.21.99-2.21,2.21s.99,2.21,2.21,2.21,2.21-.99,2.21-2.21-.99-2.21-2.21-2.21Z" />
    </SvgIcon>
  );
}

export default DistributionsIcon;
