import { gql } from '@/__generated__';

const GET_USER_PROFILE_AUDIT_LOGS = gql(`#graphql
  query UserProfile_GetUserAuditLogs($input: UserInput!) {
    user(input: $input) {
      id
      auditLogs {
        id
        timestamp
        action {
          type
        }
        entity {
          id
          type
        }
      }
    }
  }
`);

export default GET_USER_PROFILE_AUDIT_LOGS;
