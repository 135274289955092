import { gql } from '@apollo/client';

const FETCH_LAST_DISTRIBUTION = gql`
  query FetchLastDistribution($irrigation_channel_network_id: Int!) {
    Sira {
      FetchLastDistributionInstance(irrigation_channel_network_id: $irrigation_channel_network_id) {
        id
        title
        source
        owner_firstname
        owner_lastname
        expected_lossvalue
        expected_lossunit
        last_distribution_id
        irrigation_channel_network_id
        distribution_waterflow
        distribution_lossvalue
        distribution_lossunit
        distributionClassId
        sent_date
        email_message
        sms_message
        note
        distribution_start_date
        canals {
          additional_phone_numbers
          canal_class_id
          additional_emails
          max_flow_alert
          inlet_device_id
          config_type
          shares
          name
          note
          id
        }
      }
    }
  }
`;

export default FETCH_LAST_DISTRIBUTION;
