// material-ui
import { Trans } from '@lingui/macro';
import { Box, Button, Dialog, DialogContent, DialogTitle } from '@mui/material';

// utils
import _ from 'lodash';

// redux
import { useSelector } from 'src/store';

const SimplePicturePreview = ({ show, handleClose, src, alt }) => {
  const { selectedStation } = useSelector((state) => state.stations_store);
  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle>
        <Trans>Nueva foto de medición para</Trans> {_.get(selectedStation, 'name', '')}
      </DialogTitle>
      <DialogContent>
        <img
          src={src}
          alt={alt}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '5px',
          }}
        />
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button color="primary" variant="contained" onClick={handleClose}>
            <Trans>Cerrar</Trans>
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SimplePicturePreview;
