import { GetDgaNotificationConfigurationQuery } from '@/__generated__/graphql';
import NumericTextField from '@/components/NumericTextField';
import ModalTitle from '@/components/modal/ModalTitle';
import { useModalStatusMessage } from '@/hooks';
import { useSelector } from '@/store';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import ModalActions from '@components/modal/ModalActions';
import { zodResolver } from '@hookform/resolvers/zod';
import { type I18n } from '@lingui/core';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  InputAdornment,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import Fuse from 'fuse.js';
import { Suspense, useEffect, useState, type FC } from 'react';
import { Controller, DefaultValues, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { FixedSizeList } from 'react-window';
import { z } from 'zod';
import UPDATE_DGA_NOTIFICATION_CONFIGURATION from '../../graphql/mutations/updateDGANotificationConfiguration';
import GET_DGA_NOTIFICATION_CONFIGURATION from '../../graphql/queries/getDGANotificationConfiguration';
import { setUpdateDGANotificationConfigurationModal } from '../../slices/dgaSlice';

type FormValues = {
  deviceId: string;
  daysUntilFailureNotification: number;
  failureNotificationFrequencyInDays: number;
  users: {
    userId: number;
    notifyEmail: boolean;
  }[];
};

const getDefaultValues = (
  deviceId: string,
  configuration: GetDgaNotificationConfigurationQuery['device']['dgaNotificationConfiguration'],
  users: GetDgaNotificationConfigurationQuery['device']['users'],
): DefaultValues<FormValues> => {
  const {
    daysUntilFailureNotification,
    failureNotificationFrequencyInDays,
    users: configurationUsers,
  } = configuration ?? {
    deviceId,
    daysUntilFailureNotification: 0,
    failureNotificationFrequencyInDays: 0,
    users: [],
  };

  const formUsers = users.map((user) => {
    const notifyEmail =
      configurationUsers.find((u) => u.user.id === user.user.id)?.notifyEmail ?? false;
    return {
      userId: user.user.id,
      notifyEmail,
    };
  });

  return {
    deviceId,
    daysUntilFailureNotification,
    failureNotificationFrequencyInDays,
    users: formUsers,
  };
};

const zodSchema = (i18n: I18n) =>
  z.object({
    deviceId: z.string(),
    daysUntilFailureNotification: z.preprocess(
      (x) => (x == null || x === '' ? undefined : Number(x)),
      z
        .number({
          required_error: i18n._(msg`Este campo es requerido`),
        })
        .int(),
    ),
    failureNotificationFrequencyInDays: z.preprocess(
      (x) => (x == null || x === '' ? undefined : Number(x)),
      z
        .number({
          required_error: i18n._(msg`Este campo es requerido`),
        })
        .int(),
    ),
    users: z.array(
      z.object({
        userId: z.number(),
        notifyEmail: z.boolean(),
      }),
    ),
  });

const getUserRoleFromDeviceOrganization = (user: GetDgaNotificationConfigurationQuery['device']['users'][number]['user'], organizationId: number | undefined) => {
  if (user.mainOrganization?.organization.id === organizationId) {
    return user.mainOrganization?.role.name;
  }
  const additionalOrganization = user.additionalOrganizations.find(
    (org) => org.organization.id === organizationId,
  );
  return additionalOrganization?.role.name;
}

interface Props {
  deviceId: string;
  onClose: () => void;
}

const Component: FC<Props> = ({ deviceId, onClose }) => {
  const { _, i18n } = useLingui();
  const [search, setSearch] = useState('');
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const [filteredUserIds, setFilteredUserIds] = useState<
    { dataIndex: number; userId: number }[] | null
  >(null);
  const { data } = useSuspenseQuery(GET_DGA_NOTIFICATION_CONFIGURATION, {
    variables: { deviceId },
  });
  const [update] = useMutation(UPDATE_DGA_NOTIFICATION_CONFIGURATION, {
    onCompleted(updateData) {
      const defaultValues = getDefaultValues(
        deviceId,
        updateData.updateDgaDeviceNotificationConfiguration,
        data.device.users,
      );
      methods.reset(defaultValues);
      openModalSuccessMessage(_(msg`La configuración de notificaciones DGA ha sido actualizada`));
    },
    onError(error) {
      openModalErrorMessage(error.message);
    },
  });

  const methods = useForm<FormValues>({
    defaultValues: getDefaultValues(
      deviceId,
      data.device.dgaNotificationConfiguration,
      data.device.users,
    ),
    resolver: zodResolver(zodSchema(i18n)),
  });

  useEffect(() => {
    if (search === '') {
      setFilteredUserIds(null);
      return;
    }

    const users = data.device.users;

    const fuse = new Fuse(users, {
      keys: ['user.id', 'user.fullName', 'user.email'],
    });

    const filteredUserIds = fuse.search(search, { limit: 5 }).map(({ item }) => ({
      dataIndex: users.findIndex(({ user }) => user.id === item.user.id),
      userId: item.user.id,
    }));
    setFilteredUserIds(filteredUserIds ?? []);
  }, [search, data]);

  const formUsers = methods.watch('users');
  const selectedUsers = formUsers.filter((user) => user.notifyEmail);
  const userList = data.device.users;

  const onSelectAll = () => {
    methods.setValue(
      'users',
      formUsers.map((user) => ({
        ...user,
        notifyEmail: true,
      })),
      {
        shouldDirty: true,
      },
    );
  };

  const onDeselectAll = () => {
    methods.setValue(
      'users',
      formUsers.map((user) => ({
        ...user,
        notifyEmail: false,
      })),
      {
        shouldDirty: true,
      },
    );
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    update({
      variables: {
        input: {
          deviceId,
          daysUntilFailureNotification: data.daysUntilFailureNotification,
          failureNotificationFrequencyInDays: data.failureNotificationFrequencyInDays,
          users: data.users,
        },
      },
    });
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <DialogContent dividers>
        <Typography variant="h6" mb={2}>
          <Trans>Configuración de notificación</Trans>
        </Typography>
        <Box display="grid" gridTemplateColumns="2fr 2fr 1fr" gap={1} mb={2}>
          <Controller
            name="daysUntilFailureNotification"
            control={methods.control}
            render={({ field, fieldState }) => (
              <NumericTextField
                {...field}
                label={_(msg`Tiempo para comenzar a notificar`)}
                fullWidth
                size="small"
                inputMode="numeric"
                error={Boolean(fieldState.error)}
                helperText={
                  fieldState.error
                    ? fieldState.error.message
                    : _(msg`Tiempo para enviar la primera notificación en caso de falla`)
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Trans>días</Trans>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name="failureNotificationFrequencyInDays"
            control={methods.control}
            render={({ field, fieldState }) => (
              <NumericTextField
                {...field}
                label={_(msg`Frecuencia de notificaciones`)}
                fullWidth
                size="small"
                inputMode="numeric"
                error={Boolean(fieldState.error)}
                helperText={
                  fieldState.error
                    ? fieldState.error.message
                    : _(msg`Frecuencia para volver a enviar notificaciones`)
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Trans>días</Trans>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Box>
        <Typography variant="h6" mb={2}>
          <Trans>Usuarios notificados por correo electrónico</Trans>
        </Typography>
        <Box
          mb={1}
          display="grid"
          alignItems="center"
          gridTemplateColumns="1fr auto auto 1fr"
          gap={1}
        >
          <TextField
            label="Buscar"
            fullWidth
            size="small"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
          <Button
            variant="outlined"
            color="info"
            disabled={selectedUsers.length === userList.length}
            onClick={onSelectAll}
          >
            <Trans>Seleccionar todos</Trans>
          </Button>
          <Button
            variant="outlined"
            color="info"
            disabled={selectedUsers.length === 0}
            onClick={onDeselectAll}
          >
            <Trans>Deseleccionar todos</Trans>
          </Button>
          <Typography justifySelf="right">
            <Trans>
              <b>{selectedUsers.length}</b> usuarios seleccionados de <b>{userList.length}</b>
            </Trans>
          </Typography>
        </Box>
        <Box border={1} borderColor="divider" borderRadius={1}>
          <FixedSizeList
            height={300}
            width="100%"
            itemSize={72}
            itemCount={filteredUserIds?.length ?? userList.length ?? 0}
            itemKey={(index) => userList[index].user.id ?? index}
          >
            {({ style, index }) => {
              const users = data.device.users;

              const { user } = filteredUserIds
                ? users[filteredUserIds[index].dataIndex]
                : users[index];
              return (
                <Controller
                  name={`users.${index}.notifyEmail`}
                  control={methods.control}
                  render={({ field, fieldState }) => {
                    return (
                      <ListItem
                        disablePadding
                        style={style}
                        key={user.id}
                        sx={{
                          borderBottom: index !== users.length - 1 ? '1px solid' : null,
                          borderColor: 'divider',
                        }}
                      >
                        <ListItemButton
                          onClick={() => {
                            field.onChange(!field.value);
                          }}
                        >
                          <ListItemText
                            primary={user.id + ' - ' + user.fullName + ' - ' + getUserRoleFromDeviceOrganization(user, data.device.profile.organization?.id)}
                            secondary={user.email}
                          />
                          {fieldState.isDirty && (
                            <Typography fontSize="small" fontStyle="italic" color="text.secondary">
                              <Trans>(editado)</Trans>
                            </Typography>
                          )}

                          <ListItemIcon>
                            <Checkbox edge="end" checked={field.value} tabIndex={-1} />
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    );
                  }}
                />
              );
            }}
          </FixedSizeList>
        </Box>
      </DialogContent>
      <ModalActions
        onClose={onClose}
        onSubmit="submit"
        onResetForm={() => methods.reset()}
        submitLoading={methods.formState.isSubmitting}
        dirtyForm={methods.formState.isDirty}
      />
    </form>
  );
};

const Fallback: FC<{ onClose: () => void }> = ({ onClose }) => (
  <>
    <DialogContent dividers>
      <Skeleton variant="rounded" height={400} />
    </DialogContent>
    <ModalActions onClose={onClose} />
  </>
);

const UpdateDGANotificationConfigurationModal: FC = () => {
  const dispatch = useDispatch();
  const { _ } = useLingui();
  const { updateDGANotificationConfigurationModal: modalState } = useSelector(
    (state) => state.dga_store,
  );
  const onClose = () => {
    dispatch(
      setUpdateDGANotificationConfigurationModal({
        open: false,
      }),
    );
  };

  return (
    <Dialog onClose={onClose} open={modalState.open} maxWidth="md" fullWidth>
      <ModalTitle
        onClose={onClose}
        title={_(msg`Actualizar configuración de notificaciones DGA`)}
        subtitle={modalState.open ? _(msg`Dispositivo ${modalState.deviceId}`) : undefined}
      />
      <ApolloErrorBoundary>
        <Suspense fallback={<Fallback onClose={onClose} />}>
          {modalState.open && <Component onClose={onClose} deviceId={modalState.deviceId} />}
        </Suspense>
      </ApolloErrorBoundary>
    </Dialog>
  );
};

export default UpdateDGANotificationConfigurationModal;
