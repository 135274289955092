import { gql } from '@/__generated__';

const GET_CHART_PREVIEW_DATA = gql(`#graphql
  query MathModeling_GetChartPreviewData($input: DevicesInput!, $telemetryInput: TelemetryParametersInput!) {
    devices(input: $input) {
      id
      sensors {
        id
        type: type {
          id
          label
          name
        }
      }
      telemetry(input: $telemetryInput) {
        id
        flow
        level
        position
        angle
      }
    }
  }
`);

export default GET_CHART_PREVIEW_DATA;
