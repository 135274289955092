import { gql } from '@/__generated__';

const RETRY_DGA_REPORTS_FOR_DEVICE = gql(`#graphql
  mutation RetryDGAReportsForDevice($deviceId: String!) {
    RetryDGAReportsForDevice(deviceId: $deviceId) {
      message
      success
    }
  }
`);

export default RETRY_DGA_REPORTS_FOR_DEVICE;
