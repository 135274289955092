import { createSlice } from '@reduxjs/toolkit';
import { GateMovementState } from '@/__generated__/graphql';

export interface GateInMovement extends GateMovementState {
  state: 'STARTED_MOVING' | 'FINISHED_MOVING' | 'MOVING';
}

const initialState: {
  gatesInMovement: GateInMovement[];
} = { gatesInMovement: [] };

const gatesInMovementSlice = createSlice({
  name: 'gates_in_movement_store',
  initialState,
  reducers: {
    setGateInMovement(state, action: { payload: GateInMovement }) {
      const gateIndex = state.gatesInMovement.findIndex(
        (gate) => gate.gateId === action.payload.gateId,
      );
      if (gateIndex === -1) {
        state.gatesInMovement.push(action.payload);
      } else {
        state.gatesInMovement[gateIndex] = action.payload;
      }
    },
    removeGateInMovement(state, action: { payload: { gateId: string } }) {
      state.gatesInMovement = state.gatesInMovement.filter(
        (gate) => gate.gateId !== action.payload.gateId,
      );
    },
  },
});

const { actions, reducer } = gatesInMovementSlice;

export const { setGateInMovement, removeGateInMovement } = actions;
export default reducer;
