// client
import { gql } from '@apollo/client';

const FETCH_TAGS = gql`
  query Tags {
    Tags {
      id
      name
      color
    }
  }
`;

export default FETCH_TAGS;
