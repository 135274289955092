import { gql } from '@/__generated__';

const UPDATE_DEVICE_MATH_MODELING = gql(`#graphql
  mutation UpdateDeviceMathModeling($input: UpdateDeviceMathModelingInput!) {
    updateDeviceMathModeling(input: $input) {
      deviceId
      mathModeling {
        flowModel {
          expression
        }
      }
    }
  }
`);

export default UPDATE_DEVICE_MATH_MODELING;
