import { Grid, TextField } from '@mui/material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';

interface Props {
  dateTime: moment.Moment;
  setDateTime: (dateTime: moment.Moment) => void;
}

const DateTimePicker = ({ dateTime, setDateTime }: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Grid container spacing={1}>
        <Grid item>
          <DesktopDatePicker
            label="Fecha"
            inputFormat="DD/MM/YYYY"
            value={dateTime}
            onChange={(e) => setDateTime(e)}
            renderInput={(params) => (
              <TextField sx={{ width: '100%' }} {...params} helperText="" />
            )}
          />
        </Grid>
        <Grid item>
          <TimePicker
            label="Hora"
            value={dateTime}
            renderInput={(params) => <TextField {...params} />}
            onChange={(e) => setDateTime(e)}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default DateTimePicker;
