import { gql } from '@/__generated__';

const GET_USER_PREFERENCES_REGION_FACTORY_DEFAULTS = gql(`#graphql
  query GetUserPreferencesRegionFactoryDefaults {
    user {
      id
      preferences {
        id
        region(defaultValues: true) {
          hour12
          locale
          timezone
          numberLocale
          dateLocale
          dateMonthFormat
        }
      }
    }
  }
`);

export default GET_USER_PREFERENCES_REGION_FACTORY_DEFAULTS;
