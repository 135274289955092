import { gql } from '@/__generated__';

const GET_ACCESS_RATING_CURVES = gql(`#graphql
  query GetAccessRatingCurves($input: AuthorizationInput!) {
    authorization {
      authorizationDevice(input: $input) {
        deviceId
        updateRatingCurve
      }
    }
  }
`);

export default GET_ACCESS_RATING_CURVES;
