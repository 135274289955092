import { InputLabel, Select, SelectProps } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { FormControl, MenuItem } from '@mui/material';

type Props<T extends FieldValues> = SelectProps & {
  name: Path<T>;
  control: Control<T>;
  options: {
    value: string;
    label: string;
  }[];
  rules?: any;
  fullWidth?: boolean;
};

const FormSelect = <T extends FieldValues>(props: Props<T>) => {
  const { fullWidth, name, label, control, options, rules, ...rest } = props;
  const labelId = name + '-label';
  return (
    <FormControl fullWidth={fullWidth ?? true}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Select {...rest} labelId={labelId} label={label} onChange={onChange} value={value}>
            {options.map((o) => (
              <MenuItem id={`${o.value}`} key={o.value} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

export { FormSelect };
