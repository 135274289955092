import { useDispatch, useSelector } from '@/store';
import { closeUserProfileModal } from '../slices/userProfileSlice';
import { Dialog } from '@mui/material';
import { Suspense } from 'react';
import UserProfile from './UserProfile';
import UserProfileSkeleton from './UserProfileSkeleton';

const UserProfileModal = () => {
  const dispatch = useDispatch();
  const { userProfileModal } = useSelector((state) => state.user_profile_store);

  const handleClose = () => {
    dispatch(closeUserProfileModal());
  };

  return (
    <Dialog open={userProfileModal.open} onClose={handleClose} maxWidth="xs" fullWidth>
      <Suspense fallback={<UserProfileSkeleton />}>
        <UserProfile />
      </Suspense>
    </Dialog>
  );
};

export default UserProfileModal;
