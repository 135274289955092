import { Trans } from '@lingui/macro';
import { List, Typography, ListItem, ListItemAvatar, Skeleton, ListItemText } from '@mui/material';

const UserProfileAuditLogsSkeleton = () => (
  <>
    <Typography variant="h6" mt={1}>
      <Trans>Actividad reciente</Trans>
    </Typography>
    <List>
      {[0, 1, 2].map((item) => (
        <ListItem key={item} sx={{ py: 0 }}>
          <ListItemAvatar>
            <Skeleton variant="circular" width={34} height={34} />
          </ListItemAvatar>
          <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
        </ListItem>
      ))}
    </List>
  </>
);

export default UserProfileAuditLogsSkeleton;
