import { Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch } from 'src/store';
import useHasDebts from '../hooks/useHasDebts';
import { openModal } from '../slices/debtsAlertSlice';
import { Trans } from '@lingui/macro';

const DebtsAlertBanner = () => {
  const hasDebts = useHasDebts();
  const dispatch = useDispatch();

  if (!hasDebts) return <></>;

  const handleClickBanner = () => {
    dispatch(openModal());
  };

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'yellow',
        position: 'fixed',
        color: 'black',
        px: 1,
        textAlign: 'center',
        bottom: 0,
        cursor: 'pointer',
        zIndex: 10000,
      }}
      onClick={handleClickBanner}
    >
      <Typography noWrap sx={{ display: { xs: 'none', xl: 'block' } }}>
        <Trans>
          ¡Acción requerida! Esta cuenta en AMARU presenta un saldo pendiente. Para evitar
          limitaciones en el servicio, favor contáctenos al{' '}
          <Link href="tel:+56944580196" color="inherit">
            +56 9 9222 1736
          </Link>{' '}
          o enviar correo a{' '}
          <Link href="mailto:cs@captahydro.com" color="inherit">
            cs@captahydro.com
          </Link>
          .
        </Trans>
      </Typography>
      <Typography noWrap sx={{ display: { xs: 'none', md: 'block', xl: 'none' } }}>
        <Trans>
          ¡Acción requerida! Cuenta con saldo pendiente. Favor contactar al equipo comercial{' '}
          <Link color="inherit">aquí.</Link>
        </Trans>
      </Typography>
      <Typography noWrap sx={{ display: { xs: 'block', md: 'none' } }}>
        <Trans>
          Saldo pendiente. <Link color="inherit">Detalles aquí.</Link>
        </Trans>
      </Typography>
    </Box>
  );
};

export default DebtsAlertBanner;
