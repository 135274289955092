import { gql } from 'src/__generated__';

const SEND_ACCOUNT_CODE_EMAIL = gql(`
  mutation SendPasswordRecoveryEmail($input: sendPasswordRecoveryEmailInput!) {
    sendPasswordRecoveryEmail(input: $input) {
      success
      message
    }
  }
`);

export default SEND_ACCOUNT_CODE_EMAIL;
