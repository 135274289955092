import { useFormatters } from '@/hooks/useFormatters';
import { useSuspenseQuery } from '@apollo/client';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { SettingsInputAntenna } from '@mui/icons-material';
import { Avatar, Box, IconButton, Link, Skeleton, Tooltip, Typography } from '@mui/material';
import { Suspense, useState, type FC } from 'react';
import GET_DEVICE_PROFILE_INFO from '../../graphql/queries/getDeviceProfile';
import DeviceProfileStatus from './Status';
import { useFormatDeviceType } from 'src/hooks/useFormatDeviceType';
import ImagePreviewModal from 'src/components/ImagePreviewModal';

interface Props {
  deviceId: string;
}

const Component: FC<Props> = ({ deviceId }) => {
  const { _ } = useLingui();
  const { formatRelativeTime, formatDateTime } = useFormatters();
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const typeFormat = useFormatDeviceType(deviceId);
  const { data } = useSuspenseQuery(GET_DEVICE_PROFILE_INFO, {
    variables: {
      input: {
        id: deviceId,
      },
    },
  });

  const id = data.device.id;
  const picture = data.device.hardwareConfiguration?.images;
  const { name, organization, irrigation, project } = data.device.profile;
  const organizationName = organization?.name ?? null;
  const organizationId = organization?.id ?? null;
  const irrigationName = irrigation?.name ?? null;
  const irrigationId = irrigation?.id ?? null;
  const relativeCreated = formatRelativeTime(data.device.profile.created);

  return (
    <>
      {picture && (
        <ImagePreviewModal
          open={openImagePreview}
          onClose={() => setOpenImagePreview(false)}
          url={picture}
          title={_(msg`Imagen de perfil`)}
        />
      )}
      <Box
        width="100%"
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        gap={3}
        p={3}
        pl={4}
        alignItems="center"
      >
        {picture ? (
          <Tooltip title={_(msg`Ver imagen completa`)}>
            <IconButton onClick={() => setOpenImagePreview(true)}>
              <Avatar sx={{ width: 96, height: 96 }} src={picture} alt={_(msg`Imagen de perfil`)} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={_(msg`Sin imagen de perfil`)}>
            <Avatar sx={{ width: 96, height: 96, fontSize: 48 }}>
              <SettingsInputAntenna fontSize="inherit" />
            </Avatar>
          </Tooltip>
        )}
        <Box
          width="100%"
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          gap={2}
        >
          <Box>
            <Typography fontSize={22} fontWeight="bold" lineHeight={1.2} mb={{ xs: 1, sm: 0 }}>
              {name}
            </Typography>
            <Typography color="text.secondary" mb={1}>
              ID: <b>{id}</b>
            </Typography>
            <DeviceProfileStatus deviceId={deviceId} />
          </Box>

          <Box
            display="grid"
            columnGap={4}
            sx={{
              gridTemplate: {
                xs: 'repeat(6, 1fr) / 1fr',
                sm: 'repeat(3, auto) / repeat(2, 1fr)',
              },
              alignContent: 'center',
            }}
            gridAutoFlow="column"
          >
            <Typography color="text.secondary">
              <Trans>Tipo</Trans>: <b style={{ color: typeFormat.color }}>{typeFormat.text}</b>
            </Typography>
            <Tooltip
              title={
                data.device.profile.organization ? `${organizationId} - ${organizationName}` : ''
              }
            >
              <Typography color="text.secondary" maxWidth={250} noWrap>
                <Trans>Organización</Trans>: <b>{organizationName ?? '-'}</b>
              </Typography>
            </Tooltip>
            <Tooltip
              title={data.device.profile.irrigation ? `${irrigationId} - ${irrigationName}` : ''}
            >
              <Typography color="text.secondary" maxWidth={250} noWrap>
                <Trans>Irrigación</Trans>: <b>{irrigationName ?? '-'}</b>
              </Typography>
            </Tooltip>
            <Typography color="text.secondary">
              <Trans>Proyecto</Trans>: <b>{project ?? '-'}</b>
            </Typography>
            <Typography color="text.secondary">
              <Trans>Ubicación</Trans>:{' '}
              <b>
                {data.device.profile.location ? (
                  <Tooltip
                    title={`Lat: ${data.device.profile.location.latitude}, Lng: ${data.device.profile.location.longitude}`}
                  >
                    <Link
                      target="_blank"
                      href={`https://www.google.com/maps/search/?api=1&query=${data.device.profile.location.latitude},${data.device.profile.location.longitude}`}
                    >
                      <Trans>Ver en mapa</Trans>
                    </Link>
                  </Tooltip>
                ) : (
                  <Trans>Sin ubicación</Trans>
                )}
              </b>
            </Typography>
            <Tooltip title={formatDateTime(data.device.profile.created)}>
              <Typography color="text.secondary">
                <Trans>
                  Creado <b>{relativeCreated}</b>
                </Trans>
              </Typography>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const DeviceProfileInfoSkeleton: FC<Props> = ({ deviceId }) => (
  <Box
    width="100%"
    display="flex"
    flexDirection={{ xs: 'column', sm: 'row' }}
    gap={3}
    p={3}
    pl={4}
    alignItems="center"
  >
    <Box flexShrink={0}>
      <Skeleton variant="circular" width={96} height={96} />
    </Box>
    <Box width="100%" display="flex" justifyContent="space-between" gap={2}>
      <Box>
        <Typography fontSize={22} fontWeight="bold" lineHeight={1.2} mb={{ xs: 1, sm: 0 }}>
          <Skeleton width={200} />
        </Typography>
        <Typography color="text.secondary" mb={{ xs: 0, sm: 1 }}>
          <Skeleton width={100} />
        </Typography>
        <DeviceProfileStatus deviceId={deviceId} />
      </Box>

      <Box
        display="grid"
        columnGap={4}
        sx={{
          gridTemplate: {
            xs: 'repeat(6, 1fr) / 1fr',
            sm: 'repeat(3, auto) / repeat(2, 1fr)',
          },
          alignContent: 'center',
        }}
        gridAutoFlow="column"
      >
        {Array.from({ length: 6 }).map((_, index) => (
          <Typography key={index}>
            <Skeleton width={200} />
          </Typography>
        ))}
      </Box>
    </Box>
  </Box>
);

const DeviceProfileInfo: FC<Props> = (props) => (
  <ApolloErrorBoundary>
    <Suspense fallback={<DeviceProfileInfoSkeleton {...props} />}>
      <Component {...props} />
    </Suspense>
  </ApolloErrorBoundary>
);

export default DeviceProfileInfo;
