import { gql } from '@/__generated__';

const CREATE_DEVICE_REPORT = gql(`#graphql
  mutation CreateDeviceReport(
    $deviceId : String!,
    $messageReport : String!,
    $dateReport: String!,
  ) {
    createDeviceReport(
      deviceId : $deviceId
      messageReport : $messageReport
      dateReport: $dateReport
    ) {
      success
      message
    }
  }
`);

export default CREATE_DEVICE_REPORT;
