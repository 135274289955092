import { gql } from '@/__generated__';

const UPDATE_DGA_NOTIFICATION_CONFIGURATION = gql(`#graphql
  mutation UpdateDgaDeviceNotificationConfiguration(
    $input: UpdateDgaDeviceNotificationConfigurationInput!
  ) {
    updateDgaDeviceNotificationConfiguration(input: $input) {
      deviceId
      daysUntilFailureNotification
      failureNotificationFrequencyInDays
      users {
        notifyEmail
        user {
          id
          fullName
          email
        }
      }
    }
  }
`);

export default UPDATE_DGA_NOTIFICATION_CONFIGURATION;
