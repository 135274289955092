import { gql } from '@/__generated__';

const SEND_SLACK_MESSAGE = gql(/* GraphQL */ `
  mutation SendSlackMessage($input: SendSlackMessageInput!) {
    sendSlackMessage(input: $input) {
      response
    }
  }
`);

export default SEND_SLACK_MESSAGE;
