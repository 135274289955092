import type { FC } from 'react';

interface Props {
  style?: React.CSSProperties;
}

const LogoAmaru: FC<Props> = ({ style }) => (
  <img
    src="/static/amaru_logo.svg"
    alt="amaru-logo"
    id="amaru-logo"
    style={
      style ?? {
        maxHeight: '100%',
      }
    }
  />
);

export default LogoAmaru;
