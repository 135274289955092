import { gql } from '@apollo/client';

const FETCH_LAST_COMMENTS = gql`
  query FetchLastComments {
    FetchLastComments {
      contain
      component_id
      account_id
      modified
      created
      account {
        id
        name
        lastnames
        username
      }
      component {
        id
        name
        node_id
      }
    }
  }
`;

export default FETCH_LAST_COMMENTS;
