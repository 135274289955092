import { DeviceConfiguration_GetAccessFieldsQuery } from '@/__generated__/graphql';
import { useModalStatusMessage } from '@/hooks';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import ModalActions from '@components/modal/ModalActions';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Check, Deselect, SelectAll, Tune } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  DialogContent,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Fuse from 'fuse.js';
import { Suspense, useCallback, useEffect, useState, type FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FixedSizeList } from 'react-window';
import { getRoleName, getRoleColor } from '@/utils/roles';
import UPDATE_DEVICE_USERS_ACCESS from '../../graphql/mutations/updateDeviceUsersAccess';
import GET_UPDATE_DEVICE_ACCESS_FIELDS from '../../graphql/queries/getUpdateDeviceAccessFields';
import { DeviceConfigurationTabProps } from '../../types';

const getDefaultValues = (
  deviceId: string,
  users: DeviceConfiguration_GetAccessFieldsQuery['device']['users'],
) => {
  return {
    deviceId,
    users: users.map((user) => {
      return {
        id: user.user.id,
        hasAccess: user.hasAccess,
      };
    }),
  };
};

type FormValues = {
  deviceId: string;
  users: {
    id: number;
    hasAccess: boolean;
  }[];
};

enum Role {
  NoRole = 0,
  Capta = 1,
  Admin = 2,
  Operator = 3,
  Observer = 4,
}

interface FiltersMenuProps {
  accessFilter: boolean | null;
  setAccessFilter: (value: boolean | null) => void;
  roleFilter: Role | null;
  setRoleFilter: (value: Role | null) => void;
}

const FiltersMenu: FC<FiltersMenuProps> = ({
  accessFilter,
  setAccessFilter,
  roleFilter,
  setRoleFilter,
}) => {
  const { _ } = useLingui();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const Item = ({
    label,
    selected,
    onClick,
  }: {
    label: string;
    selected: boolean;
    onClick: () => void;
  }) => {
    return (
      <MenuItem onClick={onClick}>
        {selected ? (
          <>
            <ListItemIcon sx={{ mr: 0 }}>
              <Check fontSize="small" />
            </ListItemIcon>
            {label}
          </>
        ) : (
          <ListItemText inset>{label}</ListItemText>
        )}
      </MenuItem>
    );
  };

  return (
    <>
      <Button
        variant={accessFilter !== null || roleFilter !== null ? 'contained' : 'outlined'}
        size="large"
        color="info"
        onClick={handleClick}
      >
        <Tune />
      </Button>
      <Menu
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{ dense: true }}
      >
        <Item
          onClick={() => setAccessFilter(null)}
          label={_(msg`Todos`)}
          selected={accessFilter === null}
        />
        <Item
          onClick={() => setAccessFilter(true)}
          label={_(msg`Con acceso`)}
          selected={accessFilter === true}
        />
        <Item
          onClick={() => setAccessFilter(false)}
          label={_(msg`Sin acceso`)}
          selected={accessFilter === false}
        />
        <Divider />
        <Item
          onClick={() => setRoleFilter(null)}
          label={_(msg`Todos los roles`)}
          selected={roleFilter === null}
        />
        <Item
          onClick={() => setRoleFilter(Role.Admin)}
          label={_(msg`Administradores`)}
          selected={roleFilter === Role.Admin}
        />
        <Item
          onClick={() => setRoleFilter(Role.Operator)}
          label={_(msg`Operadores`)}
          selected={roleFilter === Role.Operator}
        />
        <Item
          onClick={() => setRoleFilter(Role.Observer)}
          label={_(msg`Observadores`)}
          selected={roleFilter === Role.Observer}
        />
      </Menu>
    </>
  );
};

const Component: FC<DeviceConfigurationTabProps> = ({ deviceId, handleClose, setDirtyForm }) => {
  const { _, i18n } = useLingui();
  const [search, setSearch] = useState('');
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const [accessFilter, setAccessFilter] = useState<boolean | null>(null);
  const [roleFilter, setRoleFilter] = useState<Role | null>(null);
  const [filteredUsers, setFilteredUsers] = useState<
    {
      dataIndex: number;
      id: number;
      fullName: string;
      email: string;
      role: Role;
      hasAccess: boolean;
    }[]
  >([]);
  const { data } = useSuspenseQuery(GET_UPDATE_DEVICE_ACCESS_FIELDS, {
    variables: { deviceId },
  });
  const [update] = useMutation(UPDATE_DEVICE_USERS_ACCESS, {
    onCompleted(data) {
      const defaultValues = getDefaultValues(deviceId, data.updateDeviceUsersAccess.users);
      methods.reset(defaultValues);
      openModalSuccessMessage(_(msg`Los usuarios con acceso al dispositivo han sido actualizados`));
    },
    onError(error) {
      openModalErrorMessage(error.message);
    },
  });

  const methods = useForm<FormValues>({
    defaultValues: getDefaultValues(deviceId, data.device.users),
  });

  const formUsers = methods.watch('users');
  const selectedUsers = formUsers.filter((user) => user.hasAccess);

  const getUserRole = (userId: number) => {
    const user = data.device.users.find((user) => user.user.id === userId);
    if (user) {
      const userOrganizations = [user.user.mainOrganization, ...user.user.additionalOrganizations];
      for (const userOrganization of userOrganizations) {
        if (!userOrganization) continue;
        if (userOrganization?.organization.id === data.device.profile.organization?.id) {
          return userOrganization.role.id;
        }
      }
    }
    return Role.NoRole;
  };

  const filterUsers = useCallback(() => {
    let filteredUsers = formUsers.flatMap((formUser, index) => {
      const dataUser = data.device.users.find(({ user }) => user.id === formUser.id);
      if (!dataUser) return [];
      const userRole = getUserRole(formUser.id);

      return {
        dataIndex: index,
        id: formUser.id,
        fullName: dataUser.user.fullName,
        email: dataUser.user.email,
        role: userRole,
        hasAccess: formUser.hasAccess,
      };
    });

    if (accessFilter !== null) {
      filteredUsers = filteredUsers.filter((user) => user.hasAccess === accessFilter);
    }
    if (roleFilter !== null) {
      filteredUsers = filteredUsers.filter((user) => user.role === roleFilter);
    }
    if (search) {
      const fuse = new Fuse(filteredUsers, {
        keys: ['id', 'fullName', 'email'],
      });
      filteredUsers = fuse.search(search).map(({ item }) => item);
    }
    setFilteredUsers(filteredUsers);
  }, [data, accessFilter, roleFilter, search]);

  useEffect(() => {
    filterUsers();
  }, [filterUsers]);

  // useEffect(() => {
  //   const subscription = methods.watch(() => {
  //     filterUsers();
  //   });
  //   return () => subscription.unsubscribe();
  // }, [methods.watch, filterUsers]);

  const onSelectAll = useCallback(() => {
    methods.setValue(
      'users',
      formUsers.map((user) => {
        if (filteredUsers.some((filteredUser) => filteredUser.id === user.id)) {
          return {
            ...user,
            hasAccess: true,
          };
        }
        return user;
      }),
      {
        shouldDirty: true,
      },
    );
  }, [filteredUsers]);

  const onDeselectAll = useCallback(() => {
    methods.setValue(
      'users',
      formUsers.map((user) => {
        if (filteredUsers.some((filteredUser) => filteredUser.id === user.id)) {
          return {
            ...user,
            hasAccess: false,
          };
        }
        return user;
      }),
      {
        shouldDirty: true,
      },
    );
  }, [filteredUsers]);

  useEffect(
    () => setDirtyForm(methods.formState.isDirty),
    [methods.formState.isDirty, setDirtyForm],
  );

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    update({ variables: { deviceId, users: data.users } });
  };

  const totalUsers = formUsers.length;
  const totalFilteredUsers = filteredUsers.length;
  const totalSelectedUsers = selectedUsers.length;
  const totalSelectedFilteredUsers = formUsers.filter(
    (user) => filteredUsers.some((filteredUser) => filteredUser.id === user.id) && user.hasAccess,
  ).length;
  const filtered = totalUsers !== totalFilteredUsers;

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <DialogContent
        sx={{
          maxWidth: 'md',
          minHeight: 500,
          margin: 'auto',
        }}
      >
        <Typography variant="h6" mb={2}>
          <Trans>Usuarios con acceso al dispositivo</Trans>
        </Typography>
        <Box
          mb={1}
          display="grid"
          alignItems="center"
          gridTemplateColumns="auto 1fr auto auto 1fr"
          gap={1}
        >
          <Tooltip title={_(msg`Ver filtros`)}>
            <FiltersMenu
              accessFilter={accessFilter}
              setAccessFilter={setAccessFilter}
              roleFilter={roleFilter}
              setRoleFilter={setRoleFilter}
            />
          </Tooltip>
          <TextField
            label={_(msg`Buscar`)}
            fullWidth
            size="small"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
          <Tooltip title={_(msg`Seleccionar todos los usuarios visibles`)}>
            <Button
              variant="outlined"
              color="info"
              size="large"
              disabled={selectedUsers.length === data.device.users.length}
              onClick={onSelectAll}
            >
              <SelectAll />
            </Button>
          </Tooltip>
          <Tooltip title={_(msg`Deseleccionar todos los usuarios visibles`)}>
            <Button
              variant="outlined"
              color="info"
              size="large"
              disabled={selectedUsers.length === 0}
              onClick={onDeselectAll}
            >
              <Deselect />
            </Button>
          </Tooltip>
          <Typography justifySelf="right">
            {filtered ? (
              <Trans>
                <b>{totalSelectedFilteredUsers}</b>{' '}
                <Typography color="textSecondary" component="span">
                  ({totalSelectedUsers})
                </Typography>{' '}
                seleccionados de <b>{totalFilteredUsers}</b>{' '}
                <Typography color="textSecondary" component="span">
                  ({totalUsers})
                </Typography>
              </Trans>
            ) : (
              <Trans>
                <b>{totalSelectedFilteredUsers}</b> seleccionados de <b>{totalFilteredUsers}</b>
              </Trans>
            )}
          </Typography>
        </Box>
        <Box border={1} borderColor="divider" borderRadius={1}>
          <FixedSizeList
            height={400}
            width="100%"
            itemSize={72}
            itemCount={filteredUsers.length}
            itemKey={(index) => filteredUsers[index].id}
          >
            {({ style, index }) => {
              const user = filteredUsers[index];
              return (
                <Controller
                  name={`users.${user.dataIndex}.hasAccess`}
                  control={methods.control}
                  render={({ field, fieldState }) => {
                    return (
                      <ListItem
                        disablePadding
                        style={style}
                        key={user.id}
                        sx={{
                          borderBottom: index !== filteredUsers.length - 1 ? '1px solid' : null,
                          borderColor: 'divider',
                        }}
                      >
                        <ListItemButton
                          onClick={() => {
                            field.onChange(!field.value);
                          }}
                        >
                          <ListItemText
                            primary={
                              <>
                                {user.id} - {user.fullName}
                                <Chip
                                  size="small"
                                  label={getRoleName(user.role, i18n)}
                                  sx={{
                                    backgroundColor: getRoleColor(user.role),
                                    color: 'black',
                                    ml: 1,
                                  }}
                                />
                              </>
                            }
                            secondary={user.email}
                          />
                          {fieldState.isDirty && (
                            <Typography fontSize="small" fontStyle="italic" color="text.secondary">
                              <Trans>(editado)</Trans>
                            </Typography>
                          )}
                          <ListItemIcon>
                            <Checkbox edge="end" checked={field.value} tabIndex={-1} />
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    );
                  }}
                />
              );
            }}
          </FixedSizeList>
        </Box>
      </DialogContent>
      <Box
        sx={{
          borderTop: '1px solid',
          borderColor: 'divider',
          pb: 0,
        }}
      >
        <ModalActions
          onClose={handleClose}
          onSubmit="submit"
          onResetForm={() => methods.reset()}
          submitLoading={methods.formState.isSubmitting}
          dirtyForm={methods.formState.isDirty}
        />
      </Box>
    </form>
  );
};

const AccessTab: FC<DeviceConfigurationTabProps> = (props) => {
  return (
    <ApolloErrorBoundary>
      <Suspense
        fallback={
          <DialogContent>
            <Skeleton height={500} variant="rounded" />
          </DialogContent>
        }
      >
        <Component {...props} />
      </Suspense>
    </ApolloErrorBoundary>
  );
};

export default AccessTab;
