import { useMutation } from '@apollo/client';
import { Trans, msg } from '@lingui/macro';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { type FC } from 'react';
import DELETE_DGA_CONFIGURATION from '../../graphql/mutations/deleteDgaConfiguration';
import { useModalStatusMessage } from '@/hooks';
import { useLingui } from '@lingui/react';
import { LoadingButton } from '@mui/lab';

interface Props {
  open: boolean;
  onClose: () => void;
  deviceId: string;
  onSuccesfulDelete: () => void;
}

const DeleteDGAConfigurationConfirmationModal: FC<Props> = ({
  open,
  onClose,
  deviceId,
  onSuccesfulDelete,
}) => {
  const { _ } = useLingui();
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();

  const [deleteDgaConfiguration, { loading }] = useMutation(DELETE_DGA_CONFIGURATION, {
    variables: { deviceId },
    onError: (error) => {
      openModalErrorMessage(error.message);
    },
    onCompleted: () => {
      openModalSuccessMessage(_(msg`Configuración DGA eliminada correctamente`));
      setTimeout(() => {
        onClose();
        onSuccesfulDelete();
      }, 100);
    },
    update(cache) {
      cache.modify({
        fields: {
          devicesConnection(_, { INVALIDATE }) {
            return INVALIDATE;
          },
          devices(_, { INVALIDATE }) {
            return INVALIDATE;
          },
        },
      });
      cache.modify({
        id: cache.identify({ __typename: 'Device', id: deviceId }),
        fields: {
          dgaConfiguration(_, { DELETE }) {
            return DELETE;
          },
        },
      });
    },
    awaitRefetchQueries: true,
    onQueryUpdated(observableQuery) {
      return observableQuery.refetch();
    },
  });

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Trans>Eliminar configuración DGA</Trans>
      </DialogTitle>
      <DialogContent dividers>
        <Trans>
          ¿Estás seguro que deseas eliminar la configuración DGA para el dispositivo {deviceId}?
        </Trans>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={onClose}>
          <Trans>Cancelar</Trans>
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="error"
          onClick={() => deleteDgaConfiguration()}
        >
          <Trans>Confirmar</Trans>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDGAConfigurationConfirmationModal;
