import { Canal } from './Canal';
import { ContributingCanal } from './ContributingCanal';
import { CanalAA } from './CanalAA';
import { DistributionData } from '../../types/canalManagerTypes';

class CanalManager {
  private canalAA: CanalAA;

  private canals: ContributingCanal[] = [];

  private riverFlow: number = 0;

  private dotacion: number = 0;

  private obligatoryDotacion: number = 0;

  constructor(
    riverFlow: number,
    canalAA: CanalAA,
    canals: ContributingCanal[],
  ) {
    this.riverFlow = riverFlow;
    this.canalAA = canalAA;
    this.canals = canals;
  }

  getFlowRightsAA(): number {
    return this.canalAA.getFlowWithDotacion(this.dotacion);
  }

  private getAllStocksOf(canalArray: Canal[]) {
    return canalArray.reduce((acc, canal) => acc + canal.stocks, 0);
  }

  /**
   * Get stocks from ALL the canals including the AA canal
   */
  getTotalStocks(): number {
    return this.getContributingCanalStocks() + this.canalAA.stocks;
  }

  getContributingCanalStocks(): number {
    return this.getAllStocksOf(this.canals);
  }

  calculateAndSetDotacion() {
    this.dotacion = this.riverFlow / this.getTotalStocks();
  }

  setStocksCanal(i: number, stocks: number) {
    this.canals[i].stocks = stocks;
  }

  getStocksCanal(i: number): number {
    return this.canals[i].stocks;
  }

  getObligatoryDotacion(): number {
    return this.obligatoryDotacion;
  }

  getDotacion(): number {
    return this.dotacion;
  }

  /**
   * Calculate distributions returns two objects: One the value of Canal AA, and the other the values for all canals
   * @returns
   */
  calculateDistribution(): DistributionData[] {
    // Set values as default
    this.obligatoryDotacion = 0;
    this.dotacion = 0;

    this.calculateAndSetDotacion();

    const flowNeededAA = this.canalAA.flowNeeded(this.dotacion);

    // In this case AA needs LESS flow than the one assigned by rights
    if (flowNeededAA < 0) {
      return this.getCanalsDistributionExtraFlowAA();
    }

    if (flowNeededAA === 0) {
      return this.getCanalsDistributionWithoutContributions();
    }

    const volunteerContributions =
      this.getVolunteerContributionsWithoutObligatory();

    if (volunteerContributions > 0) {
      return this.calculateDistributionCaseVolunteer();
    }

    return this.calculateDistributionCaseObligatory();
  }

  calculateDistributionCaseVolunteer(): DistributionData[] {
    const volunteerContributions =
      this.getVolunteerContributionsWithoutObligatory();
    const flowNeeded = this.canalAA.flowNeeded(this.dotacion);
    const enoughVolunteerContributions = volunteerContributions >= flowNeeded;

    if (!enoughVolunteerContributions) {
      return this.calculateDistributionCaseVolunteerObligatory();
    }

    // case only volunteer.
    const volunteerContributionProportion = flowNeeded / volunteerContributions;
    return this.getCanalsDistributionCaseVolunteer(
      volunteerContributionProportion,
    );
  }

  calculateDistributionCaseObligatory(): DistributionData[] {
    const flowToDistribute =
      this.calculateFlowRightsOf(this.canals) -
      this.canalAA.flowNeeded(this.dotacion);
    const obligatoryDotacion = this.calculateDotacionProRata(
      this.canals,
      flowToDistribute,
    );
    this.obligatoryDotacion = obligatoryDotacion;
    return this.getCanalsDistributionCaseObligatory();
  }

  getCanalsDistributionCaseObligatory() {
    const { canals, dotacion, obligatoryDotacion } = this;

    const mapFunction = (canal: ContributingCanal) => {
      const flowRights = canal.getFlowWithDotacion(dotacion);
      const volunteerContribution = 0;
      const obligatoryContribution = canal.getObligatoryContribution(
        dotacion,
        obligatoryDotacion,
      );
      const distributedFlow = flowRights - obligatoryContribution;
      return {
        flowRights: flowRights,
        volunteerContribution: volunteerContribution,
        obligatoryContribution: obligatoryContribution,
        distributedFlow: distributedFlow,
      };
    };

    return canals.map(mapFunction);
  }

  getCanalsDistributionWithoutContributions(): DistributionData[] {
    const { canals, dotacion } = this;

    const mapFunction = (canal: ContributingCanal) => {
      const flowRights = canal.getFlowWithDotacion(dotacion);
      return {
        flowRights: flowRights,
        volunteerContribution: 0,
        obligatoryContribution: 0,
        distributedFlow: flowRights,
      };
    };

    return canals.map(mapFunction);
  }

  getCanalsDistributionCaseVolunteer(
    volunteerContributionProportion: number,
  ): DistributionData[] {
    const { canals, dotacion } = this;

    const mapFunction = (canal: ContributingCanal) => {
      const flowRights = canal.getFlowWithDotacion(dotacion);
      const volunteerContribution =
        canal.getContributionsWithMinDotacion(dotacion) *
        volunteerContributionProportion;
      const distributedFlow = flowRights - volunteerContribution;
      return {
        flowRights: flowRights,
        volunteerContribution: volunteerContribution,
        obligatoryContribution: 0,
        distributedFlow: distributedFlow,
      };
    };

    return canals.map(mapFunction);
  }

  calculateFlowRightsOf(canals: Canal[]) {
    return canals.reduce(
      (acc, canal) => acc + canal.getFlowWithDotacion(this.dotacion),
      0,
    );
  }

  calculateDotacionProRata(arrayOfCanals: Canal[], flowToDistribute: number) {
    const stocks = this.getAllStocksOf(arrayOfCanals);
    return flowToDistribute / stocks;
  }

  getVolunteerContributionsWithoutObligatory(): number {
    const { canals, dotacion } = this;
    return canals.reduce(
      (acc, current) => acc + current.getContributionsWithMinDotacion(dotacion),
      0,
    );
  }

  calculateDistributionCaseVolunteerObligatory(): DistributionData[] {
    const flowNeededAA = this.canalAA.flowNeeded(this.dotacion);
    const flowToDistribute =
      this.calculateFlowRightsOf(this.canals) - flowNeededAA;
    const obligatoryDotacion = this.calculateDotacionProRata(
      this.canals,
      flowToDistribute,
    );
    this.obligatoryDotacion = obligatoryDotacion;

    let play = true;

    while (play) {
      const obligatoryCanals: ContributingCanal[] = [];
      let contributions = 0;

      this.canals.forEach((canal) => {
        const result = canal.compareCaseObligatory(this.obligatoryDotacion);
        if (result) {
          contributions += canal.getContributionsWithMinDotacion(this.dotacion);
        } else {
          obligatoryCanals.push(canal);
        }
      });

      if (contributions === 0) {
        this.obligatoryDotacion = obligatoryDotacion;
        return this.getCanalsDistributionCaseObligatory();
      }
      const newFlowToDistribute =
        this.calculateFlowRightsOf(obligatoryCanals) -
        flowNeededAA +
        contributions;
      const newObligatoryDotacion = this.calculateDotacionProRata(
        obligatoryCanals,
        newFlowToDistribute,
      );
      this.obligatoryDotacion = newObligatoryDotacion;

      // Check if we have to loop again
      let continueAlgorithm = false;
      obligatoryCanals.forEach((canal) => {
        if (canal.compareCaseObligatory(this.obligatoryDotacion)) {
          continueAlgorithm = true;
        }
      });

      if (!continueAlgorithm) {
        obligatoryCanals.forEach((canal) => {
          canal.useMinDotacion = false;
        });
      }

      play = continueAlgorithm;
    }
    return this.getCanalsDistributionCaseVolunteerObligatory();
  }

  getCanalsDistributionCaseVolunteerObligatory(): DistributionData[] {
    const { canals, dotacion, obligatoryDotacion } = this;
    const mapFunction = (canal: ContributingCanal) => {
      const flowRights = canal.getFlowWithDotacion(dotacion);
      const obligatoryContribution = canal.getObligatoryContribution(
        dotacion,
        obligatoryDotacion,
      );
      const volunteerContribution = Math.max(
        canal.getContributionsWithMinDotacion(dotacion) -
          obligatoryContribution,
        0,
      );
      const distributedFlow =
        flowRights - obligatoryContribution - volunteerContribution;
      return {
        flowRights: flowRights,
        volunteerContribution: volunteerContribution,
        obligatoryContribution: obligatoryContribution,
        distributedFlow: distributedFlow,
      };
    };

    return canals.map(mapFunction);
  }

  getCanalsDistributionExtraFlowAA() {
    const { canals, dotacion, riverFlow, canalAA } = this;

    const distributedDotacion =
      (riverFlow - canalAA.requiredFlow + canalAA.extraFlow) /
      this.getContributingCanalStocks();

    const mapFunction = (canal: ContributingCanal) => {
      const flowRights = canal.getFlowWithDotacion(dotacion);
      const distributedFlow = canal.getFlowWithDotacion(distributedDotacion);
      return {
        flowRights: flowRights,
        distributedFlow: distributedFlow,
        volunteerContribution: 0,
        obligatoryContribution: flowRights - distributedFlow,
      };
    };

    return canals.map(mapFunction);
  }
}

export { CanalManager };
