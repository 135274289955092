import { gql } from '@/__generated__';

const UPDATE_USER_LANGUAGE = gql(`#graphql
  mutation UpdateUserLanguage($input: UpdateUserPreferencesLanguageInput!) {
    updateUserPreferencesLanguage(input: $input) {
      id
      locale
      timezone
      numberLocale
      dateLocale
      hour12
    }
  }
`);

export default UPDATE_USER_LANGUAGE;
