import { SvgIcon, SvgIconProps } from '@mui/material';

function ControlCenterIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="m18.23,5.76c0-3-2.44-5.43-5.43-5.43s-5.43,2.44-5.43,5.43c0,.4,0,1.32.54,2.39l4.27,6.37c.14.21.37.33.62.33h0c.25,0,.48-.13.62-.34l4.26-6.45c.53-1.09.56-1.68.55-2.2v-.11Zm-3.52-.17c0,1.05-.86,1.91-1.91,1.91s-1.91-.86-1.91-1.91.86-1.91,1.91-1.91,1.91.86,1.91,1.91Z" />
      <path d="m21.68,20.96H2.64v-2l2.68-4.08,5.05,4.81c.32.31.8.38,1.2.18l3.21-1.62,2.74,1.6c.17.1.35.14.53.14.29,0,.58-.12.79-.35l3.63-4.08c.38-.43.35-1.1-.09-1.48-.43-.38-1.1-.34-1.48.09l-3.06,3.44-2.49-1.45c-.31-.18-.69-.19-1-.03l-3.06,1.55-5.43-5.17c-.23-.21-.54-.32-.84-.28-.31.04-.59.21-.76.47l-1.61,2.46V1.7c0-.58-.47-1.05-1.05-1.05s-1.05.47-1.05,1.05v20.31c0,.58.47,1.05,1.05,1.05h20.08c.58,0,1.05-.47,1.05-1.05s-.47-1.05-1.05-1.05Z" />
    </SvgIcon>
  );
}

export default ControlCenterIcon;
