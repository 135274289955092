import { RichTextReadOnly as RichTextReadOnlyTipTap } from 'mui-tiptap';
import { FC } from 'react';
import useExtensions from '../hooks/useExtensions';

interface Props {
  content: string;
}

const RichTextReadOnly: FC<Props> = ({ content }) => {
  const extensions = useExtensions();

  return <RichTextReadOnlyTipTap extensions={extensions} content={content} />;
};

export default RichTextReadOnly;
