import { useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import Loading from 'src/components/Loading';
import TableComponent from './Table';
import GET_GATE_MOVEMENTS from 'src/features/gateAdministration/graphql/queries/getGateMovementHistory';
import { Trans } from '@lingui/macro';

interface Props {
  gate: { id: string };
}

const QueryComponent = ({ gate }: Props) => {
  const { data, loading, error } = useQuery(GET_GATE_MOVEMENTS, {
    variables: { input: { id: gate.id } },
  });

  if (loading || !data) return <Loading />;

  if (error)
    return (
      <Typography color="error">
        <Trans>Algo salió mal</Trans>
      </Typography>
    );

  if (data.gate.movements == null) {
    return <></>;
  }
  
  return <TableComponent movements={data.gate.movements} />;
};

export default QueryComponent;
