import { useMutation, useSuspenseQuery } from '@apollo/client';
import { Clear, Notifications, NotificationsOff } from '@mui/icons-material';
import {
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Popper,
  Skeleton,
  Typography,
} from '@mui/material';
import { type FC, Fragment, Suspense, useEffect, useState } from 'react';
import { NotificationObjectEntityType, NotificationStatus } from '@/__generated__/graphql';
import { useDispatch } from '@/store';
import UPDATE_NOTIFICATION_STATUS from '../graphql/mutations/updateNotificationStatus';
import GET_NOTIFICATIONS from '../graphql/queries/getNotifications';
import NOTIFICATION_CREATED from '../graphql/subscriptions/notificationCreated';
import NOTIFICATION_DELETED from '../graphql/subscriptions/notificationDeleted';
import { entitiesHandlers } from '../utils/entitiesHandlers';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

const unreadNotificationStyle = {
  position: 'relative',
  '::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: 4,
    backgroundColor: '#1976d2',
  },
};

const NotificationsPopup = () => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  return (
    <>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div>
          <Suspense
            fallback={
              <IconButton disabled>
                <Badge badgeContent={0} color="primary">
                  <Notifications />
                </Badge>
              </IconButton>
            }
          >
            <NotificationsButton onClick={handleClick} />
          </Suspense>
          <Popper
            open={open}
            anchorEl={anchorEl}
            sx={{ zIndex: 9999 }}
            transition
            placement="bottom-end"
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper sx={{ border: 1, borderColor: 'divider' }}>
                  <Suspense
                    fallback={
                      <Paper sx={{ border: 1, borderColor: 'divider' }}>
                        <List
                          disablePadding
                          className="custom-scrollbar"
                          sx={{
                            maxHeight: 540,
                            overflow: 'auto',
                            width: 360,
                            padding: 2,
                            display: 'flex',
                            gap: 2,
                          }}
                        >
                          <Skeleton variant="rounded" height={40} />
                          <Skeleton variant="rounded" height={40} />
                          <Skeleton variant="rounded" height={40} />
                          <Skeleton variant="rounded" height={40} />
                        </List>
                      </Paper>
                    }
                  >
                    <NotificationsMenu onClose={() => setOpen(false)} />
                  </Suspense>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </ClickAwayListener>
    </>
  );
};

interface NotificationsButtonProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const NotificationsButton: FC<NotificationsButtonProps> = ({ onClick }) => {
  const { data, subscribeToMore } = useSuspenseQuery(GET_NOTIFICATIONS);

  useEffect(() => {
    subscribeToMore({
      document: NOTIFICATION_CREATED,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newNotification = subscriptionData.data.notificationCreated.notification;
        return {
          notifications: [
            newNotification,
            ...prev.notifications.filter((notification) => notification.id !== newNotification.id),
          ],
        };
      },
    });
    subscribeToMore({
      document: NOTIFICATION_DELETED,

      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const deletedNotification = subscriptionData.data.notificationDeleted.notification;
        return {
          ...prev,
          notifications: prev.notifications.filter(
            (notification) => notification.id !== deletedNotification.id,
          ),
        };
      },
    });
  }, []);

  return (
    <IconButton onClick={onClick}>
      <Badge
        badgeContent={
          data?.notifications.filter(
            (notification) =>
              notification.status === NotificationStatus.New ||
              notification.status === NotificationStatus.Unread,
          ).length
        }
        color="primary"
      >
        <Notifications />
      </Badge>
    </IconButton>
  );
};

interface NotificationsMenuProps {
  onClose: () => void;
}

const NotificationsMenu: FC<NotificationsMenuProps> = ({ onClose }) => {
  const { _ } = useLingui();
  const dispatch = useDispatch();
  const { data } = useSuspenseQuery(GET_NOTIFICATIONS);
  const [updateNotificationStatusMutate] = useMutation(UPDATE_NOTIFICATION_STATUS);

  const statusGroups = {
    [_(msg`Nuevos`)]: [NotificationStatus.New, NotificationStatus.Unread],
    [_(msg`Leídos`)]: [NotificationStatus.Read],
  };

  const updateNotificationStatus = async (id: number, status: NotificationStatus) => {
    await updateNotificationStatusMutate({
      variables: {
        input: {
          id,
          status,
        },
      },
      optimisticResponse: {
        updateNotificationStatus: {
          __typename: 'UpdateNotificationStatusPayload',
          notification: {
            __typename: 'Notification',
            id,
            status,
          },
        },
      },
    });
  };

  const handleDismissNotification = async (notification: (typeof data.notifications)[number]) => {
    await updateNotificationStatus(notification.id, NotificationStatus.Dismissed);
  };

  const handleViewNotification = async (notification: (typeof data.notifications)[number]) => {
    const { handleClickNotification } = entitiesHandlers[NotificationObjectEntityType.Announcement];
    dispatch(handleClickNotification(notification.notificationObject.entity.id));
    await updateNotificationStatus(notification.id, NotificationStatus.Read);
    onClose();
  };
  return (
    <Paper sx={{ border: 1, borderColor: 'divider' }}>
      {!data?.notifications.some(
        (notification) => notification.status !== NotificationStatus.Dismissed,
      ) && <NoNotifications />}
      <List
        disablePadding
        className="custom-scrollbar"
        sx={{
          maxHeight: 540,
          overflow: 'auto',
          width: 360,
          paddingBottom: 2,
        }}
      >
        {(Object.keys(statusGroups) as Array<keyof typeof statusGroups>).map((status) => {
          const groupedNotifications = data.notifications.filter((notification) => {
            const group = statusGroups[status];
            return group.includes(notification.status);
          });
          if (groupedNotifications.length === 0) return null;

          return (
            <Fragment key={status}>
              <ListSubheader sx={{ textTransform: 'uppercase' }}>{status}</ListSubheader>
              {groupedNotifications.map((notification, index) => (
                <Fragment key={notification.id}>
                  <ListItem
                    sx={{
                      scrollSnapAlign: 'start',
                      ...((notification.status === NotificationStatus.New ||
                        notification.status === NotificationStatus.Unread) &&
                        unreadNotificationStyle),
                    }}
                    disablePadding
                    secondaryAction={
                      <IconButton
                        onClick={() => handleDismissNotification(notification)}
                        size="small"
                        edge="end"
                        aria-label="dismiss"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    }
                  >
                    <ListItemButton onClick={() => handleViewNotification(notification)}>
                      <ListItemText
                        primary={notification.notificationObject.entity.title}
                        secondary={notification.notificationObject.entity.description}
                      />
                    </ListItemButton>
                  </ListItem>
                  {index !== groupedNotifications.length - 1 && <Divider />}
                </Fragment>
              ))}
            </Fragment>
          );
        })}
      </List>
    </Paper>
  );
};

const NoNotifications = () => (
  <Box
    px={2}
    pt={2}
    display="flex"
    flexDirection="column"
    alignItems="center"
    gap={2}
    color="text.secondary"
  >
    <NotificationsOff fontSize="large" />
    <Typography variant="body1" fontStyle="italic">
      <Trans>No hay notificaciones nuevas</Trans>
    </Typography>
  </Box>
);

export default NotificationsPopup;
