import { Box, Skeleton, ToggleButton } from '@mui/material';
import { Suspense, type FC, type TransitionStartFunction, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from '@/store';
import { setAvailableFields, toggleShowEvents } from '../../slices/devicesAnalysisSlice';
import Chart from './Chart';
import Sidebar from './sidebar';
import SidebarComparisonDevices from './sidebar/SidebarComparisonDevices';
import { MetricField } from '@/__generated__/graphql';
import { DevicesAnalysisDevice } from '../DevicesAnalysis';
import Toolbar from './Toolbar';
import { Trans } from '@lingui/macro';

import GET_ACCESS_EVENTS from 'src/graphql/querys/access/getAccessEvents';
import { useQuery } from '@apollo/client';

interface Props {
  devices: DevicesAnalysisDevice[];
  selectedDevice: DevicesAnalysisDevice;
  startTransition: TransitionStartFunction;
  isPending: boolean;
}

const ChartPanel: FC<Props> = ({ devices, selectedDevice, startTransition, isPending }) => {
  const { data: access } = useQuery(GET_ACCESS_EVENTS);
  const dispatch = useDispatch();
  const {
    events: { show: showEvents },
  } = useSelector((state) => state.devices_analysis_store);

  const toggleEvents = () => {
    startTransition(() => {
      dispatch(toggleShowEvents());
    });
  };

  useEffect(() => {
    if (devices) {
      const availableFields = new Set<MetricField>();
      devices.forEach((device) => {
        device.metrics.availableFields.forEach((field) => {
          if (field) availableFields.add(field);
        });
      });
      dispatch(setAvailableFields([...availableFields]));
    }
  }, [devices]);

  return (
    <>
      <Box
        p={1}
        sx={{
          gridArea: 'toolbar',
        }}
      >
        <Toolbar device={selectedDevice} startTransition={startTransition} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gridArea: 'sidebar',
          p: 1,
        }}
      >
        <Sidebar />
        <Box>
          <Suspense fallback={<Skeleton height={56} />}>
            <SidebarComparisonDevices />
          </Suspense>
          {access?.authorization.authorizationGeneral?.viewEvents && (
            <ToggleButton
              sx={{ mt: 2 }}
              fullWidth
              value="events"
              selected={showEvents}
              onChange={toggleEvents}
            >
              <Trans>Ver Eventos</Trans>
            </ToggleButton>
          )}
        </Box>
      </Box>
      <Box
        className={isPending ? 'pulse-opacity' : ''}
        sx={{
          gridArea: 'chart',
          p: 1,
          display: 'grid',
          height: '62vh',
          gridTemplateRows: 'minmax(0, 1fr)',
          gridTemplateColumns: 'minmax(0, 1fr)',
        }}
      >
        <Chart devices={devices} />
      </Box>
    </>
  );
};

export default ChartPanel;
