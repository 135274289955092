import { ApolloProvider } from '@apollo/client';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { type FC } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { Provider as ReduxProvider } from 'react-redux';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import ApolloErrorBoundary from './components/ApolloErrorBoundary';
import GlobalComponents from './components/GlobalComponents';
import { AuthProvider } from './contexts/JWTContext';
import { client } from './graphql';
import useScrollReset from './hooks/useScrollReset';
import routes from './routes';
import store from './store';
import { theme } from './theme';

const ApiContext = () => {
  useScrollReset();

  return (
    <ApolloProvider client={client}>
      <I18nProvider i18n={i18n}>
        <ApolloErrorBoundary>
          <ReduxProvider store={store}>
            <HelmetProvider>
              <StyledEngineProvider injectFirst>
                <AuthProvider>
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <GlobalComponents />
                      <Toaster position="top-center" />
                      <Outlet />
                    </ThemeProvider>
                  </StyledEngineProvider>
                </AuthProvider>
              </StyledEngineProvider>
            </HelmetProvider>
          </ReduxProvider>
        </ApolloErrorBoundary>
      </I18nProvider>
    </ApolloProvider>
  );
};

const App: FC = () => {
  const router = createBrowserRouter([
    {
      element: <ApiContext />,
      children: routes,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
