import { createSlice } from '@reduxjs/toolkit';
import { MetricField, MetricUnit } from '@/__generated__/graphql';
import { type SourceType } from '@features/events/data/types';
import type { AngleUnit, DistanceUnit, FlowUnit, VelocityUnit, VolumeUnit } from '@/utils/units';

export enum TelemetryKey {
  Flow = 'flow',
  FlowRaw = 'flowRaw',
  FlowUnit = 'flowUnit',
  Level = 'level',
  LevelRaw = 'levelRaw',
  LevelUnit = 'levelUnit',
  OriginalDistance = 'originalDistance',
  Connection = 'connectionState',
  Velocity = 'velocity',
  VelocityRaw = 'velocityRaw',
  SignalQuality = 'signalQuality',
  Angle = 'angle',
  Position = 'position',
  Upstream = 'upstreamLevel',
  Downstream = 'downstreamLevel',
  RatingCurvesLimits = 'ratingCurvesLimits',
  VolumeHour = 'volumeHour',
  VolumeDay = 'volumeDay',
  VolumeMonth = 'volumeMonth',
  AccumulatedVol = 'accumulatedVol',
  FlowAlerts = 'flowAlerts',
  LevelAlerts = 'levelAlerts',
  DistributionAlerts = 'distributionAlerts',
  BatterySoc = 'batterySoc',
  BatteryV = 'batteryV',
  ChargeI = 'chargeI',
  ChargeP = 'chargeP',
  SolarI = 'solarI',
  SolarP = 'solarP',
  SolarV = 'solarV',
  DGAFlow = 'dgaFlow',
  DGALevel = 'dgaLevel',
  DGAAccumulatedVol = 'dgaAccumulatedVol',
  DGALevelUnit = 'dgaLevelUnit',
  Temperature = 'temperature',
  TemperatureRaw = 'temperatureRaw',
  Conductivity = 'conductivity',
  ConductivityRaw = 'conductivityRaw',
  Ph = 'ph',
  PhRaw = 'phRaw',
  Orp = 'orp',
  OrpRaw = 'orpRaw',
  DissolvedOxygen = 'dissolvedOxygen',
  DissolvedOxygenRaw = 'dissolvedOxygenRaw',
  DissolvedOxygenSaturation = 'dissolvedOxygenSaturation',
  DissolvedOxygenSaturationRaw = 'dissolvedOxygenSaturationRaw',
  TurbidityNtu = 'turbidityNtu',
  TurbidityNtuRaw = 'turbidityNtuRaw',
  TurbidityFnu = 'turbidityFnu',
  TurbidityFnuRaw = 'turbidityFnuRaw',
  SnowLevel = 'snowLevel',
  SnowLevelRaw = 'snowLevelRaw',
  SnowTemperature = 'snowTemperature',
  SnowTemperatureRaw = 'snowTemperatureRaw',
  SnowRadiation = 'snowRadiation',
  SnowRadiationRaw = 'snowRadiationRaw',
  SnowWaterEquivalent = 'snowWaterEquivalent',
  SnowWaterEquivalentRaw = 'snowWaterEquivalentRaw',
  SnowWeight = 'snowWeight',
  SweNonCalibrated = 'snowWaterEquivalentNonCalibrated',
  SnowLevelNonCalibrated = 'snowLevelNonCalibrated',
}

export const initialState = {
  modalDisplayedStationChartId: null as string | null,
  events: {
    show: false,
    filter: 'all' as SourceType,
  },
  telemetry: {
    availableFields: [] as MetricField[],
    flow: true,
    flowUnit: undefined as FlowUnit | undefined,
    flowRaw: false,
    flowPrecision: 2,
    level: true,
    levelRaw: false,
    levelUnit: undefined as DistanceUnit | undefined,
    levelPrecision: 1,
    velocity: false,
    velocityRaw: false,
    velocityUnit: MetricUnit.MillimeterPerSecond as VelocityUnit,
    velocityPrecision: 2,
    angle: false,
    angleUnit: MetricUnit.Degree as AngleUnit,
    anglePrecision: 1,
    position: true,
    positionUnit: MetricUnit.Meter as DistanceUnit,
    positionPrecision: 2,
    upstreamLevel: false,
    downstreamLevel: false,
    originalDistance: false,
    originalDistanceUnit: MetricUnit.Meter as DistanceUnit,
    originalDistancePrecision: 2,
    connectionState: false,
    signalQuality: false,
    ratingCurvesLimits: false,
    volumeHour: false,
    volumeHourUnit: MetricUnit.CubicMeter as VolumeUnit,
    volumeHourPrecision: 1,
    volumeDay: false,
    volumeDayUnit: MetricUnit.CubicMeter as VolumeUnit,
    volumeDayPrecision: 1,
    volumeMonth: false,
    volumeMonthUnit: MetricUnit.CubicMeter as VolumeUnit,
    volumeMonthPrecision: 1,
    accumulatedVol: false,
    accumulatedVolUnit: MetricUnit.CubicMeter as VolumeUnit,
    accumulatedVolPrecision: 1,
    flowAlerts: false,
    levelAlerts: false,
    distributionAlerts: false,
    dgaFlow: false,
    dgaLevel: false,
    dgaAccumulatedVol: false,
    dgaLevelUnit: MetricUnit.Meter,
    batterySoc: false,
    batteryV: false,
    chargeI: false,
    chargeP: false,
    solarI: false,
    solarP: false,
    solarV: false,
    pollingInterval: 5 * 60 * 1000,
    [TelemetryKey.Temperature]: false,
    [TelemetryKey.TemperatureRaw]: false,
    [TelemetryKey.Conductivity]: false,
    [TelemetryKey.ConductivityRaw]: false,
    [TelemetryKey.Ph]: false,
    [TelemetryKey.PhRaw]: false,
    [TelemetryKey.Orp]: false,
    [TelemetryKey.OrpRaw]: false,
    [TelemetryKey.DissolvedOxygen]: false,
    [TelemetryKey.DissolvedOxygenRaw]: false,
    [TelemetryKey.DissolvedOxygenSaturation]: false,
    [TelemetryKey.DissolvedOxygenSaturationRaw]: false,
    [TelemetryKey.TurbidityNtu]: false,
    [TelemetryKey.TurbidityNtuRaw]: false,
    [TelemetryKey.TurbidityFnu]: false,
    [TelemetryKey.TurbidityFnuRaw]: false,
    [TelemetryKey.SnowLevel]: false,
    [TelemetryKey.SnowLevelRaw]: false,
    [TelemetryKey.SnowTemperature]: false,
    [TelemetryKey.SnowTemperatureRaw]: false,
    [TelemetryKey.SnowRadiation]: false,
    [TelemetryKey.SnowRadiationRaw]: false,
    [TelemetryKey.SnowWaterEquivalent]: false,
    [TelemetryKey.SnowWaterEquivalentRaw]: false,
    [TelemetryKey.SnowWeight]: false,
    [TelemetryKey.SweNonCalibrated]: false,
    [TelemetryKey.SnowLevelNonCalibrated]: false,
  },
  parameters: {
    deviceId: null as string | null,
    comparisonDeviceIds: [] as string[],
    start: '-12h',
    stop: null as string | null,
    window: null as string | null,
    pollingInterval: 5 * 60 * 1000,
  },
};

export enum ParametersKey {
  Start = 'start',
  Stop = 'stop',
  Window = 'window',
  PollingInterval = 'pollingInterval',
}

const devicesAnalysisSlice = createSlice({
  name: 'devices_analysis_store',
  initialState,
  reducers: {
    setModalDisplayedStationChart(state, action: { payload: string | null }) {
      state.modalDisplayedStationChartId = action.payload;
    },
    toggleShowEvents(state, action: { payload: boolean | undefined }) {
      state.events.show = action.payload ?? !state.events.show;
    },
    setEventsFilter(state, action: { payload: SourceType }) {
      state.events.filter = action.payload;
    },
    toggleTelemetry(
      state,
      action: {
        payload:
          | Exclude<
              TelemetryKey,
              TelemetryKey.FlowUnit | TelemetryKey.LevelUnit | TelemetryKey.DGALevelUnit
            >
          | Partial<(typeof initialState)['telemetry']>;
      },
    ) {
      if (typeof action.payload === 'string') {
        const telemetryKey = action.payload;
        if (typeof state.telemetry[telemetryKey] === 'boolean')
          state.telemetry[telemetryKey] = !state.telemetry[telemetryKey];
        return;
      }
      state.telemetry = {
        ...state.telemetry,
        ...action.payload,
      };
    },
    setAvailableFields(state, action: { payload: MetricField[] }) {
      state.telemetry.availableFields = action.payload;
    },
    setParameters(
      state,
      action: {
        payload: Partial<(typeof initialState)['parameters']>;
      },
    ) {
      state.parameters = {
        ...state.parameters,
        ...action.payload,
      };
    },
    setInitialStateFromSearchParams(
      state,
      action: { payload: Omit<typeof initialState, 'modalDisplayedStationChartId'> },
    ) {
      state.events = action.payload.events;
      state.telemetry = action.payload.telemetry;
      state.parameters = action.payload.parameters;
    },
  },
});

const { actions, reducer } = devicesAnalysisSlice;

export const {
  setModalDisplayedStationChart,
  setAvailableFields,
  setEventsFilter,
  setParameters,
  toggleTelemetry,
  toggleShowEvents,
  setInitialStateFromSearchParams,
} = actions;
export default reducer;
