import {
  Box,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import AUTHENTICATE_USER_TWO_FACTORS from '@features/login/graphql/mutations/authenticateTwoFactors';
import AUTHENTICATE_USER from '@features/login/graphql/mutations/authenticate';

import { enc, iv } from 'src/utils/encrypt';
import useAuth from 'src/hooks/useAuth';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaLogin, schemaLoginTwoFactor } from '@features/login/utils/schema'
import { FormInputText } from 'src/components/form';

const LogRocketKey = import.meta.env.VITE_REACT_APP_LOGROCKET_ID;

if (LogRocketKey) {
  LogRocket.init(LogRocketKey);
  setupLogRocketReact(LogRocket);
}

const initialState = {
  iv: null,
  code: null,
  name: '',
  lastnames: '',
  login: false,
  email: '',
};

type props = {
  state: any;
  setState: (state: any) => void;
};

const initialValuesLogin = {
  email: '',
  password: '',
  submit: null,
}

const initialValuesTwoFactor = {
  code: '',
  email: '',
  password: '',
  submit: null,
}

const Login = ({ state, setState }: props) => {
  const [customErrors, setCustomErrors] = useState({
    submit: null,
  });
  const [count, setCount] = useState(0);
  const [stateLogin, setStateLogin] = useState({ email: '', password: ''});
  const { login } = useAuth();
  const [AuthenticateTwoFactor, { loading }] = useMutation(AUTHENTICATE_USER_TWO_FACTORS, {
    onCompleted: (data) => {
      const user = data?.authenticateUserTwoFactors;
      const token = data?.authenticateUserTwoFactors?.token;

      if (token && user) {
        if (LogRocketKey) {
          LogRocket.identify(user.id, {
            name: `${user.name ? user.name + ' ' : ''}${
              user.lastnames ? user.lastnames : ''
            }`,
            email: user.username ? user.username : '',
          });
        }
        login(user);
      }

      setCustomErrors({ submit: 'Código no válido!' });
    },
    onError: (error) => {
      setCustomErrors({ submit: error.message })
    }
  });
  const [Authenticate, { loading: loadingLogin }] = useMutation(AUTHENTICATE_USER, {
      onCompleted: (data) => { 
        const user = data?.authenticateUser?.user ?? null;
        const tokens = data?.authenticateUser?.user?.token ?? null;

        if (user && tokens) {
          if (LogRocketKey) {
            LogRocket.identify(user.id, {
              name: `${user.name ? user.name + ' ' : ''}${
                user.lastnames ? user.lastnames : ''
              }`,
              email: user.username ? user.username : '',
            });
          }
          login(user);
        }

        setTimeout(
          () => {
            setState(initialState);
          },
          1000 * 60 * 60,
        );
        setState({
          ...initialState,
          name: data?.authenticateUser?.name,
          lastnames: data?.authenticateUser?.lastnames,
          login: true,
        });
        setCustomErrors({ submit: '' });
      },
      onError: (error) => {
        setCustomErrors({ submit: error.message })
      }
    });

  useEffect(() => {
    if (count >= 4) {
      setState(initialState);
      setCount(0);
      setCustomErrors({ submit: '' });
    }
  }, [count]);

  const {
    control: controlTwoFactor,
    handleSubmit: handleSubmitTwoFactor,
    formState: { errors: errorsTwoFactor, isSubmitting: isSubmittingTwoFactor },
  } = useForm({
    defaultValues: initialValuesTwoFactor,
    resolver: yupResolver(schemaLoginTwoFactor)
  });

  const submitTwoFactorHandler = async (values) => {
    try {
      const password = enc(stateLogin.password);
      setCount(count + 1);
      AuthenticateTwoFactor({
        variables: {
          email: stateLogin.email,
          password,
          iv: iv.toString('hex'),
          code: values?.code,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: initialValuesLogin,
    resolver: yupResolver(schemaLogin)
  });

  const submitHandler = async (values) => {
    const password = enc(values.password);
    const inputIv = iv.toString('hex');
    setStateLogin({ email: values.email, password: values.password })
    
    Authenticate({
      variables: {
        email: values.email,
        password,
        iv: inputIv,
      },
    });
  }

  return (
    <>
      {!(state?.login ?? null) ? (
        <>
          <form onSubmit={handleSubmit(submitHandler)}>
            {!(state?.login ?? null) && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormInputText
                    name="email"
                    control={control}
                    label="Correo electrónico"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormInputText
                    name='password'
                    control={control}
                    label='Contraseña'
                    type='password'
                  />
                </Grid>
                {customErrors.submit && (
                  <Grid item xs={12}>
                    <FormHelperText error>
                      {customErrors.submit}
                    </FormHelperText>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <LoadingButton
                    loading={loadingLogin}
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Iniciar sesión
                  </LoadingButton>
                </Grid>
              </Grid>
            )}
          </form>
        </>
      ) : (
        <>
          <form onSubmit={handleSubmitTwoFactor(submitTwoFactorHandler)}>
            <Controller
              name="code"
              control={controlTwoFactor}
              render={({ field }) => (
                <TextField
                  {...field}
                  autoFocus
                  fullWidth
                  label="Código de autenticación"
                  type="text"
                  margin="normal"
                  variant="outlined"
                  error={!!errorsTwoFactor?.code}
                  helperText={errorsTwoFactor?.code?.message}
                />
              )}
            />
            {customErrors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{customErrors.submit}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 2 }}>
              <LoadingButton
                loading={isSubmittingTwoFactor}
                color="primary"
                disabled={loading}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Enviar código
              </LoadingButton>
            </Box>
          </form>
          <br />
          <Box>
            <Typography color="textSecondary" variant="body2">
              El código enviado tiene un tiempo de expedición
            </Typography>
          </Box>
          {count > 0 && (
            <Box>
              <Typography color="textSecondary" variant="body2">
                Número de intentos válidos por realizar{' '}
                <span style={{ fontWeight: '900' }}>{4 - count}</span>
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default Login;
