import { StandardAutocomplete, type StandardAutocompleteValue } from '@features/standardDesign';

const OrganizationsAutocomplete = (
  props: Omit<
    Parameters<typeof StandardAutocomplete<'organizations'>>[0],
    'dataType' | 'onChange'
  > & {
    onChange: (organization: StandardAutocompleteValue<'organizations'> | null | undefined) => void;
  },
) => (
  <StandardAutocomplete
    {...props}
    onChange={(_, value) => {
      props.onChange(value);
    }}
    dataType="organizations"
  />
);

export default OrganizationsAutocomplete;
