import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { DevicesOrderBy } from 'src/__generated__/graphql';
import type { AppThunk } from 'src/store';

interface IntegrationsState {
  filters: {
    search: string;
    page: number;
    rowsPerPage: number;
    source_info: string | null;
    source: string | null;
    orderBy: DevicesOrderBy;
  };
}

const initialState: IntegrationsState = {
  filters: {
    search: '',
    page: 1,
    rowsPerPage: 10,
    source_info: null,
    source: null,
    orderBy: DevicesOrderBy.NameAsc,
  },
};

const slice = createSlice({
  name: 'integrations_state',
  initialState,
  reducers: {
    restore() {
      return initialState;
    },
    setIntegrationSourceFilter: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.filters.source_info = action.payload;
      state.filters.page = 1;
    },
    setSearchValueFilter: (state, action: PayloadAction<string>) => {
      state.filters.search = action.payload;
      state.filters.page = 1;
    },
    setPageFilter: (state, action: PayloadAction<number>) => {
      state.filters.page = action.payload;
    },
    setRowsPerPageFilter: (state, action: PayloadAction<number>) => {
      state.filters.rowsPerPage = action.payload;
    },
    setOrderByFilter: (state, action: PayloadAction<string>) => {
      state.filters.orderBy = action.payload;
    },
  },
});

export const { reducer } = slice;

export const restoreComponentStore = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.restore());
};

export const setIntegrationSourceFilter =
  (integrationType: string | null): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setIntegrationSourceFilter(integrationType));
  };

export const setPageFilter =
  (page: number): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setPageFilter(page));
  };

export const setRowsPerPageFilter =
  (rowPerPage: number): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setRowsPerPageFilter(rowPerPage));
  };

export const setOrderByFilter =
  (orderBy: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setOrderByFilter(orderBy));
  };

export const setSearchValueFilter =
  (search: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSearchValueFilter(search));
  };
