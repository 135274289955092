import { useDispatch, useSelector } from '@/store';
import CheckboxList from './CheckboxList';
import CheckboxListItem from './CheckboxListItem';
import { useAnalyticsEventTracker } from 'src/gaEvents';
import { MetricField } from '@/__generated__/graphql';
import { type FC } from 'react';
import { TelemetryKey, toggleTelemetry } from '../../../slices/devicesAnalysisSlice';
import { useLingui } from '@lingui/react';
import { msg } from '@lingui/macro';

const SidebarAlertLevelsCheckboxList: FC = () => {
  const dispatch = useDispatch();
  const { _ } = useLingui();
  const { telemetry } = useSelector((state) => state.devices_analysis_store);
  const { checkBoxEvent } = useAnalyticsEventTracker('Station Analysis');

  return (
    <CheckboxList>
      {telemetry.availableFields.includes(MetricField.FlowAlerts) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.FlowAlerts]}
          onClick={() => {
            checkBoxEvent({ label: 'flow-alerts', value: !telemetry[TelemetryKey.FlowAlerts] });
            dispatch(
              toggleTelemetry({
                [TelemetryKey.FlowAlerts]: !telemetry[TelemetryKey.FlowAlerts],
                ...(!telemetry[TelemetryKey.FlowAlerts] && {
                  [TelemetryKey.Flow]: true,
                }),
              }),
            );
          }}
          label={_(msg`Caudal`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.LevelAlerts) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.LevelAlerts]}
          onClick={() => {
            checkBoxEvent({ label: 'level-alerts', value: !telemetry[TelemetryKey.LevelAlerts] });
            dispatch(
              toggleTelemetry({
                [TelemetryKey.LevelAlerts]: !telemetry[TelemetryKey.LevelAlerts],
                ...(!telemetry[TelemetryKey.LevelAlerts] && {
                  [TelemetryKey.Level]: true,
                }),
              }),
            );
          }}
          label={_(
            msg({
              message: 'Nivel',
            }),
          )}
        />
      )}
      {telemetry.availableFields.includes(MetricField.DistributionAlerts) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.DistributionAlerts]}
          onClick={() => {
            checkBoxEvent({
              label: 'distribution-alerts',
              value: !telemetry[TelemetryKey.DistributionAlerts],
            });
            dispatch(
              toggleTelemetry({
                [TelemetryKey.DistributionAlerts]: !telemetry[TelemetryKey.DistributionAlerts],
                ...(!telemetry[TelemetryKey.DistributionAlerts] && {
                  [TelemetryKey.Flow]: true,
                }),
              }),
            );
          }}
          label={_(msg`Reparto`)}
        />
      )}
    </CheckboxList>
  );
};

export default SidebarAlertLevelsCheckboxList;
