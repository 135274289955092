import { useDispatch, useSelector } from '@/store';
import Close from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';
import EventsTable from '@features/events/components/EventsTable';
import { setModalDisplayedStationChart, setParameters } from '../slices/devicesAnalysisSlice';
import { Suspense, useEffect } from 'react';
import DevicesAnalysis from './DevicesAnalysis';
import LastData from './LastData';
import Panel from './panel';
import ApolloErrorBoundary from 'src/components/ApolloErrorBoundary';

const ModalSkeleton = ({ handleClose }: { handleClose: () => void }) => (
  <>
    <DialogTitle
      variant="h6"
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
        <Close />
      </IconButton>
      <Box pl={2} flexGrow={1}>
        <Skeleton width={300} />
        <Typography variant="subtitle2" color="text.secondary">
          <Skeleton width={100} />
        </Typography>
      </Box>
      <Skeleton height={65} variant="rounded" width="15.5rem" />
    </DialogTitle>
    <DialogContent>
      <Skeleton height={600} variant="rounded" width="100%" />
    </DialogContent>
  </>
);

const DevicesAnalysisViewModal = () => {
  const { modalDisplayedStationChartId: id } = useSelector((state) => state.devices_analysis_store);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(setModalDisplayedStationChart(null));
  };

  useEffect(() => {
    if (id == null)
      dispatch(
        setParameters({
          deviceId: null,
        }),
      );
    else dispatch(setParameters({ deviceId: id }));
  }, [id]);

  return (
    <Dialog maxWidth="lg" fullWidth open={id != null} onClose={closeModal}>
      <ApolloErrorBoundary>
        <Suspense fallback={<ModalSkeleton handleClose={closeModal} />}>
          <DevicesAnalysis>
            {({
              selectedDevice,
              devices,
              handleRefetch,
              isPending,
              startTransition,
              showEventsTable,
              isTestingDevice,
            }) => (
              <>
                <DialogTitle
                  variant="h6"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <IconButton edge="start" color="inherit" onClick={closeModal} aria-label="close">
                    <Close />
                  </IconButton>
                  {selectedDevice && (
                    <Box
                      gap={2}
                      flexGrow={1}
                      pl={2}
                      display="flex"
                      justifyContent="space-between"
                      flexDirection={{
                        xs: 'column',
                        sm: 'row',
                      }}
                    >
                      <Box flexGrow={1}>
                        {selectedDevice.profile.name}
                        <Typography variant="subtitle2" color="text.secondary">
                          ID: {selectedDevice?.id}
                        </Typography>
                      </Box>
                      <Box width="15.5rem" display="flex">
                        <LastData
                          refetch={handleRefetch}
                          isPending={isPending}
                          device={selectedDevice}
                        />
                      </Box>
                    </Box>
                  )}
                </DialogTitle>
                <DialogContent sx={{ p: 1 }}>
                  {selectedDevice && devices && (
                    <Box
                      display="grid"
                      gridTemplateColumns={{
                        xs: 'minmax(0, 1fr)',
                        md: 'minmax(0, 1fr) 18rem',
                      }}
                      gridTemplateRows={{
                        xs: 'auto minmax(0, 1fr) auto',
                        md: 'auto minmax(0, 1fr)',
                      }}
                      gridTemplateAreas={{
                        xs: `"toolbar" "chart" "sidebar"`,
                        md: `"toolbar sidebar""chart sidebar"`,
                      }}
                      alignItems="stretch"
                      gap={2}
                      bgcolor={isTestingDevice ? 'background.opacity' : 'background.paper'}
                    >
                      <Panel
                        devices={devices}
                        selectedDevice={selectedDevice}
                        startTransition={startTransition}
                        isPending={isPending}
                      />
                    </Box>
                  )}
                  {selectedDevice && selectedDevice && showEventsTable && (
                    <EventsTable deviceId={selectedDevice.id} />
                  )}
                </DialogContent>
              </>
            )}
          </DevicesAnalysis>
        </Suspense>
      </ApolloErrorBoundary>
    </Dialog>
  );
};

export default DevicesAnalysisViewModal;
