import { useState } from 'react';
import type { FC } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import useAccess from 'src/hooks/useAccess';
import { useLingui } from '@lingui/react';
import { getLanguageOptions } from '@/utils/i18n/getLanguageOptions';
import { useI18n } from '@/hooks/useI18n';

const LanguagePopover: FC = () => {
  const { i18n } = useLingui();
  const options = getLanguageOptions(i18n.locale);
  const { language, updateLanguage } = useI18n();

  // TODO: eliminar cuando se pase a producción "idiomas"
  const { isCaptaUser } = useAccess();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const selectedIndex = options.findIndex(({ value }) => value === language);

  const open = Boolean(anchorEl);

  const handleClickButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (_: React.MouseEvent<HTMLElement>, index: number) => {
    if (index === selectedIndex) return;
    updateLanguage(options[index].value);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* TODO: eliminar cuando se pase a producción "idiomas" */}
      {isCaptaUser ? (
        <IconButton size="large" onClick={handleClickButton}>
          <img width={20} src={options[selectedIndex].icon} />
        </IconButton>
      ) : (
        <IconButton size="large">
          <img width={20} src={options[selectedIndex].icon} />
        </IconButton>
      )}
      <Menu
        elevation={0}
        slotProps={{
          paper: {
            variant: 'outlined',
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.value}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            <img width={18} src={option.icon} style={{ marginRight: 8 }} />
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguagePopover;
