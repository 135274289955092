import ReactGA from 'react-ga4';

/**
 * Interface representing the properties of a Google Analytics event.
 */
/**
 * Represents the properties of an event to be tracked in Google Analytics.
 */
interface EventProps {
    /** The section of Amaru where the event occurred. */
    event_section: string;
    /** A label describing the event. */
    event_label: string;
    /** An optional value associated with the event. */
    event_value?: string | number | boolean;
    /** The type of user who triggered the event. */
    event_user_type?: 'capta' | 'client';
}

const Event = (eventName: string, eventProps: EventProps) => {
    ReactGA.event(eventName, eventProps);
}

export default Event;