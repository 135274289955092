import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePaginationActions from '@components/TablePaginationActions';
import { useState } from 'react';
import { Box } from '@mui/system';
import { useQuery } from '@apollo/client';
import GET_ACCESS_MOVEMENT_HISTORY from 'src/graphql/querys/access/getAccessMovementHistory';
import { Trans } from '@lingui/macro';
import { useFormatters } from '@/hooks/useFormatters';
import { GateMovements } from 'src/features/gateAdministration/graphql/queries/getGateMovementHistory';

interface Props {
  movements: GateMovements;
}

const TableComponent = ({ movements }: Props) => {
  const { data: access } = useQuery(GET_ACCESS_MOVEMENT_HISTORY)
  const { formatDateTime, formatMetricValue } = useFormatters();

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const onChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
  };

  const { unit, precision } = movements;

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Solicitado por</Trans>
              </TableCell>
              <TableCell align="right">
                <Trans>Fecha</Trans>
              </TableCell>
              <TableCell align="right">
                <Trans>Posición solicitada</Trans>
              </TableCell>
              <TableCell align="right">
                <Trans>Posición de inicio</Trans>
              </TableCell>
              <TableCell align="right">
                <Trans>Posición final</Trans>
              </TableCell>
              <TableCell align="right">
                <Trans>Mensaje</Trans>
              </TableCell>
              <TableCell align="right">
                <Trans>Fecha Inicio</Trans>
              </TableCell>
              <TableCell align="right">
                <Trans>Fecha Finalización</Trans>
              </TableCell>
              {access?.authorization.authorizationGeneral?.viewGateMovementAdvancedFields && (
                <TableCell align="right">
                  <Trans>Request ID</Trans>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {movements.data.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((movement) => {
              const {
                id,
                startPosition,
                startTimestamp,
                finishPosition,
                finishTimestamp,
                requestedPosition,
                userName,
              } = movement;

              return (
                <TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="item">
                    {userName}
                  </TableCell>
                  <TableCell align="right">{formatDateTime(movement.timestamp)}</TableCell>
                  <TableCell align="right">
                    {requestedPosition
                      ? formatMetricValue(requestedPosition, { unit, precision })
                      : '-'}
                  </TableCell>
                  <TableCell align="right">
                    {startPosition ? formatMetricValue(startPosition, { unit, precision }) : '-'}
                  </TableCell>
                  <TableCell align="right">
                    {finishPosition ? formatMetricValue(finishPosition, { unit, precision }) : '-'}
                  </TableCell>
                  <TableCell align="right">{movement.message}</TableCell>
                  <TableCell align="right">
                    {startTimestamp ? formatDateTime(startTimestamp) : '-'}
                  </TableCell>
                  <TableCell align="right">
                    {finishTimestamp ? formatDateTime(finishTimestamp) : '-'}
                  </TableCell>
                  {access?.authorization.authorizationGeneral?.viewGateMovementAdvancedFields && <TableCell align="right">{id}</TableCell>}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePaginationActions
        page={page}
        rowsPerPage={rowsPerPage}
        count={movements.data.length}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </Box>
  );
};

export default TableComponent;
