import { gql } from '@apollo/client';

const UPLOAD_KML = gql`
  mutation UploadS3Kml(
    $contentType: String
    $key: String
    $bucket: String!
    $region: String
  ) {
    UploadS3file(
      contentType: $contentType
      key: $key
      bucket: $bucket
      region: $region
    ) {
      fileName
      signedURL
      uuid
    }
  }
`;

export default UPLOAD_KML;
