import { useQuery } from '@apollo/client';
import FETCH_DEVICE_NAMING from 'src/graphql/querys/fetchDeviceNaming';

const useDeviceNaming = (serie?: string) => {
  const { data = {}, loading, refetch } = useQuery(FETCH_DEVICE_NAMING, {
    fetchPolicy: 'cache-first',
    variables: {
      serie: serie,
    }
  });
  
  const { deviceNaming = null } = data;
  const types = deviceNaming ?? [];

  return { types, loading, refetch };
};

export default useDeviceNaming;
