import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { Box, Chip, Link } from '@mui/material';
import { useState, type FC } from 'react';
import { ActionType } from '@/__generated__/graphql';
import { getActionTypeOptions } from '@features/audit/utils';
import DeviceProfileRecentActivity from '../modal/RecentActivity';
import { SectionTitle } from './General';

interface Props {
  deviceId: string;
}

const availableActions = [
  null,
  ActionType.UpdateDeviceStatus,
  ActionType.UpdateDeviceAlerts,
  ActionType.UpdateRatingCurves,
];

const DeviceProfileAudit: FC<Props> = ({ deviceId }) => {
  const { _, i18n } = useLingui();
  const [selectedAction, setSelectedAction] = useState<ActionType | null>(null);
  const actionTypeOptions = getActionTypeOptions(i18n);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <SectionTitle id="audit" title={_(msg`Registro de cambios`)} />
      <Box>
        <Box mb={2} display="flex" gap={1.5}>
          {availableActions.map((action) => {
            const selected = action === selectedAction;
            const label = !action
              ? _(msg`Todos`)
              : actionTypeOptions.find(({ value }) => value === action)?.label ?? action;
            return (
              <Chip
                icon={selected ? <CheckCircle /> : <RadioButtonUnchecked />}
                size="small"
                onClick={() => {
                  if (!selected) setSelectedAction(action);
                }}
                // color="info"
                variant={selected ? 'filled' : 'outlined'}
                label={label}
              />
            );
          })}
        </Box>
        <DeviceProfileRecentActivity deviceId={deviceId} />
        <Link>Ver más</Link>
      </Box>
    </Box>
  );
};

export default DeviceProfileAudit;
