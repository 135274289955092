import { Trans } from '@lingui/macro';
import { TableCell, TableHead, TableRow } from '@mui/material';

const HeaderTable = ({ grouped }) => {
  return (
    <TableHead>
      {grouped ? (
        <TableRow>
          <TableCell align="left">
            <Trans>Mensaje</Trans>
          </TableCell>
          <TableCell align="left">
            <Trans>Cantidad</Trans>
          </TableCell>
        </TableRow>
      ) : (
        <TableRow>
          <TableCell align="left">
            <Trans>Fecha</Trans>
          </TableCell>
          <TableCell align="left">
            <Trans>Device Id</Trans>
          </TableCell>
          <TableCell align="left">
            <Trans>Mensaje</Trans>
          </TableCell>
          <TableCell align="left">
            <Trans>Fuente</Trans>
          </TableCell>
          <TableCell align="left">
            <Trans>Usuario</Trans>
          </TableCell>
        </TableRow>
      )}
    </TableHead>
  );
};

export default HeaderTable;
