import { FormInputText } from '@/components/form/FormInputText';
import { setSnackbar } from '@/slices/components';
import { useSelector } from '@/store';
import { getSmaMetricMap, getSmaMetricUnitOptions } from '@/utils/sma';
import { useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  DayOfWeek,
  MetricField,
  SmaFrequency,
  SmaMetricName,
  SmaMetricUnit,
} from 'src/__generated__/graphql';
import NumericTextField from 'src/components/NumericTextField';
import { FormSelect } from 'src/components/form';
import ModalActions from 'src/components/modal/ModalActions';
import ModalTitle from 'src/components/modal/ModalTitle';
import StandardAutocompleteIdsWithFallback from 'src/features/standardDesign/components/autocomplete/StandardAutocompleteIds';
import { setUpsertSmaConfigurationModal } from 'src/slices/modals';
import { getMetricDisplayName } from 'src/utils/i18n/getMetricDisplayName';
import { z } from 'zod';
import CREATE_SMA_DEVICE_METRIC from '../graphql/mutations/createSmaDeviceMetric';
import UPDATE_SMA_DEVICE_METRIC from '../graphql/mutations/updateSmaDeviceMetric';
import GET_DEVICE_INFO from '../graphql/queries/getInfoFromDevice';

const schema = z.object({
  deviceId: z.string(),
  metric: z.nativeEnum(MetricField),
  username: z.string().min(1, t`El nombre de usuario es requerido`),
  password: z.string().min(1, t`La contraseña es requerida`),
  unitId: z.string().min(1, t`El ID de la unidad fiscalizable de la SMA es requerido`),
  processId: z.string().min(1, t`El ID del proceso de la SMA es requerido`),
  smaDeviceId: z.string().min(1, t`El ID del dispositivo en la SMA es requerido`),
  smaMetricName: z.nativeEnum(SmaMetricName),
  smaMetricUnit: z.nativeEnum(SmaMetricUnit),
  enable: z.boolean(),
  schedule: z
    .object({
      frequency: z.nativeEnum(SmaFrequency),
      weekday: z.nativeEnum(DayOfWeek).optional(),
      hour: z.coerce.number().int().min(0).max(23).optional(),
      day: z.coerce.number().int().min(1).max(27).optional(),
    })
    .optional(),
});

interface FormValues {
  deviceId: string;
  metric: MetricField;
  username: string;
  password: string;
  unitId: string;
  processId: string;
  smaDeviceId: string;
  smaMetricUnit: SmaMetricUnit;
  smaMetricName: SmaMetricName;
  enable: boolean;
  schedule?: {
    frequency: SmaFrequency;
    weekday: DayOfWeek;
    hour: number;
    day: number;
  };
}

const nonValidMetricFields = [
  MetricField.SnowLevelNonCalibrated,
  MetricField.ChargeP,
  MetricField.ChargeI,
  MetricField.ConnectionState,
  MetricField.Energy,
  MetricField.SnowWaterEquivalentNonCalibrated,
  MetricField.DgaAccumulatedVolume,
  MetricField.DgaFlow,
  MetricField.DgaLevel,
  MetricField.RatingCurvesLimits,
  MetricField.DistributionAlerts,
  MetricField.SolarI,
  MetricField.SolarP,
  MetricField.SolarV,
  MetricField.VolumeDay,
  MetricField.VolumeMonth,
  MetricField.VolumeHour,
  MetricField.CalculatedVolume,
  MetricField.LevelAlerts,
  MetricField.FlowAlerts,
  MetricField.OriginalDistance,
];

const SmaUpsertModal = () => {
  const { upsertSmaConfigurationModal: modalState } = useSelector((state) => state.modals_store);
  const { mode, initialSmaConfig, initialDeviceId, open: show } = modalState;

  const dispatch = useDispatch();
  const close = () => dispatch(setUpsertSmaConfigurationModal({ open: false }));
  const { i18n } = useLingui();
  const defaultInitialValues = {
    enable: false,
    schedule: {
      frequency: SmaFrequency.Every_5Min,
    },
  };

  const initialValuesForm =
    initialSmaConfig != null
      ? {
          deviceId: initialDeviceId,
          metric: initialSmaConfig.metricField,
          username: initialSmaConfig.username,
          password: initialSmaConfig.password,
          enable: initialSmaConfig.enabled,
          smaDeviceId: initialSmaConfig.smaDeviceId,
          processId: initialSmaConfig.smaProcessId,
          smaMetricName: initialSmaConfig.smaMetricName,
          smaMetricUnit: initialSmaConfig.smaMetricUnit,
          unitId: initialSmaConfig.smaUnitId,
          schedule:
            initialSmaConfig.schedule != null
              ? {
                  frequency: initialSmaConfig.schedule.frequency,
                  day: initialSmaConfig.schedule.day ?? undefined,
                  weekday: initialSmaConfig.schedule.weekday ?? undefined,
                  hour: initialSmaConfig.schedule.hour ?? undefined,
                }
              : undefined,
        }
      : defaultInitialValues;

  const { control, handleSubmit, watch, formState, reset } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: initialValuesForm,
  });

  useEffect(() => reset(initialValuesForm), [show]);

  const [mutCreateSmaDeviceMetric, { loading: loadingCreation }] = useMutation(
    CREATE_SMA_DEVICE_METRIC,
    {
      onCompleted: () => {
        dispatch(
          setSnackbar({
            message: 'Configuración SMA creada exitósamente',
            severity: 'success',
            open: true,
          }),
        );
        close();
      },
      update(cache) {
        cache.modify({
          fields: {
            devicesConnection(_, { INVALIDATE }) {
              return INVALIDATE;
            },
            devices(_, { INVALIDATE }) {
              return INVALIDATE;
            },
          },
        });
      },

      onQueryUpdated(observableQuery) {
        if (observableQuery.queryName !== 'StandardAutocompleteData') {
          return observableQuery.refetch();
        }
      },
      onError(error) {
        dispatch(
          setSnackbar({
            message: error.message,
            severity: 'error',
            open: true,
          }),
        );
      },
    },
  );
  const [mutUpdateSmaDeviceMetric, { loading: loadingUpdate }] = useMutation(
    UPDATE_SMA_DEVICE_METRIC,
    {
      onCompleted: () => {
        dispatch(
          setSnackbar({
            message: 'Configuración SMA actualizada exitósamente',
            severity: 'success',
            open: true,
          }),
        );
        close();
      },
      update(cache, { data }) {
        if (!data) return;
        cache.modify({
          id: cache.identify({
            __typename: 'Device',
            id: deviceId,
          }),
          fields: {
            smaConfig(_, { INVALIDATE }) {
              return INVALIDATE;
            },
          },
        });
      },
      onQueryUpdated(observableQuery) {
        if (observableQuery.queryName !== 'StandardAutocompleteData') {
          return observableQuery.refetch();
        }
      },
      onError(error) {
        dispatch(
          setSnackbar({
            message: error.message,
            severity: 'error',
            open: true,
          }),
        );
      },
    },
  );

  const deviceId = watch('deviceId');
  const metricField = watch('metric');
  const smaMetricName = watch('smaMetricName');
  const smaEnabled = watch('enable');
  const frequency = watch('schedule.frequency');

  const smaMetricUnitOptions = getSmaMetricUnitOptions(smaMetricName);
  const smaMetricOptions = getSmaMetricMap(metricField);
  const { data } = useQuery(GET_DEVICE_INFO, {
    variables: { input: { id: deviceId } },
    skip: deviceId == null,
  });
  const onSubmit = (formData: FormValues) => {
    const scheduleInput = formData.enable ? formData.schedule : undefined;
    const input = {
      deviceId: formData.deviceId,
      metricField: formData.metric,
      username: formData.username,
      password: formData.password,
      unitId: formData.unitId,
      processId: formData.processId,
      smaDeviceId: formData.smaDeviceId,
      smaMetricName: formData.smaMetricName,
      smaMetricUnit: formData.smaMetricUnit,
      enable: formData.enable,
    };

    if (mode === 'create') {
      mutCreateSmaDeviceMetric({ variables: { input, scheduleInput } });
    } else if (mode === 'update') {
      const updateSmaDeviceMetricId = initialSmaConfig?.id;
      if (updateSmaDeviceMetricId == null) {
        throw new Error('Sma device metric id is null');
      }
      mutUpdateSmaDeviceMetric({ variables: { updateSmaDeviceMetricId, input, scheduleInput } });
    }
  };
  const orgName = data?.device.profile.organization?.name;

  const metricFieldsOptions =
    data?.device.metrics.availableFields
      .filter((i) => !nonValidMetricFields.includes(i))
      .map((i) => ({
        label: getMetricDisplayName(i, i18n),
        value: i,
      })) ?? [];

  return (
    <Dialog open={show} maxWidth="lg" fullWidth>
      <ModalTitle
        title={
          mode === 'create'
            ? 'Agregar Dispositivo SMA'
            : mode === 'update'
            ? 'Actualizar Dispositivo SMA'
            : ''
        }
        onClose={close}
      />
      <DialogContent dividers>
        <Box display="flex" gap={1} flexDirection="column">
          <Box display="flex">
            <Controller
              name="deviceId"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <StandardAutocompleteIdsWithFallback
                  fullWidth
                  disabled={mode === 'update'}
                  dataType="devices"
                  value={value}
                  onChange={(_, value) => onChange(value)}
                  error={Boolean(error)}
                />
              )}
            />
            <Box sx={{ width: '30%' }}>
              {orgName != null && (
                <Typography textAlign="end">
                  Organización: <b>{orgName}</b>
                </Typography>
              )}
            </Box>
          </Box>
          <Box>
            <Typography variant="h6" mb={1}>
              Credenciales
            </Typography>
            <Box display="flex" gap={2}>
              <FormInputText control={control} name={'username'} label="Usuario" />
              <FormInputText control={control} name={'password'} label="Contraseña" />
            </Box>
          </Box>
          <Box display="flex" gap={1} flexDirection="column">
            <Typography variant="h6" mb={1}>
              Configuración SMA
            </Typography>
            <Box display="flex" gap={2}>
              <FormInputText control={control} name={'unitId'} label="ID Unidad Fiscalizable" />
              <FormInputText control={control} name={'processId'} label="ID Proceso" />
              <FormInputText control={control} name={'smaDeviceId'} label="ID Dispositivo SMA" />
            </Box>
          </Box>
          <Box display="flex" gap={1} flexDirection="column">
            <Typography variant="h6">Métrica</Typography>
            <Box display="flex" gap={2}>
              <FormSelect
                control={control}
                name="metric"
                label="Métrica Amaru"
                options={metricFieldsOptions}
                fullWidth
              />
              <FormSelect
                control={control}
                name={'smaMetricName'}
                label="Nombre Métrica SMA"
                options={smaMetricOptions}
                fullWidth
              />
              <FormSelect
                control={control}
                name={'smaMetricUnit'}
                label="Unidad Métrica SMA"
                options={smaMetricUnitOptions}
                fullWidth
              />
            </Box>
          </Box>
          <Divider sx={{ mt: 1 }} />
          <Box>
            <Box mb={2}>
              <Controller
                control={control}
                name={'enable'}
                render={({ field: { value, ref, ...rest } }) => (
                  <FormGroup>
                    <FormControlLabel
                      {...rest}
                      checked={value}
                      inputRef={ref}
                      control={<Switch />}
                      label="Habilitar Envíos SMA"
                    />
                  </FormGroup>
                )}
              />
            </Box>
            {smaEnabled && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <FormSelect
                  control={control}
                  name={'schedule.frequency'}
                  label="Frequencia"
                  options={[
                    { label: 'Cada 5 minutos', value: SmaFrequency.Every_5Min },
                    { label: 'Cada Hora', value: SmaFrequency.Hourly },
                    { label: 'Diario', value: SmaFrequency.Daily },
                    { label: 'Semanal', value: SmaFrequency.Weekly },
                    { label: 'Mensual', value: SmaFrequency.Monthly },
                  ]}
                />
                <Box display="flex" gap={2}>
                  {frequency === SmaFrequency.Weekly && (
                    <FormSelect
                      control={control}
                      name={'schedule.weekday'}
                      label="Día de Semana"
                      options={[
                        { label: 'Lunes', value: DayOfWeek.Monday },
                        { label: 'Martes', value: DayOfWeek.Tuesday },
                        { label: 'Miércoles', value: DayOfWeek.Wednesday },
                        { label: 'Jueves', value: DayOfWeek.Thursday },
                        { label: 'Viernes', value: DayOfWeek.Friday },
                        { label: 'Sábado', value: DayOfWeek.Saturday },
                        { label: 'Domingo', value: DayOfWeek.Sunday },
                      ]}
                    />
                  )}

                  {frequency === SmaFrequency.Monthly && (
                    <Controller
                      control={control}
                      name={'schedule.day'}
                      render={({ field, fieldState }) => (
                        <NumericTextField
                          fullWidth
                          {...field}
                          label={`Día del mes`}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          inputMode="numeric"
                          value={Number.isNaN(field.value) ? '' : field.value}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value === '') field.onChange(NaN);
                            else field.onChange(value);
                          }}
                        />
                      )}
                    />
                  )}

                  {![SmaFrequency.Hourly, SmaFrequency.Every_5Min].includes(frequency) && (
                    <Controller
                      control={control}
                      name="schedule.hour"
                      render={({ field, fieldState }) => (
                        <NumericTextField
                          {...field}
                          fullWidth
                          label={`Hora`}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          inputMode="numeric"
                          value={Number.isNaN(field.value) ? '' : field.value}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value === '') field.onChange(NaN);
                            else field.onChange(value);
                          }}
                        />
                      )}
                    />
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      <ModalActions
        submitDisabled={!formState.isValid || loadingUpdate || loadingCreation}
        onClose={close}
        onSubmit={handleSubmit(onSubmit)}
      />
    </Dialog>
  );
};

export { SmaUpsertModal };
