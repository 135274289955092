import { SvgIcon, SvgIconProps } from '@mui/material';
const ReportsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 19 20">
      <g>
        <path d="M2,4h2v3h10v-3h2v6h2v-6c0-1.1-.9-2-2-2h-4.18c-.42-1.16-1.52-2-2.82-2s-2.4.84-2.82,2H2c-1.1,0-2,.9-2,2v14c0,1.1.9,2,2,2h5v-2H2V4ZM9,2c.55,0,1,.45,1,1s-.45,1-1,1-1-.45-1-1,.45-1,1-1Z" />
        <polygon points="15.01 12 13.59 13.41 15.17 14.99 9 14.99 9 16.99 15.17 16.99 13.59 18.58 15.01 19.99 19 15.99 15.01 12" />
      </g>
      <line stroke="currentColor" strokeWidth="1.5px" x1="4" y1="9.51" x2="14" y2="9.51" />
      <line stroke="currentColor" strokeWidth="1.5px" x1="4" y1="12.64" x2="10.64" y2="12.64" />
    </SvgIcon>
  );
};
export default ReportsIcon;
