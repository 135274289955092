import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { CloudUpload, Delete, InsertDriveFile } from '@mui/icons-material';
import { Box, FormLabel, IconButton, Typography } from '@mui/material';
import React, { forwardRef, useMemo } from 'react';
import { v4 } from 'uuid';

interface Props {
  onChange: (value: File) => void;
  value: File | string | null;
  label: string;
}

const FileInput = forwardRef<HTMLInputElement, Props>(({ onChange, value, label }, ref) => {
  const { _ } = useLingui();
  const htmlId = `file-upload-input-${v4()}`;
  const image = useMemo<HTMLImageElement>(() => {
    const img = new Image();
    if (typeof value === 'string') img.src = value;
    else if (value instanceof File) img.src = URL.createObjectURL(value);
    else img.src = '';
    return img;
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.files[0]);
  };

  return (
    <Box>
      {label && <FormLabel htmlFor={htmlId}>{label}</FormLabel>}
      <Box
        mt={1}
        display="flex"
        flexGrow={1}
        borderRadius={1}
        border={value ? '1px solid' : '2px dashed'}
        borderColor="rgba(255, 255, 255, 0.23)"
        color="rgba(255, 255, 255, 0.23)"
        sx={{
          ':hover': {
            borderColor: 'rgba(255, 255, 255, 0.87)',
            color: 'rgba(255, 255, 255, 0.87)',
          },
        }}
        overflow="hidden"
      >
        <FormLabel
          htmlFor={htmlId}
          sx={{
            flexGrow: 1,
            cursor: 'pointer',
            gridRowStart: 1,
            gridColumnStart: 1,
            gridColumnEnd: 3,
          }}
        >
          <input ref={ref} onChange={handleChange} type="file" id={htmlId} hidden />
          <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            p={value ? 0 : 2}
            gap={{ xs: 1, md: 2 }}
            textAlign={{ xs: 'center', md: 'left' }}
            justifyContent={value ? 'left' : 'center'}
            alignItems="center"
            width="100%"
          >
            {value ? (
              <>
                {image.src ? (
                  <img
                    src={image.src}
                    alt={_(msg`Previsualización de la imagen`)}
                    style={{
                      width: 128,
                      height: 128,
                      objectFit: 'cover',
                    }}
                  />
                ) : (
                  <Box display="grid" width={64} height={64} sx={{ placeItems: 'center' }}>
                    <InsertDriveFile
                      sx={{
                        fontSize: { xs: 48, md: 48 },
                        color: 'text.primary',
                      }}
                    />
                  </Box>
                )}
                <Box flexGrow={1}>
                  <Typography color="text.primary">
                    {value instanceof File ? value.name : image.src}
                  </Typography>
                  {value instanceof File && (
                    <Typography color="text.secondary" fontSize="small">
                      {value.size} bytes
                    </Typography>
                  )}
                </Box>
              </>
            ) : (
              <>
                <CloudUpload sx={{ fontSize: { xs: 64, md: 96 } }} />
                <Typography fontSize={{ sx: 20, md: 24 }}>
                  <Trans>Arrastra un archivo o haz click para seleccionar uno</Trans>
                </Typography>
              </>
            )}
          </Box>
        </FormLabel>
        {value && (
          <Box alignSelf="center" pr={2}>
            <IconButton onClick={() => onChange(null)}>
              <Delete />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
});

export default FileInput;
