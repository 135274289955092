import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';


export const FormInputDate = ({ name, control, label, dateFormat = "DD/MM/YYYY" }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <DesktopDatePicker
            label={label}
            inputFormat={dateFormat}
            value={value}
            onChange={onChange}
            renderInput={(params) => (
              <TextField {...params} helperText="" style={{ width: '100%' }} />
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
};
