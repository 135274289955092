import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  deviceProfileModal: {
    open: false,
    deviceId: null as string | null,
  },
};

const slice = createSlice({
  name: 'device_profile_store',
  initialState,
  reducers: {
    openDeviceProfileModal(state, action: { payload: { deviceId: string } }) {
      state.deviceProfileModal = {
        open: true,
        deviceId: action.payload.deviceId,
      };
    },
    closeDeviceProfileModal(state) {
      state.deviceProfileModal.open = false;
    },
  },
});

const { actions, reducer } = slice;

export const { openDeviceProfileModal, closeDeviceProfileModal } = actions;
export default reducer;
