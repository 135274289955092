import { FormValues } from '../components/RadarParametersTab';
import {
  DeviceDataConfiguration,
  DeviceRadarParametersAreaCurveInput,
  DeviceRadarParametersVelocityCurveInput,
  UpdateDeviceRadarParametersInput,
} from '@/__generated__/graphql';

/**
 * Used to transform the data from the form to the format
 * required by the mutation UPDATE_DEVICE_RADAR_PARAMETERS
 */
export const transformFormValuesToApiData = (
  values: FormValues,
): UpdateDeviceRadarParametersInput => {
  const areaCurve: DeviceRadarParametersAreaCurveInput = {
    type: values.areaCurve.type,
    ...(values.areaCurve.levelOffset != null && { levelOffset: values.areaCurve.levelOffset }),
  };

  // convert `a` { value: number } objects to flat array
  if (values.areaCurve.type !== 'piecewise') {
    if (values.areaCurve.curve?.functionType === 'polynomial')
      areaCurve.curve = {
        functionType: 'polynomial',
        parameters: {
          a: values.areaCurve.curve.parameters.a.map((a) => a.value),
        },
        ...(values.areaCurve.type === 'interval' && {
          min: values.areaCurve.curve.min,
          max: values.areaCurve.curve.max,
        }),
      };
    if (values.areaCurve.curve?.functionType === 'chiu')
      areaCurve.curve = {
        functionType: 'chiu',
        parameters: {
          vMaxDepthNorm: values.areaCurve.curve.parameters.vMaxDepthNorm,
          m: values.areaCurve.curve.parameters.m,
        },
        ...(values.areaCurve.type === 'interval' && {
          min: values.areaCurve.curve.min,
          max: values.areaCurve.curve.max,
        }),
      };
    if (values.areaCurve.curve?.functionType === 'power')
      areaCurve.curve = {
        functionType: 'power',
        parameters: {
          A: values.areaCurve.curve.parameters.A,
          B: values.areaCurve.curve.parameters.B,
          C: values.areaCurve.curve.parameters.C,
        },
        ...(values.areaCurve.type === 'interval' && {
          min: values.areaCurve.curve.min,
          max: values.areaCurve.curve.max,
        }),
      };
    if (values.areaCurve.curve?.functionType === 'trapezoidal')
      areaCurve.curve = {
        functionType: 'trapezoidal',
        parameters: {
          talud: values.areaCurve.curve.parameters.talud,
          baseWidth: values.areaCurve.curve.parameters.baseWidth,
        },
        ...(values.areaCurve.type === 'interval' && {
          min: values.areaCurve.curve.min,
          max: values.areaCurve.curve.max,
        }),
      };
  }
  const velocityCurve: DeviceRadarParametersVelocityCurveInput = {
    type: values.velocityCurve.type,
    ...(values.velocityCurve.model && { model: values.velocityCurve.model }),
  };

  if (values.velocityCurve.type !== 'piecewise') {
    if (values.velocityCurve.curve?.functionType === 'polynomial')
      velocityCurve.curve = {
        functionType: 'polynomial',
        parameters: {
          a: values.velocityCurve.curve.parameters.a.map((a) => a.value),
        },
        ...(values.velocityCurve.type === 'interval' && {
          min: values.velocityCurve.curve.min,
          max: values.velocityCurve.curve.max,
        }),
      };
    if (values.velocityCurve.curve?.functionType === 'chiu')
      velocityCurve.curve = {
        functionType: 'chiu',
        parameters: {
          vMaxDepthNorm: values.velocityCurve.curve.parameters.vMaxDepthNorm,
          m: values.velocityCurve.curve.parameters.m,
        },
        ...(values.velocityCurve.type === 'interval' && {
          min: values.velocityCurve.curve.min,
          max: values.velocityCurve.curve.max,
        }),
      };
    if (values.velocityCurve.curve?.functionType === 'power')
      velocityCurve.curve = {
        functionType: 'power',
        parameters: {
          A: values.velocityCurve.curve.parameters.A,
          B: values.velocityCurve.curve.parameters.B,
          C: values.velocityCurve.curve.parameters.C,
        },
        ...(values.velocityCurve.type === 'interval' && {
          min: values.velocityCurve.curve.min,
          max: values.velocityCurve.curve.max,
        }),
      };
    if (values.velocityCurve.curve?.functionType === 'trapezoidal')
      velocityCurve.curve = {
        functionType: 'trapezoidal',
        parameters: {
          talud: values.velocityCurve.curve.parameters.talud,
          baseWidth: values.velocityCurve.curve.parameters.baseWidth,
        },
        ...(values.velocityCurve.type === 'interval' && {
          min: values.velocityCurve.curve.min,
          max: values.velocityCurve.curve.max,
        }),
      };
  }

  if (values.areaCurve.type === 'piecewise') {
    areaCurve.curves = values.areaCurve.curves.map((curve) => {
      if (curve.functionType === 'polynomial')
        return {
          ...curve,
          parameters: {
            a: curve.parameters.a.map((a) => a.value),
          },
        };
      if (curve.functionType === 'chiu')
        return {
          ...curve,
          parameters: {
            vMaxDepthNorm: curve.parameters.vMaxDepthNorm,
            m: curve.parameters.m,
          },
        };
      if (curve.functionType === 'power')
        return {
          ...curve,
          parameters: {
            A: curve.parameters.A,
            B: curve.parameters.B,
            C: curve.parameters.C,
          },
        };
      if (curve.functionType === 'trapezoidal')
        return {
          ...curve,
          parameters: {
            talud: curve.parameters.talud,
            baseWidth: curve.parameters.baseWidth,
          },
        };
      return curve;
    });
  }
  if (values.velocityCurve.type === 'piecewise') {
    velocityCurve.curves = values.velocityCurve.curves.map((curve) => {
      if (curve.functionType === 'polynomial')
        return {
          ...curve,
          parameters: {
            a: curve.parameters.a.map((a) => a.value),
          },
        };
      if (curve.functionType === 'chiu')
        return {
          ...curve,
          parameters: {
            vMaxDepthNorm: curve.parameters.vMaxDepthNorm,
            m: curve.parameters.m,
          },
        };
      if (curve.functionType === 'power')
        return {
          ...curve,
          parameters: {
            A: curve.parameters.A,
            B: curve.parameters.B,
            C: curve.parameters.C,
          },
        };
      if (curve.functionType === 'trapezoidal')
        return {
          ...curve,
          parameters: {
            talud: curve.parameters.talud,
            baseWidth: curve.parameters.baseWidth,
          },
        };
      return curve;
    });
  }

  const input: UpdateDeviceRadarParametersInput = {
    deviceId: values.deviceId,
    radarParameters: {
      ratingCurve: values.ratingCurve,
      areaCurve,
      velocityCurve,
    },
  };

  return input;
};

export const transformApiDataToFormValues = (
  data: DeviceDataConfiguration | undefined | null,
  deviceId: string,
): FormValues | undefined => {
  if (!data?.radarParameters)
    return {
      deviceId,
      ratingCurve: {
        active: false,
      },
      areaCurve: {
        type: 'function',
        levelOffset: 0,
        curve: {
          functionType: '',
        },
      },
      velocityCurve: {
        type: 'function',
        model: 'index-velocity',
        curve: {
          functionType: '',
        },
      },
    };
  const formValues = {
    deviceId: data.deviceId,
    ratingCurve: data.radarParameters.ratingCurve,
    areaCurve: {
      ...data.radarParameters.areaCurve,
      ...(data.radarParameters.areaCurve.curve?.parameters.a && {
        curve: {
          ...data.radarParameters.areaCurve.curve,
          parameters: {
            ...data.radarParameters.areaCurve.curve.parameters,
            a: data.radarParameters.areaCurve.curve.parameters.a.map((a) => ({
              value: a,
            })),
          },
        },
      }),
      ...(data.radarParameters.areaCurve.curves && {
        curves: data.radarParameters.areaCurve.curves.map((curve) => {
          if (!curve.parameters.a) return curve;
          return {
            ...curve,
            parameters: {
              ...curve.parameters,
              a: curve.parameters.a.map((a) => ({ value: a })),
            },
          };
        }),
      }),
    },
    velocityCurve: {
      ...data.radarParameters.velocityCurve,
      ...(data.radarParameters.velocityCurve.curve?.parameters.a && {
        curve: {
          ...data.radarParameters.velocityCurve.curve,
          parameters: {
            ...data.radarParameters.velocityCurve.curve.parameters,
            a: data.radarParameters.velocityCurve.curve.parameters.a.map((a) => ({
              value: a,
            })),
          },
        },
      }),
      ...(data.radarParameters.velocityCurve.curves && {
        curves: data.radarParameters.velocityCurve.curves.map((curve) => {
          if (!curve.parameters.a) return curve;
          return {
            ...curve,
            parameters: {
              ...curve.parameters,
              a: curve.parameters.a.map((a) => ({ value: a })),
            },
          };
        }),
      }),
    },
  };
  return formValues as FormValues;
};
