import { gql } from '@/__generated__';

const GET_ACCESS_AUDIT = gql(`#graphql
  query GetAccessAudit {
    authorization {
      authorizationGeneral {
        showAuditAdminUsers
      }
    }
  }
`);

export default GET_ACCESS_AUDIT;
