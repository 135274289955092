import { useState, useEffect, type FC } from 'react';
import { TableContainer, Table, Typography, Paper, Grid, Tabs, Tab } from '@mui/material';
import TablePaginationActions from '@components/TablePaginationActions';
import TableLoading from '@components/TableLoading';
import HeaderTable from '@features/events/components/HeaderTable';
import BodyTable from './BodyTable';
import EventsFilter from './EventsFilter';
import { useQuery } from '@apollo/client';
import FETCH_EVENTS from '@features/events/graphql/queries/fetchEvents';
import { useSelector } from '@/store';
import { EventPoint } from 'src/__generated__/graphql';
import { GroupedEvent } from '../data/types';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

interface Props {
  deviceId: string;
}

const EventsTable: FC<Props> = ({ deviceId }) => {
  const { _ } = useLingui();
  const {
    events: { filter: eventsFilter },
    parameters,
  } = useSelector((state) => state.devices_analysis_store);
  const [grouped, setGrouped] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tabIndex, setTabIndex] = useState(0);

  const { data, loading: loadingEvents } = useQuery(FETCH_EVENTS, {
    variables: {
      deviceInput: { id: deviceId },
      input: {
        start: parameters.start,
        stop: parameters.stop,
      },
      eventsFilter: {
        eventsSource: eventsFilter === 'all' ? null : eventsFilter,
      },
    },
  });

  const { events } = data?.device.telemetry ?? ({} as { events: EventPoint[] });

  // if grouped = true the events will be grouped by value
  const [groupedEvents, setGroupedEvents] = useState<GroupedEvent[]>([]);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setGrouped(newValue === 1);
    setTabIndex(newValue);
    setPage(0);
  };
  // calculate grouped events when events change
  useEffect(() => {
    if (grouped) {
      const groupedEvents = events?.reduce((acc: GroupedEvent[], event) => {
        const index = acc.findIndex((item) => item.value === event.value);
        if (index === -1) {
          acc.push({ value: event.value, count: 1 });
        } else {
          acc[index].count += 1;
        }
        return acc;
      }, []);
      setGroupedEvents(groupedEvents);
    }
  }, [events, grouped]);

  useEffect(() => {
    setPage(0);
  }, [eventsFilter]);

  return (
    <Paper sx={{ gridArea: 'eventsTable' }}>
      {loadingEvents ? (
        <TableLoading withCard={false} />
      ) : (
        <Grid container mt={2} p={2} spacing={2}>
          <Grid item xs={12} md={9}>
            <Typography variant="h5">
              <Trans>Tabla de eventos</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <EventsFilter />
          </Grid>
          <Tabs value={tabIndex} onChange={handleTabChange}>
            <Tab label={_(msg`Listado completo`)} />
            <Tab label={_(msg`Agrupados por tipo`)} />
          </Tabs>
          <Grid item xs={12} md={12}>
            {events?.length ?? 0 > 0 ? (
              <TableContainer>
                <Table aria-labelledby="tableTitle">
                  <HeaderTable grouped={grouped} />
                  <BodyTable
                    items={grouped ? groupedEvents ?? [] : events}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    grouped={grouped}
                    deviceId={deviceId}
                  />
                </Table>
                <TablePaginationActions
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onChangePage={(p) => setPage(p)}
                  count={events?.length ?? 0}
                  onChangeRowsPerPage={(r) => setRowsPerPage(r)}
                />
              </TableContainer>
            ) : (
              <Typography align="center" color="textSecondary">
                {' '}
                <Trans>No hay datos</Trans>{' '}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
    </Paper>
  );
};

export default EventsTable;
