import { gql } from '@/__generated__';

const GET_ACCESS_DEVICE_DATA_CONFIGURATION = gql(`#graphql
  query GetAccessDeviceDataConfiguration($input: AuthorizationInput!) {
    authorization {
      authorizationGeneral {
        viewSourceInfo
      }
      authorizationDevice(input: $input) {
        deviceId
        viewCalibration
        viewFlowLimit
        viewFilter
        viewRatingCurve
        viewMathModeling
        viewRadarParameter
      }
    }
  }
`);

export default GET_ACCESS_DEVICE_DATA_CONFIGURATION;
