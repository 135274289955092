import { gql } from '@/__generated__';
import { useAuth, useModalStatusMessage } from '@/hooks';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import ModalActions from '@components/modal/ModalActions';
import ModalTitle from '@components/modal/ModalTitle';
import SEND_DGA_REPORTS_EXCEL_BY_EMAIL from '@features/dga/graphql/mutations/sendDgaReportsExcelByEmail';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Dialog, DialogContent } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-latest';
import { AdapterDayjs } from '@mui/x-date-pickers-latest/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { Suspense, useState, type FC } from 'react';

type Props = {
  deviceId: string;
  open: boolean;
  onClose: () => void;
};

const DGA_REPORTS_TABLE_GET_DEVICE = gql(`#graphql
  query DGAReportsTableModal_GetDeviceForSendEmailModal($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      profile {
        id
        name
      }
    }
  }`);

const Component: FC<Props> = ({ deviceId, open, onClose }) => {
  const { data: deviceData } = useSuspenseQuery(DGA_REPORTS_TABLE_GET_DEVICE, {
    variables: {
      deviceId,
    },
  });
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const { user } = useAuth();
  const { _, i18n } = useLingui();
  const [send, { loading }] = useMutation(SEND_DGA_REPORTS_EXCEL_BY_EMAIL, {
    onCompleted() {
      openModalSuccessMessage(_(msg`Se ha enviado el reporte al correo ${user?.email}`));
      onClose()
    },
    onError(error) {
      openModalErrorMessage(error.message);
    },
  });
  const getStartAndStopDates = () => {
    const stop = dayjs().startOf('hour');
    const start = stop.subtract(1, 'day');
    return [start, stop];
  };
  const defaultDates = getStartAndStopDates();
  const [start, setStart] = useState<Dayjs | null>(defaultDates[0]);
  const [stop, setStop] = useState<Dayjs | null>(defaultDates[1]);

  const deviceName = deviceData?.device?.profile?.name;

  const onSubmit = async () => {
    if (!start || !stop) return;
    send({
      variables: {
        deviceId,
        start: start.unix() * 1000,
        stop: stop.unix() * 1000,
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <ModalTitle
        title={_(msg`Descargar tabla de reportes DGA`)}
        subtitle={`${deviceName} - ID: ${deviceId}`}
      />
      <DialogContent dividers>
        <Trans>
          Se enviará la información solicitada en formato excel al correo <i>{user?.email}</i>
        </Trans>
        <LocalizationProvider adapterLocale={i18n.locale} dateAdapter={AdapterDayjs}>
          <Box display="flex" gap={2} mt={2}>
            <DateTimePicker
              views={['year', 'month', 'day', 'hours', 'minutes']}
              ampm={false}
              label={_(msg`Desde`)}
              disableFuture
              value={start}
              onChange={(newDate) => setStart(newDate)}
              maxDateTime={stop}
            />
            <DateTimePicker
              views={['year', 'month', 'day', 'hours', 'minutes']}
              ampm={false}
              label={_(msg`Hasta`)}
              disableFuture
              minDateTime={start}
              value={stop}
              onChange={(newDate) => setStop(newDate)}
            />
          </Box>
        </LocalizationProvider>
      </DialogContent>
      <ModalActions
        submitButton={{
          label: _(msg`Enviar`),
          onClick: () => onSubmit(),
        }}
        onClose={onClose}
        submitDisabled={loading}
      />
    </Dialog>
  );
};

const SendReportsByEmailModal: FC<Props> = (props) => {
  return (
    <ApolloErrorBoundary>
      <Suspense>
        <Component {...props} />
      </Suspense>
    </ApolloErrorBoundary>
  );
};

export default SendReportsByEmailModal;
