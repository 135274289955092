import { v4 } from 'uuid';
import { get } from 'lodash';

import putS3 from 'src/controllers/aws/putS3';

// graphql
import { useMutation } from '@apollo/client';
import UPLOAD_FILE from 'src/graphql/mutations/uploadS3File';
import UPLOAD_KML from 'src/graphql/mutations/uploadS3Kml';

const useUploadS3Images = (bucket) => {
  const [uploadFiles, { loading }] = useMutation(UPLOAD_FILE);
  const [uploadKml, { loading: loadingKML }] = useMutation(UPLOAD_KML);

  const uploadS3Images = async (img) => {
    const imageName = v4();

    const { data } = await uploadFiles({
      variables: {
        key: imageName,
        bucket: bucket,
      },
    });

    if (get(data, 'UploadS3file.signedURL', null)) {
      await putS3(get(data, 'UploadS3file.signedURL', null), img);
      return get(data, 'UploadS3file.fileName', null);
    }

    return '';
  };

  const uploadS3Kml = async (kml) => {
    const imageName = v4() + '.kml';

    const { data } = await uploadKml({
      variables: {
        key: imageName,
        bucket: bucket,
        contentType: 'application/vnd.google-earth.kml+xml',
        region: 'us-west-2',
      },
    });

    if (get(data, 'UploadS3file.signedURL', null)) {
      await putS3(get(data, 'UploadS3file.signedURL', null), kml);
      return get(data, 'UploadS3file.uuid', null);
    }

    return '';
  };

  return {
    uploadS3Images,
    loading,
    uploadS3Kml,
  };
};

export default useUploadS3Images;
