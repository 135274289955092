import { CircularProgress, Grid, Paper, Typography } from '@mui/material';

const Loading = () => {
  return (
    <Paper>
      <Grid container direction="column" alignItems="center" spacing={2} p={5}>
        <Grid item>
          <Typography variant="h5">Cargando...</Typography>
        </Grid>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Loading;
