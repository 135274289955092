import type { FC } from 'react';

const Logo: FC = () => (
  <img
    src="/static/capta_logo.png"
    alt="capta-logo"
    id="capta-logo"
    style={{
      width: 300,
    }}
  />
);

export default Logo;
