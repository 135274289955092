import { useFormatters } from '@/hooks/useFormatters';
import { setCreateDeviceObservationModal } from '@/slices/modals';
import { useSuspenseQuery } from '@apollo/client';
import ApolloErrorBoundary from '@components/ApolloErrorBoundary';
import UserAvatar from '@components/UserAvatar';
import { Add } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Fab,
  List,
  ListItem,
  Skeleton,
  Typography,
} from '@mui/material';
import { Suspense, useEffect, useRef, useState, type FC } from 'react';
import { useDispatch } from 'react-redux';
import GET_DEVICE_OBSERVATIONS from '../../graphql/queries/getDeviceObservations';
import ObservationMenu from './ObservationMenu';
import { Trans } from '@lingui/macro';

interface Props {
  deviceId: string;
}

const Component: FC<Props> = ({ deviceId }) => {
  const dispatch = useDispatch();
  const { formatDateTime } = useFormatters();
  const { data } = useSuspenseQuery(GET_DEVICE_OBSERVATIONS, {
    variables: {
      deviceId,
    },
  });

  const stickyBoxRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const [isFloating, setIsFloating] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsFloating(entry.intersectionRatio < 1);
      },
      {
        root: parentRef.current,
        threshold: 1,
      },
    );
    const stickyBox = stickyBoxRef.current;
    if (stickyBox) observer.observe(stickyBox);

    return () => {
      if (stickyBox) observer.unobserve(stickyBox);
    };
  }, [data]);

  const observations = data.device.profile.observations.items;

  const openCreateObservationModalHandler = () => {
    dispatch(
      setCreateDeviceObservationModal({
        open: true,
        deviceId,
      }),
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      maxHeight={{
        sm: 'unset',
        md: 'calc(100vh - 420px)',
      }}
      minHeight={300}
      className="custom-scrollbar"
      sx={{
        overflowY: 'auto',
      }}
      pb={1}
      ref={parentRef}
    >
      <List>
        {observations.length === 0 && (
          <ListItem>
            <Typography color="textSecondary" fontStyle="italic">
              <Trans>No hay observaciones</Trans>
            </Typography>
          </ListItem>
        )}
        {observations.map((item) => (
          <ListItem key={item.id}>
            <Card variant="outlined" sx={{ width: '100%' }}>
              <CardHeader
                avatar={<UserAvatar userId={item.author.id} />}
                title={item.author.fullName}
                subheader={formatDateTime(item.date)}
                action={<ObservationMenu observationId={item.id} />}
              />
              <CardContent sx={{ pt: 0 }}>
                <Typography variant="body2" color="text.secondary">
                  {item.content}
                </Typography>
              </CardContent>
            </Card>
          </ListItem>
        ))}
      </List>
      <Box flex={1} />
      <Box position="sticky" bottom={0} left={0} textAlign="right" px={1}>
        <Fab
          color="success"
          onClick={openCreateObservationModalHandler}
          variant={!isFloating ? 'extended' : 'circular'}
          sx={{ transition: 'width 0.3s' }}
        >
          {!isFloating ? (
            <>
              <Add sx={{ mr: 1 }} /> Crear observación
            </>
          ) : (
            <Add />
          )}
        </Fab>
      </Box>
      <div ref={stickyBoxRef} />
    </Box>
  );
};

const Fallback = () => {
  return (
    <Box
      maxHeight={{
        sm: 'unset',
        md: 'calc(100vh - 320px)',
      }}
      minHeight={300}
      className="custom-scrollbar"
      sx={{
        overflowY: 'auto',
      }}
    >
      <List>
        {Array.from({ length: 2 }).map((_, index) => (
          <ListItem key={index}>
            <Card variant="outlined" sx={{ width: '100%' }}>
              <CardHeader
                avatar={<Skeleton variant="circular" width={40} height={40} />}
                title={<Skeleton width={120} />}
                subheader={<Skeleton width={100} />}
              />
              <CardContent sx={{ pt: 0 }}>
                <Typography variant="body2" color="text.secondary">
                  <Skeleton width={340} />
                </Typography>
              </CardContent>
            </Card>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const DeviceProfileObservations: FC<Props> = (props) => {
  return (
    <ApolloErrorBoundary>
      <Suspense fallback={<Fallback />}>
        <Component {...props} />
      </Suspense>
    </ApolloErrorBoundary>
  );
};

export default DeviceProfileObservations;
