import { useDispatch, useSelector } from '@/store';
import { SelectCustom } from '@/components';
import { SourceType, sourceTypes } from '../data/types';
import { setEventsFilter } from '@features/devicesAnalysis/slices/devicesAnalysisSlice';
import { useLingui } from '@lingui/react';
import { msg } from '@lingui/macro';

const EventsFilter = () => {
  const { _ } = useLingui();
  const dispatch = useDispatch();
  const {
    events: { filter },
  } = useSelector((state) => state.devices_analysis_store);

  const handleTypeChange = (value: SourceType) => {
    dispatch(setEventsFilter(value));
  };

  return (
    <SelectCustom
      id="source-type-filter"
      name={_(msg`Fuente de evento`)}
      value={filter}
      options={sourceTypes}
      onChange={handleTypeChange}
    />
  );
};

export default EventsFilter;
