import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  openedModal: false,
};

const debtsAlertSlice = createSlice({
  name: 'debts_alert_store',
  initialState,
  reducers: {
    openModal(state) {
      state.openedModal = true;
    },
    closeModal(state) {
      state.openedModal = false;
    },
  },
});

const { actions, reducer } = debtsAlertSlice;

export const { openModal, closeModal } = actions;
export { reducer };
