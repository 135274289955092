import { gql } from '@/__generated__';

const GET_DGA_NOTIFICATION_CONFIGURATION = gql(`#graphql
  query GetDGANotificationConfiguration($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      profile {
        id
        name
        organization {
          id
        }
      }
      users {
        user {
          id
          fullName
          email
          mainOrganization {
            organization {
              id
            }
            role {
              name
            }
          }
          additionalOrganizations {
            organization {
              id
            }
            role {
              name
            }
          }
        }
        hasAccess
      }
      dgaNotificationConfiguration {
        deviceId
        daysUntilFailureNotification
        failureNotificationFrequencyInDays
        users {
          user {
            id
          }
          notifyEmail
        }
      }
    }
  }
`);

export default GET_DGA_NOTIFICATION_CONFIGURATION;
