import { gql } from '@/__generated__';

const GET_USER_PREFERENCES_REGION = gql(`#graphql
  query GetUserPreferencesRegion {
    user {
      id
      preferences {
        id
        region {
          hour12
          locale
          timezone
          numberLocale
          dateLocale
          dateMonthFormat
        }
      }
    }
  }
`);

export default GET_USER_PREFERENCES_REGION;
