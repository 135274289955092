import { gql } from 'src/__generated__';

const DISABLE_USER = gql(`#graphql
  mutation DisableUser($input: DisableUserInput!) {
    disableUser(input: $input) {
      id
    }
  }
`);

export default DISABLE_USER;
