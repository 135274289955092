import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { type FC, useEffect } from 'react';

export interface ConfirmationDialog {
  open: boolean;
  title?: string;
  content?: string;
  action?: () => void;
  actionButtonText?: string;
}

interface Props {
  dirtyForm: boolean;
  confirmationDialog: ConfirmationDialog | null;
  onClose: () => void;
}

const ModalDirtyConfirmation: FC<Props> = ({ dirtyForm, confirmationDialog, onClose }) => {
  // Prevent user from leaving the page if the form is dirty
  useEffect(() => {
    const handleBeforeUnload = (event: Event) => {
      if (dirtyForm) event.preventDefault();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dirtyForm]);

  return (
    <Dialog open={!!confirmationDialog?.open}>
      <DialogTitle>{confirmationDialog?.title}</DialogTitle>
      <DialogContent dividers>{confirmationDialog?.content ?? ''}</DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={onClose}>
          Cancelar
        </Button>
        <Button variant="contained" color="error" onClick={confirmationDialog?.action}>
          {confirmationDialog?.actionButtonText ?? 'Confirmar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDirtyConfirmation;
