import { gql } from '@/__generated__';
import { type GateOperation_GetGateQuery } from '@/__generated__/graphql';

export type Gate = GateOperation_GetGateQuery['gate'];

const GET_GATE_OPTIONS_DATA = gql(`#graphql
  query GateOptions_GetGate($input: GateInput!) {
    gate(input: $input) {
      id
      mainDevice {
        id
        profile {
          id
          name
          type: type
          organization {
            name
          }
          status
          componentID
          serie
          irrigation {
            id
          }
          logicalDeviceID
        }
      }
      hasAlarm
      alarmData
      dataConfiguration {
        minPosition
        maxPosition
      }
    }
  }
`);

export default GET_GATE_OPTIONS_DATA;
