import { type FC } from 'react';
import {
  Controller,
  type Control,
  useWatch,
  useFieldArray,
  UseFormSetValue,
  UseFormGetValues,
  useFormState,
} from 'react-hook-form';
import { type FormValues } from './RadarParametersTab';
import {
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Button,
  Divider,
  FormHelperText,
} from '@mui/material';
import { Box } from '@mui/system';
import NumericTextField from '@components/NumericTextField';
import formSelectOptions from '../constants/formSelectOptions.json';
import FormParametersSection from './FormParametersSection';
import { Add } from '@mui/icons-material';
import { get } from 'lodash';

interface Props {
  control: Control<FormValues>;
  path: 'areaCurve' | 'velocityCurve';
  setValue: UseFormSetValue<FormValues>;
  getValues: UseFormGetValues<FormValues>;
}

interface PropsPiecewise {
  control: Control<FormValues>;
  path: 'areaCurve' | 'velocityCurve';
  setValue: UseFormSetValue<FormValues>;
  getValues: UseFormGetValues<FormValues>;
  functionTypeOptions: (typeof formSelectOptions.functionType)[`variant${1 | 2 | 3}`];
}

const FormCurveSectionPiecewise: FC<PropsPiecewise> = ({
  control,
  path,
  getValues,
  setValue,
  functionTypeOptions,
}) => {
  const { fields, append, remove } = useFieldArray({
    name: `${path}.curves`,
    control,
  });
  const { errors } = useFormState({
    control,
  });

  return (
    <Box>
      {fields.map((field, index) => (
        <Box key={field.id} pt={4} display="grid" gap={2}>
          <Box display="flex" gap={2}>
            <Controller
              name={`${path}.curves.${index}.functionType`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error} size="small">
                  <InputLabel htmlFor={`${path}-function-type-${index}`}>
                    Tipo de Función
                  </InputLabel>
                  <Select
                    {...field}
                    error={!!error}
                    inputProps={{ id: `${path}-function-type-${index}` }}
                    label="Tipo de Función"
                  >
                    {functionTypeOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {error && <FormHelperText error>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
            <Button
              sx={{ flexShrink: 0 }}
              variant="outlined"
              color="error"
              aria-label="Eliminar intervalo"
              disabled={fields.length <= 1}
              onClick={() => {
                // first set the min of the next interval to the min of the current interval
                if (index < fields.length - 1) {
                  const min = getValues(`${path}.curves.${index}.min`);
                  setValue(`${path}.curves.${index + 1}.min`, min, {
                    shouldValidate: true,
                  });
                }
                remove(index);
              }}
            >
              Eliminar Intervalo
            </Button>
          </Box>
          <Box display="grid" gridTemplateColumns="1fr 1fr" alignItems="center" gap={2}>
            <Controller
              name={`${path}.curves.${index}.min`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <NumericTextField
                  {...field}
                  size="small"
                  label="Intervalo Inicio"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                  }}
                  // change the max of the previous interval if it exists
                  onChange={(e) => {
                    if (index > 0) {
                      setValue(`${path}.curves.${index - 1}.max`, Number(e.target.value), {
                        shouldValidate: true,
                      });
                    }
                    field.onChange(e);
                  }}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name={`${path}.curves.${index}.max`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <NumericTextField
                  {...field}
                  size="small"
                  label="Intervalo Fin"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                  }}
                  // change the min of the next interval if it exists
                  onChange={(e) => {
                    if (index < fields.length - 1) {
                      setValue(`${path}.curves.${index + 1}.min`, Number(e.target.value), {
                        shouldValidate: true,
                      });
                    }
                    field.onChange(e);
                  }}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Box>
          <Typography>Parámetros</Typography>
          <FormParametersSection control={control} path={`${path}.curves.${index}`} />
          <Divider />
        </Box>
      ))}
      <Button
        fullWidth
        onClick={() =>
          append({
            functionType: 'polynomial',
            parameters: { a: [] },
            min: getValues(`${path}.curves.${fields.length - 1}.max`) ?? '',
            max: 0,
          })
        }
        size="large"
        variant="outlined"
        sx={{ mt: 2 }}
        startIcon={<Add />}
      >
        Agregar Intervalo
      </Button>
      {get(errors, `${path}.curves`) && (
        <Box sx={{ gridColumnStart: 1, gridColumnEnd: 4 }}>
          <FormHelperText error>{get(errors, `${path}.curves.message`)}</FormHelperText>
        </Box>
      )}
    </Box>
  );
};

const FormCurveSection: FC<Props> = ({ control, path, setValue, getValues }) => {
  const curveType = useWatch({
    control,
    name: `${path}.type`,
  });

  const velocityModel = useWatch({ control, name: 'velocityCurve.model' });

  let functionTypeOptions = formSelectOptions.functionType.variant2;
  if (path === 'areaCurve')
    if (curveType !== 'piecewise') functionTypeOptions = formSelectOptions.functionType.variant3;

  if (path === 'velocityCurve')
    if (velocityModel !== 'N(h)')
      if (curveType === 'function' || curveType === 'interval')
        functionTypeOptions = formSelectOptions.functionType.variant1;

  if (curveType === 'interval' || curveType === 'function')
    return (
      <Box pt={4} display="grid" gap={2}>
        <Controller
          name={`${path}.curve.functionType`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth size="small">
              <InputLabel htmlFor={`${path}-function-type`}>Tipo de Función</InputLabel>
              <Select
                {...field}
                inputProps={{ id: `${path}-function-type` }}
                label="Tipo de Función"
                error={!!error}
              >
                {functionTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {error && <FormHelperText error>{error.message}</FormHelperText>}
            </FormControl>
          )}
        />
        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
          {curveType === 'interval' && (
            <>
              <Controller
                name={`${path}.curve.min`}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <NumericTextField
                    {...field}
                    size="small"
                    label="Intervalo Inicio"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">mm/s</InputAdornment>,
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              <Controller
                name={`${path}.curve.max`}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <NumericTextField
                    {...field}
                    size="small"
                    label="Intervalo Fin"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">mm/s</InputAdornment>,
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </>
          )}
        </Box>
        <Typography>Parámetros</Typography>
        <FormParametersSection control={control} path={`${path}.curve`} />
      </Box>
    );

  if (curveType === 'piecewise')
    return (
      <FormCurveSectionPiecewise
        control={control}
        setValue={setValue}
        getValues={getValues}
        functionTypeOptions={functionTypeOptions}
        path={path}
      />
    );
};

export default FormCurveSection;
