import * as Yup from 'yup';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{10,}$/; // 10 characters, 1 uppercase, 1 lowercase, 1 number, 1 special character


export const validationSchema = (editPassword: boolean = false) => { 

  const username = Yup.string()
  .email('El correo electrónico tiene que ser válido.')
  .max(255)

  const name = Yup.string().max(255)
  const lastnames = Yup.string().max(255)
  const code = Yup.object()
  const phone_number = Yup.string().matches(
    /^\d{9,11}$/,
    'El formato correcto es el siguiente: Eje. 997313442',
  )
  const passwordSchema = Yup
  .string()
  .matches(
    passwordRegex,
    'La contraseña debe tener al menos 10 caracteres, una mayúscula, una minúscula, un número y un caracter especial.',
  );

  const confirmPasswordSchema = Yup
  .string()
  .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir.');

  return Yup.object({
    id: Yup.number(),
    username: editPassword ? username.transform((value) => value || null).nullable() : username.required('El email es requerido.'),
    code: editPassword ? code.transform((value) => value || null).nullable() : code.required('El código de país es requerido.'),
    name: editPassword ? name.transform((value) => value || null).nullable() : name.required('El nombre es requerido.'),
    lastnames: editPassword ? lastnames.transform((value) => value || null).nullable() : lastnames.required('El apellido es requerido.'),
    phone_number: editPassword ? phone_number.transform((value) => value || null).nullable() : phone_number.required('El número de teléfono es requerido.'),
    password: !editPassword
    ? passwordSchema.transform((value) => value || null).nullable()
    : passwordSchema.required('La contraseña es requerida.'),
    confirmPassword: !editPassword
    ? confirmPasswordSchema.transform((value) => value || null).nullable()
    : confirmPasswordSchema.required('La confirmación de contraseña es requerida.'),

  })

}




