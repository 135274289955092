import CreateDGAConfigurationModal from './CreateDGAConfigurationModal';
import DGAReportsRetryModal from './DGAReportsRetryModal';
import DGAUpdateMaxFlowModal from './DGAUpdateMaxFlowModal';
import DGAReportsChartModal from './reports/ChartModal';
import DGAReportsTableModal from './reports/TableModal';
import UpdateDGAConfigurationModal from './UpdateDGAConfigurationModal';
import UpdateDGANotificationConfigurationModal from './UpdateDGANotificationConfigurationModal';
import UpdateDGAScheduleModal from './UpdateDGAScheduleModal';
import UpdateDGATicketState from './UpdateDGATicketStateModal';
import { TicketConfiguration } from './ticketConfiguration';

const DGAModals = () => {
  return (
    <>
      <CreateDGAConfigurationModal />
      <UpdateDGAConfigurationModal />
      <UpdateDGAScheduleModal />
      <UpdateDGANotificationConfigurationModal />
      <DGAReportsChartModal />
      <DGAReportsRetryModal />
      <DGAUpdateMaxFlowModal />
      <TicketConfiguration />
      <UpdateDGATicketState />
      <DGAReportsTableModal />
    </>
  );
};

export default DGAModals;
