import { gql } from 'src/__generated__';

const GET_DEVICE_FLOW_LIMITS = gql(`
  query GetDeviceFlowLimits($deviceId: String!) {
    deviceDataConfiguration(deviceId: $deviceId) {
      deviceId
      state {
        isDry
      }
      flowLimits {
        min
        max
      }
    }
  }
`);

export default GET_DEVICE_FLOW_LIMITS;
