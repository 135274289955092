import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userProfileModal: {
    open: false,
    userId: null as number | null,
    source: 'userProfile' as 'userProfile' | 'userAdministration',
  },
  updateUserProfileModal: {
    open: false,
    userId: null as number | null,
  },
  advancedUpdateUserProfileModal: {
    open: false,
    userId: null as number | null,
  },
};

const slice = createSlice({
  name: 'user_profile_store',
  initialState,
  reducers: {
    openUserProfileModal(
      state,
      action: { payload: { userId: number; source?: 'userProfile' | 'userAdministration' } },
    ) {
      state.userProfileModal = {
        open: true,
        userId: action.payload.userId,
        source: action.payload.source || 'userProfile',
      };
    },
    closeUserProfileModal(state) {
      state.userProfileModal = {
        ...initialState.userProfileModal,
        open: false,
      };
    },
    openUpdateUserProfileModal(state, action: { payload: { userId: number } }) {
      state.updateUserProfileModal = {
        open: true,
        userId: action.payload.userId,
      };
    },
    closeUpdateUserProfileModal(state) {
      state.updateUserProfileModal = {
        ...state.updateUserProfileModal,
        open: false,
      };
    },
    openAdvancedUpdateUserProfileModal(state, action: { payload: { userId: number } }) {
      state.advancedUpdateUserProfileModal = {
        open: true,
        userId: action.payload.userId,
      };
    },
    closeAdvancedUpdateUserProfileModal(state) {
      state.advancedUpdateUserProfileModal = {
        ...state.advancedUpdateUserProfileModal,
        open: false,
      };
    },
  },
});

const { actions, reducer } = slice;

export const {
  openUserProfileModal,
  closeUserProfileModal,
  openUpdateUserProfileModal,
  closeUpdateUserProfileModal,
  openAdvancedUpdateUserProfileModal,
  closeAdvancedUpdateUserProfileModal,
} = actions;
export default reducer;
