// react
import { useRef, useState } from 'react';
import type { FC } from 'react';

import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';

// icons
import CogIcon from 'src/icons/Cog';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

// hooks
import useAuth from 'src/hooks/useAuth';
// redux
import { useDispatch } from 'src/store';
import { resetGlobalStore } from 'src/slices/config';
import { resetMapStore } from 'src/slices/map';
import {
  resetStationsStore,
  setShowPerfil,
  setShowAccount,
  setSelectedAccount,
} from 'src/slices/stations';
import { resetDistributionStore } from 'src/slices/distribution';
import { restoreGateStore } from 'src/slices/gates';
import { restoreComponentStore } from 'src/features/integrations/slices/integrations';

// graphql
import { useApolloClient } from '@apollo/client';

// utils
import { get } from 'lodash';
import toast from 'react-hot-toast';
import { useLingui } from '@lingui/react';
import { Trans, msg } from '@lingui/macro';
import { openUpdateUserProfileModal } from 'src/features/userProfile/slices/userProfileSlice';

const AccountPopover: FC = () => {
  const { _ } = useLingui();
  const dispatch = useDispatch();
  const apolloClient = useApolloClient();

  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { user, logout } = useAuth();
  const [open, setOpen] = useState<boolean>(false);

  if (!user) return null;

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      window.localStorage.removeItem('icn');
      window.localStorage.removeItem('accessToken');
      window.localStorage.removeItem('user');
      dispatch(resetGlobalStore());
      dispatch(resetMapStore());
      dispatch(resetStationsStore());
      dispatch(resetDistributionStore());
      dispatch(restoreGateStore());
      dispatch(restoreComponentStore());
      apolloClient.stop();
      apolloClient.clearStore();
      logout();
    } catch (err) {
      console.error(err);
      toast.error(_(msg`No se pudo cerrar la sesión`));
    }
  };

  const onEditProfileClick = () => {
    dispatch(openUpdateUserProfileModal({ userId: user.id }));
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Avatar
          sx={{
            height: 32,
            width: 32,
          }}
          src={get(user, 'picture', '')}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box p={2}>
          <Typography color="textPrimary" variant="subtitle2">
            {get(user, 'name', '')} {get(user, 'lastnames', '')}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 0 }}>
          <MenuItem
            id="AccountCircleRoundedIcon"
            onClick={() => {
              dispatch(setShowAccount(true));
              dispatch(setSelectedAccount(user));
            }}
          >
            <ListItemIcon>
              <AccountCircleRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  <Trans>Perfil</Trans>
                </Typography>
              }
            />
          </MenuItem>
          <MenuItem id="CogIcon" onClick={onEditProfileClick}>
            <ListItemIcon>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  <Trans>Editar Perfil</Trans>
                </Typography>
              }
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button color="primary" fullWidth onClick={handleLogout} variant="outlined" id="logout">
            <Trans>Cerrar sesión</Trans>
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
