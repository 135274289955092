import { EventPoint } from 'src/__generated__/graphql';
import RowTable from './RowTable';
import { GroupedEvent } from '../data/types';

interface Props {
  items: EventPoint[] | GroupedEvent[],
  page: number,
  rowsPerPage: number,
  grouped: boolean,
  deviceId: string,
}

const BodyTable = ({ items, page, rowsPerPage, grouped, deviceId }: Props) => {
  const offset = page * rowsPerPage;
  return items
    .slice(offset, offset + rowsPerPage)
    .map((row, index) => <RowTable key={'event-' + index} grouped= {grouped} deviceId={deviceId} userId={(row as EventPoint)?.userId ?? ''} {...row} />);
};

export default BodyTable;
