export const getRelativeTimeOptions = (
  timestamp: number,
  now: number,
  unit?: Intl.RelativeTimeFormatUnit | null,
) => {
  unit = 'second';
  let value = Math.floor((timestamp - now) / 1000);
  const absValue = Math.abs(value);
  if (absValue > 59) [value, unit] = [Math.floor(value / 60), 'minute'];
  if (absValue > 3599) [value, unit] = [Math.floor(value / 60), 'hour'];
  if (absValue > 86399) [value, unit] = [Math.floor(value / 24), 'day'];
  if (absValue > 2591999) [value, unit] = [Math.floor(value / 30.44), 'month'];
  if (absValue > 31535999) [value, unit] = [Math.floor(value / 365.25), 'year'];

  return [value, unit] as const;
};
