import { useQuery } from '@apollo/client';
import FETCH_ACCOUNT_STATIONS from '../graphql/querys/fetchAccountStations';
import { User } from 'src/__generated__/graphql';

const useAccessAndAlarm = (deviceId: string) => {
  const { data, loading, refetch } = useQuery(FETCH_ACCOUNT_STATIONS, {
    variables: {
      input: {
        id: deviceId,
      },
    },
  });
  const users = (data?.device?.users ?? []).filter((user) => user !== null) as User[];
  return {
    users,
    loading,
    refetch,
  };
};

export default useAccessAndAlarm;
