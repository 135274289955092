import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

const ROLE_COLOR: Record<number, string> = {
  1: '#00e393',
  2: '#03fcf4',
  3: '#ff7373',
  4: '#b70fff',
};

export const getRoleColor = (roleId: number) => {
  return ROLE_COLOR[roleId] ?? '#ddd';
};

export const getRoleName = (roleId: number, i18n: I18n) => {
  if (roleId === 1) return t(i18n)`Capta Hydro`;
  if (roleId === 2) return t(i18n)`Administrador`;
  if (roleId === 3) return t(i18n)`Operador`;
  if (roleId === 4) return t(i18n)`Observador`;
  return t(i18n)`Rol desconocido`;
};

export default getRoleColor;
