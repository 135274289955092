import { SvgIcon, SvgIconProps } from '@mui/material';

const AlertIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <rect opacity={0.2} x=".5" y=".5" width="23" height="23" rx="3" ry="3" />
    <path d="m12.07,6.45l6.23,11.72H5.84l6.23-11.72m0-2.77c-.33,0-.66.17-.85.52l-7.57,14.25c-.36.68.11,1.52.85,1.52h15.13c.74,0,1.21-.84.85-1.52l-7.57-14.25c-.19-.35-.52-.52-.85-.52h0Z" />
    <path d="m12.07,14.73c-.41,0-.75-.34-.75-.75v-3.38c0-.41.34-.75.75-.75s.75.34.75.75v3.38c0,.41-.34.75-.75.75Z" />
    <path d="m12.09,17.06h-.06c-.41,0-.75-.34-.75-.75s.34-.75.75-.75.78.34.78.75-.31.75-.72.75Z" />
    <path d="m20.5,24H3.5c-1.93,0-3.5-1.57-3.5-3.5V3.5C0,1.57,1.57,0,3.5,0h17C22.43,0,24,1.57,24,3.5v17c0,1.93-1.57,3.5-3.5,3.5ZM3.5,2c-.83,0-1.5.67-1.5,1.5v17c0,.83.67,1.5,1.5,1.5h17c.83,0,1.5-.67,1.5-1.5V3.5c0-.83-.67-1.5-1.5-1.5H3.5Z" />
  </SvgIcon>
);

export default AlertIcon;
