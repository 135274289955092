import { i18n } from '@lingui/core';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import 'dayjs/locale/en';
import { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import GET_USER_PREFERENCES from 'src/graphql/querys/getUserPreferences';
import { getLanguageOptions } from 'src/utils/i18n/getLanguageOptions';
import { Language } from '@/__generated__/graphql';
import UPDATE_USER_LANGUAGE from '@/graphql/mutations/updateUserPreferencesRegion';
import useModalStatusMessage from './useModalStatusMessage';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

// TODO: cambiar cuando se pase a producción "idiomas"
// const DEFAULT_LOCALE = navigator.language.startsWith('en') ? 'en' : 'es';
const DEFAULT_LOCALE = 'es';

const dynamicActivate = async (locale: string) => {
  const { messages } = await import(`../locales/${locale}.po`);

  i18n.load(locale, messages);
  i18n.activate(locale);
  dayjs.locale(locale);
};

dynamicActivate(DEFAULT_LOCALE);

const languageOptions = getLanguageOptions('en');

const getLanguageValue = (language?: Language) => {
  return languageOptions.find((_language) => _language.enumValue === language)?.value;
};

export const useI18n = () => {
  const { _ } = useLingui();
  const { data: userPreferencesData } = useQuery(GET_USER_PREFERENCES);
  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const [updateLanguageMutation] = useMutation(UPDATE_USER_LANGUAGE, {
    onCompleted() {
      openModalSuccessMessage(_(msg`Lenguaje actualizado correctamente`));
    },
    onError(error) {
      openModalErrorMessage(error.message);
    },
  });

  const updateLanguage = (language: 'es' | 'en') => {
    if (!userPreferencesData) return;
    updateLanguageMutation({
      variables: {
        input: {
          userId: userPreferencesData?.user.id,
          locale: language === 'es' ? Language.Es : Language.En,
        },
      },
    });
  };

  useEffect(() => {
    const localeEnum = userPreferencesData?.user.preferences.region.locale;
    const locale = getLanguageValue(localeEnum) ?? DEFAULT_LOCALE;
    dynamicActivate(locale);
  }, [userPreferencesData?.user.preferences.region.locale]);

  return {
    language:
      getLanguageValue(userPreferencesData?.user.preferences.region.locale) ?? DEFAULT_LOCALE,
    updateLanguage,
  };
};
