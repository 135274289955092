import { gql } from '@/__generated__';

const GET_DEVICE_PROFILE_GENERAL = gql(`#graphql
  query DeviceProfile_GetDeviceProfileGeneral($deviceId: ID!) {
    device(input: { id: $deviceId }) {
      id
      profile {
        id
        alias
        originalName
        type
        created
        status
        location {
          latitude
          longitude
        }
        organization {
          id
          name
        }
        irrigation {
          id
          name
        }
      }
      hardwareConfiguration {
        id
        nameProject
        dateAction
        idEmnify
        energizationOption {
          id
          name
        }
        dataConnectionOption {
          id
          name
        }
        mobileOperatorOption {
          id
          name
        }
        cpuOption {
          id
          name
        }
      }
    }
  }
`);

export default GET_DEVICE_PROFILE_GENERAL;
