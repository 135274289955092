import './coming-soon.css';

const ComingSoon = () => {
  return (
    <div className="scene">
      <div className="scene_titanShadow"></div>
      <div className="t_wrap">
        <div className="scene_titan">
          <div className="eyes">
            <div className="eye eye--left"></div>
            <div className="eye eye--right"></div>
          </div>
        </div>
      </div>
      <div className="scene_saturn">
        <div className="scene_saturn__face">
          <div className="face_clip">
            <div className="eye eye--left"></div>
            <div className="eye eye--right"></div>
            <div className="mouth"></div>
          </div>
        </div>
        <div className="scene_saturn__shadow"></div>
        <div className="scene_saturn__shadowRing"></div>
        <div className="scene_saturn__sparks">
          <div className="spark"></div>
          <div className="spark"></div>
          <div className="spark"></div>
          <div className="spark"></div>
          <div className="spark"></div>
          <div className="spark"></div>
          <div className="spark"></div>
          <div className="spark"></div>
          <div className="spark"></div>
          <div className="spark"></div>
          <div className="spark"></div>
          <div className="spark"></div>
          <div className="spark"></div>
          <div className="spark"></div>
          <div className="spark"></div>
          <div className="spark"></div>
          <div className="spark"></div>
          <div className="spark"></div>
          <div className="spark"></div>
          <div className="spark"></div>
        </div>
        <div className="scene_saturn__ring">
          <div className="small">
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
            <div className="small_part"></div>
          </div>
          <div className="layer">
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
          </div>
          <div className="layer">
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
          </div>
          <div className="layer">
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
            <div className="layer_part"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
