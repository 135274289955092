import ChangeStatusDevice from '@features/changeStatusDevice/components/ChangeStatusDevice';
import { CloneDevice } from '@features/deviceClone/components/CloneDevice';
import UpdateStationComponent from '@features/stationUpdate/components/UpdateStation';
import { Device } from 'src/types/device';
import {
  CreateMeasurement,
  DeleteStation,
  DeviceNameAliases,
  ManualDataDetails,
} from '.';
import { DialogEnum } from '../DialogEnum';
import AccessEndAlarm from './accessEndAlarm';

interface Props {
  dialogToShow: DialogEnum;
  handleDialogClose: (refetch: boolean) => void;
  device: Device;
}

const DialogComponents = ({ dialogToShow, handleDialogClose, device }: Props) => {
  const handleEditClose = (refetch: boolean) => {
    handleDialogClose(refetch);
  };

  const handleCloseChangeStatus = (refetch: boolean) => {
    handleDialogClose(refetch);
  };

  const handleCloseManualDataInfo = () => {
    handleDialogClose(false);
  };

  const handleUserListClose = () => {
    handleDialogClose(false);
  };

  const handleDeleteClose = (refetch: boolean) => {
    handleDialogClose(refetch);
  };

  const handleCreateMeasurementClose = (refetch: boolean) => {
    handleDialogClose(refetch);
  };

  const handleDeviceNameAliasesClose = (refetch: boolean) => {
    handleDialogClose(refetch);
  };

  const handleCloseClone = (refetch: boolean) => {
    handleDialogClose(refetch);
  };

  return (
    <>
      <ChangeStatusDevice
        show={dialogToShow === DialogEnum.changeStatus}
        device={device}
        handleClose={handleCloseChangeStatus}
      />
      <ManualDataDetails
        show={dialogToShow === DialogEnum.manualData}
        device={device}
        handleClose={handleCloseManualDataInfo}
      />
      <UpdateStationComponent
        show={dialogToShow === DialogEnum.edit}
        device={device}
        handleClose={handleEditClose}
      />
      <AccessEndAlarm
        show={dialogToShow === DialogEnum.userList}
        device={device}
        handleClose={handleUserListClose}
      />
      <DeleteStation
        show={dialogToShow === DialogEnum.delete}
        device={device}
        handleClose={handleDeleteClose}
      />
      <CreateMeasurement
        show={dialogToShow === DialogEnum.createMeasurement}
        device={device}
        handleClose={handleCreateMeasurementClose}
      />
      <DeviceNameAliases
        show={dialogToShow === DialogEnum.deviceNameAliases}
        device={device}
        handleClose={handleDeviceNameAliasesClose}
      />
      <CloneDevice
        show={dialogToShow === DialogEnum.clone}
        device={device}
        handleClose={handleCloseClone}
      />
    </>
  );
};

export default DialogComponents;
