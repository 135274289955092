import { gql } from '@/__generated__';

const GET_ACCESS_DEVICE_OPTIONS = gql(`#graphql
  query GetAccessDeviceOptions($input: AuthorizationInput!) {
    authorization {
      authorizationDevice(input: $input) {
        deviceId
        update
        updateState
        updateAlarm
        updateAlias
        viewProfile
        viewStateHistorial
        viewUserAccess
        viewDeviceConfiguration
        delete
        clone
      }
    }
  }
`);

export default GET_ACCESS_DEVICE_OPTIONS;
