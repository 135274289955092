import { gql } from '@/__generated__';
import { GetNotificationsQuery } from '@/__generated__/graphql';

export type Notification = GetNotificationsQuery['notifications'][number];

const GET_NOTIFICATIONS = gql(`#graphql
  query GetNotifications {
    notifications {
      id
      status
      notificationObject {
        id
        status
        entity {
          ... on Announcement {
            __typename
            id
            title
            createdAt
            description
          }
        }
      }
    }
  }
`);

export default GET_NOTIFICATIONS;
