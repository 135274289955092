import { gql } from '@/__generated__';
import { Gate_GetGateMovementsQuery } from 'src/__generated__/graphql';

export type GateMovements = NonNullable<Gate_GetGateMovementsQuery['gate']['movements']>;

const GET_GATE_MOVEMENTS = gql(`
query Gate_GetGateMovements($input: GateInput!) {
    gate(input: $input) {
      id
      movements {
        unit
        precision
        data {
          id
          completed
          finishPosition
          finishTimestamp
          requestedPosition
          startPosition
          startTimestamp
          timestamp
          userName
          state
          message
        }
      }
    }
  }
`);

export default GET_GATE_MOVEMENTS;
