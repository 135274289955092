import { SvgIcon, SvgIconProps } from '@mui/material';

const GearIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <rect opacity={0.2} x=".5" y=".5" width="23" height="23" rx="3" ry="3" />
    <path d="m14.28,20.2c-.48,0-.95-.26-1.2-.7-.1-.18-.26-.33-.44-.44-.29-.17-.63-.22-.96-.13-.33.08-.6.29-.77.58-.35.56-1.01.81-1.63.62-.42-.13-.83-.3-1.22-.52-.55-.29-.81-.94-.6-1.53.06-.19.06-.44,0-.67-.19-.67-.89-1.06-1.56-.87-.59.16-1.22-.12-1.5-.66-.21-.43-.38-.86-.52-1.29-.22-.58.05-1.25.64-1.54.35-.2.59-.62.59-1.07,0-.46-.24-.88-.63-1.1-.52-.33-.76-.93-.6-1.49.14-.47.32-.93.56-1.36.31-.54.92-.78,1.49-.61.41.11.87-.02,1.19-.34.31-.31.44-.76.33-1.19-.12-.62.14-1.19.65-1.47.3-.15.58-.28.87-.39.31-.13.99-.43,1.59.02.04.03.08.06.11.1.16.17.31.35.44.55.01,0,.08.08.16.13.21.15.41.23.61.26.02-.01.13,0,.14,0h0c.43,0,.83-.22,1.06-.58.11-.18.36-.6.96-.66.42,0,.81.07,1.14.24.26.1.5.21.74.34.53.23.83.88.65,1.5-.11.41.02.87.33,1.18.32.32.78.44,1.22.33.53-.18,1.18.08,1.47.64.21.43.39.86.54,1.29.18.61-.06,1.22-.59,1.52-.38.22-.62.65-.62,1.1h0c0,.45.24.88.62,1.1.51.3.75.91.59,1.48-.13.46-.31.9-.53,1.33-.27.54-.9.83-1.5.65-.44-.13-.9,0-1.22.32-.34.34-.46.82-.33,1.26.18.56-.07,1.2-.61,1.49-.4.21-.8.37-1.2.51-.15.05-.3.08-.46.08Zm-5.02-2c.09.04.19.08.28.12.41-.55,1-.95,1.69-1.13.79-.21,1.62-.09,2.32.33.35.2.65.48.89.8.1-.04.19-.08.29-.12-.13-.96.18-1.92.87-2.6.67-.69,1.62-1,2.56-.87.04-.1.09-.21.12-.32-.74-.58-1.18-1.48-1.17-2.43,0-.94.44-1.83,1.17-2.41-.04-.11-.09-.21-.13-.32-.95.11-1.87-.21-2.53-.87-.67-.66-.99-1.6-.88-2.52-.08-.04-.16-.07-.24-.1-.03-.01-.06-.02-.09-.04-.58.73-1.45,1.16-2.39,1.17-.11,0-.25,0-.39-.02-.49-.05-.99-.25-1.42-.57-.19-.13-.4-.32-.58-.54,0-.01-.02-.03-.03-.04,0,0-.01,0-.02,0-.11.04-.22.09-.33.14.11.89-.21,1.84-.88,2.5-.67.67-1.62.99-2.54.88-.05.1-.09.21-.13.32.74.56,1.2,1.47,1.19,2.44,0,.95-.44,1.84-1.19,2.41.04.11.08.21.13.32,1.48-.2,2.92.73,3.34,2.21.12.42.15.85.08,1.28Zm9.37-3.57h0s0,0,0,0Z" />
    <path d="m12,15.38c-1.86,0-3.38-1.52-3.38-3.38s1.52-3.38,3.38-3.38,3.38,1.52,3.38,3.38-1.52,3.38-3.38,3.38Zm0-4.96c-.87,0-1.58.71-1.58,1.58s.71,1.58,1.58,1.58,1.58-.71,1.58-1.58-.71-1.58-1.58-1.58Z" />
    <path d="m20.5,24H3.5c-1.93,0-3.5-1.57-3.5-3.5V3.5C0,1.57,1.57,0,3.5,0h17C22.43,0,24,1.57,24,3.5v17c0,1.93-1.57,3.5-3.5,3.5ZM3.5,2c-.83,0-1.5.67-1.5,1.5v17c0,.83.67,1.5,1.5,1.5h17c.83,0,1.5-.67,1.5-1.5V3.5c0-.83-.67-1.5-1.5-1.5H3.5Z" />
  </SvgIcon>
);

export default GearIcon;
