import { gql } from '@/__generated__';

const GET_ACCESS_DEVICE_STATUS = gql(`#graphql
  query GetAccessDeviceStatus {
    authorization {
      authorizationGeneral {
        viewAllDeviceStatus
      }
    }
  }
`);

export default GET_ACCESS_DEVICE_STATUS;
