import { gql } from '@/__generated__';

const UPDATE_USER_PREFERENCES_REGION = gql(`#graphql
  mutation UpdateUserPreferencesRegion($input: UpdateUserPreferencesRegionInput!) {
    updateUserPreferencesRegion(input: $input) {
      id
      locale
      timezone
      numberLocale
      dateLocale
      hour12
      dateMonthFormat
    }
  }
`);

export default UPDATE_USER_PREFERENCES_REGION;
