import { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Box, Button, Grid, Typography } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import useWindowSize from 'src/hooks/useWindowSize';
import _ from 'lodash';
import { useDispatch, useSelector } from 'src/store';
import { setPicturePreview } from 'src/slices/components';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useFormatters } from 'src/hooks/useFormatters';
import { MetricUnit } from 'src/__generated__/graphql';

const PicturePreview = () => {
  const { _: __ } = useLingui();
  const { height } = useWindowSize();
  const dispatch = useDispatch();
  const {
    formatMetricValue,
    formatRelativeTime,
    formatDateTime,
  } = useFormatters();

  const {
    picturePreview: { show, picture, measurement, title, name },
  } = useSelector((state) => state.components_store);

  const [imageHeight, setImageHeight] = useState(0);

  useEffect(() => {
    setImageHeight(height ? height * 0.8 : 0);
  }, [height]);

  const onZoomIn = () => {
    setImageHeight(imageHeight * 1.1);
  };

  const onZoomOut = () => {
    setImageHeight(imageHeight * 0.8 < height * 0.8 ? height * 0.8 : imageHeight * 0.9);
  };

  const handleClose = () => {
    dispatch(setPicturePreview({ show: false }));
  };
  return (
    <Dialog open={show} onClose={handleClose} maxWidth={false} fullWidth>
      <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
        <DialogTitle>{title}</DialogTitle>
        <Box>
          <Button onClick={onZoomIn}>
            <ZoomInIcon
              fontSize="large"
              sx={{
                color: 'white',
              }}
            />
          </Button>
          <Button onClick={onZoomOut}>
            <ZoomOutIcon
              fontSize="large"
              sx={{
                color: 'white',
              }}
            />
          </Button>
          <Button onClick={() => setImageHeight(height * 0.8)} color="inherit">
            <Typography fontWeight="bold">
              <Trans>Zoom por defecto</Trans>
            </Typography>
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              ml: 5,
            }}
          >
            <Trans>Cerrar</Trans>
          </Button>
        </Box>
      </Box>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid container justifyContent="center">
          <Box maxHeight={height * 0.8} display="flex" justifyContent="center">
            <img
              alt={__(msg`Imagen de telemetría`)}
              src={picture}
              height={imageHeight}
              style={{
                borderRadius: '15px',
              }}
            />
            {!_.isNil(measurement) && (
              <Box
                sx={{
                  position: 'absolute',
                  alignSelf: 'flex-end',
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  my: 1,
                  borderRadius: '10px',
                  p: 2,
                }}
              >
                <Typography fontSize={13}>
                  {!_.isNil(name) ? (
                    <>
                      <Trans>Nombre</Trans>: {name}
                    </>
                  ) : (
                    ''
                  )}
                </Typography>
                <Typography fontSize={13}>
                  {!_.isNil(measurement.level) && typeof measurement.level === 'number' ? (
                    <>
                      <Trans>Nivel</Trans>:{' '}
                      {formatMetricValue(measurement.level, {
                        unit: MetricUnit.Centimeter,
                        precision: 0,
                      })}
                    </>
                  ) : (
                    ''
                  )}
                  {!_.isNil(measurement.level) && typeof measurement.level === 'string' ? (
                    <>
                      <Trans>Nivel</Trans>:{' '}
                      {formatMetricValue(parseInt(measurement.level), {
                        unit: MetricUnit.Centimeter,
                        precision: 0,
                      })}
                    </>
                  ) : (
                    ''
                  )}
                </Typography>
                <Typography fontSize={13}>
                  {!_.isNil(measurement.flow) && typeof measurement.flow === 'number' ? (
                    <>
                      <Trans>Caudal</Trans>: $
                      {formatMetricValue(measurement.flow, {
                        unit: MetricUnit.LiterPerSecond,
                        precision: 1,
                      })}
                    </>
                  ) : (
                    ''
                  )}
                  {!_.isNil(measurement.flow) && typeof measurement.flow === 'string' ? (
                    <>
                      <Trans>Caudal</Trans>:{' '}
                      {formatMetricValue(parseInt(measurement.flow), {
                        unit: MetricUnit.LiterPerSecond,
                        precision: 1,
                      })}
                    </>
                  ) : (
                    ''
                  )}
                </Typography>
                <Typography fontSize={13}>
                  {measurement?.measured ? formatDateTime(parseInt(measurement.measured)) : '-'}
                </Typography>
                <Typography fontSize={13}>
                  <Trans>Medido</Trans>{' '}
                  {measurement?.measured ? formatRelativeTime(parseInt(measurement.measured)) : '-'}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PicturePreview;
