import { DeleteTwoTone } from '@mui/icons-material';
import { InputAdornment, IconButton, Button, Divider, FormHelperText } from '@mui/material';
import { Box } from '@mui/system';
import { type FC } from 'react';
import { Control, Controller, useFieldArray, useFormState, useWatch } from 'react-hook-form';
import { type FormValues } from './RadarParametersTab';
import NumericTextField from '@components/NumericTextField';
import AddCircle from '@mui/icons-material/AddCircle';
import { get } from 'lodash';

interface Props {
  control: Control<FormValues>;
  path:
    | `${'areaCurve' | 'velocityCurve'}.curve`
    | `${'areaCurve' | 'velocityCurve'}.curves.${number}`;
}

const FormParametersSection: FC<Props> = ({ control, path }) => {
  const functionType = useWatch({
    control,
    name: `${path}.functionType`,
    exact: true,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${path}.parameters.a`,
  });

  const { errors } = useFormState({
    control,
  });

  if (functionType === 'chiu') {
    return (
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
        <Controller
          name={`${path}.parameters.vMaxDepthNorm`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <NumericTextField
              {...field}
              size="small"
              label="h/D"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        {/* CURVA DE VELOCIDAD / M */}
        <Controller
          name={`${path}.parameters.m`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <NumericTextField
              {...field}
              size="small"
              label="m"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Box>
    );
  }

  if (functionType === 'polynomial') {
    return (
      <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={2}>
        {get(errors, `${path}.parameters.a.message`) && (
          <Box sx={{ gridColumnStart: 1, gridColumnEnd: 4 }}>
            <FormHelperText error>{get(errors, `${path}.parameters.a.message`)}</FormHelperText>
          </Box>
        )}
        {fields.map((field, index) => (
          <Controller
            key={field.id}
            name={`${path}.parameters.a.${index}.value`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <NumericTextField
                {...field}
                size="small"
                label={
                  <>
                    a<sub>{index}</sub>
                  </>
                }
                error={!!error}
                helperText={error?.message}
                InputProps={{
                  endAdornment:
                    fields.length > 1 ? (
                      <InputAdornment sx={{ height: '100%', mr: -1 }} position="end">
                        <Box display="flex" gap={0.5} height="88%">
                          <Divider orientation="vertical" />
                          <IconButton
                            size="small"
                            aria-label="Eliminar campo"
                            onClick={() => remove(index)}
                          >
                            <DeleteTwoTone color="info" fontSize="small" />
                          </IconButton>
                        </Box>
                      </InputAdornment>
                    ) : undefined,
                }}
              />
            )}
          />
        ))}
        <Button onClick={() => append({ value: 0 })} size="medium" startIcon={<AddCircle />}>
          Agregar
        </Button>
      </Box>
    );
  }

  if (functionType === 'trapezoidal') {
    return (
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
        <Controller
          name={`${path}.parameters.baseWidth`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <NumericTextField
              {...field}
              size="small"
              label="Ancho Basal (B)"
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
              }}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        {/* CURVA DE VELOCIDAD / M */}
        <Controller
          name={`${path}.parameters.talud`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <NumericTextField
              {...field}
              size="small"
              label="Talud (K)"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Box>
    );
  }

  if (functionType === 'power') {
    return (
      <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={2}>
        <Controller
          name={`${path}.parameters.A`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <NumericTextField
              {...field}
              size="small"
              label="A"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name={`${path}.parameters.B`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <NumericTextField
              {...field}
              size="small"
              label="B"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name={`${path}.parameters.C`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <NumericTextField
              {...field}
              size="small"
              label="C"
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      </Box>
    );
  }
};

export default FormParametersSection;
