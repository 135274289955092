import { Alert, Snackbar } from '@mui/material';
import { setSnackbar } from 'src/slices/components';
import { useDispatch, useSelector } from 'src/store';

const GlobalSnackbar = () => {
  const dispatch = useDispatch();

  const {
    snackbar: { open, message, anchorOrigin, severity },
  } = useSelector((state) => state.components_store);

  const handleClose = () => {
    dispatch(
      setSnackbar({
        open: false,
        message: null,
      }),
    );
  };

  return (
    <Snackbar anchorOrigin={anchorOrigin} open={open} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default GlobalSnackbar;
