import { gql } from '@apollo/client';

const DELETE_MOVE_REQUEST = gql`
  mutation DeleteMoveRequest($deviceId: String!) {
    DeleteMoveRequest(deviceId: $deviceId) {
      success
    }
  }
`;

export default DELETE_MOVE_REQUEST;
