import { gql } from 'src/__generated__';

const AUTHENTICATE_USER_TWO_FACTORS = gql(`
  mutation AuthenticateUserTwoFactors(
    $email: String
    $password: String
    $iv: String
    $code: String
  ) {
    authenticateUserTwoFactors(email: $email, password: $password, iv: $iv, code: $code) {
      id
      email
      username
      name
      lastnames
      phone
      calling_code
      phone_number
      token
      picture
      organization_id
      updatedPassword
      organizationsArray
      status
      access {
        controlCenter
        allDevices
        stations
        users
        virtualStations
        integrations
        dataAnalytics
        dataAdvanceAnalytics
        gates
        systems
        distribution
        dgaReports
        dataExtraction
        deviceConfiguration
        faq
        dataTables
        offLogin
        permits
        distributionMaipo
        apiCapta
        metricView
        deviceDiagnostics
        sendCommand
        channelNetwork
        audit
        standardDesign
        announcements
        notifications
        organizations
        billing
        alerts
        scheduledReports
        smaReports
        alertNotification
      }
      role {
        id
        name
      }
      organizations {
        id
        name
        address
        website
        phone
        role {
          id
          name
        }
      }
    }
  }
`);

export default AUTHENTICATE_USER_TWO_FACTORS;
