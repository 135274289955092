import { gql } from '@apollo/client';

const STOP_MOVING_GATE = gql`
  mutation StopMovingGate($deviceId: String!) {
    StopMovingGate(device_id: $deviceId) {
      success
      message
    }
  }
`;

export default STOP_MOVING_GATE;
