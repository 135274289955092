import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { CircularProgress, IconButton } from '@mui/material';
import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import MapMarker from 'src/features/controlCenter/components/map/MapMarker';
import StationMarkerIcon from 'src/features/controlCenter/components/map/StationMarkerIcon';

const INITIAL_ZOOM = 14;

interface Props {
  position: google.maps.LatLngLiteral;
  onMapClick?: (position: google.maps.LatLngLiteral) => void;
  handleChangeMarkerPosition?: (position: google.maps.LatLngLiteral) => void;
}

const Map: FC<Props> = ({
  position,
  handleChangeMarkerPosition,
  onMapClick,
}) => {
  const [map, setMap] = useState<google.maps.Map>();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    const newMap = new google.maps.Map(ref.current, {
      center: position,
      zoom: INITIAL_ZOOM,
      mapTypeId: 'satellite',
      // https://developers.google.com/maps/documentation/javascript/advanced-markers/start#create_a_map_id
      mapId: import.meta.env.VITE_REACT_APP_MAP_ID ?? 'DEMO_MAP_ID',
    });

    setMap(newMap);
  }, []);

  useEffect(() => {
    if (map) {
      map.setCenter(position);
    }
  }, [map, position]);
  
  useEffect(() => {
    if (onMapClick && map)
      map.addListener(
        'click',
        (event: { latLng: { toJSON: () => google.maps.LatLngLiteral } }) => {
          if (event?.latLng?.toJSON()) {
            onMapClick(event.latLng.toJSON());
          }
        },
      );

    return () => {
      if (map) {
        map.addListener('click', () => {});
      }
    };
  }, [map, onMapClick]);

  return (
    <>
      <div ref={ref} style={{ width: '100%', height: '100%' }} />
      {map && (
        <MapMarker
          gmpDraggable
          position={position}
          map={map}
          onDragEnd={handleChangeMarkerPosition}
        >
          <IconButton>
            <StationMarkerIcon
              fontSize="large"
              primaryColor="white"
              secondaryColor="black"
            />
          </IconButton>
        </MapMarker>
      )}
    </>
  );
};

const MapWrapper: FC<Props> = (props) => {
  const render = (status: Status): ReactElement => {
    if (status === Status.LOADING) return <CircularProgress />;
    if (status === Status.FAILURE) return <h3>{status} ...</h3>;
    return <Map {...props} />;
  };

  return (
    <Wrapper
      version="beta"
      libraries={['marker']}
      apiKey={import.meta.env.VITE_REACT_APP_MAP_API_KEY ?? ''}
      render={render}
    />
  );
};

export default MapWrapper;
