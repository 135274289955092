import { gql } from '@/__generated__';

const NOTIFICATION_DELETED = gql(`#graphql
  subscription NotificationDeleted {
    notificationDeleted {
      notification {
        id
      }
    }
  }
`);

export default NOTIFICATION_DELETED;
