import { StandardAutocomplete, useSuspenseAutocompleteOptions } from '@features/standardDesign';
import { useDispatch } from 'react-redux';
import { useSelector } from '@/store';
import { setParameters } from '../../../slices/devicesAnalysisSlice';
import { type FC } from 'react';
import { useLingui } from '@lingui/react';
import { msg } from '@lingui/macro';

const SidebarComparisonDevices: FC = () => {
  const dispatch = useDispatch();
  const { _ } = useLingui();
  const { data: options } = useSuspenseAutocompleteOptions('devices', {
    includeGates: true,
  });
  const {
    parameters: { comparisonDeviceIds },
  } = useSelector((state) => state.devices_analysis_store);

  return (
    <StandardAutocomplete
      dataType="devices"
      multiple
      label={_(msg`Dispositivos a comparar`)}
      value={options.devices?.filter((option) => comparisonDeviceIds.includes(option.id))}
      onChange={(_, options) => {
        const newIds = options.map((option) => option.id);
        dispatch(
          setParameters({
            comparisonDeviceIds: newIds,
          }),
        );
      }}
      queryOptions={{
        includeGates: true,
      }}
    />
  );
};

export default SidebarComparisonDevices;
