import { useState } from 'react';
import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import OrganizationSettingDrawer from './organizationConfiguration/OrganizationSettingDrawer';

const OrganizationSetting = () => {
  const [showSetting, setShowSetting] = useState<boolean>(false);

  const handleOpen = () => {
    setShowSetting(!showSetting);
  };

  // El close ocurre cuando el usuario clickea fuera del Drawer
  const handleClose = () => {
    setShowSetting(false);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <SettingsIcon />
      </IconButton>
      <OrganizationSettingDrawer open={showSetting} handleClose={handleClose} />
    </>
  );
};

export default OrganizationSetting;
