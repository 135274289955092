import { SvgIcon, SvgIconProps } from '@mui/material';

function GatesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="m20.62,6.1h-7.08v-3.29h5.47c.58,0,1.05-.47,1.05-1.05s-.47-1.05-1.05-1.05H5.73c-.58,0-1.05.47-1.05,1.05s.47,1.05,1.05,1.05h5.7v3.29H3.79c-.91,0-1.64.74-1.64,1.64v13.9c0,.91.74,1.64,1.64,1.64h16.83c.91,0,1.64-.74,1.64-1.64V7.75c0-.91-.74-1.64-1.64-1.64Zm-1.61,13.76H5.35c-.58,0-1.05-.47-1.05-1.05s.47-1.05,1.05-1.05h13.66c.58,0,1.05.47,1.05,1.05s-.47,1.05-1.05,1.05Zm1.15-11.66v6.42h-6.62v-6.42h6.62Zm-8.72,0v6.42h-7.19v-6.42h7.19Z" />
    </SvgIcon>
  );
}

export default GatesIcon;
