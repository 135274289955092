import { gql } from '@/__generated__';

const GET_ANNOUNCEMENT = gql(`#graphql
  query GetAnnouncement($input: AnnouncementInput!) {
    announcement(input: $input) {
      id
      title
      description
      visibility
      content
      author
      publisher {
        id
        name
      }
      categories {
        id
        name
        color
      }
      createdAt
      updatedAt
    }
  }
`);

export default GET_ANNOUNCEMENT;
