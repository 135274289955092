import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Canal } from '@/types/canal';
import { type StandardAutocompleteValue } from '@features/standardDesign';

type Irrigation = StandardAutocompleteValue<'irrigations'>;

interface LastFlow {
  canalId: string;
  flow: number;
  measured: string;
}

interface DistributionState {
  selectedIrrigation: Irrigation | null;
  averageCanalFlows: LastFlow[];
  irrigationName: string | null;
  startDate: string | null;
  startTime: string | null;
  totalFlow: number | null;
  showSendConfirmation: boolean;
  sendDistributionLoading: boolean;
  currentCanalNote: string | null;
  currentCanalFlow: string | null;
  distributionNote: string | null;
  canals: Canal[] | null;
  submitError: string | null;
}

const initialState: DistributionState = {
  selectedIrrigation: null,
  averageCanalFlows: [],
  irrigationName: null,
  startDate: null,
  startTime: null,
  totalFlow: null,
  showSendConfirmation: false,
  sendDistributionLoading: false,
  currentCanalNote: null,
  currentCanalFlow: null,
  distributionNote: null,
  canals: null,
  submitError: null,
};

const slice = createSlice({
  name: 'map_store',
  initialState,
  reducers: {
    restore() {
      return initialState;
    },
    setAverageCanalFlows(state: DistributionState, action: PayloadAction<LastFlow[]>): void {
      state.averageCanalFlows = action.payload;
    },
    setIrrigationName(state: DistributionState, action: PayloadAction<string | null>): void {
      state.irrigationName = action.payload;
    },
    setStartDate(state: DistributionState, action: PayloadAction<string | null>): void {
      state.startDate = action.payload;
    },
    setStartTime(state: DistributionState, action: PayloadAction<string | null>): void {
      state.startTime = action.payload;
    },
    setTotalFlow(state: DistributionState, action: PayloadAction<number | null>): void {
      state.totalFlow = action.payload;
    },
    setShowSendConfirmation(state: DistributionState, action: PayloadAction<boolean>): void {
      state.showSendConfirmation = action.payload;
    },
    setSendDistributionLoading(state: DistributionState, action: PayloadAction<boolean>): void {
      state.sendDistributionLoading = action.payload;
    },
    setCurrentCanalNote(state: DistributionState, action: PayloadAction<string | null>): void {
      state.currentCanalNote = action.payload;
    },
    setCurrentCanalFlow(state: DistributionState, action: PayloadAction<string | null>): void {
      state.currentCanalFlow = action.payload;
    },
    setDistributionNote(state: DistributionState, action: PayloadAction<string | null>): void {
      state.distributionNote = action.payload;
    },
    setCanals(state: DistributionState, action: PayloadAction<Canal[] | null>): void {
      state.canals = action.payload;
    },
    setSubmitError(state: DistributionState, action: PayloadAction<string | null>): void {
      state.submitError = action.payload;
    },
    setDistributionSelectedIrrigation(state, action: PayloadAction<Irrigation | null>) {
      state.selectedIrrigation = action.payload;
    },
  },
});

const { actions, reducer } = slice;

export const {
  restore: resetDistributionStore,
  setAverageCanalFlows,
  setIrrigationName,
  setStartDate,
  setStartTime,
  setTotalFlow,
  setShowSendConfirmation,
  setSendDistributionLoading,
  setCurrentCanalNote,
  setCurrentCanalFlow,
  setDistributionNote,
  setCanals,
  setSubmitError,
  setDistributionSelectedIrrigation,
} = actions;
export default reducer;
