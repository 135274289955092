import { useAnalyticsEventTracker } from '@/gaEvents';
import { useAuth } from '@/hooks';
import { useDispatch, useSelector } from '@/store';
import { TelemetryKey, toggleTelemetry } from '../../../slices/devicesAnalysisSlice';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { type FC } from 'react';
import CheckboxList from './CheckboxList';
import CheckboxListItem from './CheckboxListItem';
import { MetricField, MetricUnit } from '@/__generated__/graphql';
import { useLingui } from '@lingui/react';
import { msg } from '@lingui/macro';
import { DistanceUnit, FlowUnit, getUnit } from 'src/utils/units';

const SidebarTelemetryCheckboxList: FC = () => {
  const { _, i18n } = useLingui();
  const { user } = useAuth();
  const { checkBoxEvent, selectorEvent } = useAnalyticsEventTracker('Station Analysis');
  const { telemetry } = useSelector((state) => state.devices_analysis_store);
  const dispatch = useDispatch();

  return (
    <CheckboxList>
      {telemetry.availableFields.includes(MetricField.Flow) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.Flow]}
          onClick={() => {
            checkBoxEvent({ label: 'flow', value: !telemetry[TelemetryKey.Flow] });
            dispatch(
              toggleTelemetry({
                [TelemetryKey.Flow]: !telemetry[TelemetryKey.Flow],
                ...(telemetry[TelemetryKey.Flow] && {
                  [TelemetryKey.FlowAlerts]: false,
                  [TelemetryKey.DistributionAlerts]: false,
                }),
              }),
            );
          }}
          label={_(msg`Caudal`)}
          secondaryAction={
            <FormControl size="small">
              <Select
                value={telemetry[TelemetryKey.FlowUnit]}
                onChange={(event: SelectChangeEvent) => {
                  const value = event.target.value as FlowUnit;
                  selectorEvent({ label: 'unit-flow', value });
                  dispatch(toggleTelemetry({ [TelemetryKey.FlowUnit]: value }));
                }}
              >
                <MenuItem value={MetricUnit.LiterPerSecond}>
                  {getUnit(MetricUnit.LiterPerSecond, i18n)?.short}
                </MenuItem>
                <MenuItem value={MetricUnit.CubicMeterPerSecond}>
                  {getUnit(MetricUnit.CubicMeterPerSecond, i18n)?.short}
                </MenuItem>
                <MenuItem value={MetricUnit.CubicMeterPerHour}>
                  {getUnit(MetricUnit.CubicMeterPerHour, i18n)?.short}
                </MenuItem>
              </Select>
            </FormControl>
          }
        />
      )}
      {telemetry.availableFields.includes(MetricField.Level) && (
        <CheckboxListItem
          checked={telemetry.level}
          onClick={() => {
            checkBoxEvent({ label: 'level', value: !telemetry.level });
            dispatch(
              toggleTelemetry({
                [TelemetryKey.Level]: !telemetry.level,
                ...(telemetry.level && {
                  [TelemetryKey.LevelAlerts]: false,
                  [TelemetryKey.RatingCurvesLimits]: false,
                }),
              }),
            );
          }}
          label={_(
            msg({
              message: 'Nivel',
            }),
          )}
          secondaryAction={
            <FormControl size="small">
              <Select
                value={telemetry[TelemetryKey.LevelUnit]}
                onChange={(event: SelectChangeEvent) => {
                  const value = event.target.value as DistanceUnit;
                  selectorEvent({ label: 'unit-height', value });
                  dispatch(toggleTelemetry({ [TelemetryKey.LevelUnit]: value }));
                }}
              >
                <MenuItem value={MetricUnit.Centimeter}>
                  {getUnit(MetricUnit.Centimeter, i18n)?.short}
                </MenuItem>
                <MenuItem value={MetricUnit.Meter}>
                  {getUnit(MetricUnit.Meter, i18n)?.short}
                </MenuItem>
              </Select>
            </FormControl>
          }
        />
      )}
      {telemetry.availableFields.includes(MetricField.OriginalDistance) && (
        <CheckboxListItem
          checked={telemetry.originalDistance}
          onClick={() => {
            checkBoxEvent({ label: 'original-distance', value: !telemetry.originalDistance });
            dispatch(toggleTelemetry(TelemetryKey.OriginalDistance));
          }}
          label={_(msg`Distancia del sensor`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.SignalQuality) && user?.role?.id === 1 && (
        <CheckboxListItem
          checked={telemetry.signalQuality}
          onClick={() => {
            checkBoxEvent({ label: 'signal-quality', value: !telemetry.signalQuality });
            dispatch(toggleTelemetry(TelemetryKey.SignalQuality));
          }}
          label={_(msg`Calidad de señal`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.Velocity) && (
        <CheckboxListItem
          checked={telemetry.velocity}
          onClick={() => {
            checkBoxEvent({ label: 'velocity', value: !telemetry.velocity });
            dispatch(toggleTelemetry(TelemetryKey.Velocity));
          }}
          label={_(msg`Velocidad superficial`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.Position) && (
        <>
          <CheckboxListItem
            checked={telemetry.position}
            onClick={() => {
              checkBoxEvent({ label: 'position', value: !telemetry.position });
              dispatch(toggleTelemetry(TelemetryKey.Position));
            }}
            label={_(msg`Posición compuerta`)}
          />
          <CheckboxListItem
            checked={telemetry.upstreamLevel}
            onClick={() => {
              checkBoxEvent({ label: 'upstream-level', value: !telemetry.upstreamLevel });
              dispatch(toggleTelemetry(TelemetryKey.Upstream));
            }}
            label={_(msg`Nivel aguas arriba`)}
          />
          <CheckboxListItem
            checked={telemetry.downstreamLevel}
            onClick={() => {
              checkBoxEvent({ label: 'downstream-level', value: !telemetry.downstreamLevel });
              dispatch(toggleTelemetry(TelemetryKey.Downstream));
            }}
            label={_(msg`Nivel aguas abajo`)}
          />
        </>
      )}
      {telemetry.availableFields.includes(MetricField.Angle) && (
        <CheckboxListItem
          checked={telemetry.angle}
          onClick={() => {
            checkBoxEvent({ label: 'angle', value: !telemetry.angle });
            dispatch(toggleTelemetry(TelemetryKey.Angle));
          }}
          label={_(msg`Movimiento radial`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.Temperature) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.Temperature]}
          onClick={() => {
            checkBoxEvent({ label: 'temperature', value: !telemetry[TelemetryKey.Temperature] });
            dispatch(toggleTelemetry(TelemetryKey.Temperature));
          }}
          label={_(msg`Temperatura`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.Conductivity) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.Conductivity]}
          onClick={() => {
            checkBoxEvent({ label: 'conductivity', value: !telemetry[TelemetryKey.Conductivity] });
            dispatch(toggleTelemetry(TelemetryKey.Conductivity));
          }}
          label={_(msg`Conductividad`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.Ph) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.Ph]}
          onClick={() => {
            checkBoxEvent({ label: 'ph', value: !telemetry[TelemetryKey.Ph] });
            dispatch(toggleTelemetry(TelemetryKey.Ph));
          }}
          label={_(msg`pH`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.Orp) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.Orp]}
          onClick={() => {
            checkBoxEvent({ label: 'orp', value: !telemetry[TelemetryKey.Orp] });
            dispatch(toggleTelemetry(TelemetryKey.Orp));
          }}
          label={_(msg`Potencial reducción oxidación (ORP)`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.DissolvedOxygen) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.DissolvedOxygen]}
          onClick={() => {
            checkBoxEvent({
              label: 'dissolved-oxygen-mg-l',
              value: !telemetry[TelemetryKey.DissolvedOxygen],
            });
            dispatch(toggleTelemetry(TelemetryKey.DissolvedOxygen));
          }}
          label={_(msg`Oxígeno disuelto (mg/L)`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.DissolvedOxygenSaturation) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.DissolvedOxygenSaturation]}
          onClick={() => {
            checkBoxEvent({
              label: 'dissolved-oxygen-saturation',
              value: !telemetry[TelemetryKey.DissolvedOxygenSaturation],
            });
            dispatch(toggleTelemetry(TelemetryKey.DissolvedOxygenSaturation));
          }}
          label={_(msg`Oxígeno disuelto saturación (%)`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.TurbidityNtu) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.TurbidityNtu]}
          onClick={() => {
            checkBoxEvent({ label: 'turbidity-ntu', value: !telemetry[TelemetryKey.TurbidityNtu] });
            dispatch(toggleTelemetry(TelemetryKey.TurbidityNtu));
          }}
          label={_(msg`Turbidez (NTU)`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.TurbidityFnu) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.TurbidityFnu]}
          onClick={() => {
            checkBoxEvent({ label: 'turbidity-ftu', value: !telemetry[TelemetryKey.TurbidityFnu] });
            dispatch(toggleTelemetry(TelemetryKey.TurbidityFnu));
          }}
          label={_(msg`Turbidez (FNU)`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.SnowLevel) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.SnowLevel]}
          onClick={() => {
            checkBoxEvent({ label: 'snow-level', value: !telemetry[TelemetryKey.SnowLevel] });
            dispatch(toggleTelemetry(TelemetryKey.SnowLevel));
          }}
          label={_(msg`Nivel de nieve`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.SnowTemperature) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.SnowTemperature]}
          onClick={() => {
            checkBoxEvent({
              label: 'snow-temperature',
              value: !telemetry[TelemetryKey.SnowTemperature],
            });
            dispatch(toggleTelemetry(TelemetryKey.SnowTemperature));
          }}
          label={_(msg`Temperatura`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.SnowRadiation) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.SnowRadiation]}
          onClick={() => {
            checkBoxEvent({
              label: 'snow-radiation',
              value: !telemetry[TelemetryKey.SnowRadiation],
            });
            dispatch(toggleTelemetry(TelemetryKey.SnowRadiation));
          }}
          label={_(msg`Radiación`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.SnowWaterEquivalent) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.SnowWaterEquivalent]}
          onClick={() => {
            checkBoxEvent({
              label: 'snow-water-equivalent',
              value: !telemetry[TelemetryKey.SnowWaterEquivalent],
            });
            dispatch(toggleTelemetry(TelemetryKey.SnowWaterEquivalent));
          }}
          label={_(msg`Equivalente de agua de nieve`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.SnowWeight) && (
        <CheckboxListItem
          checked={telemetry[TelemetryKey.SnowWeight]}
          onClick={() => {
            checkBoxEvent({
              label: 'snow-weight',
              value: !telemetry[TelemetryKey.SnowWeight],
            });
            dispatch(toggleTelemetry(TelemetryKey.SnowWeight));
          }}
          label={_(msg`Masa de nieve`)}
        />
      )}
      {telemetry.availableFields.includes(MetricField.ConnectionState) && (
        <CheckboxListItem
          checked={telemetry.connectionState}
          onClick={() => {
            checkBoxEvent({ label: 'connection', value: !telemetry.connectionState });
            dispatch(toggleTelemetry(TelemetryKey.Connection));
          }}
          label={_(msg`Estados de conexión`)}
        />
      )}
    </CheckboxList>
  );
};

export default SidebarTelemetryCheckboxList;
