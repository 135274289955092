import { gql } from '@apollo/client';

const UPDATE_DGA_TICKET_INFORMATION_FOR_DEVICE = gql`
  mutation UpdateDGATicketInformationForDevice(
    $deviceId: String!
    $ticketID: String!
    $ticketTimestamp: Float!
  ) {
    UpdateDGATicketInformationForDevice(
      deviceId: $deviceId
      ticketID: $ticketID
      ticketTimestamp: $ticketTimestamp
    ) {
      message
      success
    }
  }
`;

export default UPDATE_DGA_TICKET_INFORMATION_FOR_DEVICE;
