import { Box, CircularProgress, Typography } from '@mui/material';

export const Loading = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height={200}
    >
      <Typography
        variant="h5"
        color="textSecondary"
        sx={{
          mb: 2,
        }}
      >
        Cargando...
      </Typography>
      <CircularProgress />
    </Box>
  );
};
