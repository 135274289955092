import { Box, SelectChangeEvent, Skeleton } from '@mui/material';
import { type FC, useState, Suspense } from 'react';
import { TimeFormatSelector } from './configurationComponents';
import { useMutation } from '@apollo/client';
import UPDATE_ORGANIZATION_PREFERENCES from '@/graphql/mutations/updateOrganizationPreferences';
import { useModalStatusMessage } from 'src/hooks';
import { LoadingButton } from '@mui/lab';
import GET_ORGANIZATION_PREFERENCES from 'src/graphql/querys/getOrganizationPreferences';

interface Props {
  organizationId: number;
}

interface Values {
  hour12: boolean | null | undefined;
}

const defaultValues: Values = {
  hour12: undefined,
};

const QueryOrganizationSetting: FC<Props> = ({ organizationId }) => {
  const [values, setValues] = useState<Values>(defaultValues);
  const { openModalSuccessMessage, openModalErrorMessage } = useModalStatusMessage();
  const [updateOrganizationPreferences, { loading }] = useMutation(
    UPDATE_ORGANIZATION_PREFERENCES,
    {
      onCompleted: () => {
        openModalSuccessMessage('Configuración guardada correctamente');
      },
      onError: (error) => {
        openModalErrorMessage(`Error al guardar la configuración: ${error.message}`);
      },
      refetchQueries: [GET_ORGANIZATION_PREFERENCES],
    },
  );

  const handleTimeFormatChange = (event: SelectChangeEvent<string>) => {
    const newTimeFormatString = event.target.value;
    if (newTimeFormatString === 'auto') {
      setValues({ ...values, hour12: null });
    }
    if (newTimeFormatString === 'hours12') {
      setValues({ ...values, hour12: true });
    }
    if (newTimeFormatString === 'hours24') {
      setValues({ ...values, hour12: false });
    }
  };

  const handleSaveOnClick = () => {
    updateOrganizationPreferences({
      variables: { input: { organizationId, hour12: values.hour12 } },
    });
  };

  return (
    <Box>
      <Box>
        <Suspense fallback={<Skeleton variant="rounded" width="100%" height={40} />}>
          <TimeFormatSelector
            value={
              typeof values.hour12 === 'boolean'
                ? values.hour12
                  ? 'hours12'
                  : 'hours24'
                : values.hour12 === null
                ? 'auto'
                : undefined
            }
            onChange={handleTimeFormatChange}
          />
        </Suspense>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <LoadingButton loading={loading} onClick={handleSaveOnClick} variant="contained">
          Guardar
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default QueryOrganizationSetting;
