export type SourceType = 'support' | 'device' | 'device-alerts' | 'all' | 'cmd' | 'pipeline' | 'sat';

export const sourceTypes = [
  { label: 'Capta Hydro', value: 'support' },
  { label: 'Dispositivo', value: 'device' },
  { label: 'Alertas', value: 'device-alerts' },
  { label: 'Comandos', value: 'cmd' },
  { label: 'Pipeline', value: 'pipeline'},
  { label: 'Satelital', value: 'sat'},
  { label: 'Todos', value: 'all' },
];

export type GroupedEvent = { 
  value: string,
  count: number,
}