import { MetricField, MetricUnit } from '@/__generated__/graphql';
import { type I18n } from '@lingui/core';
import { getUnitTranslation } from './getUnitTranslation';

export const getUnitsPerMetric = (metric: MetricField, i18n: I18n) => {
  if (metric === MetricField.Flow)
    return [
      {
        value: 'liter-per-second',
        enumValue: MetricUnit.LiterPerSecond,
        label: getUnitTranslation('liter-per-second', 'short', i18n),
      },
      {
        value: 'cubic-meter-per-second',
        enumValue: MetricUnit.CubicMeterPerSecond,
        label: getUnitTranslation('cubic-meter-per-second', 'short', i18n),
      },
      {
        value: 'cubic-meter-per-hour',
        enumValue: MetricUnit.CubicMeterPerHour,
        label: getUnitTranslation('cubic-meter-per-hour', 'short', i18n),
      },
    ];
  if (
    metric === MetricField.Level ||
    metric === MetricField.OriginalDistance ||
    metric === MetricField.Position
  )
    return [
      {
        value: 'millimeter',
        enumValue: MetricUnit.Millimeter,
        label: getUnitTranslation('millimeter', 'short', i18n),
      },
      {
        value: 'centimeter',
        enumValue: MetricUnit.Centimeter,
        label: getUnitTranslation('centimeter', 'short', i18n),
      },
      {
        value: 'meter',
        enumValue: MetricUnit.Meter,
        label: getUnitTranslation('meter', 'short', i18n),
      },
    ];
  if (metric === MetricField.Angle)
    return [
      {
        value: 'degree',
        enumValue: MetricUnit.Degree,
        label: getUnitTranslation('degree', 'short', i18n),
      },
    ];
  if (metric === MetricField.Velocity)
    return [
      {
        value: 'centimeter-per-second',
        enumValue: MetricUnit.CentimeterPerSecond,
        label: getUnitTranslation('centimeter-per-second', 'short', i18n),
      },
      {
        value: 'millimeter-per-second',
        enumValue: MetricUnit.MillimeterPerSecond,
        label: getUnitTranslation('millimeter-per-second', 'short', i18n),
      },
    ];
  if (
    metric === MetricField.AccumulatedVolume ||
    metric === MetricField.VolumeMonth ||
    metric === MetricField.VolumeDay ||
    metric === MetricField.VolumeHour
  )
    return [
      {
        value: 'liter',
        enumValue: MetricUnit.Liter,
        label: getUnitTranslation('liter', 'short', i18n),
      },
      {
        value: 'cubic-meter',
        enumValue: MetricUnit.CubicMeter,
        label: getUnitTranslation('cubic-meter', 'short', i18n),
      },
    ];

  return [];
};
