import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { ListItemText, IconButton, Menu, MenuItem } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import GateDialogComponents from './dialogs/GateDialogComponents';
import { DialogEnum } from './DialogEnum';
import GearIcon from 'src/icons/amaruNewIcons/GearIcon';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import GET_GATE_OPTIONS_DATA from './getGateOptionsData';
import GET_ACCESS_GATE_OPTIONS from 'src/graphql/querys/access/getAccessGateOptions';
import { useLazyQuery } from '@apollo/client';
import Loading from '../Loading';

interface Props {
  gateId: string;
  reloadData: () => void;
  buttonSize?: 'small' | 'medium' | 'large';
  newIcon: boolean;
}

const GateOptions: FC<Props> = ({ gateId, reloadData, buttonSize = 'large', newIcon = false }) => {
  const { _ } = useLingui();
  const [fetch, { data, called }] = useLazyQuery(GET_GATE_OPTIONS_DATA, {
    variables: {
      input: {
        id: gateId,
      },
    },
  });

  const [ fetchAccess, {data: dataAccess, called: calledAccess} ] = useLazyQuery(GET_ACCESS_GATE_OPTIONS, {
    variables: {
      input: {
        deviceId: gateId,
      },
    },
  });
  
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [shouldRenderDialogs, setShouldRenderDialogs] = useState<boolean>(false);
  const [dialogToShow, setDialogToShow] = useState<DialogEnum>(DialogEnum.null);

  useEffect(() => {
    if (!called) {
      fetch();
    }
  }, [called, openMenu]);

  useEffect(() => {
    if (!calledAccess) {
      fetchAccess()
    }
  }, [calledAccess, openMenu]);

  const gateOLD = useMemo(() => {
    if (!data?.gate) return null;
    const gate = data.gate;
    return {
      id: gate.id,
      name: gate.mainDevice.profile.name,
      has_alarm: gate.hasAlarm,
      alarm_armed: gate.hasAlarm,
      status: gate.mainDevice.profile.status,
      parent_component_id: gate.mainDevice.profile.componentID,
      serie: gate.mainDevice.profile.serie,
      icn_id: gate.mainDevice.profile.irrigation?.id,
      organization_name: gate.mainDevice.profile.organization?.name,
      type: gate.mainDevice.profile.type,
      logical_device_id: gate.mainDevice.profile.logicalDeviceID,
      alarm_data: gate.alarmData,
    };
  }, [data]);

  useEffect(() => {
    if (openMenu) {
      setShouldRenderDialogs(true);
    }
  }, [openMenu]);

  const handleMenuOpen = (): void => {
    setOpenMenu(true);
  };

  const handleMenuClose = (): void => {
    setOpenMenu(false);
  };

  const handleShowEdit = () => {
    setDialogToShow(DialogEnum.edit);
    handleMenuClose();
  };

  const handleShowUpdateAlarm = () => {
    setDialogToShow(DialogEnum.updateAlarm);
    handleMenuClose();
  };

  const handleChangeStatusDevice = () => {
    setDialogToShow(DialogEnum.changeStatus);
    handleMenuClose();
  };

  const handleOpenMovementHistory = () => {
    setDialogToShow(DialogEnum.gateMovementHistory);
    handleMenuClose();
  };

  const handleShowDelete = () => {
    setDialogToShow(DialogEnum.delete);
    handleMenuClose();
  };

  const handleShowUserList = () => {
    setDialogToShow(DialogEnum.userList);
    handleMenuClose();
  };

  const handleDialogClose = (shouldRefetch: boolean) => {
    if (shouldRefetch) {
      reloadData();
    }
    setDialogToShow(DialogEnum.null);
    setShouldRenderDialogs(false);
  };

  return (
    <>
      {newIcon ? (
        <IconButton size={buttonSize} onClick={handleMenuOpen} ref={anchorRef} color="info">
          <GearIcon fontSize={buttonSize} />
        </IconButton>
      ) : (
        <IconButton color="info" onClick={handleMenuOpen} ref={anchorRef} size={buttonSize}>
          <SettingsIcon fontSize={buttonSize} />
        </IconButton>
      )}
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={handleMenuClose}
        open={openMenu}
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: 280,
          },
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        {!data ? (
          <Loading />
        ) : (
          <>
            {(dataAccess?.authorization.authorizationDevice?.updateState) && (
              <MenuItem onClick={handleChangeStatusDevice}>
                <ListItemText primary={_(msg`Cambio de estado`)} />
              </MenuItem>
            )}
            {dataAccess?.authorization.authorizationDevice?.update && (
              <MenuItem onClick={handleShowEdit}>
                <ListItemText primary={_(msg`Editar`)} />
              </MenuItem>
            )}
            {(dataAccess?.authorization.authorizationDevice?.updateAlarm) && data?.gate.hasAlarm && (
              <MenuItem onClick={handleShowUpdateAlarm}>
                <ListItemText primary={_(msg`Editar datos de alarma`)} />
              </MenuItem>
            )}
            {dataAccess?.authorization.authorizationDevice?.delete && (
              <MenuItem onClick={handleShowDelete}>
                <ListItemText primary={_(msg`Eliminar`)} />
              </MenuItem>
            )}
            {(dataAccess?.authorization.authorizationDevice?.viewUserAccess) && (
              <MenuItem onClick={handleShowUserList}>
                <ListItemText primary={_(msg`Usuarios con accesos y alertas`)} />
              </MenuItem>
            )}
            <MenuItem onClick={handleOpenMovementHistory}>
              <ListItemText primary={_(msg`Historial de movimientos`)} />
            </MenuItem>
          </>
        )}
      </Menu>
      {shouldRenderDialogs && gateOLD && (
        <GateDialogComponents
          dialogToShow={dialogToShow}
          handleDialogClose={handleDialogClose}
          gate={gateOLD}
        />
      )}
    </>
  );
};

export default GateOptions;
