import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';

interface Props {
  loading?: boolean;
  closeFunction: () => void;
  actionFunction: () => void;
  actionButtonText: string;
  actionButtonColor?: ActionButtonColor;
  disabledAction?: boolean;
}

type ActionButtonColor =
  | 'info'
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'warning';

const ActionButtons = ({
  loading = false,
  closeFunction,
  actionFunction,
  actionButtonText,
  actionButtonColor,
  disabledAction = false,
}: Props) => {
  return (
    <Box display="flex" flexDirection="row" justifyContent="flex-end">
      <Button color="info" variant="outlined" sx={{ mr: 2 }} onClick={closeFunction}>
        <Trans>Cancelar</Trans>
      </Button>
      <LoadingButton
        loading={loading}
        color={actionButtonColor}
        variant="contained"
        onClick={actionFunction}
        disabled={disabledAction}
      >
        {actionButtonText}
      </LoadingButton>
    </Box>
  );
};

export default ActionButtons;
