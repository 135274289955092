import { FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { ParametersKey, setParameters } from '../../../slices/devicesAnalysisSlice';
import { useDispatch, useSelector } from '@/store';
import { type FC } from 'react';
import { useAnalyticsEventTracker } from '@/gaEvents';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

const selectOptions = [
  {
    value: 1 * 60 * 1000,
    label: msg`1 minuto`,
  },
  {
    value: 2 * 60 * 1000,
    label: msg`2 minutos`,
  },
  {
    value: 5 * 60 * 1000,
    label: msg`5 minutos`,
  },
  {
    value: 30 * 60 * 1000,
    label: msg`30 minutos`,
  },
  {
    value: 60 * 60 * 1000,
    label: msg`1 hora`,
  },
];

const SidebarPollingInterval: FC = () => {
  const { _ } = useLingui();
  const dispatch = useDispatch();
  const {
    parameters: { pollingInterval },
  } = useSelector((state) => state.devices_analysis_store);
  const { selectorEvent } = useAnalyticsEventTracker('Station Analysis');

  return (
    <FormControl fullWidth>
      <InputLabel id="time-range-select-label">
        <Trans>Intervalo de actualización automática</Trans>
      </InputLabel>
      <Select
        label={_(msg`Intervalo de actualización automática`)}
        labelId="time-range-select-label"
        variant="outlined"
        value={pollingInterval}
        onChange={(e) => {
          const value = Number(e.target.value);
          selectorEvent({ label: 'reload-interval', value });
          dispatch(
            setParameters({
              [ParametersKey.PollingInterval]: value,
            }),
          );
        }}
        fullWidth
        size="small"
      >
        {selectOptions.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {_(item.label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SidebarPollingInterval;
