import { Trans } from '@lingui/macro';
import { Card, CircularProgress, Grid, Typography } from '@mui/material';

const GridLoading = () => {
  return (
    <Grid
      item
      xs={12}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={5}
    >
      <Typography
        variant="h5"
        sx={{
          mb: 2,
        }}
      >
        <Trans>Cargando...</Trans>
      </Typography>
      <CircularProgress />
    </Grid>
  );
};

const TableLoading = ({ withCard = true }: { withCard?: boolean }) => {
  return withCard ? (
    <Card>
      <GridLoading />
    </Card>
  ) : (
    <GridLoading />
  );
};

export default TableLoading;
