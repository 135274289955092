import { gql } from '@/__generated__';

const GET_USER_PREFERENCES = gql(`#graphql
  query GetUserPreferences {
    user {
      id
      preferences {
        id
        region {
          hour12
          id
          locale
          numberLocale
          timezone
          dateLocale
          dateMonthFormat
        }
      }
    }
  }
`);

export default GET_USER_PREFERENCES;
