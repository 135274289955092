import ECharts from '@features/echarts/components/ECharts';
import { type FC, useMemo } from 'react';
import { EChartsOptionBuilder } from '@features/echarts';
import {
  connectionStateMarkAreaSerie,
  flowAlertsSeries,
  levelAlertsSeries,
} from '@features/echarts/utils/commonSeries';
import { type DevicesAnalysisDevice } from '../DevicesAnalysis';
import { useSelector } from '@/store';
import { relativeTimeOptions } from '../../../standardDesign/components/DateTimeRangePicker';
import { useLingui } from '@lingui/react';
import { msg } from '@lingui/macro';
import { useFormatters } from '@/hooks/useFormatters';
import { getUnit } from '@/utils/units';
import { MetricUnit } from '@/__generated__/graphql';

interface Props {
  devices: DevicesAnalysisDevice[];
}

const serieOptions = {
  smooth: true,
  symbol: 'circle',
} as const;

const Chart: FC<Props> = ({ devices }) => {
  const { parameters, telemetry } = useSelector((state) => state.devices_analysis_store);
  const { _, i18n } = useLingui();
  const formatters = useFormatters();

  const option = useMemo(() => {
    const relativeNumericValue = relativeTimeOptions.find(
      (option) => option.value === parameters.start,
    )?.numericValue;

    const min = relativeNumericValue != null ? Date.now() + relativeNumericValue : parameters.start;

    const yAxisUnits = devices.reduce(
      (acc, device) => {
        for (const key of Object.keys(device.metrics) as Array<keyof typeof device.metrics>) {
          if (key === '__typename' || key === 'availableFields' || key === 'events' || key === 'id')
            continue;
          const metric = device.metrics[key];
          if (metric == null) continue;
          if (metric.unit && !acc.some((unit) => unit.unit === metric.unit)) {
            acc.push({ unit: metric.unit, precision: metric.precision });
          }
        }
        return acc;
      },
      [] as { unit: MetricUnit; precision: number }[],
    );

    const option = new EChartsOptionBuilder({
      brush: {
        horizontal: true,
      },
      override: (option) => ({
        ...option,
        grid: {
          left: 0,
          right: 20,
          containLabel: true,
        },
      }),
    });

    for (const { unit, precision } of yAxisUnits) {
      option.addYAxis(
        {
          id: unit,
          type: 'value',
          unit: getUnit(unit, i18n)?.short,
          precision,
        },
        (yAxis) => ({
          ...yAxis,
          ...(unit === MetricUnit.Percent
            ? {
                min: 0,
                max: 100,
              }
            : {}),
          ...(unit === MetricUnit.None
            ? {
                show: false,
                axisLabel: {
                  show: false,
                },
                axisPointer: {
                  type: 'none',
                  show: false,
                },
              }
            : {}),
        }),
      );
    }

    option
      .addXAxis(
        {
          id: 'time',
          type: 'time',
        },
        (xAxis) => ({
          ...xAxis,
          min: min,
          max: parameters.stop ?? Date.now(),
        }),
      )
      .addXAxis(
        {
          id: 'category',
          type: 'category',
        },

        (xAxis) => ({
          ...xAxis,
          axisPointer: {
            type: 'shadow',
          },
        }),
      )
      .addYAxis(
        {
          id: 'events',
          type: 'category',
        },
        (yAxis) => ({
          ...yAxis,
          show: false,
          axisLabel: {
            show: false,
          },
          axisPointer: {
            type: 'none',
            show: false,
          },
        }),
      )
      .addYAxis({
        id: 'aggregatedEvents',
        type: 'value',
      });

    devices.forEach((device) => {
      const serieLegendPrefix = devices.length > 1 ? `${device.profile.name} - ` : '';
      if (device.metrics.connectionState.data)
        option.addSeries([
          connectionStateMarkAreaSerie({
            serieName: `${serieLegendPrefix}${_(msg`Estado de conexión`)}`,
            connectionState: device.metrics.connectionState.data,
            yAxisId: device.metrics.connectionState.unit,
            xAxisId: 'time',
            i18n,
          }),
        ]);
      option
        .addSeries(
          flowAlertsSeries({
            namePrefix: serieLegendPrefix,
            flow: device.alerts.basicConfig.flow,
            distributionMaxFlow: device.alerts.basicConfig.distributionLimits?.maxFlow ?? null,
            unit: device.metrics.flow.unit,
            precision: device.metrics.flow.precision,
            xAxisId: 'time',
            yAxisId: device.metrics.flow.unit,
            i18n,
            formatters,
          }),
        )
        .addSeries(
          levelAlertsSeries({
            namePrefix: serieLegendPrefix,
            level: device.alerts.basicConfig.level,
            ratingCurvesMax: device.dataConfiguration?.ratingCurves?.limits.max,
            ratingCurvesMin: device.dataConfiguration?.ratingCurves?.limits.min,
            unit: device.metrics.level.unit,
            precision: device.metrics.level.precision,
            xAxisId: 'time',
            yAxisId: device.metrics.level.unit,
            i18n,
            formatters,
          }),
        );

      option.addSeries([
        {
          meta: {
            name: `${serieLegendPrefix}${_(msg`Volumen hora`)}`,
            type: 'bar',
            data: device.metrics.volumeHour.data?.map((point) => [
              formatters.formatDateTime(point[0]),
              point[1],
            ]),
            dataType: 'volumeHour',
            yAxisId: device.metrics.volumeHour.unit,
            xAxisId: 'category',
          },
        },
        {
          meta: {
            name: `${serieLegendPrefix}${_(msg`Volumen día`)}`,
            type: 'bar',
            data: device.metrics.volumeDay.data?.map((point) => [
              formatters.formatDateTime(point[0]),
              point[1],
            ]),
            dataType: 'volumeDay',
            yAxisId: device.metrics.volumeDay.unit,
            xAxisId: 'category',
          },
        },
        {
          meta: {
            name: `${serieLegendPrefix}${_(msg`Volumen mes`)}`,
            type: 'bar',
            data: device.metrics.volumeMonth.data?.map((point) => [
              formatters.formatDateTime(point[0]),
              point[1],
            ]),
            dataType: 'volumeMonth',
            yAxisId: device.metrics.volumeMonth.unit,
            xAxisId: 'category',
          },
        },
        {
          meta: {
            name: `${serieLegendPrefix}${_(msg`Eventos`)}`,
            type: 'scatter',
            data:
              parameters.window == null
                ? device.metrics.events.data?.map((event) => [
                    event.timestamp,
                    event.value,
                    event.source,
                  ])
                : [],
            dataType: 'events',
            yAxisId: 'events',
            xAxisId: 'time',
          },
          value: (serie) => ({
            ...serie,
            z: 1000,
            tooltip: {
              ...serie.tooltip,
              trigger: 'item',
              formatter: (params) => {
                if (!Array.isArray(params.value)) return '';
                const [timestamp, value, source] = params.value as [
                  number,
                  number,
                  number | undefined,
                ];
                return `${formatters.formatDateTime(timestamp)}</br>${params.marker} ${
                  params.seriesName
                } &emsp; <b>${value}</b>&ensp;(<i>${_(
                  msg({
                    message: 'Fuente',
                    comment: 'Origen de un evento en un dispositivo',
                    context: 'source',
                  }),
                )}:</i> ${source ?? _(msg`Desconocido`)})`;
              },
            },
          }),
        },
        {
          meta: {
            name: `${serieLegendPrefix}${_(msg`Cantidad de eventos`)}`,
            type: 'bar',
            data:
              parameters.window != null
                ? device.metrics.events.data?.map((event) => [
                    formatters.formatDateTime(event.timestamp),
                    event.value,
                  ])
                : [],
            dataType: 'events',
            yAxisId: 'aggregatedEvents',
            xAxisId: 'category',
          },
        },
        ...(
          [
            ['flow', msg`Caudal`, 'area', msg`Caudal bruto`, 'line'],
            ['level', msg({ message: 'Nivel' }), 'line', msg`Nivel bruto`, 'line'],
            ['accumulatedVolume', msg`Volumen acumulado`, 'line'],
            ['position', msg`Abertura de compuerta`, 'line'],
            ['upstreamLevel', msg`Nivel aguas arriba`, 'line'],
            ['downstreamLevel', msg`Nivel aguas abajo`, 'line'],
            [
              'velocity',
              msg`Velocidad superficial`,
              'line',
              msg`Velocidad superficial bruta`,
              'line',
            ],
            ['angle', msg`Movimiento radial`, 'line'],
            ['originalDistance', msg`Distancia del sensor`, 'line'],
            ['signalQuality', msg`Calidad de señal`, 'line'],
            ['batterySoc', msg`Porcentaje Batería`, 'line'],
            ['batteryV', msg`Voltaje Batería`, 'line'],
            ['chargeI', msg`Corriente Carga`, 'line'],
            ['chargeP', msg`Potencia Carga`, 'line'],
            ['solarI', msg`Corriente Panel Solar`, 'line'],
            ['solarP', msg`Potencia Panel Solar`, 'line'],
            ['solarV', msg`Voltaje Panel Solar`, 'line'],
            ['temperature', msg`Temperatura`, 'line', msg`Temperatura bruta`, 'line'],
            ['conductivity', msg`Conductividad`, 'line', msg`Conductividad bruta`, 'line'],
            ['ph', msg`pH`, 'line', msg`pH bruto`, 'line'],
            [
              'oxidationReductionPotential',
              msg`Potencial de oxidación-reducción`,
              'line',
              msg`Potencial de oxidación-reducción bruto`,
              'line',
            ],
            ['turbidityNTU', msg`Turbidez NTU`, 'line', msg`Turbidez NTU bruta`, 'line'],
            ['turbidityFNU', msg`Turbidez FNU`, 'line', msg`Turbidez FNU bruta`, 'line'],
            ['dissolvedOxygen', msg`Oxígeno disuelto`, 'line', msg`Oxígeno disuelto bruto`, 'line'],
            [
              'dissolvedOxygenSaturation',
              msg`Saturación de oxígeno disuelto`,
              'line',
              msg`Saturación de oxígeno disuelto bruto`,
              'line',
            ],
            ['snowLevel', msg`Nivel de nieve`, 'line', msg`Nivel de nieve bruto`, 'line'],
            ['snowLevelNonCalibrated', msg`Distancia sensor`, 'line'],
            ['snowTemperature', msg`Temperatura`, 'line', msg`Temperatura bruta`, 'line'],
            ['snowRadiation', msg`Radiación`, 'line', msg`Radiación bruta`, 'line'],
            [
              'snowWaterEquivalent',
              msg`Equivalente de agua de nieve`,
              'line',
              msg`Equivalente de agua de nieve bruto`,
              'line',
            ],
            [
              'snowWaterEquivalentNonCalibrated',
              msg`Equivalente de agua de nieve sin calibrar`,
              'line',
            ],
            ['snowWeight', msg`Masa de nieve`, 'line'],
          ] as const
        ).flatMap(
          ([key, dataName, type, rawDataName, rawType]): Parameters<
            EChartsOptionBuilder['addSeries']
          >[0][number][] => {
            const series =
              rawDataName && rawType
                ? [['data', dataName, type] as const, ['rawData', rawDataName, rawType] as const]
                : [['data', dataName, type] as const];

            return series.map(([dataKey, dataName, type]) => ({
              meta: {
                name: `${serieLegendPrefix}${_(dataName)}`,
                type,
                data: device.metrics[key]?.[dataKey as 'data'] ?? [],
                dataType: key,
                yAxisId: device.metrics[key]?.unit,
                xAxisId: 'time',
              },
              value(serie) {
                return {
                  ...serie,
                  ...serieOptions,
                };
              },
            }));
          },
        ),
      ]);
    });

    return option;
  }, [devices, parameters, telemetry, formatters, i18n]);

  return <ECharts renderer="svg" option={option} />;
};

export default Chart;
