import { Close, Language, Straighten } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Skeleton,
  Tab,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from '@/store';
import { setModalPreferencesUserId } from '../slices/userPreferencesSlice';
import { Suspense, useState } from 'react';
import { Trans } from '@lingui/macro';
import RegionTab from './RegionTab';
import MetricsTab from './MetricsTab';

const UserPreferencesModal = () => {
  const tabs = [
    {
      id: 'language',
      label: (
        <Box display="flex" alignItems="center" gap={2}>
          <Language fontSize="small" />
          <Trans>Idioma y región</Trans>
        </Box>
      ),
      component: (
        <Suspense fallback={<Skeleton variant="rounded" height={400} />}>
          <RegionTab />
        </Suspense>
      ),
    },
    {
      id: 'units',
      label: (
        <Box display="flex" alignItems="center" gap={2}>
          <Straighten fontSize="small" />
          <Trans>Unidades de medida</Trans>
        </Box>
      ),
      component: (
        <Suspense fallback={<Skeleton variant="rounded" height={400} />}>
          <MetricsTab />
        </Suspense>
      ),
    },
  ];

  const [tab, setTab] = useState(tabs[0].id);
  const dispatch = useDispatch();
  const { modalPreferencesUserId } = useSelector((state) => state.user_preferences_store);

  const handleClose = () => {
    dispatch(setModalPreferencesUserId(null));
  };

  const handleChangeTab = (_: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Dialog maxWidth="md" fullWidth onClose={handleClose} open={modalPreferencesUserId !== null}>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
          <Close />
        </IconButton>
        <Trans>Preferencias de usuario</Trans>
      </DialogTitle>
      <Divider />
      <Box display="flex" overflow="hidden" minHeight={400}>
        <TabContext value={tab}>
          <Box
            sx={{
              my: '-1px',
              backgroundColor: '#343c47',
              flexShrink: 0,
              '& .MuiTabs-indicator': {
                width: '100%',
                backgroundColor: 'background.paper',
                borderLeft: '4px solid #eee',
                borderTop: 1,
                borderTopColor: 'divider',
                borderBottom: 1,
                borderBottomColor: 'divider',
                zIndex: 1,
              },
            }}
          >
            <TabList
              sx={{
                height: '100%',
                '& .MuiTabs-flexContainer': {
                  height: '100%',
                },
                '& .MuiButtonBase-root': {
                  flexGrow: 0,
                },
                '& .MuiTabs-flexContainer::after': {
                  content: '""',
                  display: 'block',
                  flexGrow: 1,
                  borderRight: 1,
                  borderRightColor: 'divider',
                },
              }}
              orientation="vertical"
              onChange={handleChangeTab}
              aria-label="Modal tabs"
            >
              {tabs.map(({ label, id }) => (
                <Tab
                  key={id}
                  label={
                    <Typography
                      zIndex={2}
                      variant="h6"
                      fontSize="16px !important"
                      textTransform="unset"
                    >
                      {label}
                    </Typography>
                  }
                  value={id}
                  sx={{
                    flexGrow: 1,
                    alignItems: 'start',
                    maxWidth: 'unset',
                    textTransform: 'unset',
                    borderRight: 1,
                    borderRightColor: 'divider',
                    '&.Mui-selected': {
                      color: 'white',
                    },
                  }}
                />
              ))}
            </TabList>
          </Box>
          {tabs.map(({ id, component }) => (
            <TabPanel sx={{ flexGrow: 1 }} key={id} value={id}>
              {component}
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    </Dialog>
  );
};

export default UserPreferencesModal;
