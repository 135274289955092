import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from 'react';

interface Props extends SvgIconProps {
  primaryColor?: string;
  secondaryColor?: string;
}

const StationMarkerIcon: FC<Props> = ({
  primaryColor,
  secondaryColor,
  ...rest
}) => (
  <SvgIcon {...rest} viewBox="0 0 32 32">
    <circle
      cx="16"
      cy="16"
      r="15.5"
      fill={primaryColor}
      stroke={secondaryColor}
      strokeWidth={1}
    />
    <path
      fill={secondaryColor}
      d="M11.6855 24.0674H14.814V21.116C14.1331 20.84 13.5691 20.3351 13.2187 19.6877C12.8683 19.0404 12.7533 18.291 12.8935 17.5679C13.0337 16.8449 13.4202 16.1932 13.9869 15.7247C14.5536 15.2562 15.2651 15 15.9995 15C16.7339 15 17.4454 15.2562 18.0121 15.7247C18.5788 16.1932 18.9653 16.8449 19.1055 17.5679C19.2456 18.291 19.1307 19.0404 18.7803 19.6877C18.4299 20.3351 17.8659 20.84 17.185 21.116V24.0695H20.3145C20.6289 24.0695 20.9304 24.1948 21.1528 24.4177C21.3751 24.6407 21.5 24.9432 21.5 25.2585C21.5 25.5739 21.3751 25.8764 21.1528 26.0993C20.9304 26.3223 20.6289 26.4476 20.3145 26.4476H11.6855C11.3711 26.4476 11.0696 26.3223 10.8472 26.0993C10.6249 25.8764 10.5 25.5739 10.5 25.2585C10.5 24.9432 10.6249 24.6407 10.8472 24.4177C11.0696 24.1948 11.3711 24.0695 11.6855 24.0695M16 17.3845C15.8434 17.3847 15.6904 17.4314 15.5603 17.5188C15.4302 17.6062 15.3289 17.7302 15.2691 17.8754C15.2092 18.0205 15.1936 18.1801 15.2242 18.3341C15.2548 18.4881 15.3302 18.6296 15.441 18.7406C15.5517 18.8517 15.6927 18.9273 15.8462 18.958C15.9998 18.9887 16.159 18.973 16.3037 18.913C16.4483 18.853 16.5721 18.7514 16.6592 18.6209C16.7463 18.4904 16.7929 18.3369 16.7931 18.1799C16.7928 17.969 16.7092 17.7669 16.5605 17.6178C16.4118 17.4686 16.2103 17.3847 16 17.3845Z"
    />
    <path
      fill={secondaryColor}
      d="M27.6189 10.2575C24.5032 7.20608 20.3366 5.5 16 5.5C11.6634 5.5 7.49685 7.20608 4.38109 10.2575C4.26531 10.365 4.17199 10.4948 4.10662 10.6393C4.04126 10.7838 4.00518 10.9401 4.00052 11.099C3.99586 11.2578 4.02271 11.416 4.07949 11.5642C4.13627 11.7124 4.22182 11.8476 4.3311 11.9617C4.44037 12.0759 4.57116 12.1668 4.71572 12.229C4.86029 12.2912 5.01571 12.3234 5.17281 12.3238C5.3299 12.3243 5.48548 12.2928 5.63037 12.2314C5.77525 12.17 5.9065 12.0798 6.01636 11.9662C8.69376 9.3445 12.2739 7.87875 16 7.87875C19.7261 7.87875 23.3062 9.3445 25.9836 11.9662C26.0935 12.0798 26.2247 12.17 26.3696 12.2314C26.5145 12.2928 26.6701 12.3243 26.8272 12.3238C26.9843 12.3234 27.1397 12.2912 27.2843 12.229C27.4288 12.1668 27.5596 12.0759 27.6689 11.9617C27.7782 11.8476 27.8637 11.7124 27.9205 11.5642C27.9773 11.416 28.0041 11.2578 27.9995 11.099C27.9948 10.9401 27.9587 10.7838 27.8934 10.6393C27.828 10.4948 27.7347 10.365 27.6189 10.2575Z"
    />
    <path
      fill={secondaryColor}
      d="M15.9995 10.5001C13.2641 10.4911 10.6047 11.3804 8.44728 13.0256C8.20132 13.2219 8.04359 13.5046 8.00777 13.8131C7.97195 14.1217 8.06089 14.4317 8.25558 14.677C8.45028 14.9223 8.73529 15.0833 9.04974 15.1258C9.36419 15.1682 9.68314 15.0887 9.93846 14.9042C11.6735 13.59 13.8057 12.8769 16 12.8769C18.1943 12.8769 20.3265 13.59 22.0615 14.9042C22.3169 15.0887 22.6358 15.1682 22.9503 15.1258C23.2647 15.0833 23.5497 14.9223 23.7444 14.677C23.9391 14.4317 24.028 14.1217 23.9922 13.8131C23.9564 13.5046 23.7987 13.2219 23.5527 13.0256C21.395 11.3802 18.7353 10.4908 15.9995 10.5001Z"
    />
  </SvgIcon>
);

export default StationMarkerIcon;
