import { msg, Trans } from '@lingui/macro';
import { Edit } from '@mui/icons-material';
import { List, Typography, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import { type FC } from 'react';
import GET_USER_PROFILE_AUDIT_LOGS from '../graphql/queries/getUserProfileAuditLogs';
import { useSuspenseQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';
import { useFormatters } from 'src/hooks/useFormatters';
import { getDisplayNameActionType, getDisplayNameEntity } from '@features/audit/utils';

interface Props {
  userId: number;
}

const UserProfileAuditLogs: FC<Props> = ({ userId }) => {
  const { _, i18n } = useLingui();
  const { formatDateTime } = useFormatters();
  const { data, error } = useSuspenseQuery(GET_USER_PROFILE_AUDIT_LOGS, {
    errorPolicy: 'all',
    variables: {
      input: {
        id: userId,
      },
    },
  });

  if (error) {
    if (error.graphQLErrors.length === 1) {
      if (error.graphQLErrors[0].extensions?.code !== 'UNAUTHORIZED') {
        throw error;
      }
    } else {
      throw error;
    }
  }

  if (!data) return null;
  const { user } = data;

  if (user.auditLogs == null) return null;

  return (
    <>
      <Typography variant="h6" mt={1}>
        <Trans>Actividad reciente</Trans>
      </Typography>
      <List>
        {user.auditLogs.length === 0 ? (
          <ListItem>
            <ListItemText secondary={_(msg`No hay actividad reciente`)} />
          </ListItem>
        ) : (
          user.auditLogs.map((log) => (
            <ListItem key={log?.id} sx={{ py: 0 }}>
              <ListItemAvatar>
                <Avatar sx={{ width: 34, height: 34, bgcolor: 'primary.main' }}>
                  <Edit fontSize="small" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    <Trans>El usuario</Trans>{' '}
                    <Typography color="textSecondary" component="span">
                      <Trans>{getDisplayNameActionType(log.action.type, i18n)} en</Trans>
                    </Typography>{' '}
                    <Typography
                      component="span"
                      color="textSecondary"
                      sx={{
                        textDecoration: 'underline',
                        textDecorationStyle: 'dotted',
                        textDecorationColor: (theme) => theme.palette.text.secondary,
                      }}
                    >
                      {getDisplayNameEntity(log.entity.type, i18n)} {log.entity.id}
                    </Typography>
                  </>
                }
                secondary={formatDateTime(log.timestamp)}
              />
            </ListItem>
          ))
        )}
      </List>
    </>
  );
};

export default UserProfileAuditLogs;
